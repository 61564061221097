import cloneDeep from 'clone-deep'
import { SettingKey } from 'config'
import { ILoanGlobalSettings } from 'pages/Admin/AdminTools/Configuration/GlobalSettings'
import { useEffect, useState } from 'react'
import { updateSetting } from 'services'
import { Checkbox, Modal } from 'stories/components'
import { isEmpty } from 'utils'

import { TermSheetPlaceholders } from './constants'

interface IProps {
  globalSetting: ILoanGlobalSettings
  additionalPlaceholders?: Array<string>
  onClose: () => void
  setGlobalSetting: (data: ILoanGlobalSettings) => void
}
export const PickPlaceholdersUp = (props: IProps) => {
  const { globalSetting, onClose, setGlobalSetting } = props

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<string[]>([])

  const defautPlaceholders = ['BrokerFeeAmount', 'BrokerFeePercent', 'CommitmentFee']

  const placeholders = [...TermSheetPlaceholders, ...(props.additionalPlaceholders || [])]
  useEffect(() => {
    if (isEmpty(globalSetting)) return
    setData(globalSetting.showingTermSheetPlaceholders || [])
  }, [globalSetting])

  const onChange = (value: string) => {
    const newData = cloneDeep(data)

    const targetIndex = newData.findIndex((item) => item === value)

    if (targetIndex !== -1) newData.splice(targetIndex, 1)
    else newData.push(value)

    setData(newData)
  }

  const onSubmit = async () => {
    if (!globalSetting) return

    const newSettings = cloneDeep(globalSetting)
    newSettings.showingTermSheetPlaceholders = data.sort()

    setLoading(true)
    await updateSetting(SettingKey.GLOBAL_SETTINGS, JSON.stringify(newSettings))
    setGlobalSetting(newSettings)
    setLoading(false)
    onClose()
  }

  const onSelctAll = (value: boolean) => {
    let newData = cloneDeep(data)
    if (value) newData = placeholders
    else newData = defautPlaceholders
    setData(newData)
  }

  return (
    <Modal
      title={`Pick placeholders to display on the generate modal`}
      titleOkay="Save"
      loading={isLoading}
      isOpen={true}
      onClose={onClose}
      onOk={onSubmit}
    >
      <div className="w-full md:w-[70vw]">
        <Checkbox
          key="all"
          id="all"
          title="Check All"
          value={data.length === placeholders.length}
          onChange={(value) => onSelctAll(value)}
        />
        <hr className="my-2" />
        <div className="flex justify-start flex-wrap gap-x-4 gap-y-2">
          {placeholders.sort().map((item, index) => (
            <Checkbox
              key={`${index}`}
              id={`${index}`}
              title={item}
              size={3}
              value={data.includes(item)}
              disabled={defautPlaceholders.includes(item)}
              className="w-[210px]"
              onChange={() => onChange(item)}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}
