import { ClockIcon, ShareIcon } from '@heroicons/react/24/outline'
import { LoanStatusType } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { loanMoveToServicing, loanStatusChange } from 'services'
import { svgLoading } from 'stories/assets'
import { Select } from 'stories/components'
import { confirm, openAuditLog } from 'utils'

export const getLoanStatusChangeErrorMessage = (
  errors: Record<string, string[]>,
  loanStatus: string,
  loanGlobalStatus: any,
) => {
  let content: any = []
  ;[
    ['structure', 'Loan Structure'],
    ['application', 'Loan Application'],
    ['submission', 'Loan Submission'],
    ['overview', 'Loan Overview'],
    ['underwriting', 'Underwirting'],
    ['tasks', 'Tasks'],
    ['budget', 'Budget & Scope of Work'],
  ].map((item) => {
    const key = item[0]
    const label = item[1]
    if (errors[key].length) {
      content.push(
        <div className="mt-1">
          <div className="border-b border-red-200 mb-1">{label}</div>
          {errors[key].map((error: string) => {
            return <div className="ml-4">- {error}</div>
          })}
        </div>,
      )
    }
  })
  const contentElement = (
    <div className="text-left bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[14px] mb-4">
      <div className="font-semibold text-[14.5px] mb-2">
        Following Steps are required to change status to "{loanGlobalStatus[loanStatus]}"{' '}
      </div>
      {content}
    </div>
  )

  return contentElement
}

export function LoanStatus(props: any) {
  const { status, loanNumber, servicingPipeline, size = 5 } = props

  const isNewLoan = loanNumber === 'New'

  const [loading, setLoading] = useState(false)

  const { data: permissionData, hasPermission } = usePermissions()

  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])

  const options = useMemo(() => {
    let rlt: any = loanGlobalStatus
    if (!hasPermission('MANAGE_ALL_LOAN_STATUS')) {
      rlt = {
        [status]: rlt[status],
      }
      if (['withdrawn', 'funded'].indexOf(status) === -1) {
        rlt['withdrawn'] = 'Withdrawn'
      }
    }
    return rlt
  }, [permissionData])

  const canMoveToServicing = hasPermission('MOVE_LOAN_TO_SERVICING')

  const changeLoanDetail = async (value: string) => {
    if (isNewLoan) return
    if (value === LoanStatusType.RESTRUCTURERELEASE) {
      toast(`Please Update on 'Loan Progress Restrictions'`, { type: 'warning' })
      return
    }
    setLoading(true)
    try {
      const res = await loanStatusChange(value, loanNumber)
      if (res.success) {
        props.onChange(value)
      } else {
        const contentElement = getLoanStatusChangeErrorMessage(res.errors, value, loanGlobalStatus)

        const canStatusOverride = hasPermission('CAN_LOAN_STATUS_OVERRIDE')
        if (canStatusOverride) {
          const result = await confirm(contentElement, { titleYes: 'Override', titleNo: 'Close' })
          if (result) {
            const res = await loanStatusChange(value, loanNumber, true)
            if (res.success) props.onChange(value)
          }
        } else await confirm(contentElement, { titleYes: false, titleNo: 'Close' })
      }
    } catch {}
    setLoading(false)
  }

  const moveToServicing = async () => {
    if (isNewLoan) return
    const content = <div className="mb-4">Are you sure want to move this loan to Servicing?</div>
    const rlt = await confirm(content)
    if (!rlt) return
    setLoading(true)
    try {
      const res = await loanMoveToServicing(loanNumber)
      if (res.success) {
        props.onMoveServicing()
      }
    } catch {}
    setLoading(false)
  }

  const showHistory = () => {
    if (isNewLoan) return
    const options = {
      table: 'LoanProcess',
      field: 'loanStatus',
      keys: {
        field: 'loanStatus',
        loanNumber: loanNumber,
      },
    }
    openAuditLog(options)
  }

  let widthClass = 'w-44'
  let sizeClass = 'w-5 h-5'
  let selectSize = 5
  let moveServicingFont = 'font-semibold'
  if (size === 4) {
    widthClass = 'w-36'
    sizeClass = 'w-4 h-4'
    selectSize = 3
    moveServicingFont = 'font-normal'
  }

  return (
    <div className="LoanStatus-container">
      <div className="flex items-center">
        <div className={`${widthClass} -mb-4`}>
          <Select
            id={`loan-status-${loanNumber}`}
            size={selectSize}
            title=""
            options={options}
            disabled={loading}
            value={status}
            onChange={(value) => changeLoanDetail(value)}
          />
        </div>
        {loading && <img src={svgLoading} className={`inline ${sizeClass} ml-2 mt-2 mb-2 text-white animate-spin`} />}
        {!loading && (
          <span className="ml-2 text-gray-500 cursor-pointer">
            <ClockIcon className={sizeClass} onClick={showHistory} />
          </span>
        )}
      </div>
      {!servicingPipeline && canMoveToServicing && status === 'funded' && (
        <div
          className="ml-2 mt-1 gap-2 flex items-center text-[12.5px] italic hover:underline cursor-pointer"
          onClick={moveToServicing}
        >
          <span className={moveServicingFont}>Move to Servicing</span>
          <ShareIcon className="w-4 h-4"></ShareIcon>
        </div>
      )}
    </div>
  )
}
