import { setMenu } from 'actions'
import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAdminConfig } from 'services'
import { Modal } from 'stories/components'
import { getTextWidth, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    title: {
      inputType: 'text',
      type: 'text',
      title: 'Title',
      value: '',
      visible: true,
      required: true,
      span: 2,
    },
    link: {
      inputType: 'text',
      type: 'text',
      title: 'Link',
      value: '',
      visible: true,
      required: true,
      span: 2,
    },
    value: {
      inputType: 'Toggle',
      title: 'Visible',
      value: true,
      visible: true,
      required: true,
      span: 2,
    },
    internal: {
      inputType: 'Toggle',
      title: 'Internal',
      value: true,
      visible: false,
    },
  }
}

export const InterfaceAddSectionModal = (props: {
  onClose: any
  portal: string
  origin: Record<string, any>
  editData?: { key: string; data: any }
  isEdit?: boolean
}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    if (props.isEdit && props.editData) {
      const { data } = props.editData
      const temp = cloneDeep(inputs)
      Object.keys(inputs).map((key) => {
        let value = data[key]
        if (key === 'link')
          value = data[key] ? data[key].replace(data.internal ? window.location.origin : 'https://', '') : ''
        temp[key].value = value
      })
      setInputs(temp)
    }
  }, [props.isEdit, props.editData])

  const onChange = async (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value

    setLoading(false)
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}

    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      if (newInputs[key].error) hasError = true
      data[key] = newInputs[key].value
    }

    setInputs(newInputs)

    if (hasError) return

    setLoading(true)
    const temp = cloneDeep(props.origin)

    const newLink = `${data.internal ? '' : 'https://'}${data.link}`
    if (props.isEdit && props.editData) {
      const { key } = props.editData
      temp[props.portal][key] = {
        title: data.title,
        link: newLink,
        value: data.value,
      }
    } else {
      const key = data.title.toLowerCase().replace(/\s+/g, '_')
      temp[props.portal][key] = {
        title: data.title,
        link: newLink,
        value: data.value,
      }
    }

    setAdminConfig('interface', temp).then(() => {
      dispatch(setMenu(temp))
      setLoading(false)
      props.onClose()
    })
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal
      title={props.isEdit ? 'Edit Menu' : 'Add Menu'}
      titleOkay={'Save'}
      loading={loading}
      onClose={onClose}
      onOk={onSubmit}
      isOpen
    >
      <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          if (key === 'link') {
            input.additionalElements = (
              <select
                name={`show-Link-Directory`}
                id={`show-Link-Directory`}
                className="py-0 pl-1 pr-6 text-[12.5px] leading-[16px] border-gray-300"
                onChange={(e) => onChange('internal', e.target.value === 'true')}
              >
                <option value="true" selected={inputs.internal.value}>
                  Internal
                </option>
                <option value="false" selected={!inputs.internal.value}>
                  External
                </option>
              </select>
            )
            input.prefix = inputs.internal.value ? window.location.origin : 'https://'
            input.indent = inputs.internal.value ? `${getTextWidth(window.location.origin)}px` : '61px'
          }

          if (!input.visible) return null

          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
