import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'
import { MultiSelect, ToggleButton } from 'stories/components'
import { capitalizeLettersSeparatedBySlash } from 'utils'

export const Features = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<
    Record<string, { title: string; url: string; order: number; value: boolean; permissions: string[] }>
  >({})
  const [permissions, setPermissions] = useState<Record<string, string>>({})

  const initData = async () => {
    setIsLoading(true)
    const [featuresRes, permissionsRes] = await Promise.all([getAdminConfig('features'), getAdminConfig('permissions')])
    setData(featuresRes)
    setPermissions(permissionsRes)
    setIsLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  const onChange = async (key: string, value: boolean) => {
    const temp = cloneDeep(data)
    temp[key] = { ...temp[key], value }
    setData(temp)
    setIsLoading(true)
    await setAdminConfig('features', temp)
    setIsLoading(false)
  }

  const onPermissionsChange = async (key: string, permissions: string[]) => {
    const temp = cloneDeep(data)
    temp[key] = { ...temp[key], permissions }
    setData(temp)
    setIsLoading(true)
    await setAdminConfig('features', temp)
    setIsLoading(false)
  }

  const orderOptions = useMemo(() => {
    const options: number[] = []
    Object.keys(data).forEach((_, index) => options.push(index + 1))
    return options
  }, [Object.keys(data).length])

  const changeOrder = async (key: string, newOrder: string) => {
    const temp = cloneDeep(data)
    const currentOrder = temp[key].order
    const targetOrder = parseInt(newOrder)

    // Update all affected items
    Object.keys(temp).forEach((k) => {
      if (k === key) {
        temp[k].order = targetOrder
      } else if (currentOrder < targetOrder) {
        if (temp[k].order > currentOrder && temp[k].order <= targetOrder) {
          temp[k].order--
        }
      } else {
        if (temp[k].order >= targetOrder && temp[k].order < currentOrder) {
          temp[k].order++
        }
      }
    })

    setData(temp)
    setIsLoading(true)
    await setAdminConfig('features', temp)
    setIsLoading(false)
  }

  // Sort data by order
  const sortedData = useMemo(() => {
    return Object.entries(data).sort(([, a], [, b]) => a.order - b.order)
  }, [data])

  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />

      <div className="text-xl font-bold mb-4">Features</div>
      {sortedData.map(([key, value], index: number) => {
        return (
          <div
            className={`flex justify-between items-center p-2 pl-4 ${index % 2 && 'bg-slate-100'}`}
            key={`${key}-${index}`}
          >
            <div className="flex-1 flex justify-between items-center">
              <div className="flex items-center gap-4">
                <p className="text-sm">
                  {index + 1}. {capitalizeLettersSeparatedBySlash(key)}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <MultiSelect
                  id={`${key}-permissions`}
                  title="Permissions"
                  options={permissions}
                  value={value.permissions || []}
                  onChange={(selected) => onPermissionsChange(key, selected)}
                  className="w-64"
                />
                <select
                  className="py-1 border-gray-300 text-[15px]"
                  value={value.order.toString()}
                  onChange={(e) => changeOrder(key, e.target.value)}
                >
                  {orderOptions.map((item: any, index1) => {
                    return (
                      <option key={index1} value={`${item}`} disabled={item === index + 1}>
                        {item}
                      </option>
                    )
                  })}
                </select>
                <ToggleButton id={`${key}-${index}`} value={value.value} onChange={(value) => onChange(key, value)} />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
