import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'
import { Button } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { EmailDNSConfig } from '../../EmailDNSSetup/type'
import { defaultInputs, IFromEmails } from './constants'

export const EmailSetup = () => {
  const [inputs, setInputs] = useState(defaultInputs())
  const [emailDns, setEmailDns] = useState<Record<string, string>>({})
  const [edit, setEdit] = useState(false)
  const [action, setAction] = useState('')
  const [fromEmails, setFromEmails] = useState<IFromEmails>()
  const [isLoading, setIsLoading] = useState(true)
  const [emailDnsConfig, setEmailDnsConfig] = useState<EmailDNSConfig[]>([])

  useEffect(() => {
    Promise.all([getAdminConfig('emailSetup'), getAdminConfig('emailDNSSetup')])
      .then(([emailSetup, dnsSetup]) => {
        setAction('setting')
        setFromEmails(emailSetup)
        let newInputs = cloneDeep(inputs)
        let emailDns: Record<string, string> = {}
        Object.keys(inputs).map((key) => {
          const value = emailSetup[key] || ''
          const values = value.split('@')
          newInputs[key].value = values[0]
          emailDns[key] = values[1] || ''
        })
        setInputs(newInputs)
        setEmailDnsConfig(dnsSetup)
        setEmailDns(emailDns)
        setIsLoading(false)
      })
      .finally(() => setAction(''))

    setEdit(false)
  }, [])

  const primaryDns = useMemo(() => {
    const primary = emailDnsConfig.find((v) => v.isPrimary)
    if (!primary) return ''
    return primary.dns
  }, [emailDnsConfig])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
    setEdit(true)
  }

  const onSubmit = async () => {
    if (!fromEmails) return
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    const newEmails = cloneDeep(fromEmails)
    Object.keys(newEmails).forEach((key) => {
      if (data[key]) {
        if (key === 'adminEmailDns') (newEmails as any)[key] = data[key]
        else (newEmails as any)[key] = `${data[key]}@${emailDns[key] || primaryDns}`
      }
    })

    setAction('setting')
    await setAdminConfig('emailSetup', newEmails)
    setAction('')
    setEdit(false)
  }

  const onChangeEmailDns = (key: string, value: string) => {
    const newValues = cloneDeep(emailDns)
    newValues[key] = value
    setEmailDns(newValues)
    setEdit(true)
  }

  const renderEmailDnsOptions = (key: string) => {
    return (
      <select
        onChange={(e) => onChangeEmailDns(key, e.target.value)}
        value={emailDns[key] || primaryDns}
        className="block rounded py-0 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
      >
        {emailDnsConfig
          .filter((v) => v.verified)
          .map((item) => (
            <option key={item.dns}>{item.dns}</option>
          ))}
      </select>
    )
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <div className="mb-4 font-semibold text-xl"> Primary DNS : @{primaryDns}</div>
      <div className="relative mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]
          if (input.inputType === 'custom') return <div className="col-span-full border-b"></div>
          if (key !== 'adminEmailDns') input.additionalElements = renderEmailDnsOptions(key)

          return (
            <div className={`input md:col-span-${input.span || 1} `} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
        <p className="col-span-full italic">
          Administrator Emails will be filtered by this. Use semicolon(;) to separate multiple domains
        </p>
      </div>
      {edit && (
        <div className="flex justify-center">
          <Button className="w-[200px]" loading={action == 'setting'} onClick={onSubmit}>
            Save
          </Button>
        </div>
      )}
    </div>
  )
}
