import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { TaskAssigneeOptions } from 'pages/Tasks/constants'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAdminConfig, getAdminEmails, getTasks, setAdminConfig } from 'services'

import { LoanSubmissionDetails } from './details'
import {
  ActionType,
  actionTypeHeaders,
  actionTypeTitlePrefix,
  actionTypeTitles,
  ILoanActionConfigBase,
  ILoanSubmissionActionConfig,
  isActionTypeHasStatusTo,
} from './types'

export const LoanSubmission = () => {
  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<Record<ActionType, Record<string, ILoanActionConfigBase>>>()
  const [actionLabels, setActionLabels] = useState<Record<ActionType, Record<string, string>>>()

  const [currentItem, setCurrentItem] = useState<{ type: ActionType | null; key: string | null }>({
    key: null,
    type: null,
  })
  const [adminEmails, setAdminEmails] = useState<string[]>([])
  const [sortKeys, setSortKeys] = useState<Record<ActionType, string[]>>()
  const [tempTasks, setTempTasks] = useState<Array<any>>([])

  useEffect(() => {
    load()
    getAdminEmails().then((emails: string[]) => setAdminEmails(emails))
    getTasks().then(({ data }) => setTempTasks(data))
  }, [])

  const load = async () => {
    setLoading(true)
    getAdminConfig('loanActions')
      .then(({ data, labels }) => {
        setData(data)
        setActionLabels(labels)

        const sortKeys: any = {}
        Object.values(ActionType).forEach((actionKey) => {
          sortKeys[actionKey] = Object.keys(Array(Object.keys(labels[actionKey]).length).fill(1))
        })
        setSortKeys(sortKeys)
      })
      .finally(() => setLoading(false))
  }

  const onBack = (values: ILoanActionConfigBase | null) => {
    if (values && currentItem.key) {
      setLoading(true)
      const newData = cloneDeep(data!)
      newData[currentItem.type!][currentItem.key] = values
      setData(newData)
      setAdminConfig(`loanActions/${currentItem.type!}`, newData[currentItem.type!]).finally(() => setLoading(false))
    }
    setCurrentItem({ key: null, type: null })
  }

  const onChangeOrder = async (toIndex: number, fromIndex: number, type: ActionType) => {
    const keys = Object.keys(data![type])

    const newKeys = [...keys]
    const [movedKey] = newKeys.splice(fromIndex, 1)
    newKeys.splice(toIndex, 0, movedKey)

    const reorder: any = {}
    newKeys.forEach((key) => {
      reorder[key] = data![type][key]
    })
    setLoading(true)
    await setAdminConfig(`loanActions/${type!}`, reorder)
    setLoading(false)

    const newData = cloneDeep(data!)
    newData[type] = reorder
    setData(reorder)
  }

  const renderItem = (key: string, item: ILoanSubmissionActionConfig, index: number, type: ActionType) => {
    const cn = `${index % 2 ? 'bg-slate-50' : 'bg-white'}`

    const hasStatusTo = isActionTypeHasStatusTo(type)

    const tasks = (
      <>
        {item.tasks?.map((task, index) => {
          return (
            <div key={`task-${index} mb-2`} className="flex">
              <div className="w-[20px]">{index + 1}. </div>
              <div>
                <div>Description: {task.description}</div>
                <div>Assigned to: {task.assignedTo.map((to: string) => TaskAssigneeOptions[to] || to).join(', ')}</div>
                <div>Due Days: {task.dueDays}</div>
              </div>
            </div>
          )
        })}
      </>
    )

    return [
      <tr className={cn} key={`${index}`}>
        <td className="px-3 py-3" rowSpan={hasStatusTo ? 2 : 1}>
          {index + 1}
        </td>
        <td className="px-3 py-2" rowSpan={hasStatusTo ? 2 : 1}>
          {actionLabels?.[type][key]}
        </td>
        {hasStatusTo ? (
          <td className="px-3">{item.statusTo ? loanGlobalStatus[item.statusTo] : '- No Change -'}</td>
        ) : (
          <td className="px-3">{tasks}</td>
        )}
        <td>
          <span className={`flex px-3 ${hasStatusTo ? '' : 'justify-end'}`}>
            <span
              className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
              onClick={() => setCurrentItem({ type, key })}
            >
              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
            </span>
          </span>
        </td>
        {hasStatusTo && (
          <td className="px-1 text-right">
            <select
              value={index}
              className="text-sm py-1 border-gray-200 pr-2 w-16"
              onChange={(e) => onChangeOrder(parseInt(e.target.value), index, type)}
            >
              {[...sortKeys![type]].map((value) => (
                <option value={value} key={`option-${value}`}>
                  {parseInt(value) + 1}
                </option>
              ))}
            </select>
          </td>
        )}
      </tr>,

      hasStatusTo ? (
        <tr className={`${cn} border-b italic`} key={`2-${index}`}>
          <td colSpan={hasStatusTo ? 3 : 1} className="p-2">
            {tasks}
          </td>
        </tr>
      ) : null,
    ].filter((v) => !!v)
  }

  if (!data)
    return (
      <div className="relative px-3 py-1.5">
        <LayoutLoading show={isLoading} />
      </div>
    )

  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />

      {!currentItem.key ? (
        <div className="w-full">
          {Object.values(ActionType).map((actionKey, index) => (
            <>
              <div className={`border-b-2 border-shade-blue w-full italic flex justify-between items-center`}>
                <p>
                  {index + 1}. {actionTypeTitles[actionKey]}
                </p>
              </div>

              <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 mb-4">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {actionTypeHeaders[actionKey].map((v, index) => (
                      <th
                        scope="col"
                        className={`px-3 py-3 ${index == actionTypeHeaders[actionKey].length - 1 ? 'text-right' : ''}`}
                      >
                        {v}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="">
                  {Object.keys(data![actionKey])
                    .filter((key) => actionLabels && !!actionLabels?.[actionKey][key])
                    .map((key, index) => renderItem(key, data![actionKey][key] as any, index, actionKey))}
                </tbody>
              </table>
            </>
          ))}
        </div>
      ) : (
        <LoanSubmissionDetails
          actionType={currentItem.type!}
          action={currentItem.key}
          label={`${actionTypeTitlePrefix[currentItem.type!] || ''} ${
            actionLabels![currentItem.type!][currentItem.key]
          }`}
          data={data![currentItem.type!][currentItem.key] as any}
          adminEmails={adminEmails}
          tempTasks={tempTasks}
          onBack={onBack}
        />
      )}
    </div>
  )
}
