import cloneDeep from 'clone-deep'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'
import { Input2 } from 'stories/components'

export const HomePageCreditBoxSettingKey = 'HOMEPAGE_CREDIT_BOX'

const types = [
  {
    title: 'Loan Amount',
    key: 'loanAmount',
  },
  {
    title: 'Max Term for Bridge',
    key: 'maxTermBridge',
  },
  {
    title: 'Max Term for Perm',
    key: 'maxPermPerm',
  },
  {
    title: 'Interest Rate for Bridge',
    key: 'rateBridge',
  },
  {
    title: 'Interest Rate for Perm',
    key: 'ratePerm',
  },
  {
    title: 'As-is LTV',
    key: 'aivLtv',
  },
  {
    title: 'LTC',
    key: 'ltc',
  },
  {
    title: 'LTP',
    key: 'ltp',
  },
  {
    title: 'After Repaired LTV',
    key: 'arvLtv',
  },
  {
    title: 'Borrower Min Fico',
    key: 'minFico',
  },
]

export const HomePageCreditBox = () => {
  const [action, setAction] = useState('')
  const [content, setContent] = useState<Record<string, string>>({})
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    setAction('setting')
    getSetting(HomePageCreditBoxSettingKey)
      .then(({ value }) => setContent(JSON.parse(value || '{}')))
      .finally(() => setAction(''))
  }, [])

  const onChange = (key: string, value: string) => {
    const newValues = cloneDeep(content)
    newValues[key] = value
    setContent(newValues)
    setChanged(true)
  }

  const onSubmit = async () => {
    if (!changed) return
    setAction('setting')
    await updateSetting(HomePageCreditBoxSettingKey, JSON.stringify(content))
    setAction('')
    setChanged(false)
  }

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">
        Homepage Credit Box
        {action && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>

      <div className="relative mb-6 grid grid-cols-1 md:grid-cols-4 gap-4">
        {types.map(({ key, title }) => (
          <div className="mb-4 md:col-span-1" key={key}>
            <Input2
              title={title}
              type="text"
              value={content[key] || ''}
              onChange={(value) => onChange(key, value)}
              onBlur={onSubmit}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
