import {
  ArrowDownIcon,
  ArrowUpIcon,
  BarsArrowDownIcon,
  BoltIcon,
  BoltSlashIcon,
  CheckIcon,
  EyeIcon,
  EyeSlashIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes, accountTypeWithBorrower } from 'components/Modals/CreateUser/config'
import { PageOrderModal } from 'components/Modals/PageOrder'
import { SaveChanges } from 'components/SaveChanges'
import {
  FieldOrigin,
  ILoanFieldPos,
  InputType,
  IVisibleProp,
  loanCustomRangeOptionFields,
  loanCustomSelectOptionFields,
  LoanFieldProps,
} from 'config'
import { canHaveSection, getVisibleLogics2Text, InputTypeOfLoans } from 'config/loan.input.fields.constants'
import { ILoanOriginSection, isCustomOrigin } from 'config/loan.input.visibility.type'
import React, { useEffect, useMemo, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getAdminConfig, setAdminConfig } from 'services'
import { Button, Modal } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { confirm, convertToSanitize, fillObject, prompt, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { addCustomInputs, extendedFieldOriginOptions, getInputs, hasRequiredProps, visibilitySections } from './inputs'
import { convertSelectRangeOptionsToMap, setLoanFieldDefaultSection } from './utils'
import { VisiblePropertyModal } from './VisiblePropertyModal'

export const defaultAccessAccountTypes = fillObject([...Object.keys(accountTypes), 'borrower'], true)
export const LoanFieldsProperties = (props: { setChanged?: Function }) => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<LoanFieldProps>()
  const [inputs, setInputs] = useState<InputTypeOfLoans>()
  const [isChanged, _setChanged] = useState<boolean>()
  const [selectedField, setSelectedField] = useState<ILoanFieldPos | null>(null)
  const [editTitleID, setEditTitleId] = useState<FieldOrigin | null>(null)
  const [editTitle, setEditTitle] = useState('')
  const [editAccountsID, setEditAccountsID] = useState<FieldOrigin | null>(null)
  const [showAccountTypesModal, setShowAccountTypesModal] = useState(false)
  const [accountTypes, setAccountTypes] = useState<Record<string, any>>({})
  const [orderPageIndex, setOrderPageIndex] = useState<{ fieldOrigin: FieldOrigin; sectionId: number } | null>(null)
  const [showOrderModal, setOrderModal] = useState(false)

  useEffect(() => {
    getAdminConfig('loanFieldProperties')
      .then((data) => {
        const newData = setLoanFieldDefaultSection(data)
        setData(newData)
        setInputs(getInputs(newData, false))
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!data || !inputs) return

    const newInputs = cloneDeep(inputs)
    addCustomInputs(newInputs, data)
    setInputs(newInputs)
    if (isChanged === undefined) setChanged(false)
    else !isChanged && setChanged(true)
  }, [data])

  const customTitle = (fieldOrigin: FieldOrigin) => {
    let title = (data as any)?.customTitle?.[fieldOrigin] || ''

    if (!title && isCustomOrigin(fieldOrigin)) title = extendedFieldOriginOptions[FieldOrigin.CustomInformation]

    return title || extendedFieldOriginOptions[fieldOrigin]
  }

  const pageTitlesOptions = useMemo(() => {
    const values: Record<string, string> = {}
    data?.order
      ?.filter((v) => v != FieldOrigin.LoanStructure)
      .forEach((fieldOrigin) => (values[fieldOrigin] = customTitle(fieldOrigin)))
    return values
  }, [data])

  const setChanged = (value: boolean) => {
    _setChanged(value)
    props.setChanged && props.setChanged(value)
  }

  const selectedFieldData = useMemo(() => {
    if (!selectedField || !data) return null
    if (!data[selectedField.fieldOrigin]) return {}
    return data[selectedField.fieldOrigin][selectedField.fieldKey] || {}
  }, [selectedField])

  const orderingSectionInputs = useMemo(() => {
    if (!orderPageIndex || !inputs) return []
    const { fieldOrigin, sectionId } = orderPageIndex
    const section = data?.sections?.[fieldOrigin]?.find((v) => v.id == sectionId)
    if (!section) return []

    return section.fields.map((fieldKey) => ({
      fieldKey,
      ...inputs[fieldOrigin][fieldKey],
    }))
  }, [orderPageIndex])

  if (!inputs) return <LayoutLoading show />

  const isShowVisibleSection = (fieldOrigin: FieldOrigin) =>
    Object.keys(visibilitySections).includes(fieldOrigin) || isCustomOrigin(fieldOrigin)

  const accessAccountTypes = (fieldOrigin: FieldOrigin) =>
    (data as any)?.accountTypes?.[fieldOrigin] || defaultAccessAccountTypes

  const isVisibleSection = (fieldOrigin: FieldOrigin) =>
    data && ((data as any)['visibility'] || {})[fieldOrigin] !== false

  const onVisibleSection = (fieldOrigin: FieldOrigin) => {
    if (!data) return
    const newData = cloneDeep(data) as any
    if (!newData['visibility']) newData['visibility'] = {}
    newData['visibility'][fieldOrigin] = !isVisibleSection(fieldOrigin)
    setData(newData)
  }

  const onChange = (
    fieldOrigin: FieldOrigin,
    fieldKey: string,
    subKey: keyof IVisibleProp,
    value: any,
    input: InputType,
  ) => {
    if (!data) return
    if (!data[fieldOrigin]) data[fieldOrigin] = {}
    const temp = cloneDeep(data)
    if (!temp[fieldOrigin]) temp[fieldOrigin] = {}
    if (!temp[fieldOrigin][fieldKey]) temp[fieldOrigin][fieldKey] = {} as any

    const defaultValue = (temp[fieldOrigin][fieldKey] as any).defaultValue
    if (subKey == 'visible' && !value && ['', undefined].includes(defaultValue)) {
      toast('Default value is required', { type: 'warning' })
      return
    }

    if (
      ['number', 'thousandSep', 'thousandSepNoDecimal'].includes(input.type as any) &&
      subKey == 'defaultValue' &&
      value != ''
    ) {
      value = removeComma(value)
    }
    ;(temp[fieldOrigin][fieldKey] as any)[subKey] = value
    setData(temp)
  }

  const onShowProperty = (fieldOrigin: FieldOrigin, fieldKey: string, input: InputType) => {
    setSelectedField({ fieldOrigin, fieldKey, title: input.title })
  }

  const onAddNewPage = () => {
    if (!data) return

    const newFieldOrigin = `${FieldOrigin.CustomInformation}-${Date.now()}`
    const newData = cloneDeep(data)
    newData[newFieldOrigin] = {}
    newData.order!.push(newFieldOrigin as any)
    setData(newData)
  }

  const onSubmit = async () => {
    if (!data) return
    setLoading(true)
    setAdminConfig('loanFieldProperties', data)
      .then(() => toast('Loan Fields Configuration has been saved.', { type: 'info' }))
      .finally(() => {
        setLoading(false)
        setChanged(false)
      })
  }

  const onEditTitle = (fieldOrigin: FieldOrigin) => {
    setEditTitleId(fieldOrigin)
    setEditTitle(customTitle(fieldOrigin))
  }

  const onCloseEdit = () => {
    setEditTitle('')
    setEditTitleId(null)
  }

  const onOkEdit = () => {
    if (!data || !editTitleID) return
    if (!editTitle) {
      toast('Title is required', { type: 'warning' })
      return
    }
    const newData = cloneDeep(data) as any
    if (!newData['customTitle']) newData['customTitle'] = {}
    newData['customTitle'][editTitleID] = editTitle
    setData(newData)
    setEditTitle('')
    setEditTitleId(null)
  }

  const onRemoveCustomInput = async (fieldOrigin: FieldOrigin, fieldKey: string) => {
    if (!data) return
    const isContinue = await confirm('Are you sure you want to remove this item?')
    if (!isContinue) return
    const newData = cloneDeep(data) as any

    delete newData[fieldOrigin][fieldKey]
    const newInputs = cloneDeep(inputs)
    delete newInputs[fieldOrigin][fieldKey]
    setInputs(newInputs)

    newData.sections?.[fieldOrigin]?.forEach((v: ILoanOriginSection) => {
      if (v.fields.includes(fieldKey)) v.fields.splice(v.fields.indexOf(fieldKey), 1)
    })
    setData(newData)
  }

  const onShowAccountModal = (fieldOrigin: FieldOrigin) => {
    setShowAccountTypesModal(true)
    setEditAccountsID(fieldOrigin)
    setAccountTypes(accessAccountTypes(fieldOrigin))
  }

  const onRemoveOrigin = async (fieldOrigin: FieldOrigin) => {
    if (!data) return

    const isContinue = await confirm('Are you sure you want to remove this page?')
    if (!isContinue) return

    const newData = cloneDeep(data)
    delete newData[fieldOrigin]
    if (newData.sections && newData.sections[fieldOrigin]) delete newData.sections[fieldOrigin]
    setData(newData)
  }

  const onCloseAccountModal = () => {
    setShowAccountTypesModal(false)
    setEditAccountsID(null)
  }

  const onOkAccountModal = () => {
    if (!data || !editAccountsID) return

    const newData = cloneDeep(data) as any
    if (!newData['accountTypes']) newData['accountTypes'] = {}
    newData['accountTypes'][editAccountsID] = accountTypes
    setData(newData)
    setAccountTypes({})
    setEditAccountsID(null)
    setShowAccountTypesModal(false)
  }

  const getSectionFields = (fieldOrigin: FieldOrigin, sectionId: number) => {
    const section = data?.sections?.[fieldOrigin]?.find((v) => v.id == sectionId)
    if (!section) return []
    return section.fields
  }

  const getAllSectionFields = (fieldOrigin: FieldOrigin) => {
    const allFields: string[] = []
    data?.sections?.[fieldOrigin]?.forEach((v) => allFields.push(...v.fields))
    return allFields
  }

  const renderInput = (
    fieldOrigin: FieldOrigin,
    fieldKey: string,
    input: InputType,
    isCustomInput: boolean = false,
  ) => {
    if (!data) return null
    const itemData = data[fieldOrigin] && data[fieldOrigin][fieldKey] ? data[fieldOrigin][fieldKey] : {}
    if (!isCustomInput && fieldKey == `${Number(fieldKey)}`) isCustomInput = true

    const visible = itemData.visible || itemData.visible === undefined
    let defaultValue = itemData.defaultValue
    if (defaultValue === undefined) defaultValue = ''
    const tooltip = itemData.tooltip || ''
    const visibleLogicText = getVisibleLogics2Text(itemData)
    if (isCustomInput) input.title = itemData.title || input.title

    if (itemData.required !== undefined) input.required = itemData.required

    if (
      data[fieldOrigin]?.[fieldKey]?.options?.length &&
      [...loanCustomRangeOptionFields, ...loanCustomSelectOptionFields].includes(fieldKey)
    ) {
      ;(input as any).options = convertSelectRangeOptionsToMap(data[fieldOrigin][fieldKey]?.options || [])
    }

    input.type = (itemData.type || input.type) as any
    const RequiredIcon = input.required ? BoltIcon : BoltSlashIcon

    let additionalElements = (
      <div className="flex">
        {tooltip && (
          <Tooltip message={tooltip}>
            <span className="btn-icon">
              <QuestionMarkCircleIcon className="w-4 h-4 text-gray-500" />
            </span>
          </Tooltip>
        )}
        <Tooltip message={visibleLogicText}>
          <span className="btn-icon" onClick={() => onChange(fieldOrigin, fieldKey, 'visible', !visible, input)}>
            {visible ? (
              <EyeIcon className={`w-4 h-4 ${!visibleLogicText && 'text-gray-500'}`} />
            ) : (
              <EyeSlashIcon className={`w-4 h-4 ${!visibleLogicText && 'text-gray-500'}`} />
            )}
          </span>
        </Tooltip>
        <span className="btn-icon" onClick={() => onShowProperty(fieldOrigin, fieldKey, input)}>
          <PencilSquareIcon className="w-4 h-4 text-gray-500" />
        </span>

        {hasRequiredProps(fieldOrigin) && (
          <Tooltip message={input.required ? 'Required' : 'Not Required'}>
            <span
              className="btn-icon"
              onClick={() => onChange(fieldOrigin, fieldKey, 'required', !input.required, input)}
            >
              <RequiredIcon className="w-4 h-4 text-gray-500" />
            </span>
          </Tooltip>
        )}

        {isCustomInput && (
          <span className="btn-icon" onClick={() => onRemoveCustomInput(fieldOrigin, fieldKey)}>
            <TrashIcon className="w-4 h-4 text-red-500" />
          </span>
        )}
      </div>
    )

    if (input.inputType == 'Section') additionalElements = <></>

    if (fieldOrigin == FieldOrigin.DeclarationsHMDA && ['1', '2'].includes(itemData.section || '')) {
      input.span = 'full'
      if (input.inputType.toLowerCase() == 'togglebutton') (input as any).textLeft = true
    }

    return (
      <React.Fragment key={`${fieldOrigin}-${fieldKey}`}>
        <div
          className={`grid grid-cols-1 items-center ${visible ? 'opacity-100' : 'opacity-30 z-10'} ${
            input.span ? `col-span-${input.span}` : ''
          } mb-2`}
          key={`${fieldOrigin}-${fieldKey}`}
        >
          <RenderInput
            key={`${fieldOrigin}-${fieldKey}`}
            input={{
              ...input,
              value: defaultValue,
              additionalElements,
            }}
            Key={`${fieldOrigin}-${fieldKey}`}
            onChange={(inputKey: string, value: any) =>
              itemData.visible !== false && onChange(fieldOrigin, fieldKey, 'defaultValue', value, input)
            }
          />
        </div>
      </React.Fragment>
    )
  }

  const getSectionOptions = (fieldOrigin: FieldOrigin) => {
    if (!data) return null
    if (!data.sections || !data.sections[fieldOrigin]) return null
    const sections = data.sections![fieldOrigin]!
    const options: Record<string, string> = {}
    sections.forEach((section) => {
      ;(options as any)[`${section.id}`] = section.title
    })
    return options
  }

  const renderSectionFields = (fieldOrigin: FieldOrigin, sectionId: number | null) => {
    if (!data || !inputs[fieldOrigin]) return null

    const sectionFields = sectionId ? getSectionFields(fieldOrigin, sectionId) : getAllSectionFields(fieldOrigin)

    return (
      <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mt-2`}>
        {(sectionId ? sectionFields : Object.keys(inputs[fieldOrigin])).map((fieldKey: string) => {
          const item = inputs[fieldOrigin][fieldKey]
          if (!item || item.inputType === 'GoogleMap') return

          if (
            !canHaveSection(fieldOrigin) ||
            (sectionId && sectionFields.includes(fieldKey)) ||
            (!sectionId && !sectionFields.includes(fieldKey))
          )
            return renderInput(fieldOrigin, fieldKey, item, isCustomOrigin(fieldOrigin))
          return null
        })}
      </div>
    )
  }

  const renderFieldOriginSection = (fieldOrigin: FieldOrigin) => {
    if (!data) return null

    let sections: ILoanOriginSection[] = []
    if (data.sections && data.sections[fieldOrigin]) {
      sections = data.sections[fieldOrigin] || []
    }

    const onAddNewSection = () => {
      const newData = cloneDeep(data)
      if (!newData.sections) newData.sections = {}
      if (!newData.sections[fieldOrigin]) newData.sections[fieldOrigin] = []
      newData.sections[fieldOrigin]!.push({
        id: Date.now(),
        title: `Section ${newData.sections[fieldOrigin]!.length + 1}`,
        fields: [],
      })
      setData(newData)
    }

    const onUpdateSectionTitle = async (section: ILoanOriginSection, index: number) => {
      const title: any = await prompt('Input section title', { value: section.title })
      if (title === false) return

      const newData = cloneDeep(data)
      const sections = newData.sections![fieldOrigin]!
      sections[index].title = title
      setData(newData)
    }

    const onRemoveSection = async (section: ILoanOriginSection, index: number) => {
      const isContinue = await confirm('Are you sure you want to remove this section?')
      if (!isContinue) return

      const newData = cloneDeep(data)
      newData?.sections?.[fieldOrigin]?.splice(index, 1)
      setData(newData)
    }

    const onUpdateOrder = (index: number, direction: number) => {
      const newData = cloneDeep(data)
      const sections = newData.sections![fieldOrigin]!
      sections.splice(index + direction, 0, sections.splice(index, 1)[0])
      setData(newData)
    }

    const onAddNewField = (fieldOrigin: FieldOrigin) => {
      setSelectedField({
        fieldOrigin,
        fieldKey: '',
      })
    }

    return (
      <div>
        {canHaveSection(fieldOrigin) &&
          sections.map((section, index) => {
            return (
              <div key={section.id} className="mb-4">
                <RenderInput
                  Key={`section-${index}`}
                  input={{
                    inputType: 'section',
                    title: section.title || '',
                    additionalElements: (
                      <div className="flex gap-2">
                        <p
                          className="btn-icon"
                          onClick={() => setOrderPageIndex({ fieldOrigin, sectionId: section.id })}
                        >
                          <BarsArrowDownIcon className="w-4 h-4" />
                        </p>

                        {index != 0 && (
                          <span className="btn-icon" onClick={() => onUpdateOrder(index, -1)}>
                            <ArrowUpIcon className="w-4 h-4" />
                          </span>
                        )}
                        {index != sections.length - 1 && (
                          <span className="btn-icon" onClick={() => onUpdateOrder(index, 1)}>
                            <ArrowDownIcon className="w-4 h-4" />
                          </span>
                        )}

                        <span className="btn-icon" onClick={() => onUpdateSectionTitle(section, index)}>
                          <PencilSquareIcon className="w-4 h-4" />
                        </span>
                        <span className="btn-icon !text-red-500" onClick={() => onRemoveSection(section, index)}>
                          <TrashIcon className="w-4 h-4" />
                        </span>
                      </div>
                    ),
                  }}
                  onChange={() => {}}
                />
                {renderSectionFields(fieldOrigin, section.id)}
              </div>
            )
          })}

        {renderSectionFields(fieldOrigin, null)}

        <div className="flex items-center gap-4">
          {canHaveSection(fieldOrigin) && (
            <Button link onClick={onAddNewSection} className="">
              + Add New Section
            </Button>
          )}
          {(isCustomOrigin(fieldOrigin) || fieldOrigin == FieldOrigin.DeclarationsHMDA) && (
            <Button link onClick={() => onAddNewField(fieldOrigin)} className="">
              + Add New Field
            </Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <Prompt when={isChanged} message={`You've made some changes!\nAre you sure want to leave without Saving?`} />
      <div>
        {[FieldOrigin.LoanStructure, ...Object.keys(pageTitlesOptions)].map((_fieldOrigin: string, pageIdx: number) => {
          const fieldOrigin = _fieldOrigin as FieldOrigin
          const isVisible = isVisibleSection(fieldOrigin)
          const VisibleIcon = isVisible ? EyeIcon : EyeSlashIcon
          return (
            <div className={`${isVisible ? '' : 'opacity-50'}`} key={`${_fieldOrigin}-${pageIdx}`}>
              <div className="flex items-center justify-between border-b mb-2">
                <p className={`text-lg font-bold flex items-center gap-4 ${isVisible ? '' : 'line-through'}`}>
                  {pageIdx + 1}.{' '}
                  {editTitleID === fieldOrigin ? (
                    <>
                      <input
                        className="w-60 border-black border-2 rounded-md px-2"
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                      ></input>
                      <span className="text-red-600 cursor-pointer hover-shadow1 p-1 rounded" onClick={onCloseEdit}>
                        <XMarkIcon className="w-4 h-4"></XMarkIcon>
                      </span>
                      <span className="btn-icon" onClick={onOkEdit}>
                        <CheckIcon className="w-4 h-4"></CheckIcon>
                      </span>
                    </>
                  ) : (
                    <>{customTitle(fieldOrigin)}</>
                  )}
                  {isShowVisibleSection(fieldOrigin) && (
                    <>
                      {editTitleID !== fieldOrigin && (
                        <span
                          className="cursor-pointer hover-shadow1 p-1 rounded"
                          onClick={() => onEditTitle(fieldOrigin)}
                        >
                          <PencilSquareIcon className="w-4 h-4" />
                        </span>
                      )}
                      <span className="btn-icon" onClick={() => onVisibleSection(fieldOrigin)}>
                        <VisibleIcon className="w-4 h-4" />
                      </span>

                      <span className="btn-icon" onClick={() => onShowAccountModal(fieldOrigin)}>
                        <UsersIcon className="w-4 h-4" />
                      </span>

                      {isCustomOrigin(fieldOrigin) && editTitleID !== fieldOrigin && (
                        <span className="btn-icon !text-red-500" onClick={() => onRemoveOrigin(fieldOrigin)}>
                          <TrashIcon className="w-4 h-4" />
                        </span>
                      )}
                    </>
                  )}
                </p>
              </div>

              {renderFieldOriginSection(fieldOrigin)}
            </div>
          )
        })}
      </div>

      <div className="flex flex-row justify-center gap-4">
        <Button onClick={onAddNewPage} loading={isLoading} outline>
          Add new Page
        </Button>
        <Button onClick={() => setOrderModal(true)} loading={isLoading} outline>
          Update Order
        </Button>
        <Button onClick={onSubmit} loading={isLoading} disabled={!isChanged}>
          Save
        </Button>
      </div>
      <SaveChanges show={isChanged} label="Save Changes" onSave={onSubmit} />
      {selectedField && selectedFieldData && (
        <VisiblePropertyModal
          field={selectedField}
          data={selectedFieldData}
          sectionOptions={getSectionOptions(selectedField.fieldOrigin)}
          inputProps={inputs[selectedField.fieldOrigin]?.[selectedField.fieldKey] || {}}
          onClose={(newItem?: IVisibleProp) => {
            setSelectedField(null)
            if (!newItem || !data) return

            let { fieldOrigin, fieldKey } = selectedField
            const newData = cloneDeep(data)
            const newInputs = cloneDeep(inputs)
            let requireNewInputs = false
            if (!newData[fieldOrigin]) newData[fieldOrigin] = {}

            if (!fieldKey) {
              fieldKey =
                fieldOrigin == FieldOrigin.DeclarationsHMDA ? `${Date.now()}` : convertToSanitize(newItem.title!)
            }

            newData[fieldOrigin][fieldKey] = newItem

            const sections = newData.sections?.[fieldOrigin]

            if (newItem.inputType !== undefined && newInputs[fieldOrigin][fieldKey].inputType != newItem.inputType) {
              delete newInputs[fieldOrigin][fieldKey]
              requireNewInputs = true
            }

            if (selectedFieldData.section != newItem.section) {
              if (selectedFieldData.section && sections) {
                const index = sections.findIndex((v) => `${v.id}` == `${selectedFieldData.section}`)
                if (index != -1) {
                  const sIndex = sections[index].fields.indexOf(fieldKey)
                  sIndex != -1 && sections[index].fields.splice(sIndex, 1)
                }
              }
              if (newItem.section && sections) {
                const index = sections.findIndex((v) => `${v.id}` == `${newItem.section}`)
                const sIndex = index != -1 ? sections[index].fields.indexOf(fieldKey) : 0
                index != -1 && sIndex == -1 && sections[index].fields.push(fieldKey)
              }
            }

            requireNewInputs && setInputs(newInputs)
            setData(newData)
          }}
        />
      )}

      {orderPageIndex != null && (
        <PageOrderModal
          key="sectionOrder"
          inputs={orderingSectionInputs}
          onClose={(newInputs: any[] | null) => {
            if (newInputs) {
              const { fieldOrigin, sectionId } = orderPageIndex
              const index = data?.sections?.[fieldOrigin]?.findIndex((v) => v.id == sectionId)
              const fields = newInputs?.map((v) => v.fieldKey)
              const newData = cloneDeep(data)
              if (
                index !== undefined &&
                index != -1 &&
                newData &&
                newData.sections &&
                newData.sections[fieldOrigin] &&
                newData.sections?.[fieldOrigin]?.[index]
              )
                (newData as any).sections[fieldOrigin][index].fields = fields
              setData(newData)
            }
            setOrderPageIndex(null)
          }}
        />
      )}

      {showOrderModal && data && (
        <PageOrderModal
          key="pageOrder"
          inputs={Object.values(pageTitlesOptions).map((value) => ({ title: value }))}
          onClose={(newInputs: { title: string }[] | null) => {
            setOrderModal(false)
            if (!newInputs) return

            const newData = cloneDeep(data)
            const newOrder: string[] = []
            const keys = Object.keys(pageTitlesOptions)
            const titles = Object.values(pageTitlesOptions)

            newInputs.forEach((v) => {
              const index = titles.findIndex((s) => s == v.title)
              newOrder.push(keys[index])
            })
            newData.order = newOrder as any
            setData(newData)
          }}
        />
      )}

      {showAccountTypesModal && (
        <Modal title="Select Visible Account Types" isOpen={true} onClose={onCloseAccountModal} onOk={onOkAccountModal}>
          <div className="w-120 h-96">
            <RenderInput
              input={{
                inputType: 'multiselect',
                title: 'Account Type',
                options: accountTypeWithBorrower,
                defaultSelected: false,
                value: accountTypes,
                sort: true,
              }}
              Key="accountTypes"
              onChange={(key: string, value: any) => setAccountTypes(value)}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}
