import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { AccountType, InputType } from 'config'
import LightningDocs from 'pages/Loan/LightningDocs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getGenerateDocuments, updateGenerateDocuments } from 'services'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, openAuditLog } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultInputs } from '../GenerateDocuments/constants'

export const LightningDocsClosingScreen = () => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [inputFieldChanged, setInputFieldChanged] = useState(false)
  const [showLightningDocs, setShowLightningDocs] = useState(false)

  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  const canIgnoreFilter = [AccountType.ADMIN, AccountType.LOCK_DESK].includes(auth.profile.accountType)

  useEffect(() => {
    setLoanNumber()
    setLoading(true)
    getGenerateDocuments(true)
      .then(({ data, options }) => {
        let newInputs = defaultInputs(false)
        if (!data) {
          setInputs(newInputs)
          return
        }
        Object.keys(data).forEach((key) => {
          if (newInputs[key] && data[key] !== undefined) newInputs[key].value = data[key]
        })
        if (options && options.prepaymentTypes) {
          const { prepaymentTypes } = options
          const prepaymentOptions: Record<string, string> = {}
          prepaymentTypes.forEach((v: Record<string, any>) => {
            prepaymentOptions[`${v.id}`] = `${v.value} - ${v.period} months of interest`
          })
          ;(newInputs.prepayment as any).options = prepaymentOptions
        }
        setInputs(newInputs)
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
    setInputFieldChanged(true)
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'GenerateDocument',
      field: key,
      keys: {
        field: key,
      },
    }
    openAuditLog(options)
  }

  const onBlur = (key: string) => {
    if (!inputFieldChanged) return
    setInputFieldChanged(false)

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}

    data[key] = newInputs[key].value
    newInputs[key].error = InputValidate(newInputs[key])
    if (newInputs[key].error) return

    setLoading(true)

    updateGenerateDocuments(data)
      .then(() => {})
      .finally(() => setLoading(false))
  }

  if (showLightningDocs) {
    return (
      <div className="GenerateDocument-container relative">
        <h2 className="text-2xl font-bold flex items-center mb-3">Lightning Docs</h2>
        <div className="text-shade-blue flex items-center mb-2 font-bold">
          <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
          <span className="hover:underline cursor-pointer" onClick={() => setShowLightningDocs(false)}>
            Back
          </span>
        </div>
        <LightningDocs hasOverview={false} />
      </div>
    )
  }
  return (
    <>
      <div className="GenerateDocument-container relative">
        <LayoutLoading show={isLoading} />
        <h2 className="text-2xl font-bold flex items-center mb-3">Lightning Docs</h2>
        <div className="grid gap-4 md:grid-cols-12 grid-cols-1">
          <div className="md:col-span-12">
            <div className="border-b font-semibold mb-4">Details</div>
            <div className="w-full overflow-auto rounded">
              <div className="w-full grid gap-4 md:grid-cols-2 grid-cols-1">
                {Object.keys(inputs).map((key, index) => {
                  if (!canIgnoreFilter && key === 'ignoreFilter') return
                  if (key === 'downloadOption') return
                  let input = inputs[key]
                  input.history = !input.disabled && key != 'interestType'

                  return (
                    <>
                      <div className={`input md:col-span-${input.span || 1}`} key={index}>
                        <RenderInput
                          input={input}
                          Key={key}
                          onChange={onChange}
                          onBlur={onBlur}
                          showHistory={showHistory}
                        />
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="col-span-full text-center">
            <Button onClick={() => setShowLightningDocs(true)}>Proceed to generate Lightning Docs</Button>
          </div>
        </div>
      </div>
    </>
  )
}
