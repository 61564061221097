import { InputType, loanOptionsConstants } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    subjectPropertyAddress: {
      inputType: 'map',
      title: 'Property Address',
      value: '',
      error: '',
      required: true,
    },
    propertyCounty: {
      inputType: 'text',
      title: 'Property County',
      options: [],
      value: '',
      error: '',
      required: true,
    },
    apartmentUnit: {
      inputType: 'text',
      type: 'number',
      title: 'Apartment/Unit #',
      value: '',
      error: '',
    },
    propertyType: {
      inputType: 'select',
      title: 'Property Type',
      options: loanOptionsConstants.propertyTypeBigList,
      hasDefaultOption: true,
      required: true,
    },
    parcelNo: {
      inputType: 'text',
      title: 'Parcel No',
      value: '',
      error: '',
    },
    nyBlock: {
      inputType: 'text',
      title: 'Block Number',
      value: '',
      visible: false,
      error: '',
    },
    nyLot: {
      inputType: 'text',
      title: 'Lot Number',
      value: '',
      visible: false,
      error: '',
    },
    numberOfUnits: {
      inputType: 'text',
      type: 'number',
      title: 'Number of Units',
      value: '',
      error: '',
      required: true,
    },
    proposedMonthlyRent: {
      title: 'Proposed Monthly Rent',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyTaxes: {
      title: 'Proposed Monthly Taxes',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyInsurance: {
      title: 'Proposed Monthly Insurance',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyHoaDues: {
      title: 'Proposed Monthly HOA Dues',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    secondLienPayment: {
      title: 'Second Lien Payment',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
      disabled: true,
    },
    floodInsurance: {
      title: 'Flood Insurance',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    schoolTax: {
      title: 'School Tax',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    otherTax: {
      title: 'Other Tax',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    yearAcquired: {
      inputType: 'text',
      type: 'number',
      title: 'Year Acquired',
    },
    lienPayoff: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Amount Existing Liens',
      prefix: '$',
    },
    cdaValue: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'CDA Value',
      error: '',
      prefix: '$',
    },
    marketRentFromAppraisal: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Market Rent From Appraisal',
      error: '',
      prefix: '$',
    },
  }
}
