import { ArrowLeftIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { AUTH_TOKEN_SET } from 'actions'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { send2faCode, verify2faCode } from 'services'
import { InputCode, Modal } from 'stories/components'
import { convertHiddenPhoneNumber, delay, getOnlyNumber } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const TfaModal = ({
  email,
  phone,
  ...props
}: {
  email: string
  phone: string
  password: string
  onClose: any
}) => {
  const [loading, setLoading] = useState(false)
  const [option, setOption] = useState('email')
  const [isSentCode, setSentCode] = useState(false)
  const [verifyCode, setVerifyCode] = useState<string | null>(null)
  const [hasError, setHasError] = useState(false)
  const navigate = useHistory()
  const dispatch = useDispatch()

  const [currentTime, setCurrentTime] = useState(0)
  const [expiresAt, setExpiresAt] = useState(0)

  useEffect(() => {
    setLoading(false)

    const intervalId = setInterval(() => {
      setCurrentTime(Date.now())
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  const remainedSecs = useMemo(() => {
    return Math.round((expiresAt - currentTime) / 1000)
  }, [currentTime, expiresAt])

  useEffect(() => {
    if (isSentCode && remainedSecs <= 0) {
      toast('Verification code is expired', { type: 'warning' })
      onBack()
    }
  }, [remainedSecs, isSentCode])

  const options: Record<string, string> = useMemo(
    () => ({
      email: `1. Send code to email - ${email}`,
      sms: `2. Send code to phone - ${convertHiddenPhoneNumber(getOnlyNumber(phone))}`,
      call: `3. Send code via voice call - ${convertHiddenPhoneNumber(getOnlyNumber(phone))}`,
    }),
    [email, phone],
  )

  const onClose = () => {
    props.onClose()
  }

  const onBack = () => {
    setSentCode(false)
    setHasError(false)
  }

  const onResend = () => {
    setLoading(true)
    setHasError(false)
    send2faCode({ email, option })
      .then(({ expiresIn }) => {
        setExpiresAt(Date.now() + expiresIn * 1000)
        setSentCode(true)
      })
      .finally(() => setLoading(false))
  }

  const onChangeCode = (code: string | null) => {
    if (code) setHasError(false)
    setVerifyCode(code)
  }

  const onSubmitCode = (code: string) => {
    setLoading(true)
    verify2faCode({ option, verifyCode: code, email, password: props.password })
      .then(async ({ token, user }) => {
        await dispatch({ type: AUTH_TOKEN_SET, token, user })
        await delay(0.5)
        onClose()
        navigate.push('/pipeline')
      })
      .catch(() => {
        setVerifyCode(null)
        setHasError(true)
      })
      .finally(() => setLoading(false))
  }

  const onSubmit = async () => {
    if (!isSentCode) {
      onResend()
    } else {
      verifyCode && onSubmitCode(verifyCode)
    }
  }

  const isSubmitDisabled = useMemo(() => {
    if (!isSentCode) return false
    return verifyCode == null
  }, [option, verifyCode, isSentCode])

  return (
    <Modal
      title="2FA Authentication"
      titleOkay={!isSentCode ? 'Send' : 'Confirm'}
      loading={loading}
      isOpen
      onClose={onClose}
      onOk={onSubmit}
      disabled={isSubmitDisabled}
    >
      {!isSentCode ? (
        <div className="grid gap-x-3 grid-cols-1 w-[500px]">
          <RenderInput
            input={{
              inputType: 'OptionSelect',
              options: options,
              title: 'Please select 2FA option',
              value: option,
            }}
            Key="2fa-options"
            key="2fa-options"
            onChange={(key: string, value: any) => setOption(value)}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-3 grid gap-x-3 grid-cols-1 w-[500px]">
          <span className="flex hover:cursor-pointer items-center gap-2" onClick={onBack}>
            <ArrowLeftIcon className="w-4 h-4" /> Back
          </span>
          <div className="mx-auto p-3 bg-blue-100 rounded-full">
            <LockClosedIcon className="w-10 h-10" />
          </div>
          <label className="">
            We've sent 6-digits code to{' '}
            <b>{option === 'email' ? email : convertHiddenPhoneNumber(getOnlyNumber(phone))}</b>. <br />
            <div className="flex">
              It will be expired in{' '}
              <div className="text-shade-blue underline w-[24px] ml-2">{remainedSecs >= 0 ? remainedSecs : 0}</div>{' '}
              seconds
            </div>
          </label>
          <div className="flex justify-around">
            <InputCode
              value={verifyCode || ''}
              disabled={loading}
              error={hasError}
              onChange={(code: string) => onChangeCode(code)}
              onCompleted={(code: string) => onSubmitCode(code)}
            />
          </div>
          <div className="mx-auto">
            <a href="#" className="hover:underline text-blue-500" onClick={onResend}>
              Resend
            </a>
          </div>
        </div>
      )}
    </Modal>
  )
}
