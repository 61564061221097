import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { productTypeOptionsMap, ProductTypes } from 'config'
import { useEffect, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'
import { RenderInput } from 'utils/RenderInput'

import { ClosingCostSetup } from './ClosingCostSetup'

const ppItems = [
  'Hazard Insurance',
  'Mortgage Insurance',
  'School Tax',
  'Property Taxes',
  'Association Dues',
  'Flood Insurance',
  'Other Tax',
]

export const HUD1Configuration = () => {
  const [data, setData] = useState<Record<string, any>>({})
  const [productType, setProductType] = useState<ProductTypes>(ProductTypes.Bridge)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getAdminConfig('hud1')
      .then((value) => {
        setData(value)
      })
      .finally(() => setLoading(false))
  }, [])

  const onChangeCushion = (index: number, value: any) => {
    const temp = cloneDeep(data)
    temp.cushion[productType][index] = value
    setData(temp)
  }

  const onSubmit = async () => {
    setLoading(true)
    await setAdminConfig('hud1', data)
    setLoading(false)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />

      <div className="grid grid-cols-1 md:grid-cols-2 sm:gird-cols-2 gap-2">
        <div className="col-span-full">
          <RenderInput
            input={{
              inputType: 'section',
              title: 'Cushion',
              span: 'full',
            }}
            Key="section-cushion"
            onChange={() => {}}
          />
        </div>
        <div className="col-span-full">
          <RenderInput
            input={{
              inputType: 'select',
              title: 'Product Type',
              options: productTypeOptionsMap,
              value: productType,
            }}
            Key="productType"
            onChange={(key: string, value: any) => setProductType(value)}
          />
        </div>
        {ppItems.map((item, index: number) => {
          return (
            <RenderInput
              input={{
                inputType: 'text',
                type: 'number',
                title: item,
                value: data.cushion?.[productType][index],
              }}
              Key={item}
              key={item}
              onChange={(key: string, value: any) => onChangeCushion(index, value)}
              onBlur={onSubmit}
            />
          )
        })}
        <div className="col-span-full mt-8">
          <RenderInput
            input={{
              inputType: 'section',
              title: 'Closing Cost Setup',
            }}
            Key="section-ccsetup"
            onChange={() => {}}
          />
        </div>
        <div className="col-span-full">
          <ClosingCostSetup />
        </div>
      </div>
    </div>
  )
}
