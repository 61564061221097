import { isNonDSCRProduct } from 'pages/LoanStructure/Logic'
import { getPrice2decimal, removeComma } from 'utils'
import { getInterestOrNot } from 'utils/getInterestOrNot'

export function combinedMonthlyExpense(loan: any, rateData: any, withDSCR: boolean = false) {
  let interest_rate = rateData.rate
  let pricingType = rateData.type

  const proposedLoanAmount = removeComma(loan.proposedLoanAmount)
  const proposedMonthlyInsurance = removeComma(loan.proposedMonthlyInsurance)
  const proposedMonthlyTaxes = removeComma(loan.proposedMonthlyTaxes)
  const proposedMonthlyHoaDues = removeComma(loan.proposedMonthlyHoaDues)
  const proposedMonthlyRent = removeComma(loan.proposedMonthlyRent)
  const floodInsurance = removeComma(loan.floodInsurance)
  const schoolTax = removeComma(loan.schoolTax)
  const otherTax = removeComma(loan.otherTax)
  const rehabBudget = removeComma(loan.rehabBudget)
  const monthsReserve = removeComma(loan.monthsReserve)

  let mortgage_payment = (proposedLoanAmount * removeComma(interest_rate)) / 100
  mortgage_payment = mortgage_payment / 12
  let c_text1 = 'Monthly Interest Payment'

  if (!isNonDSCRProduct(loan.productType)) {
    if (!getInterestOrNot(pricingType)) {
      c_text1 = 'Monthly Principal and Interest'
      let loanTerm = 30
      if (pricingType?.indexOf('40') !== -1) loanTerm = 40
      const interest: number = removeComma(interest_rate) / 100
      const value = Math.pow(1 + interest / 12, 12 * loanTerm)
      mortgage_payment = Number((((proposedLoanAmount * interest) / 12) * value) / (value - 1))
    }
  }

  let secondLienPayment = 0
  if (removeComma(loan.secondLien) > 0) secondLienPayment = removeComma(loan.secondLienPayment)

  let Rlt: any = {
    title: c_text1,
    mortgagePayment: getPrice2decimal(Number(mortgage_payment.toFixed(2))),
    proposedMonthlyInsurance: getPrice2decimal(proposedMonthlyInsurance),
    proposedMonthlyTaxes: getPrice2decimal(proposedMonthlyTaxes),
    proposedMonthlyHoaDues: getPrice2decimal(proposedMonthlyHoaDues),
    secondLienPayment: getPrice2decimal(secondLienPayment),
    floodInsurance: getPrice2decimal(floodInsurance),
    schoolTax: getPrice2decimal(schoolTax),
    otherTax: getPrice2decimal(otherTax),
    rehabBudget: getPrice2decimal(rehabBudget),
  }

  const sum =
    removeComma(Rlt.mortgagePayment) +
    removeComma(Rlt.proposedMonthlyInsurance) +
    removeComma(Rlt.proposedMonthlyTaxes) +
    removeComma(Rlt.proposedMonthlyHoaDues) +
    removeComma(Rlt.secondLienPayment) +
    removeComma(Rlt.floodInsurance) +
    removeComma(Rlt.schoolTax) +
    removeComma(Rlt.otherTax)

  Rlt.sum = getPrice2decimal(sum)

  const rtlTraderPercent = 0 / 100
  Rlt.requiredReserves = getPrice2decimal((Number(sum) * monthsReserve + rehabBudget * rtlTraderPercent).toFixed(2))

  if (withDSCR) {
    let dscr: any = null
    if (removeComma(interest_rate) > 0) {
      dscr = removeComma(proposedMonthlyRent) / removeComma(sum)
      dscr = getPrice2decimal(dscr)
    }
    Rlt.dscr = dscr
  }
  return Rlt
}
