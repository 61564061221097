import { ArrowRightIcon, Bars3Icon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { GlobalConfigType, logout, signInRequest, USER_LOGIN_PAGE_MODAL_VIEWED } from 'actions'
import cloneDeep from 'clone-deep'
import { LogoBlue } from 'components/Logo'
import { LogInAlertModal, TfaModal } from 'components/Modals'
import { COMPANY_NAME, COMPANY_WEBSITE_URL, IS_MAIN_COMPANY } from 'config'
import { hasPagePermissions } from 'hooks/hasPagePermissions'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getLoginPageModal } from 'services'
import { getNavBarData } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'
import { Input } from 'stories/components'
import { delay, validateEmail } from 'utils'

import { renderNav } from '../utils'

interface menuType {
  label: String
  visible: Boolean | undefined
  href: any
}

type tpNavigation = {
  [key: string]: any
}

function _Header(props: any) {
  const [showMenu, setShowMenu] = useState(false)
  const [menus, setMenus] = useState<menuType[]>([])
  const {
    auth,
    menu: _menu,
    pagePermissions,
  } = useSelector((state: any) => ({
    auth: state.auth,
    menu: state.header.menu || {},
    pagePermissions: state.globalConfigReducer[GlobalConfigType.PagePermissions],
  }))
  const menu = (auth.isAuthenticated ? _menu?.brokerPortal : _menu.homePortal) || {}
  const { data: permissionData, hasPermission } = usePermissions()

  const [navigations, setNavigations] = useState<tpNavigation>({})
  const [logInAlerts, setLogInAlerts] = useState<Array<any>>([])
  const [is2fa, set2fa] = useState(false)
  const [phone, setPhone] = useState('')
  const [action, setAction] = useState('')
  const [companyBroadcast, setCompanyBroadcast] = useState(null)
  const [activeTasks, setActiveTasks] = useState(0)
  const [activeAppraisals, setActiveAppraisals] = useState(0)
  const [activeReminders, setActiveReminders] = useState(0)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const location: any = useLocation()
  const navigate = useHistory()

  const { header } = useSelector((state: any) => {
    return {
      header: state.header,
    }
  })

  const activeMenu = (to: String) => {
    if (location.pathname === to) {
      return 'shadow'
    }
    return ''
  }
  const [fields, setFields] = useState({
    email: '',
    password: '',
  })

  const dispatch = useDispatch()

  const handleLogout = () => {
    const { temparary, isBorrower, isInvestor } = auth.profile
    dispatch(logout())
    if (temparary && IS_MAIN_COMPANY) window.open(`https://${COMPANY_WEBSITE_URL}`, '_self')
    else if (isBorrower) navigate.push('/borrowerPipelines/signin')
    else if (isInvestor) navigate.push('/investorPipelines/signin')
    else navigate.push('/home')
  }

  const getNotificationCN = (size: number) => {
    return size >= 100 ? 'w-10 h-5 top-[-10px] right-[-28px]' : 'w-6 h-4 top-[-10px] right-[-18px]'
  }

  // Update navigations whenever menu or notifications change
  useEffect(() => {
    setShowMenu(false)
    const draft: tpNavigation = {}

    // Process menu items
    Object.entries(menu).forEach(([key, item]: [string, any]) => {
      if (item) {
        draft[key] = {
          name: item.title || key,
          href: item.link || `/${key.toLowerCase()}`,
          visible: item.value,
          order: item.order || 999,
          ...((key === 'bridgequal' || item.title == 'Qualify') && {
            name: `${IS_MAIN_COMPANY && key === 'bridgequal' ? 'BridgeQual&#8482;' : 'Qualify'}`,
            className: 'bg-shade-blue rounded text-white hover:bg-white',
          }),
          ...(key === 'register' && {
            className: 'bg-shade-blue rounded text-white hover:bg-white',
          }),
        }
      }
    })

    // Update visibility based on permissions and authentication
    Object.entries(draft).forEach(([key, item]) => {
      if (key === 'reports') {
        item.visible = Boolean(hasPermission('MANAGE_REPORTS') && hasPagePermissions(['reports']))
      } else if (key === 'resources') {
        item.visible = Boolean(hasPagePermissions(['resources']))
      } else if (key === 'reminders') {
        item.visible = Boolean(hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION') && hasPagePermissions(['reminders']))
      } else if (key === 'appraisals') {
        item.visible = Boolean(hasPermission('MANAGE_APPRAISAL_INFORMATION') && hasPagePermissions(['appraisals']))
      } else if (key === 'task') {
        item.visible = Boolean(hasPermission('MANAGE_TASKS') && hasPagePermissions(['tasks']))
      }

      // Update visibility based on authentication state
      if (auth.isAuthenticated) {
        if (['home', 'about_us', 'investors', 'how_it_works', 'where_we_lend', 'contacts'].includes(key)) {
          item.visible = false
        }
        if (key === 'pipeline') item.visible = true
        if (key === 'bridgequal') item.visible = true
        if (key === 'register') item.visible = false
      } else {
        if (['pipeline', 'reports', 'reminders', 'appraisals', 'task'].includes(key)) {
          item.visible = false
        }
        if (key === 'home') item.visible = !IS_MAIN_COMPANY
        if (['about_us', 'investors', 'how_it_works', 'where_we_lend', 'contacts', 'resources'].includes(key)) {
          item.visible = IS_MAIN_COMPANY
        }
        if (key === 'bridgequal') item.visible = true
        if (key === 'register') item.visible = true
      }
    })

    // Handle special cases for notifications
    const notificationItems = [
      { key: 'task', label: 'Tasks', count: activeTasks },
      { key: 'appraisals', label: 'Appraisals', count: activeAppraisals },
      { key: 'reminders', label: 'Reminders', count: activeReminders },
    ]

    notificationItems.forEach(({ key, label, count }) => {
      const item = draft[key]
      if (item?.visible && count) {
        item.name = `
          <div class="relative">
            ${label}<div class="absolute ${getNotificationCN(
          count,
        )} text-sm flex items-center justify-center text-white rounded-full bg-red-700">${count}</div>
          </div>
        `
      } else if (item?.visible) {
        item.name = label
      }
    })

    // Sort the navigations based on order value
    const sortedNavigations = Object.fromEntries(
      Object.entries(draft).sort(([, a], [, b]) => (a.order || 999) - (b.order || 999)),
    )

    setNavigations(sortedNavigations)
  }, [
    menu,
    auth.isAuthenticated,
    IS_MAIN_COMPANY,
    hasPermission,
    hasPagePermissions,
    activeTasks,
    activeAppraisals,
    activeReminders,
  ])

  useEffect(() => {
    if (!auth.isAuthenticated) {
      setCompanyBroadcast(null)
    } else {
      updateActiveNotification()
    }
  }, [auth.isAuthenticated, header.activeChangeFlag, location])

  useEffect(() => {
    if (auth.isAuthenticated) {
      getLoginPageModal().then((res) => {
        setLogInAlerts(res)
      })
    }
  }, [auth.isAuthenticated])

  const updateActiveNotification = () => {
    if (auth.profile.temparary !== true && auth.isAuthenticated) {
      getNavBarData().then((res) => {
        setCompanyBroadcast(res.broadcast)
        setActiveTasks(Number(res.activeTasks))
        setActiveAppraisals(Number(res.activeAppraisals))
        setActiveReminders(Number(res.activeReminders))
      })
    }
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        updateActiveNotification()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (!permissionData) return

    const menuConfig = [
      {
        label: 'Manage Accounts',
        href: '/registrations',
        permissions: ['MANAGE_ACCOUNTS'],
        pages: ['manage_users', 'registrations'],
      },
      {
        label: 'Manage Borrowers',
        href: '/borrowers',
        permissions: ['MANAGE_BORROWERS'],
        pages: ['manage_users', 'borrowers'],
      },
      {
        label: 'Manage Loan Scenarios',
        href: '/loanScenarios',
        permissions: ['MANAGE_SCENARIOS'],
        pages: ['manage_users', 'loanScenarios'],
      },
      {
        label: 'Manage Investors',
        href: '/manageInvestors',
        permissions: ['MANAGE_INVESTORS'],
        pages: ['manage_users', 'manageInvestors'],
      },
      {
        label: 'Manage Participants',
        href: '/manageParticipants',
        permissions: ['MANAGE_PARTICIPANTS'],
        pages: ['manage_users', 'manageParticipants'],
      },
      {
        label: 'Rate Sheet',
        href: '/ratesheet',
        pages: ['manage_users', 'ratesheet'],
        visible: menu.ratesheet?.visible,
      },
      {
        label: 'Edit Profile & Info',
        href: '/edit_profile',
        visible: true,
      },
      {
        label: 'Submit a Ticket',
        href: '/tickets',
        permissions: ['MANAGE_ADMIN_TOOLS'],
        visible: !IS_MAIN_COMPANY,
      },
      {
        label: 'divider',
        href: '#',
        visible: true,
      },
      {
        label: 'Accounting',
        href: '/accountings/accountingCategories',
        permissions: ['MANAGE_ACCOUNTING'],
        pages: ['manage_accountings'],
      },
      {
        label: 'Vendors',
        href: '/vendors/creditReport',
        permissions: ['MANAGE_VENDORS'],
        pages: ['vendors'],
      },
      {
        label: 'Conditions & Templates',
        href: '/condition_template/conditions',
        permissions: ['MANAGE_CONDITIONS_TEMPLATES'],
        pages: ['condition_template', 'conditions'],
      },
      {
        label: 'Admin Tools',
        href: '/admin_tool/permissions',
        permissions: ['MANAGE_ADMIN_TOOLS', 'MANAGE_APPRAISER_SETTLEMENT_CONTRACTOR', 'MANAGE_REVIEWS'],
        pages: ['admin_tool', 'permissions'],
      },
      {
        label: 'Secondary Marketing',
        href: '/secondary_marketing/permissions',
        permissions: ['MANAGE_ADMIN_TOOLS'],
        pages: ['manage_users', 'secondary_marketing'],
      },
      {
        label: 'User Activity',
        href: '/user_activity',
        permissions: ['MANAGE_USER_ACTIVITY'],
        pages: ['manage_users', 'user_activity'],
      },
      {
        label: 'divider',
        href: '#',
        visible: true,
      },
    ]

    const draftMenus = menuConfig.map((item) => ({
      label: item.label,
      href: item.href,
      visible:
        item.visible ??
        ((!item.permissions || item.permissions.every((p) => hasPermission(p))) &&
          (!item.pages || item.pages.every((p) => hasPagePermissions([p])))),
    }))

    setMenus(draftMenus)
  }, [permissionData, pagePermissions])

  const onChangeLoginForm = (key: string, value: string) => {
    let temp: any = cloneDeep(fields)
    temp[key] = value
    setFields(temp)
  }

  const queryClient = useQueryClient()

  const submitLogin = async () => {
    let errors = 0
    fields.email = fields.email.trim().toLowerCase()
    if (!validateEmail(fields.email)) errors += 1
    if (fields.password.length === 0) errors += 1
    if (errors) {
      return toast('Invalid Email Address or Password!', { type: 'error' })
    }
    setAction('Signing')
    await queryClient.clear()
    props.signInRequest(
      fields,
      async (isPass2fa: boolean, phone: string, requireReset: boolean) => {
        if (requireReset) {
          navigate.push('/resetPassword')
        } else {
          if (isPass2fa) {
            setFields({
              email: '',
              password: '',
            })
            if (location.state?.from) {
              navigate.push(location.state.from)
            } else {
              if (!navigate.location.pathname.includes('/loan_process/structure/new')) {
                await delay(0.5)
                navigate.push('/pipeline')
              }
            }
          } else {
            set2fa(true)
            setPhone(phone)
          }
          setAction('')
        }
      },
      () => {
        setAction('')
      },
    )
  }

  const keyPress = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      submitLogin()
      e.preventDefault()
    }
  }

  const renderLoginForm = () => {
    if (!IS_MAIN_COMPANY) return null
    if (auth.isAuthenticated) return null
    return (
      <div className="login-form-container">
        <div className="flex justify-end flex-wrap gap-2 md:pr-4 mt-2">
          <Input
            type="text"
            title="Email"
            value={fields.email}
            onChange={(value) => onChangeLoginForm('email', value)}
            className="-mb-4 w-[240px]"
          />
          <Input
            type="password"
            title="Password"
            value={fields.password}
            onChange={(value) => onChangeLoginForm('password', value)}
            className="-mb-4 w-[240px]"
            onKeyDown={(e) => keyPress(e)}
          />
          <div className="flex items-center" onClick={submitLogin}>
            <div className="hover:underline text-shade-blue cursor-pointer flex flex-wrap text-[14px] items-center gap-1">
              <UserIcon className="w-3 h-3"></UserIcon>
              <span className="font-bold">{`LogIn`}</span>
              <ArrowRightIcon className="w-3 h-4"></ArrowRightIcon>
              {action === 'Signing' && <img src={svgLoading} className="inline w-4 h-4 ml-2 text-white animate-spin" />}
            </div>
          </div>
        </div>
        <div className="flex justify-end md:pr-4">
          <Link to="/forgetPassword">
            <p className="block mt-1 text-[13px] hover:text-red-900 hover:underline text-red-800">
              Forgot/Reset your password?
            </p>
          </Link>
        </div>
        {is2fa && (
          <TfaModal email={fields.email} phone={phone} password={fields.password} onClose={() => set2fa(false)} />
        )}
      </div>
    )
  }

  const handleLoginModalViewed = () => {
    dispatch({
      type: USER_LOGIN_PAGE_MODAL_VIEWED,
    })
  }

  return (
    <>
      {companyBroadcast && (
        <div className="bg-red-100 text-red-900 font-bold w-full p-3 text-center">{companyBroadcast}</div>
      )}

      <div>
        <div className="max-w-screen-2xl m-auto relative py-4 px-2 bg-white">
          <nav className="relative flex items-center justify-between" aria-label="Global">
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link to="/home">
                  <span className="sr-only">{COMPANY_NAME}</span>
                  <LogoBlue />
                </Link>
                <div className="mr-2 flex items-center md:hidden">
                  <div
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 hover:outline-none hover:ring-2 hover:ring-inset hover:ring-blue-500"
                    onClick={() => setShowMobileMenu(true)}
                  >
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-wrap items-center md:flex md:ml-10 md:pr-4 md:space-x-8">
              {Object.keys(navigations).map((key, index) => {
                const item: any = navigations[key]
                if (item.visible && menu[key]?.value) {
                  return renderNav(
                    item,
                    `nav-${index}`,
                    `hover:shadow font-variation-settings-600 px-3 py-2 my-2 text-gray-600 hover:text-gray-900 ${activeMenu(
                      item.href,
                    )} ${item.className} `,
                  )
                }
              })}
              {auth.isAuthenticated && [
                <div className="flex header-profile-name text-gray-600" key={0}>
                  <UserIcon className="w-5 h-5"></UserIcon>
                  <span className="font-normal ml-1 text-[15px]">
                    {auth.role?.isBrokerChild ? auth.role.brokerChildEmail : auth.profile.name}
                  </span>
                </div>,
                <div
                  key={1}
                  className="relative p-2 space-y-1.5 hover:bg-gray-600 hover:cursor-pointer rounded shadow"
                  onMouseEnter={() => setShowMenu(true)}
                  onMouseLeave={() => setShowMenu(false)}
                >
                  <span className={`block rounded w-8 h-1 bg-gray-${showMenu ? 100 : 600}`}></span>
                  <span className={`block rounded w-8 h-1 bg-gray-${showMenu ? 100 : 600}`}></span>
                  <span className={`block rounded w-8 h-1 bg-gray-${showMenu ? 100 : 600}`}></span>
                  {showMenu && (
                    <div className="absolute p-5 bg-gray-600 w-72 rounded right-0 space-y-4 z-50">
                      {menus.map((item, index) => {
                        if (item.visible) {
                          if (item.label === 'divider') {
                            return <div key={`menu-${index}`} className="block rounded h-px bg-gray-400"></div>
                          }
                          return (
                            <div
                              className="text-white font-medium pl-1 hover:underline"
                              key={`menu-${index}`}
                              onClick={() => navigate.push(item.href)}
                            >
                              {item.label}
                            </div>
                          )
                        }
                      })}
                      <div className="text-white font-medium hover:underline pl-1" onClick={handleLogout}>
                        Sign Out
                      </div>
                    </div>
                  )}
                </div>,
              ]}
            </div>
          </nav>
          {renderLoginForm()}
          {auth.isAuthenticated && !auth.loginPageModalShowed && logInAlerts.length > 0 && (
            <LogInAlertModal data={logInAlerts} onClose={() => handleLoginModalViewed()} />
          )}
        </div>
        {showMobileMenu && (
          <div>
            <div className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <LogoBlue />
                  </div>
                  <div className="-mr-2">
                    <div
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 hover:outline-none hover:ring-2 hover:ring-inset hover:ring-blue-500"
                      onClick={() => setShowMobileMenu(false)}
                    >
                      <span className="sr-only">Close main menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {Object.keys(navigations).map((key, index) => {
                    const item: any = navigations[key]
                    if (item.visible) {
                      return renderNav(
                        item,
                        `${index}`,
                        `block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50 ${activeMenu(
                          item.href,
                        )}`,
                      )
                    }
                  })}
                  {auth.isAuthenticated && (
                    <div>
                      <div className="block rounded h-px bg-gray-100"></div>
                      {menus.map(
                        (item, index) =>
                          item.visible &&
                          (item.label === 'divider' ? (
                            <div className="block rounded h-px bg-gray-100" key={index}></div>
                          ) : (
                            <Link
                              key={`trans-menu-${index}`}
                              to={item.href}
                              className={`block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50 ${activeMenu(
                                item.href,
                              )}`}
                            >
                              {item.label}
                            </Link>
                          )),
                      )}
                      <div
                        className="block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50"
                        onClick={handleLogout}
                      >
                        Sign Out
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

function mapStateToProps() {
  return {}
}

export const Header = connect(mapStateToProps, { signInRequest })(_Header)
