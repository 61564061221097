import { FieldOrigin, groupedFields, ISelectRangeOption, ISelectValueOption, LoanFieldProps } from 'config'
import { loanOriginsWithSections } from 'config/loan.input.fields.constants'
import { defaultInputs as borrowerInformationInputs } from 'pages/LoanApplication/BorrowerInformation/constants'
import { financesInputs, hmdaInputs, propertyInputs } from 'pages/LoanApplication/DeclarationsHmda/constants'
import { defaultInputs as propertyInformationInputs } from 'pages/LoanApplication/PropertyInformation/constants'
import { IBankruptcyRange, IMortgageLatesRange } from 'pages/Resources'
import { getBankruptcyLabel, getMortgageLatesLabel } from 'pages/Resources/GuidelineOverlay/logic'
import { isNull } from 'utils'

import { assetLiabilityInputs } from './inputs'

export const setLoanFieldDefaultSection = (data: LoanFieldProps) => {
  loanOriginsWithSections.forEach((origin) => {
    if (!data[origin]) data[origin] = {}
  })

  const defaultSectionFields: any = {
    [FieldOrigin.BorrowerInformation]: borrowerInformationInputs(),
    [FieldOrigin.PropertyInformation]: propertyInformationInputs(),
    [FieldOrigin.AssetLiability]: assetLiabilityInputs(),
  }

  Object.keys(defaultSectionFields).forEach((fieldOrigin) => {
    const fields = Object.keys(defaultSectionFields[fieldOrigin])
    fields.forEach((fieldKey) => {
      if (!data[fieldOrigin][fieldKey]) data[fieldOrigin][fieldKey] = {}
      data[fieldOrigin][fieldKey].section = `1`
    })
  })

  Object.keys(propertyInputs).forEach((fieldKey) => {
    if (!data[FieldOrigin.DeclarationsHMDA][fieldKey]) data[FieldOrigin.DeclarationsHMDA][fieldKey] = {}
    data[FieldOrigin.DeclarationsHMDA][fieldKey].section = `1`
  })

  Object.keys(financesInputs).forEach((fieldKey) => {
    if (!data[FieldOrigin.DeclarationsHMDA][fieldKey]) data[FieldOrigin.DeclarationsHMDA][fieldKey] = {}
    data[FieldOrigin.DeclarationsHMDA][fieldKey].section = `2`
  })

  Object.keys(hmdaInputs()).forEach((fieldKey) => {
    if (!data[FieldOrigin.DeclarationsHMDA][fieldKey]) data[FieldOrigin.DeclarationsHMDA][fieldKey] = {}
    data[FieldOrigin.DeclarationsHMDA][fieldKey].section = `4`
  })

  const originalFieldKeys = [
    ...Object.keys(propertyInputs),
    ...Object.keys(financesInputs),
    ...Object.keys(hmdaInputs()),
  ]

  Object.keys(data[FieldOrigin.DeclarationsHMDA])
    .filter((fieldKey) => !originalFieldKeys.includes(fieldKey))
    .forEach((fieldKey) => {
      if (!data[FieldOrigin.DeclarationsHMDA][fieldKey]) data[FieldOrigin.DeclarationsHMDA][fieldKey] = {}
      if (!data[FieldOrigin.DeclarationsHMDA][fieldKey].section)
        data[FieldOrigin.DeclarationsHMDA][fieldKey].section = `3`
      if (!data[FieldOrigin.DeclarationsHMDA][fieldKey].inputType)
        data[FieldOrigin.DeclarationsHMDA][fieldKey].inputType = `ToggleButton`
    })

  const defaultSections = {
    [FieldOrigin.LoanStructure]: groupedFields.map((fields, index) => {
      const newId = index + 1

      fields.forEach((fieldKey) => {
        if (!data[FieldOrigin.LoanStructure][fieldKey]) data[FieldOrigin.LoanStructure][fieldKey] = {}
        data[FieldOrigin.LoanStructure][fieldKey].section = `${newId}`
      })

      return {
        id: newId,
        fields,
        title: `Section ${index + 1}`,
      }
    }),
    [FieldOrigin.BorrowerInformation]: [
      {
        id: 1,
        fields: Object.keys(borrowerInformationInputs()),
        title: 'Section 1',
      },
    ],
    [FieldOrigin.PropertyInformation]: [
      {
        id: 1,
        fields: Object.keys(propertyInformationInputs()),
        title: 'Section 1',
      },
    ],
    [FieldOrigin.AssetLiability]: [
      {
        id: 1,
        fields: Object.keys(assetLiabilityInputs()),
        title: 'Section 1',
      },
    ],
    [FieldOrigin.DeclarationsHMDA]: [
      {
        id: 1,
        fields: Object.keys(propertyInputs),
        title: 'About this Property and Your Money for this Loan',
      },
      {
        id: 2,
        fields: Object.keys(financesInputs),
        title: 'About Your Finances',
      },
      {
        id: 3,
        fields: Object.keys(data[FieldOrigin.DeclarationsHMDA]).filter(
          (fieldKey) => !originalFieldKeys.includes(fieldKey),
        ),
        title: 'Additional',
      },
      {
        id: 4,
        fields: Object.keys(hmdaInputs()),
        title: 'HMDA',
      },
    ],
  }

  if (!data.sections) data.sections = {}

  Object.keys(defaultSections).forEach((_fieldOrigin: any) => {
    const fieldOrigin = _fieldOrigin as FieldOrigin
    if (data.sections && data.sections[fieldOrigin]) return
    ;(data.sections as any)[fieldOrigin] = (defaultSections as any)[fieldOrigin]
  })

  if (!data.order)
    data.order = [
      FieldOrigin.BorrowerInformation,
      FieldOrigin.PropertyInformation,
      FieldOrigin.AssetLiability,
      FieldOrigin.TrackRecord,
      FieldOrigin.DeclarationsHMDA,
      'sign',
      'credit',
      'additionalMember',
    ] as any
  data.order!.push(
    ...(Object.keys(data).filter(
      (key) =>
        ![
          FieldOrigin.LoanStructure,
          'sections',
          'order',
          'customTitle',
          'visibility',
          'additionalInputs',
          'accountTypes',
        ].includes(key) && !data.order?.includes(key as any),
    ) as FieldOrigin[]),
  )

  return data
}

export const convertSelectRangeOptionsToMap = (
  options?: ISelectRangeOption[] | ISelectValueOption[],
): Record<string, string> => {
  if (!options || !options?.length) return {}

  if (options[0] && 'days' in options[0])
    return (options as ISelectRangeOption[])
      .filter((v) => v.visible !== false)
      .reduce((acc: Record<string, string>, opt) => {
        const { from, title, to = null, days = null, months = null } = opt
        acc[`${from}-${to}-${days}-${months}`] = title
        return acc
      }, {})
  else if (options[0] && 'chapter' in options[0])
    return (options as ISelectRangeOption[])
      .filter((v) => v.visible !== false)
      .reduce((acc: Record<string, string>, opt) => {
        const { from, title, to = null, chapter = null } = opt
        acc[`${chapter}-${from}-${to}`] = title
        return acc
      }, {})
  else if (options[0] && 'from' in options[0])
    return (options as ISelectRangeOption[])
      .filter((v) => v.visible !== false)
      .reduce((acc: Record<string, string>, opt) => {
        const { from, title, to = null } = opt
        acc[`${from}-${to}`] = title
        return acc
      }, {})
  else
    return (options as ISelectValueOption[])
      .filter((v) => v.visible !== false)
      .reduce((acc: Record<string, string>, opt) => {
        const { title, value } = opt
        acc[value] = title
        return acc
      }, {})
}

export const rangeToTitle = (value: ISelectRangeOption, key: string) => {
  const { from, to } = value
  let suffix = ''
  switch (key) {
    case 'experience':
      suffix = (from === 0 && to === 0) || (from === 1 && to === 1) ? ' Transaction' : ' Transactions'
      break
    case 'foreclosure':
    case 'deedInLieu':
    case 'shortSale':
    case 'forbearanceLoanModification':
      suffix = ' Months'
      break
  }

  if (isNull(from) && isNull(to)) return `None`
  else if (!isNull(from) && isNull(to)) return `${from}+${suffix}`
  else if (isNull(from) && !isNull(to)) return `<= ${to}${suffix}`
  else {
    if (from === to) return `${from}${suffix}`
    return `${from} - ${to}${suffix}`
  }
}

export const valueToTitle = (data: ISelectValueOption, key: string) => {
  const { value } = data
  let suffix = ''
  switch (key) {
    case 'loanTerm':
      suffix = ' Months'
      break
  }

  if (isNull(value)) return `None`
  else return `${value}${suffix}`
}

export const generateTitleFromRange = (range: ISelectRangeOption | ISelectValueOption, key: string): string => {
  if (!range) return ''
  if ('value' in range) {
    return valueToTitle(range, key)
  } else if ('from' in range) {
    if (key === 'bankruptcy') return getBankruptcyLabel(range as IBankruptcyRange)
    else if (key === 'mortgageLates') return getMortgageLatesLabel(range as IMortgageLatesRange)
    else return rangeToTitle(range, key)
  }
  return ''
}
