import { FieldOrigin, ILoanFieldPos } from 'config/loan.input.visibility.type'

export const requiredFields: Array<{ needed: ILoanFieldPos | null; required: ILoanFieldPos[] }> = [
  {
    needed: null,
    required: [
      { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'firstName' },
      { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'lastName' },
      { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'email' },
      { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'phone' },
      // { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'presentAddress' },
      { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'subjectPropertyAddress' },
      { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'productType' },
      { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'transactionType' },
    ],
  },
]

export const ignoredFields: ILoanFieldPos[] = [
  { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'borrowerFirstName' },
  { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'borrowerMiddleName' },
  { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'borrowerLastName' },
  { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'residency' },
]

export const loanProposedMonthlyFields = [
  'proposedMonthlyTaxes',
  'proposedMonthlyInsurance',
  'proposedMonthlyRent',
  'proposedMonthlyHoaDues',
]

export const isMonthlyInput = ({ fieldOrigin, fieldKey }: { fieldOrigin: FieldOrigin; fieldKey: string }) =>
  fieldOrigin == FieldOrigin.LoanStructure && loanProposedMonthlyFields.includes(fieldKey)
