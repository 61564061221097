import {
  API_LOAN_ADD_PARTY,
  API_LOAN_DELETE_PARTY,
  API_LOAN_DOWNLOAD_PARTY,
  API_LOAN_GET_BORROWER_REQUIRED_PARTIES,
  API_LOAN_GET_PARTIES,
  API_LOAN_GET_PARTY_IDS,
  API_LOAN_IMPORT_PARTY,
  API_LOAN_PARTY_ADDITIONAL_INFO,
  API_LOAN_PARTY_OPTIONS,
  API_LOAN_UPDATE_PARTIES,
  API_LOAN_UPDATE_PARTY,
} from 'config'
import Api from 'services/api'

import { downloadFile } from '../documents'

export const updateParty = (data: any) => {
  return Api.post(API_LOAN_UPDATE_PARTY, data)
}

export const getPartyOptions = (key: string) => {
  return Api.get(API_LOAN_PARTY_OPTIONS, {}, { key })
}

export const getParties = (loanNumber: number) => {
  return Api.get(API_LOAN_GET_PARTIES, {}, { loanNumber })
}

export const getPartyAdditionalInfo = (type: string) => {
  return Api.get(API_LOAN_PARTY_ADDITIONAL_INFO, {}, { type })
}

export const updateParties = (loanNumber: number, PartyID: number, data: Record<string, any>) => {
  return Api.post(API_LOAN_UPDATE_PARTIES, data, { loanNumber, PartyID }, { timeout: 150000 })
}

export const addParty = (loanNumber: number, type: string) => {
  return Api.post(API_LOAN_ADD_PARTY, { type }, { loanNumber })
}

export const deleteParty = (loanNumber: number, type: string) => {
  return Api.post(API_LOAN_DELETE_PARTY, { type }, { loanNumber })
}

export const getPartyIds = () => {
  return Api.get(API_LOAN_GET_PARTY_IDS)
}

export const getBorrowerRequiredParties = () => {
  return Api.get(API_LOAN_GET_BORROWER_REQUIRED_PARTIES)
}

export const downloadParties = async (loanNumber: number, isSample: boolean = false, isRolodex: boolean = false) => {
  const res: Blob = await Api.get(
    API_LOAN_DOWNLOAD_PARTY,
    { isSample },
    { loanNumber },
    {
      responseType: 'blob',
    },
  )
  downloadFile(`${isRolodex ? 'Rolodex' : 'Parties'} - ${isSample ? 'Sample' : loanNumber}.csv`, res)
}

export const importParties = (loanNumber: number, data: Record<string, any>[]) => {
  return Api.post(API_LOAN_IMPORT_PARTY, data, { loanNumber })
}
