import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { AUTH_LOGOUT } from 'actions/types'
import { LogoBlue } from 'components/Logo'
import { COMPANY_NAME, COMPANY_WEBSITE_URL, IS_MAIN_COMPANY } from 'config'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { renderNav } from '../utils'

interface menuType {
  label: String
  visible: Boolean | undefined
  href: any
}

interface MenuItem {
  name: string
  href: string
  visible: boolean
  order: number
  className?: string
}

type tpNavigation = {
  [key: string]: MenuItem
}

export function BorrowerHeader() {
  const [menus, setMenus] = useState<menuType[]>([])
  const [showMenu, setShowMenu] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const { auth, menu } = useSelector((state: any) => ({
    auth: state.auth,
    menu: state.header.menu.borrowerPortal || {},
  }))

  const location = useLocation()
  const navigate = useHistory()

  const [navigations, setNavigations] = useState<tpNavigation>({})

  useEffect(() => {
    const processedMenu: tpNavigation = {}

    // Process menu items from state
    Object.entries(menu).forEach(([key, item]: [string, any]) => {
      if (item) {
        processedMenu[key] = {
          name: item.title || key,
          href: key === 'home' ? item.link || `https://${COMPANY_WEBSITE_URL}` : item.link || `/${key.toLowerCase()}`,
          visible: item.value,
          order: item.order || 999,
        }
      }
    })

    // Add className for applyNow if it exists
    if (processedMenu.applyNow) {
      processedMenu.applyNow.className = 'bg-shade-blue rounded text-white hover:bg-white'
    }

    // Sort navigations by order
    const sortedMenu = Object.fromEntries(
      Object.entries(processedMenu).sort(([, a], [, b]) => (a.order || 999) - (b.order || 999)),
    ) as tpNavigation

    setNavigations(sortedMenu)
  }, [menu])

  useEffect(() => {
    setMenus([
      {
        label: 'Edit Profile & Info',
        href: '/borrowerPipeline/editProfile',
        visible: true,
      },
      {
        label: 'divider',
        href: '#',
        visible: true,
      },
    ])
  }, [])

  const activeMenu = (to: String) => {
    if (location.pathname === to) {
      return 'shadow'
    }
    return ''
  }

  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch({ type: AUTH_LOGOUT })
    window.location.href = '/borrowerPipelines/signin'
  }

  return (
    <div>
      <div className="max-w-screen-2xl m-auto relative py-4 px-2 bg-white">
        <nav className="relative flex items-center justify-between" aria-label="Global">
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to={IS_MAIN_COMPANY ? '/home' : '/borrowerPipelines'}>
                <span className="sr-only">{COMPANY_NAME}</span>
                <LogoBlue />
              </Link>
              <div className="mr-2 flex items-center md:hidden">
                <div
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 hover:outline-none hover:ring-2 hover:ring-inset hover:ring-blue-500"
                  onClick={() => setShowMobileMenu(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
          <div className="hidden flex-wrap items-center md:flex md:ml-10 md:pr-4 md:space-x-8">
            {Object.keys(navigations).map((key, index) => {
              const item: any = navigations[key]
              if (item.visible && (menu[key]?.value || key == 'home')) {
                return renderNav(
                  item,
                  `nav-${index}`,
                  `hover:shadow font-variation-settings-600 px-3 py-2 my-2 text-gray-600 hover:text-gray-900 ${activeMenu(
                    item.href,
                  )} ${item.className} `,
                )
              }
            })}
            {auth.isAuthenticated && [
              <div
                key={1}
                className="relative p-2 space-y-1.5 hover:bg-gray-600 hover:cursor-pointer rounded shadow"
                onMouseEnter={() => setShowMenu(true)}
                onMouseLeave={() => setShowMenu(false)}
              >
                <span className={`block rounded w-8 h-1 bg-gray-${showMenu ? 100 : 600}`}></span>
                <span className={`block rounded w-8 h-1 bg-gray-${showMenu ? 100 : 600}`}></span>
                <span className={`block rounded w-8 h-1 bg-gray-${showMenu ? 100 : 600}`}></span>
                {showMenu && (
                  <div className="absolute p-5 bg-gray-600 w-72 rounded right-0 space-y-4 z-50">
                    {menus.map((item, index) => {
                      if (item.visible) {
                        if (item.label === 'divider') {
                          return <div key={`menu-${index}`} className="block rounded h-px bg-gray-400"></div>
                        }
                        return (
                          <div
                            className="text-white font-medium pl-1 hover:underline"
                            key={`menu-${index}`}
                            onClick={() => navigate.push(item.href)}
                          >
                            {item.label}
                          </div>
                        )
                      }
                    })}
                    <div className="text-white font-medium hover:underline pl-1" onClick={handleLogout}>
                      Sign Out
                    </div>
                  </div>
                )}
              </div>,
            ]}
          </div>
        </nav>
      </div>
      {showMobileMenu && (
        <div>
          <div className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <LogoBlue />
                </div>
                <div className="-mr-2">
                  <div
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 hover:outline-none hover:ring-2 hover:ring-inset hover:ring-blue-500"
                    onClick={() => setShowMobileMenu(false)}
                  >
                    <span className="sr-only">Close main menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                {Object.keys(navigations).map((key, index) => {
                  const item: any = navigations[key]
                  if (item.visible && (menu[key]?.value || key == 'home')) {
                    return renderNav(
                      item,
                      `${index}`,
                      `block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50 ${activeMenu(
                        item.href,
                      )}`,
                    )
                  }
                })}
                {auth.isAuthenticated && (
                  <div>
                    <div className="block rounded h-px bg-gray-100"></div>
                    {menus.map(
                      (item, index) =>
                        item.visible &&
                        (item.label === 'divider' ? (
                          <div className="block rounded h-px bg-gray-100" key={index}></div>
                        ) : (
                          <Link
                            key={`trans-menu-${index}`}
                            to={item.href}
                            className={`block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50 ${activeMenu(
                              item.href,
                            )}`}
                          >
                            {item.label}
                          </Link>
                        )),
                    )}
                    <div
                      className="block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50"
                      onClick={handleLogout}
                    >
                      Sign Out
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
