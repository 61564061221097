import { ArrowDownTrayIcon, ArrowPathIcon, CheckIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  checkLightningRecordStatus,
  createLightningRecord,
  deleteLightningRecord,
  getAllLightningRecord,
} from 'services'
import { Button } from 'stories/components'
import { confirm, formatTime } from 'utils'

import { LightningDocsStatus } from './constants'

export default function LightningDocs({ hasOverview = true }: { hasOverview?: boolean }) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Array<Record<string, any>>>([])
  const [tableData, setTableData] = useState<Record<string, any>>({})
  const [isValidated, setIsValidated] = useState(false)

  const initData = async () => {
    setLoading(true)
    const { records, tableData, isValidated } = await getAllLightningRecord()
    setData(records)
    setTableData(tableData)
    setIsValidated(isValidated)
    setLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  const onAdd = async (isTest: boolean = false) => {
    setLoading(true)
    const res = await createLightningRecord(isTest)
    if (res.success && res.data) {
      const temp = [res.data, ...data]
      setData(temp)
      toast('Successfully created Lightning Docs Request', { type: 'success' })
    }
    setLoading(false)
  }

  const onDelete = async (recordId: string) => {
    const isContinue = await confirm('Are you sure you want to delete this record?')
    if (!isContinue) return
    setLoading(true)
    const res = await deleteLightningRecord(recordId)
    if (res.success) {
      const temp = data.filter((item) => item.recordId !== recordId)
      setData(temp)
      toast('Successfully deleted!', { type: 'info' })
    }
    setLoading(false)
  }

  const checkStatus = async (recordId: string) => {
    setLoading(true)
    const res = await checkLightningRecordStatus(recordId)
    const temp = cloneDeep(data)
    const existing = temp.find((item) => item.recordId === recordId)
    if (!existing) return
    existing.apps = [res]
    existing.status = res.status === 'Ok' ? LightningDocsStatus.COMPLETED : LightningDocsStatus.PENDING
    setData(temp)
    setLoading(false)
  }

  const dowloadFiles = async (files: Record<string, any>[]) => {
    files.map((item: any) => {
      let a = document.createElement('a')
      a.href = item.publicUrl
      a.download = item.name
      a.click()
    })
  }

  let globalIdx = 1

  return (
    <div className="py-6 px-2">
      {hasOverview && <Overview title="Lightning Docs" />}
      <div className="max-w-screen-2xl m-auto">
        <LayoutLoading show={loading} />
        <div className={`relative bg-white rounded mb-4 ${hasOverview ? 'shadow1 md:p-6 p-2' : ''}`}>
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6 mb-2 rounded-md">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-3 py-3">
                  Category
                </th>
                <th scope="col" className="px-3 py-3 min-w-[200px]">
                  Field
                </th>
                <th scope="col" className="px-3 py-3">
                  Value
                </th>
                <th scope="col" className="px-3 py-3">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {Object.keys(tableData).map((category, index: number) => {
                const item = tableData[category]
                const count = Object.keys(item).length
                return (
                  <>
                    {Object.keys(item).map((key, subIndex: number) => {
                      return (
                        <tr key={`${index}-${subIndex}`} className={`border ${index % 2 ? 'bg-gray-50' : ''}`}>
                          {!subIndex && (
                            <td className="px-3 py-2" rowSpan={count}>
                              {category}
                            </td>
                          )}
                          <td className="px-3 py-2">
                            {globalIdx++}. {key}
                          </td>
                          <td className="px-3 py-2">{item[key].value}</td>
                          <td className="px-3 py-2 flex items-center">
                            {item[key].status ? (
                              <span className="ml-1 mb-0.5 text-shade-blue" key={0}>
                                <CheckIcon className="w-4 h-4"></CheckIcon>
                              </span>
                            ) : (
                              <span className="ml-1 mb-0.5 text-red-800" key={0}>
                                <XMarkIcon className="w-4 h-4"></XMarkIcon>
                              </span>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </>
                )
              })}
            </tbody>
          </table>
          <div className="text-right">
            <Button color="gray" onClick={() => onAdd(true)}>
              Test Sample
            </Button>
            <Button onClick={() => onAdd(false)} disabled={!isValidated}>
              Add
            </Button>
          </div>
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-3 py-3">
                  No
                </th>
                <th scope="col" className="px-3 py-3">
                  Created By
                </th>
                <th scope="col" className="px-3 py-3">
                  Created At
                </th>
                <th scope="col" className="px-3 py-3">
                  Status
                </th>
                <th scope="col" className="px-3 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {data.map((item, index: number) => {
                return (
                  <tr key={item.id}>
                    <td className="px-3 py-3">{index + 1}</td>
                    <td className="px-3 py-3">{item.email}</td>
                    <td className="px-3 py-3">{formatTime(item.createdAt)}</td>
                    <td className="px-3 py-3">
                      <div className="flex gap-2 items-center">
                        <span
                          className={`p-1 min-w-[100px] text-center ${
                            item.status === LightningDocsStatus.COMPLETED ? 'bg-green-300' : 'bg-gray-300'
                          } rounded-md`}
                        >
                          {item.status}
                        </span>
                      </div>
                    </td>
                    <td className="px-3 py-3 flex gap-2 items-center">
                      <div className="flex gap-2 items-center">
                        {item.status === LightningDocsStatus.COMPLETED && item.apps[0].files.length ? (
                          <span
                            className="p-1 hover-shadow1 cursor-pointer text-shade-blue"
                            onClick={() => dowloadFiles(item.apps[0].files)}
                          >
                            <ArrowDownTrayIcon className="w-4 h-4" />
                          </span>
                        ) : (
                          <span className="cursor-pointer hover-shadow1 p-1" onClick={() => checkStatus(item.recordId)}>
                            <ArrowPathIcon className="w-4 h-4" />
                          </span>
                        )}
                        <span
                          className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                          onClick={() => onDelete(item.recordId)}
                        >
                          <TrashIcon className="w-4 h-4" />
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
