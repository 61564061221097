import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useTitle(title: string) {
  document.title = title
}

export function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const useQueryRecord = () => {
  const { search } = useLocation()

  // Convert query string to a key-value pair object
  return useMemo(() => {
    const params = new URLSearchParams(search)
    const queryObject: Record<string, any> = {}
    for (const [key, value] of params.entries()) {
      queryObject[key] = value
    }
    return queryObject
  }, [search])
}
