import {
  ArrowDownTrayIcon,
  BanknotesIcon,
  ClockIcon,
  EnvelopeIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { BaseFile, baseUrl, InputType } from 'config'
import { fundingDisbursementInputs } from 'pages/Loan/Funding'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  addTrustLedger,
  createTrustLedgerInterest,
  deleteTrustLedger,
  deleteTrustLedgerInterest,
  getTrustLedger,
  getTrustLedgerOrderHistory,
  openS3Document,
  trustLedgerDrawIssuedAccountingTask,
  updateTrustLedger,
  updateTrustLedgerAdditional,
  updateTrustLedgerInterest,
} from 'services'
import { FormTable, Input2, Modal } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import {
  calculateSum,
  confirm,
  formatDate,
  formatTime,
  getFileName,
  getPrice2decimal,
  getPrice3decimal,
  InputConvert,
  InputValidate,
  isBeforeOrEqualToday,
  openAuditLog,
  removeComma,
  solveDecimalJavascriptSum,
} from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AfxModal } from './AfxModal'
import { ChooseDocumentsForTrustLedger } from './ChooseDocumentsForTrustLedger'
import { additionalInputs, defaultInputs, header, interestDefaultInputs, interestHeader } from './constants'
import { ConstructionLienWaiver } from './ConstructionLienWaiver'

export function TrustLedger() {
  const token = useSelector((state: any) => state.auth.token)
  const { vendor } = useSelector((state: any) => ({
    vendor: state.vendor,
  }))
  const [loading, setLoading] = useState(false)
  const [initData, setInitData] = useState<Record<string, any>>({})
  const [data, setData] = useState<Array<Record<string, any>>>([])
  const [additionalData, setAdditionalData] = useState<Record<string, any>>({})
  const [interestData, setInterestData] = useState<Array<any>>([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [documents, setDocuments] = useState<BaseFile[]>([])
  const [emailID, setEmailID] = useState(-1)
  const [inputs, setInputs] = useState<Record<string, InputType>>(additionalInputs())
  const [fieldValueChanged, setFieldValueChanged] = useState(false)
  const [history, setHistory] = useState({
    id: null,
    data: [],
  })
  const [isAfxOpen, setAfxOpen] = useState(false)
  const loanNumber = setLoanNumber()

  const init = () => {
    setLoading(true)
    getTrustLedger()
      .then((data = []) => {
        setData(data.data)
        setInitData(data)
        setInterestData(data.interestData)
        initInputs(data.additional)
        setAdditionalData(data.additional)
      })
      .finally(() => setLoading(false))
  }

  const {
    fundingInfo = {},
    rehabBudget = -1,
    constructionReserve = -1,
    interestReserve = -1,
    proposedLoanAmount,
    initialLoanAmount,
    afterRepairValue,
  } = initData

  useEffect(() => {
    setLoading(true)
    init()
  }, [])

  const initInputs = (additionalData: Record<string, any>) => {
    let newInputs = cloneDeep(inputs)
    Object.keys(additionalData).forEach((key) => {
      if (newInputs[key]) newInputs[key].value = additionalData[key]
    })
    newInputs = updateInputs(newInputs)
    setInputs(newInputs)
  }

  const interestDataWithBalance = useMemo(() => {
    let balance = interestReserve === -1 ? 0 : interestReserve
    interestData.map((item) => {
      balance = solveDecimalJavascriptSum([
        removeComma(balance),
        -1 * removeComma(item.payment),
        removeComma(item.deposit),
      ])
      item.balance = getPrice3decimal(balance)
      return item
    })
    return interestData
  }, [interestData, interestReserve])

  const showOrderHistory = async (id: number) => {
    setLoading(true)
    const res = await getTrustLedgerOrderHistory(id)
    if (res.success) {
      setHistory(res.data)
    }
    setLoading(false)
  }

  const dataWithBalance = useMemo(() => {
    let balance = constructionReserve === -1 ? 0 : constructionReserve
    let calcBalanceSum = 0
    data.map((item) => {
      item.ID = (
        <span
          className="cursor-pointer hover:underline font-semibold flex items-center hover:text-shade-blue"
          onClick={() => showOrderHistory(item.id)}
        >
          <span className="text-shade-blue">{item.id}</span>
          <ClockIcon className="w-3 h-3 ml-1"></ClockIcon>
        </span>
      )
      item.referenceWithFrom = (
        <span>
          <div className="border-b w-full mb-2">{item.reference}</div>
          {item.fromWhom}
        </span>
      )
      item.eligibleWithNetAmount = (
        <span>
          <div className="border-b w-full mb-2">${getPrice3decimal(item.eligibleAmount)}</div>$
          {getPrice3decimal(item.netEligibleAmount)}
        </span>
      )
      item.paymentWithDeposit = (
        <span>
          <div className="border-b w-full mb-2">${getPrice3decimal(item.payment)}</div>${getPrice3decimal(item.deposit)}
        </span>
      )
      item.fee = getPrice3decimal(
        solveDecimalJavascriptSum([
          removeComma(item.inspectionFee),
          removeComma(item.wireFee),
          removeComma(item.titleUpdateFee),
        ]),
      )
      item.feeWithAddHoldback = (
        <span>
          <div className="border-b w-full mb-2">${getPrice3decimal(item.fee)}</div>$
          {getPrice3decimal(item.additionHoldback)}
        </span>
      )

      balance = solveDecimalJavascriptSum([
        removeComma(balance),
        -1 * removeComma(item.payment),
        removeComma(item.deposit),
        -1 * removeComma(item.fee),
        -1 * removeComma(item.additionHoldback),
      ])
      item.balance = getPrice3decimal(balance)
      item.fileCount = item.files ? item.files.length : 0
      if (constructionReserve === -1) {
        item.recommendedDisbursement = 0
      } else {
        item.recommendedDisbursement = getPrice3decimal(
          solveDecimalJavascriptSum([
            removeComma(item.netEligibleAmount),
            -1 * calcBalanceSum,
            -1 * removeComma(item.fee),
            -1 * removeComma(item.additionHoldback),
          ]),
        )
      }
      item.grossEligibleDrawAmount = getPrice3decimal(
        solveDecimalJavascriptSum([removeComma(item.netEligibleAmount), -1 * removeComma(item.previouslyDisbursed)]),
      )
      item.previouslyDisbursed = calcBalanceSum
      calcBalanceSum = solveDecimalJavascriptSum([
        calcBalanceSum,
        removeComma(item.payment),
        -1 * removeComma(item.deposit),
        removeComma(item.fee),
        removeComma(item.additionHoldback),
      ])

      item.drawWithBalance = (
        <span>
          <div className="border-b w-full mb-2">
            $
            {getPrice3decimal(
              solveDecimalJavascriptSum([
                removeComma(item.payment),
                removeComma(item.deposit),
                removeComma(item.fee),
                removeComma(item.additionHoldback),
              ]),
            )}
          </div>
          ${getPrice3decimal(item.balance)}
        </span>
      )
      item.dateWithFiles = (
        <span>
          <div className="border-b w-full mb-2">{formatDate(item.date)}</div>
          {item.fileCount}
        </span>
      )
      return item
    })
    return data
  }, [data, constructionReserve])

  const onAddPress = async () => {
    const expirationDate = additionalData['expirationDate']
    if (!expirationDate) return true

    if (!isBeforeOrEqualToday(expirationDate)) return true
    const error = `There's an active payoff with an expiration date of ${formatDate(
      expirationDate,
    )}.\nWould you like to continue?`
    const result = await confirm(error)
    return result
  }

  const onRemove = async (id: any) => {
    return new Promise((resolve) => {
      setLoading(true)
      deleteTrustLedger(id).then(() => {
        init()
        resolve(true)
      })
    })
  }

  const onSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      setLoading(true)
      if (currentId == 0)
        addTrustLedger(values).then(() => {
          init()
          resolve(values)
        })
      else
        updateTrustLedger(currentId, values).then(() => {
          init()
          resolve(values)
        })
    })
  }

  const onExportFile = (type: 'excel' | 'pdf') => {
    const url = `${baseUrl}/loan/downloadTrustLedger/${loanNumber}?token=${token}&type=${type}`

    var windowReference: any = window.open()
    windowReference.document.title = 'Downloading...'
    windowReference.location = url
  }

  const onInterestExportExcel = () => {
    const url = `${baseUrl}/loan/downloadTrustLedgerInterest/${loanNumber}?token=${token}`

    var windowReference: any = window.open()
    windowReference.document.title = 'Downloading...'
    windowReference.location = url
  }

  const renderRehabBudgetFragment = useMemo(() => {
    let totalDisbursed = constructionReserve
    const len = dataWithBalance.length
    if (len > 0) {
      totalDisbursed = solveDecimalJavascriptSum([
        removeComma(totalDisbursed),
        -1 * removeComma(dataWithBalance[len - 1].balance),
      ])
    } else {
      totalDisbursed = 0
    }
    const inputObjs: any =
      rehabBudget !== -1
        ? [
            ['rehabBudget', 'Rehab Budget', rehabBudget, '$'],
            ['constructionReserve', 'Construction Reserve', constructionReserve, '$'],
            ['percent', 'Percent', ((constructionReserve * 100) / rehabBudget).toFixed(2), '%'],
            ['totalDisbursed', 'Total Disbursed', totalDisbursed, '$'],
            ['initialLoanAmount', 'Current UPB', initialLoanAmount + totalDisbursed, '$'],
          ]
        : []
    if (interestReserve !== -1) {
      inputObjs.push(['interestReserve', 'Interest Reserve', interestReserve, '$'])
    }
    inputObjs.push(
      [
        'budgetOfLoanAmount',
        'Budget % of Loan Amount',
        getPrice2decimal((rehabBudget / proposedLoanAmount) * 100),
        '%',
      ],
      [
        'budgetOfAfterRepairValue',
        'Budget % of After Repair Value',
        getPrice2decimal((rehabBudget / afterRepairValue) * 100),
        '%',
      ],
    )
    return (
      <div className="grid grid-cols-4 mb-4 gap-4">
        {inputObjs.map((obj: any, index: number) => {
          return (
            <div key={index}>
              <Input2
                key={obj[0]}
                title={obj[1]}
                value={obj[2]}
                prefix={obj[3]}
                type={'thousandSep'}
                disabled={true}
                onChange={() => {}}
              />
            </div>
          )
        })}
      </div>
    )
  }, [rehabBudget, constructionReserve, interestReserve, dataWithBalance])

  const interestInputLogic = (inputs: Record<string, InputType>, ID?: number, key?: string) => {
    if (key === 'payment') {
      inputs['deposit'].value = 0
    }
    if (key === 'deposit') {
      inputs['payment'].value = 0
    }
    return inputs
  }

  const inputLogic = (inputs: Record<string, InputType>, ID?: number, key?: string) => {
    if (key === 'payment') {
      inputs['deposit'].value = 0
    }
    if (key === 'deposit') {
      inputs['payment'].value = 0
    }
    let previouslyDisbursed = 0
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].id === ID) break
      previouslyDisbursed = solveDecimalJavascriptSum([
        previouslyDisbursed,
        removeComma(data[i].payment),
        -1 * removeComma(data[i].deposit),
        removeComma(data[i].inspectionFee),
        removeComma(data[i].wireFee),
        removeComma(data[i].titleUpdateFee),
        removeComma(data[i].additionHoldback),
      ])
    }
    inputs['previouslyDisbursed'].value = previouslyDisbursed.toFixed(2)
    if (rehabBudget !== -1) {
      const netEligibleAmount = (
        (removeComma(inputs['eligibleAmount'].value) * constructionReserve) /
        rehabBudget
      ).toFixed(2)

      inputs['netEligibleAmount'].value = netEligibleAmount

      let recommendedDisbursement = removeComma(netEligibleAmount)
      let feeApplied = false
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].id === ID) {
          recommendedDisbursement = solveDecimalJavascriptSum([
            recommendedDisbursement,
            -1 * removeComma(inputs['inspectionFee'].value),
            -1 * removeComma(inputs['wireFee'].value),
            -1 * removeComma(inputs['titleUpdateFee'].value),
            -1 * removeComma(inputs['additionHoldback'].value),
          ])
          feeApplied = true
          break
        }
        recommendedDisbursement = solveDecimalJavascriptSum([
          recommendedDisbursement,
          -1 * removeComma(data[i].payment),
          removeComma(data[i].deposit),
          -1 * removeComma(data[i].inspectionFee),
          -1 * removeComma(data[i].wireFee),
          -1 * removeComma(data[i].titleUpdateFee),
          -1 * removeComma(data[i].additionHoldback),
        ])
      }
      if (!feeApplied) {
        recommendedDisbursement = solveDecimalJavascriptSum([
          recommendedDisbursement,
          -1 * removeComma(inputs['inspectionFee'].value),
          -1 * removeComma(inputs['wireFee'].value),
          -1 * removeComma(inputs['titleUpdateFee'].value),
          -1 * removeComma(inputs['additionHoldback'].value),
        ])
      }
      inputs['recommendedDisbursement'].value = recommendedDisbursement.toFixed(2)
    }

    const grossEligibleDrawAmount = getPrice3decimal(
      solveDecimalJavascriptSum([
        removeComma(inputs['netEligibleAmount'].value),
        -1 * removeComma(inputs['previouslyDisbursed'].value),
      ]),
    )
    inputs['grossEligibleDrawAmount'].value = grossEligibleDrawAmount

    const recommendedDisbursement = removeComma(inputs['recommendedDisbursement'].value)
    inputs['payment'].additionalElements = ((onChange: Function) => {
      return (
        <span
          className="mx-2 text-shade-blue hover:underline cursor-pointer font-variation-settings-600"
          onClick={() => {
            // console.log('maxValue', recommendedDisbursement)
            onChange(recommendedDisbursement)
          }}
        >
          Autofill
        </span>
      )
    }) as any
    inputs['totalDraw'].value = calculateSum([
      inputs.inspectionFee.value,
      inputs.wireFee.value,
      inputs.titleUpdateFee.value,
      inputs.additionHoldback.value,
      inputs.payment.value,
      inputs.deposit.value,
    ])
    return inputs
  }

  const onCloseTrustLedgerEmailModal = () => {
    setModalOpen(false)
    setDocuments([])
  }

  const updateInputs = (inputs: Record<string, InputType>) => {
    const isWiringInstructions = inputs.isWiringInstructions.value
    Object.keys(inputs).forEach((key) => {
      if (['isWiringInstructions', 'comment', 'interestComment', 'pledgedFacility', 'interestType'].indexOf(key) !== -1)
        return
      inputs[key].visible = isWiringInstructions
    })
    return inputs
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)

    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])

    newInputs = updateInputs(newInputs)
    setInputs(newInputs)
    setFieldValueChanged(true)
  }

  const onUpdate = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)
    const input: any = inputs[key]
    if (input.error && input.error.length > 0) return
    setLoading(true)
    const reqBody = {
      [key]: inputs[key].value,
    }
    await updateTrustLedgerAdditional(reqBody)
    setLoading(false)
  }

  const onAdditionalShowHistory = (key: string) => {
    const options = {
      table: 'TrustLedger',
      field: key,
      keys: {
        field: key,
        loanNumber,
      },
    }
    openAuditLog(options)
  }

  const onInterestSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      setLoading(true)
      if (!currentId) {
        values = {
          id: Date.now(),
          ...values,
        }
        createTrustLedgerInterest(values)
          .then(() => {
            resolve(values)

            let temp = cloneDeep(interestData)
            temp.push(values)
            setInterestData(temp)
          })
          .finally(() => setLoading(false))
      } else {
        updateTrustLedgerInterest(currentId, values)
          .then(() => resolve(values))
          .finally(() => {
            let temp = cloneDeep(interestData)
            temp.map((item, index) => {
              if (item.id === currentId) temp[index] = values
            })
            setInterestData(temp)
            setLoading(false)
          })
      }
    })
  }

  const onInterestRemove = async (id: any) => {
    return new Promise((resolve) => {
      setLoading(true)
      deleteTrustLedgerInterest(id)
        .then(() => {
          resolve(true)
        })
        .finally(() => {
          let temp: any = []
          interestData.map((item) => {
            if (item.id !== id) temp.push(item)
          })
          setInterestData(temp)
          setLoading(false)
        })
    })
  }

  const drawIssued = async (data: any) => {
    const content = (
      <div className="mb-4">
        <div className="mb-3">Are you sure want to Inform Subservicer of Draw?</div>
        <div className="">
          Order ID: <span className="font-bold">{data.id}</span>
        </div>
      </div>
    )
    const res = await confirm(content, {
      titleYes: 'Yes and Inform',
    })
    if (!res) return
    setLoading(true)
    await trustLedgerDrawIssuedAccountingTask({
      interestType: inputs.interestType.value,
      data: {
        id: data.id,
        reference: data.reference,
        drawIssued: data.drawIssued,
      },
    })
    init()
  }

  const renderAdditionalActions = (row: Record<string, any>) => {
    const drawCN = row.drawIssued ? 'bg-green-500' : 'bg-red-500'

    return (
      <div className="flex gap-1">
        {!!inputs.interestType.value?.length && (
          <div>
            <Tooltip message={`Draw Issue${row.drawIssued ? 'd' : ''}`}>
              <div
                className={`text-white ${drawCN} cursor-pointer hover-shadow1 p-1 rounded w-fit`}
                onClick={() => {
                  !row.drawIssued && drawIssued(row)
                }}
              >
                <BanknotesIcon className="w-4 h-4"></BanknotesIcon>
              </div>
            </Tooltip>
          </div>
        )}
        <div
          className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded w-fit"
          onClick={() => {
            setModalOpen(true)
            setDocuments(row.files)
            setEmailID(row.id)
          }}
        >
          <EnvelopeIcon className="w-4 h-4" />
        </div>
      </div>
    )
  }

  const renderDetail = (item: any) => {
    if (['Added', 'Updated'].includes(item.action))
      return <div className="max-w-md break-all">{JSON.stringify(item.info)}</div>

    let rlt: any = []

    if (['Draw Approved Email', 'Draw Review Request Email'].includes(item.action)) {
      item.info.data.map((item1: any, index: number) => {
        rlt.push(
          <div className="border p-1" key={index}>
            {item1.title}: <span>${getPrice2decimal(item1.value, false, true)}</span>
          </div>,
        )
      })
    }
    if (item.action === 'Change Review Status') {
      rlt.push(
        <div key={1}>
          {item.info.status}-{item.info.requestID}
        </div>,
      )
      if (item.info.status === 'Decline') {
        rlt.push(<div key={2}>Reason: {item.info.reason}</div>)
        item.info.files?.map((file: string, index: number) => {
          rlt.push(
            <div
              key={`file-${index}`}
              className="text-[13.5px] font-semibold hover:underline cursor-pointer"
              onClick={() => openS3Document(file)}
            >
              - {getFileName(file)}
            </div>,
          )
        })
      }
    }

    return rlt
  }

  const fundingInfoFragment = useMemo(() => {
    const fundingInputs: any = fundingDisbursementInputs()
    return (
      <div className="w-full overflow-auto">
        <table className="text-[14px] rounded">
          <tbody>
            <tr>
              <td className="border px-3 py-1 font-semibold bg-gray-100" colSpan={Object.keys(fundingInputs).length}>
                Disbursement Information
              </td>
            </tr>
            <tr>
              {Object.keys(fundingInputs).map((key, index) => {
                return (
                  <td key={index} className="px-3 py-1 border">
                    {fundingInputs[key].title}
                  </td>
                )
              })}
            </tr>
            <tr>
              {Object.keys(fundingInputs).map((key, index) => {
                return (
                  <td key={index} className="px-3 py-1 border">
                    {fundingInputs[key].prefix}
                    {fundingInfo[key] || ''}
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }, [fundingInfo])

  return (
    <div className="TrustLedger-container">
      <div className="max-w-screen-2xl m-auto">
        <div className="relative bg-white">
          <LayoutLoading show={loading} />
          {renderRehabBudgetFragment}
          <div className="mb-4">{fundingInfoFragment}</div>
          <div className={`grid gap-4 md:grid-cols-4 grid-cols-1 mb-3`}>
            {Object.keys(inputs).map((key, index) => {
              const input: any = cloneDeep(inputs[key])
              if (input.visible === false) return null
              return (
                <div className={`input md:col-span-${input.span || 1}`} key={index}>
                  <RenderInput
                    input={input}
                    Key={key}
                    onChange={onChange}
                    onBlur={onUpdate}
                    showHistory={() => onAdditionalShowHistory(key)}
                  />
                </div>
              )
            })}
          </div>

          <div className="flex justify-between items-center mt-4 flex-wrap">
            <div className="ml-4 font-variation-settings-600 text-[18px]">
              {constructionReserve > 0 ? '- Construction Reserve' : ''}
            </div>
            <div className="flex gap-3">
              {vendor.Afx && (
                <button
                  className={`text-shade-blue hover:underline p-1 rounded w-fit flex items-center text-sm gap-1`}
                  onClick={() => setAfxOpen(true)}
                >
                  <PaperAirplaneIcon className="w-4 h-4" />
                  Afx Research
                </button>
              )}
              <ConstructionLienWaiver data={dataWithBalance} />
              <button className="hover:underline text-[14px] text-shade-blue p-2" onClick={() => onExportFile('excel')}>
                <div className="flex items-center">
                  <ArrowDownTrayIcon className="w-4 h-4 mr-1" /> Export to Excel
                </div>
              </button>
              <button className="hover:underline text-[14px] text-shade-blue p-2" onClick={() => onExportFile('pdf')}>
                <div className="flex items-center">
                  <ArrowDownTrayIcon className="w-4 h-4 mr-1" /> Export to Pdf
                </div>
              </button>
            </div>
          </div>
          <FormTable
            header={header}
            addText={'Add New Item'}
            inputs={defaultInputs}
            data={dataWithBalance}
            inputLogic={inputLogic}
            cols={3}
            onSubmit={onSubmit}
            onRemove={onRemove}
            onAddPress={onAddPress}
            renderAdditionalActions={renderAdditionalActions}
          />
          <div className="mt-4">
            <RenderInput
              input={inputs.comment}
              Key={'comment'}
              onChange={onChange}
              onBlur={onUpdate}
              showHistory={() => onAdditionalShowHistory('comment')}
            />
          </div>
          {interestReserve >= 0 && (
            <div className="mt-6 border-t-[3px] border-gray-400">
              <div className="flex justify-between items-center mt-4 flex-wrap">
                <div className="ml-4 font-variation-settings-600 text-[18px]">- Interest Reserve</div>
                <button className="hover:underline text-[14px] text-shade-blue p-2" onClick={onInterestExportExcel}>
                  <div className="flex items-center">
                    <ArrowDownTrayIcon className="w-4 h-4 mr-2" /> Export to Excel
                  </div>
                </button>
              </div>
              <FormTable
                header={interestHeader}
                addText={'Add New Item'}
                inputs={interestDefaultInputs}
                data={interestDataWithBalance}
                inputLogic={interestInputLogic}
                cols={3}
                onSubmit={onInterestSubmit}
                onRemove={onInterestRemove}
              />
              <div className="mt-4">
                <RenderInput
                  input={inputs.interestComment}
                  Key={'interestComment'}
                  onChange={onChange}
                  onBlur={onUpdate}
                  showHistory={() => onAdditionalShowHistory('interestComment')}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <ChooseDocumentsForTrustLedger
          documents={documents}
          onClose={onCloseTrustLedgerEmailModal}
          data={{
            rehabBudget,
            constructionReserve,
            data,
            ID: emailID,
          }}
        />
      )}
      {history.id && (
        <Modal
          title={`Order History - ${history.id}`}
          titleOkay=""
          isOpen={true}
          lastUpdatedAt={Date.now()}
          onClose={() => {
            setHistory({
              id: null,
              data: [],
            })
          }}
        >
          <div className="overflow-auto">
            <div className="overflow-auto shadow-md rounded">
              <table className="w-full text-[14px]">
                <thead className="font-medium text-gray-600 bg-gray-100">
                  <tr className="text-left">
                    <th className="px-4 py-2">ID</th>
                    <th className="px-4 py-2">Action</th>
                    <th className="px-4 py-2">Detail</th>
                    <th className="px-4 py-2">By/Date</th>
                  </tr>
                </thead>
                <tbody>
                  {history.data.map((item: any, index: number) => {
                    return (
                      <tr key={index} className={`${index % 2 && 'bg-gray-50'}`}>
                        <td className="px-4 py-2">{item.requestID}</td>
                        <td className="px-4 py-2">{item.action}</td>
                        <td className="px-4 py-2">{renderDetail(item)}</td>
                        <td className="px-4 py-2">
                          {item.by}
                          <br />
                          {formatTime(item.time)}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      )}
      {isAfxOpen && <AfxModal onClose={() => setAfxOpen(false)} />}
    </div>
  )
}
