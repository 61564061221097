import { InputType } from 'config'

import { EmailProvider, EmailProviderTypes } from './type'

export const defaultInputs = (provider: EmailProvider | null = null): Record<string, InputType> => {
  return {
    provider: {
      inputType: 'select',
      title: 'Which email service do you want to use?',
      options: EmailProviderTypes,
      hasDefaultOption: true,
      required: true,
    },
    dns: {
      inputType: 'text',
      title: 'Email Domain Name',
      required: true,
    },
    ...getProviderInputs(provider),
  }
}

export const SendGridInputs = (): Record<string, InputType> => {
  return {
    apiKey: {
      inputType: 'text',
      title: 'Sendgrid Api Key',
      required: true,
    },
  }
}

const providerInputs = {
  [EmailProvider.Default]: {},
  [EmailProvider.SendGrid]: SendGridInputs(),
}

export const getProviderInputs = (provider: EmailProvider | null) => {
  if (!provider) return {}
  return providerInputs[provider] || {}
}
