import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { GlobalConfigType, setLoanDetail } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { LoanDetail } from 'components/LoanDetail'
import { LoanStatus } from 'components/LoanStatus'
import { ServicingStatus } from 'components/ServicingStatus'
import { companyName } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { LoanSubmissionDocument } from 'pages/LoanSubmission/types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { loanTypeMap } from 'reducers/loanDetail.reducer'
import { loanOverviewSection, updateLoanFields, updateLoanSubmission, uploadFiles } from 'services'
import { svgLoading } from 'stories/assets'
import { CopyableText, InputFile, Select } from 'stories/components'
import { openAuditLog, toFileArray, useTitle } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

export function Overview(props: any) {
  const [number, setNumber] = useState('-')
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState({
    loanNumber: '-',
    program: '',
    byteproFileName: 0,
    entityTitle: 'N/A',
    borrowerName: '-',
    propertyAddress: '-',
    loanStatus: '',
  })
  const [action, setAction] = useState('')

  const {
    noChangeTitle,
    noCompanyTitle,
    title,
    hasBackButton = true,
    backTitle = '',
    backLink = '',
    showStatusChannel = false,
  } = props

  const [borrower, profile, loanDetail, acceptedFileTypes, globalSettingV2] = useSelector((state: any) => [
    state.borrower,
    state.auth.profile,
    state.loanDetail,
    state.globalConfigReducer[GlobalConfigType.AcceptedFileTypes],
    state.globalConfigReducer[GlobalConfigType.GlobalSettingV2],
  ])
  const templateNumber = loanDetail.templateNumber

  const isBorrowerOverview = backLink === '/borrowerPipelines'

  const dispatch = useDispatch()
  const { hasPermission } = usePermissions()

  const manageServicing = hasPermission('MANAGE_FCI_SERVICING')

  useEffect(() => {
    if (borrower.borrower.hasEntityTitle === false) {
      if (detail.entityTitle !== 'N/A') {
        let temp = cloneDeep(detail)
        temp.entityTitle = 'N/A'
        setDetail(temp)
      }
    }
    if (borrower.borrower.hasEntityTitle === true) {
      if (detail.entityTitle !== borrower.borrower.entityTitle) {
        let temp = cloneDeep(detail)
        temp.entityTitle = borrower.borrower.entityTitle
        setDetail(temp)
      }
    }
  }, [borrower.borrower.hasEntityTitle, borrower.borrower.entityTitle])

  const loanNumber = setLoanNumber()

  useEffect(() => {
    setNumber(loanNumber)
    if (loanNumber == 0) return

    setLoading(true)
    loanOverviewSection().then((data) => {
      setDetail(data)
      if (props.feedOverview) {
        props.feedOverview(data)
      }
      if (noChangeTitle) {
      } else useTitle(`${data.loanNumber} - ${title}${noCompanyTitle ? '' : ` - ${companyName}`}`)
      setLoading(false)
    })
  }, [])

  const onUploadNewfile = async (value: any) => {
    if (value !== null) {
      const fileArray = toFileArray(value)
      uploadDocumentFiles(fileArray)
    }
  }

  const uploadDocumentFiles = (files: File[]): Promise<any> => {
    return new Promise((resolve) => {
      const data = {
        path: `loan/${loanNumber}/document`,
      }
      let acceptedFiles: any = []
      files.map((file) => {
        const { name } = file
        const params = name.toLowerCase().split('.')
        const length = params.length
        const ext = params[length - 1]
        if (acceptedFileTypes.includes(ext)) {
          acceptedFiles.push(file)
        } else {
          toast(`${name} is not ${acceptedFileTypes.join(', ')}`, { type: 'error' })
        }
      })
      setLoading(true)

      uploadFiles(data, acceptedFiles).then(async (keys: Array<string>) => {
        const newDocs: LoanSubmissionDocument[] = []
        keys.forEach((key, index) => {
          newDocs.push({
            conditionNo: 0,
            name: acceptedFiles[index].name,
            key,
            status: 'Not Reviewed',
            category: '',
            createdAt: Date.now(),
            generated: false,
            createdBy: profile.email,
            borrower: true,
          })
        })
        await updateLoanSubmission({}, { update: newDocs })
        setLoading(false)
        toast(`Successfully uploaded ${newDocs.length} document${newDocs.length > 1 ? 's' : ''}`, { type: 'success' })
        resolve(true)
        props.onUploadNewFile && props.onUploadNewFile(true)
      })
    })
  }

  const onLoanStatusChange = (key: string, status: string) => {
    dispatch(
      setLoanDetail({
        [key]: status,
      }),
    )
  }

  const onMoveToServicing = () => {
    dispatch(
      setLoanDetail({
        servicingPipeline: true,
        servicingStatus: 'servicing',
      }),
    )
  }

  const changeLoanChannel = async (value: string) => {
    const data = {
      type: Number(value),
    }
    setAction('changeLoanChannel')
    await updateLoanFields(data, !!templateNumber)
    dispatch(
      setLoanDetail({
        loanType: Number(value),
      }),
    )
    setAction('')
    setLoading(false)
  }

  return (
    <div className="Overview-container h-full">
      <div className="shadow1 mb-6 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 text-left w-full h-full">
        <div className="relative">
          <LayoutLoading show={loading} />
          {hasBackButton && (
            <div className="text-shade-blue flex items-center mb-2 font-bold">
              <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
              <Link to={backLink || `/loan_process/overview/${number}`} className="hover:underline">
                {backTitle || 'Return to Loan Overview'}
              </Link>
            </div>
          )}
          <div className="flex flex-wrap justify-between">
            <h1 className="text-2xl font-bold flex items-center mb-2">
              <CopyableText
                copyText={`${detail.byteproFileName || detail.loanNumber} | ${detail.entityTitle} | ${
                  detail.borrowerName
                } | ${detail.propertyAddress}`}
              >
                {title}
              </CopyableText>
              <span className="text-base ml-3">
                {loading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />}
              </span>
            </h1>
          </div>
          <div className="flex flex-wrap gap-4">
            {loanNumber != 0 && (
              <div className="mt-1 flex flex-wrap gap-x-10">
                <LoanDetail
                  loanNumber={loading ? '' : detail.loanNumber}
                  program={loading ? '' : detail.program}
                  byteNumber={loading ? 0 : detail.byteproFileName}
                  entityTitle={loading ? '' : detail.entityTitle}
                  borrowerName={loading ? '' : detail.borrowerName}
                  propertyAddress={loading ? '' : detail.propertyAddress}
                  config={globalSettingV2}
                />
              </div>
            )}
            {showStatusChannel && (
              <div>
                <div className="md:mx-6 flex flex-wrap">
                  <div className="mr-2 text-[14px] w-24">Loan Status:</div>
                  <div>
                    <div className="md:-mt-2">
                      <LoanStatus
                        key={`${loanDetail.loanStatus}-${loanDetail.loanNumber}`}
                        status={loanDetail.loanStatus}
                        servicingPipeline={loanDetail.servicingPipeline}
                        onChange={(status: string) => onLoanStatusChange('loanStatus', status)}
                        onMoveServicing={() => onMoveToServicing()}
                        loanNumber={loanDetail.loanNumber}
                      />
                      {manageServicing && loanDetail.servicingPipeline && loanDetail.loanStatus === 'funded' && (
                        <div className="mt-1">
                          <ServicingStatus
                            key={`${loanDetail.servicingStatus}-${loanDetail.loanNumber}`}
                            status={loanDetail.servicingStatus}
                            onChange={(status: string) => onLoanStatusChange('servicingStatus', status)}
                            loanNumber={loanDetail.loanNumber}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="md:mx-6 flex flex-wrap mt-4 relative">
                  <LayoutLoading show={action === 'changeLoanChannel'} />

                  <div className="mr-2 text-[14px] mt-2 w-24">Channel:</div>
                  <div>
                    {hasPermission('CAN_CHANGE_CHANNEL') ? (
                      <Select
                        id="loan-type"
                        title=""
                        options={loanTypeMap}
                        value={loanDetail.loanType}
                        onChange={changeLoanChannel}
                        history={true}
                        showHistory={() =>
                          openAuditLog({
                            table: 'Loan',
                            field: 'type',
                            keys: { field: 'type', loanNumber: loanDetail.loanNumber },
                            map: loanTypeMap,
                          })
                        }
                      />
                    ) : (
                      <div className="mt-1">{loanTypeMap[loanDetail.loanType]}</div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {isBorrowerOverview && (
            <div className="absolute right-0 top-0">
              <InputFile
                className="pt-1"
                title=""
                acceptFileTypes={acceptedFileTypes
                  .sort()
                  .map((v: string) => `.${v}`)
                  .join(', ')}
                multiple={true}
                disabled={false}
                showBrowseFiles={false}
                onChange={(value) => onUploadNewfile(value)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
