import { TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { getAdminConfig, PaymentOrderType, setAdminConfig } from 'services'
import { Button, ButtonGroup } from 'stories/components'
import { confirm } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const PaymentTermsConditionsConfig = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Record<string, Array<string>>>({})
  const [orderType, setOrderType] = useState<PaymentOrderType>(PaymentOrderType.LoanSubmissionRush)

  const buttonGroups: Record<string, string> = {
    [PaymentOrderType.LoanSubmissionRush]: 'Invoice',
    [PaymentOrderType.CounterPartyApproval]: 'Counterparty Approval',
  }

  const PaymentTermsConditionsPlaceholders: Record<string, Array<string>> = {
    [PaymentOrderType.LoanSubmissionRush]: ['Fee Amount', 'Lender Name'],
    [PaymentOrderType.CounterPartyApproval]: ['Fee Amount', 'Lender Name'],
  }

  const initData = async () => {
    setIsLoading(true)
    const res = await getAdminConfig('paymentTerms')
    setData(res)
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  const onChange = (index: number, value: string) => {
    const temp = cloneDeep(data)
    temp[orderType][index] = value
    setData(temp)
  }

  const onAdd = async () => {
    const temp = cloneDeep(data)
    temp[orderType].push('')
    setData(temp)
    await onSubmit(temp)
  }

  const onRemove = async (index: number) => {
    const res = await confirm('Are you sure you want to remove this term?')
    if (!res) return

    const temp = cloneDeep(data)
    temp[orderType].splice(index, 1)
    setData(temp)
    await onSubmit(temp)
  }

  const onSubmit = async (payload?: any) => {
    setIsLoading(true)
    await setAdminConfig('paymentTerms', payload || data)
    setIsLoading(false)
  }

  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />
      <ButtonGroup title={buttonGroups} value={orderType} onChange={setOrderType} />

      <div className="flex gap-2 items-center mb-2 mt-4">
        <span className="ml-3 text-lg font-medium">- Placeholders</span>
      </div>
      <div className="p-3 border rounded-md mb-4">
        <div className="flex gap-x-1 ">
          {PaymentTermsConditionsPlaceholders[orderType].map((item, index) => {
            return (
              <pre key={index} className="w-[240px]">
                {item}
              </pre>
            )
          })}
        </div>
      </div>
      {data[orderType]?.map((v: string, index: number) => {
        const input: InputType = {
          inputType: 'textarea',
          title: 'Text',
          value: v,
          rows: 3,
        }
        input.additionalElements = (
          <span className="text-red-800 p-1 cursor-pointer" onClick={() => onRemove(index)}>
            <TrashIcon className="w-4 h-4"></TrashIcon>
          </span>
        )
        return (
          <div className="mb-4">
            <RenderInput
              input={input}
              key={`term-${index}`}
              Key={`term-${index}`}
              onChange={(key: string, value: any) => onChange(index, value)}
              onBlur={() => onSubmit()}
            />
          </div>
        )
      })}
      <Button link onClick={onAdd}>
        + Add New
      </Button>
    </div>
  )
}
