import { Overview } from 'components/Overview'
import { removeNonPagePermissions } from 'hooks/hasPagePermissions'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { setLoanNumber } from 'utils/setLoanNumber'

import { Disbursement } from './Disbursement'

export * from './Disbursement/constant'

export default function Funding() {
  const [selectedMenu, setSelectedMenu] = useState('')
  const [menuItems, setMenuItems] = useState<any>({})

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
  }

  useEffect(() => {
    setLoanNumber()
  }, [])

  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  useEffect(() => {
    if (Object.keys(menuItems).length) return
    let temp: any = {
      disbursement: 'Disbursement Information',
    }
    removeNonPagePermissions(temp, ['pipeline', 'funding'])
    setMenuItems(temp)
    setSelectedMenu(Object.keys(temp)[0])
  }, [auth])

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'disbursement':
        return <Disbursement />
      default: {
        return <div></div>
      }
    }
  }, [selectedMenu])

  const renderMenu = (menus: Record<string, string>) => {
    return (
      <ul className="sidebar-items flex flex-col p-4 pb-10">
        {Object.keys(menus).map((item: string, index) => {
          const isActive = selectedMenu === item
          return (
            <li
              key={index}
              onClick={() => {
                setMenu(item)
              }}
              className="py-2"
            >
              <p className={`hover:underline cursor-pointer ${isActive ? 'border px-4 py-1 bg-zinc-100' : 'py-1'}`}>
                {index + 1}. {menus[item]}
              </p>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className="py-6 px-2">
      <Overview title="Funding" />
      <div className="max-w-screen-2xl m-auto grid grid-cols-12 md:gap-6">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded mb-4">
          {renderMenu(menuItems)}
        </div>
        <div className="relative h-fit content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1 mb-4">
          {renderFragment}
        </div>
      </div>
    </div>
  )
}
