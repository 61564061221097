import { LinkIcon, XMarkIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { itemCountPerPage } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { filterPipeline } from 'services'
import { svgSearch } from 'stories/assets'
import { Input2, Modal } from 'stories/components'

export const AssignLoanModal = ({
  loanNumbers,
  onAssign,
  onClose,
  assignTitle = '',
  returnByteproFileName = false,
  modalTitle = '',
}: {
  loanNumbers: number[]
  onAssign: Function
  onClose: Function
  assignTitle?: string
  returnByteproFileName?: boolean
  modalTitle?: string
}) => {
  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])

  const [isLoading, setLoading] = useState(true)
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
  })
  const [loans, setLoans] = useState<Record<string, any>[]>([])
  const [total, setTotal] = useState(0)
  const [filterQuery, setFilterQuery] = useState('')

  useEffect(() => {
    setLoading(true)
    filterLoanData({})
  }, [])

  useEffect(() => {
    const timeOutId = setTimeout(() => !isLoading && filterLoanData(filters, 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterLoanData = async (filters: any, _pageNum: number = -1) => {
    setLoading(true)
    if (_pageNum < 0) _pageNum = 0
    const { data, total } = await filterPipeline({
      ...filters,
      // status: {},
      lock: filters.lock || '0',
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
      isInvestor: true,
    })
    setLoans(data)
    setTotal(total)
    setLoading(false)
  }

  const onChangeFilter = (key: string, value: any, needRefresh = true) => {
    const newFilters = cloneDeep(filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (!needRefresh) return

    if (key === 'query') setFilterQuery(value)
    else filterLoanData(newFilters, 0)
  }

  const renderHeader = (title: string | JSX.Element, key: string) => {
    return (
      <th scope="col" className="py-3 px-2" key={key}>
        {title}
      </th>
    )
  }

  return (
    <Modal isOpen title={modalTitle || `Assign Loans`} loading={isLoading} titleOkay="" onClose={() => onClose(false)}>
      <div className="w-196">
        <p className="mb-2 font-semibold text-lg">Loans ({total})</p>
        <Input2
          type="search"
          title="Search Loans"
          hasIcon
          icon={svgSearch}
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />

        <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {renderHeader('Loan #', 'no')}
                <th scope="col" className="py-2 px-2">
                  <span>
                    <div className="border-b w-fit mb-1 border-gray-300">Borrower</div>
                    Property Address
                  </span>
                </th>
                <th scope="col" className="py-2 px-2">
                  <span>
                    <div className="border-b w-fit mb-1 border-gray-300">Purpose</div>
                    Product
                  </span>
                </th>
                {renderHeader('Status', 'loanStatus')}
                <th scope="col" className="py-2 px-2">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="text-[14px] text-gray-900">
              {loans.map((item: any, index: number) => {
                return (
                  <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="pl-3">
                      <Link
                        to={`/loan_process/overview/${item.no}`}
                        className="font-bold text-shade-blue hover:underline cursor-pointer"
                        target="_blank"
                      >
                        <span className="flex-col flex min-h-[60px] justify-center items-center">
                          <div>{item.byteproFileName || item.no}</div>
                          {filters.servicing && (
                            <div className="font-normal text-gray-700 text-[13px] mt-2">{item.servicerLoanNumber}</div>
                          )}
                          {!filters.servicing && item.rush && <div className="text-red-700 italic">Rush</div>}
                        </span>
                      </Link>
                    </td>
                    <td className="px-2 py-2 min-w-[170px]">
                      <span>
                        <div className="border-b w-full mb-2 flex gap-3 items-center">
                          <span>{`${item.borrowerFirstName} ${item.borrowerLastName}`}</span>
                        </div>
                        {item.subjectPropertyAddress}
                      </span>
                    </td>
                    <td className="px-2 py-2 min-w-[170px]">
                      <span>
                        <div className="border-b w-full mb-2">{item.transactionType}</div>
                        {item.productType}
                      </span>
                    </td>
                    <td className="px-2 py-2">{loanGlobalStatus[item.loanStatus]}</td>
                    <td className="px-2 py-2">
                      {!loanNumbers.includes(item.no) ? (
                        <span
                          className="font-bold flex items-center gap-1 text-shade-blue hover:underline cursor-pointer"
                          onClick={() => onAssign(returnByteproFileName ? item.byteproFileName || item.no : item.no)}
                        >
                          {assignTitle || 'Assign'}
                          <LinkIcon className="w-4 h-4" />
                        </span>
                      ) : (
                        <span
                          className="font-bold flex items-center gap-1 text-red-800 hover:underline cursor-pointer"
                          onClick={() => onAssign(item.no)}
                        >
                          Deallocate
                          <XMarkIcon className="w-4 h-4" />
                        </span>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}
