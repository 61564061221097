import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { submitEmailTemplate } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { getPartiesMap, getUnknownKeyInPartiesEmailTemplate, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'
import { type EmailTemplate, type IEmailButton, IButtonType } from './type'

interface EmailTemplateDetailsProps {
  emailFrom: Record<string, string>
  purposeOptions: Record<string, Record<string, string>>
  elementOptions: Record<string, Record<string, string>>
  defaults: EmailTemplate | null
  onBack: Function
  onComplete: Function
}

export function EmailTemplateDetails(props: EmailTemplateDetailsProps) {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [links, setLinks] = useState<IEmailButton[]>([])
  const [showError, setShowError] = useState(false)
  const { defaults = null } = props

  useEffect(() => {
    onUpdateDefaults()
  }, [defaults])

  const onUpdateDefaults = async () => {
    let inputs = cloneDeep(defaultInputs())
    let purposeOptions = {}
    Object.keys(props.purposeOptions).forEach((key) => {
      const newOptions: Record<string, string> = {}
      Object.keys(props.purposeOptions[key]).forEach((itemKey) => {
        newOptions[itemKey] = `${props.purposeOptions[key][itemKey]}`
      })
      purposeOptions = {
        ...purposeOptions,
        ...newOptions,
      }
    })
    ;(inputs.purpose as any).options = purposeOptions
    ;(inputs.from as any).options = props.emailFrom

    if (!defaults) {
      setInputs(inputs)
      return
    }
    for (const key in inputs) {
      inputs[key].value = (defaults as any)[key]
    }
    inputs = updateElementOptions(inputs, defaults.group)
    setInputs(inputs)
    setLinks(defaults?.links || [])
  }

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    if (key == 'group') newInputs = updateElementOptions(newInputs, value, true)
    setInputs(newInputs)
  }

  const updateElementOptions = (inputs: Record<string, InputType>, emailGroup: string, setSelectAll = false) => {
    ;(inputs.elements as any).options = props.elementOptions[emailGroup] || {}
    if (setSelectAll) (inputs.elements as any).value = Object.keys(props.elementOptions[emailGroup] || {})
    return inputs
  }

  const addLink = () => {
    const temp = cloneDeep(links)
    temp.push({ label: '', url: '', type: 'button' })
    setLinks(temp)
  }
  const onChangeLink = (key: string, value: any, index: number) => {
    const temp = cloneDeep(links)
    ;(temp as any)[index][key] = value
    setLinks(temp)
  }

  const removeLink = (index: number) => {
    const temp = cloneDeep(links)
    if (temp.length) temp.splice(index, 1)
    setLinks(temp)
  }

  const onSubmit = () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      if (value !== undefined) data[key] = value
    }

    links.map((item) => {
      if (!item.label || !item.url) hasError = true
    })
    data.links = links

    if (hasError) {
      setInputs(newStats)
      setShowError(true)
      return
    }
    const { subject, content = '' } = data
    let unknownKey = getUnknownKeyInPartiesEmailTemplate(content)
    if (unknownKey) {
      return toast(`${unknownKey} is unknown variable in "Content".`, { type: 'warning' })
    }

    unknownKey = getUnknownKeyInPartiesEmailTemplate(subject)
    if (unknownKey) {
      return toast(`${unknownKey} is unknown variable in "Type".`, { type: 'warning' })
    }

    setLoading(true)
    const id = defaults ? defaults.id : 0

    submitEmailTemplate(id, {
      ...data,
    })
      .then(() => {
        if (id == 0) toast('New Email Template is added.', { type: 'success' })
        else toast(`Email Template "${defaults?.subject}" is updated.`, { type: 'success' })
        props.onComplete()
        onBack()
      })
      .catch(() => setLoading(false))
  }

  const placeholders = getPartiesMap({})

  return (
    <div className="email-template-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Email Template - {!defaults ? 'Add' : `Update`}
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <Button link onClick={onBack}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-1" /> <p>Return to Email Template</p>
        </div>
      </Button>

      <div className="my-4">
        <div className="border-b mb-2">- Placeholders</div>
        <pre className="flex gap-1 flex-wrap">
          {Object.keys(placeholders).map((key, index) => {
            return (
              <div key={index} className="mr-2">
                {key},{' '}
              </div>
            )
          })}
        </pre>
      </div>
      <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div className={`input ${input.span ? 'col-span-full' : 'col-span-1'}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
        {links?.map((link: Record<string, any>, index: number) => {
          const input: InputType = {
            inputType: 'text',
            title: ``,
            value: link.url,
            prefix: 'Link:',
            indent: 12,
            showLinkDir: true,
          }

          input.additionalElements = (
            <>
              <input
                className="border-gray-300 border-[1px] px-1"
                value={link.label}
                onChange={(e: any) => onChangeLink('label', e.target.value, index)}
                placeholder={`${IButtonType[link.type]} Label`}
              />
              <select
                name={`button-type-${index}`}
                id={`button-type-${index}`}
                className="py-0 pl-1 pr-6 text-[12.5px] leading-[16px] border-gray-300"
                value={link.type}
                onChange={(e) => onChangeLink('type', e.target.value, index)}
              >
                {Object.keys(IButtonType).map((key) => {
                  return (
                    <option value={key} key={key}>
                      {IButtonType[key]}
                    </option>
                  )
                })}
              </select>
              <span className="cursor-pointer text-red-800" onClick={() => removeLink(index)}>
                <TrashIcon className="w-4 h-4" />
              </span>
            </>
          )

          if (showError && !(link.label && link.url)) {
            input.error = `${link.label ? '' : 'Label is required.'} ${link.url ? '' : 'Link is required.'}`
          }

          return (
            <div className={`col-span-${input.span || 1} opacity-100`} key={index}>
              <RenderInput
                input={input}
                Key={`link-${index}`}
                key={`link-${index}`}
                onChange={(key: string, value: any) => onChangeLink('url', value, index)}
              />
            </div>
          )
        })}
        <div className="col-span-full">
          <Button link onClick={addLink}>
            + Add Button & Link
          </Button>
        </div>
      </div>

      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          <>{defaults ? 'Update' : 'Add'}</>
        </Button>
      </div>
    </div>
  )
}
