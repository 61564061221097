import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { AccountingVendors } from 'pages/Admin/Accounting/AccountingVendors'
import { AssignLoanModal } from 'pages/Investor/ManageInvestors/AssignLoanModal'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { confirm, formatDate, InputConvert, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import type { IInvoice } from './types'

export const defaultInputs = (isLoan: boolean): Record<string, InputType> => {
  const loanNumber = setLoanNumber()
  const rtl: Record<string, InputType> = {
    loanNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Loan number',
      required: false,
      span: 'full',
    },
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      required: true,
    },
    payee: {
      inputType: 'text',
      type: 'text',
      title: 'Payee',
      required: true,
    },
    description: {
      inputType: 'textarea',
      type: 'text',
      title: 'Description',
      value: '',
      span: 'full',
      required: false,
    },
    no: {
      inputType: 'text',
      title: 'Invoice Number',
      required: true,
    },
    amount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Amount',
      prefix: '$',
      required: true,
    },
    addLoanCharge: {
      inputType: 'checkbox',
      title: 'Add as Loan Charge',
      value: true,
    },
    interestRate: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Interest Rate',
      prefix: '%',
      required: true,
    },
    isPaid: {
      inputType: 'checkbox',
      title: 'Paid',
      span: 'full',
    },
    paymentMethod: {
      inputType: 'select',
      title: 'Payment Method',
      required: true,
      hasDefaultOption: true,
      options: {},
    },
    paymentDate: {
      inputType: 'text',
      type: 'date',
      title: 'Payment Date',
      required: true,
    },
    files: {
      inputType: 'FileTable',
      title: 'File',
      required: false,
      acceptFileTypes: '.pdf',
      filePath: `loan/${loanNumber}/invoices`,
      span: 'full',
      showCategory: false,
      showStatus: false,
    },
  }
  if (isLoan) delete rtl.loanNumber
  return rtl
}

export const AddInvoice = ({
  index,
  item,
  onClose,
  onUpdate,
  isLoan,
  accountingAccounts,
  invoiceNos,
}: {
  index: number
  item: IInvoice | null
  onClose: Function
  onUpdate: Function
  isLoan: boolean
  accountingAccounts: Record<number, any>
  invoiceNos: Array<Record<string, any>>
}) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs(isLoan))
  const [showVendor, setShowVendor] = useState(false)
  const [showLoanSearch, setShowLoanSearch] = useState(false)

  useEffect(() => {
    const newInputs: any = cloneDeep(inputs)
    if (item) {
      Object.keys(inputs).forEach((key) => {
        if (key == 'date' && (item as any)[key]) (item as any)[key] = (item as any)[key].substring(0, 10)
        newInputs[key].value = (item as any)[key]
        if (key === 'loanNumber') newInputs[key].value = (item as any)['byteproFileName'] || (item as any)['loanNumber']
      })
    }
    newInputs.paymentMethod.options = accountingAccounts
    setInputs(newInputs)
  }, [])

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value

    if (key === 'loanNumber') (newInputs.files as any).filePath = `loan/${value}/invoices`
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      if (['paymentMethod', 'paymentDate'].includes(key) && !inputs.isPaid.value) continue
      if (key === 'interestRate' && !inputs.addLoanCharge.value) continue
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    data.amount = removeComma(data.amount)
    if (isLoan) data.loanNumber = setLoanNumber()
    data.loanNumber = Number(data.loanNumber)
    if (!data.loanNumber) data.loanNumber = null
    const existingIdx = invoiceNos.findIndex(({ no, payee }) => no === data.no && payee === data.payee)
    if (existingIdx !== -1) {
      const isContinue = await confirm(
        `This is a potential duplicate of No.${existingIdx + 1}  created At ${formatDate(
          invoiceNos[existingIdx].createdAt,
        )}.`,
        { titleYes: `Proceed and ${item ? 'Update' : 'Add'}`, titleNo: 'Cancel' },
      )
      if (!isContinue) return
    }
    setLoading(true)
    onUpdate(data)
  }

  return (
    <>
      <Modal
        title={item ? `Update Invoice - No.${index + 1}` : 'Add Invoice'}
        titleOkay="Submit"
        loading={loading}
        onClose={() => onClose()}
        onOk={onSubmit}
        isOpen
      >
        <div className="text-gray-900">
          <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2">
            {Object.keys(inputs).map((key, index) => {
              if (['paymentMethod', 'paymentDate'].includes(key) && !inputs.isPaid.value) return
              if (key === 'interestRate' && !inputs.addLoanCharge.value) return
              let input = inputs[key]
              if (key === 'payee')
                input.additionalElements = (
                  <span className="text-shade-blue cursor-pointer hover:underline" onClick={() => setShowVendor(true)}>
                    Select
                  </span>
                )
              if (key === 'loanNumber')
                input.additionalElements = (
                  <span
                    className="text-shade-blue cursor-pointer hover:underline"
                    onClick={() => setShowLoanSearch(true)}
                  >
                    Select
                  </span>
                )
              return (
                <div className={`input md:col-span-${input.span || 1}`} key={index}>
                  <RenderInput input={input} Key={key} onChange={onChange} />
                </div>
              )
            })}
          </div>
        </div>
      </Modal>
      {showVendor && (
        <Modal
          title="Select Payee"
          titleOkay=""
          onClose={() => setShowVendor(false)}
          onOk={() => {}}
          childLevel={2}
          isOpen
        >
          <div className="w-196">
            <AccountingVendors
              forSearch={true}
              onSelect={(name: string) => {
                onChange('payee', name), setShowVendor(false)
              }}
            />
          </div>
        </Modal>
      )}
      {showLoanSearch && (
        <AssignLoanModal
          loanNumbers={[]}
          onAssign={(loanNumber: number) => {
            onChange('loanNumber', loanNumber), setShowLoanSearch(false)
          }}
          onClose={() => setShowLoanSearch(false)}
          assignTitle="Select"
          returnByteproFileName
          modalTitle="Select Loan"
        />
      )}
    </>
  )
}
