export const workflowRestrictions: Record<string, string> = {
  // submit_loan
  accountExecutiveRequired: 'Account Executive is Required',
  dataComplete: 'Loan Application -> Data Complete',
  signApplication: 'Loan Application -> Sign Application',
  assetLiability: 'Loan Application -> Asset & Liability',
  creditScorePull: 'Loan Application -> Pull Credit Score',
  scoreMatch: 'Loan Structure -> Credit Score MisMatch',
  loanOfficerRquired: 'Parties -> Loan Officer is Required',
  titleCompanyRequired: 'Parties -> Title Company is Required',
  hazardCompanyRequired: 'Parties -> Hazard Insurance Company is Required',
  scopeOfWork: `The submission of the Borrower's Budget & Scope of Work Form is required`,

  // submit_to_loan_setup
  submitToLoanSetupRequired: 'Submit to Loan Setup is Required before changing template',
  taskClearedRequired: 'Task Generated from "Submit Loan" must be cleared',

  // submit_to_underwriter
  conditionTemplateRequired: 'Condition Template is Required',
  underwriterRequired: 'Underwriter is Required',
}
