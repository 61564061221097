import { CheckIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'

interface OptionSelectProps {
  /**
   * Title of Input
   */
  title?: string
  /**
   * Value of Input
   */
  value?: string | undefined
  /**
   * Options of select
   */
  options?: Array<string> | Record<string, string> // (Title => key)
  /**
   * Sort
   */
  sort?: boolean

  disabled?: boolean

  onChange?: (value: any) => void
}

export const OptionSelect = ({
  title = '',
  value = '',
  options = [],
  sort = false,
  disabled = false,
  onChange = () => {},
}: OptionSelectProps) => {
  const renderItem = (key: string, _value: string, text: string) => {
    return (
      <li
        key={key}
        onClick={() => onChange(_value)}
        className={`flex justify-between hover:ring-4 hover:ring-gray-200 border border-gray-200 rounded-sm p-4 mb-4 cursor-pointer ${
          value == _value ? 'ring-4 ring-gray-200' : ''
        }`}
      >
        {text}
        <CheckIcon className={`w-5 h-5 ${value == _value ? 'text-shade-blue' : 'text-gray-200'}`} />
      </li>
    )
  }

  const renderOptions = useMemo(() => {
    let rlt: any = []
    if (Array.isArray(options)) {
      let _options = options
      if (sort) {
        _options = options.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase())
        })
      }
      _options.map((option) => {
        rlt.push(renderItem(`${title}-${option}`, option, option))
      })
    }

    if (!Array.isArray(options)) {
      let _options: any = []
      Object.keys(options).map((key) => {
        _options.push({
          key: key,
          value: options[key],
        })
      })
      if (sort) {
        _options = _options.sort(function (a: any, b: any) {
          return a.value.toLowerCase().localeCompare(b.value.toLowerCase())
        })
      }
      _options.map((item: any) => {
        rlt.push(renderItem(`${title}-${item.key}`, item.key, item.value))
      })
    }
    return rlt
  }, [options, value])

  return (
    <div className="text-left relative">
      <p className="mb-4 font-semibold">{title}</p>
      <ul>{renderOptions}</ul>
      {disabled && <div className="absolute left-0 top-0 bottom-0 right-0 bg-white/30" />}
    </div>
  )
}
