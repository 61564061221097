import { EnvelopeIcon, EyeIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import type { InputType } from 'config/input.type.constants'
import { StripeIntentDialog } from 'pages/Loan'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  createLoanServiceExtension,
  getLoanServiceExtension,
  getStripeIntent,
  postServicingNotes,
  sendEmailForLoanServiceExtension,
  updateLoanServiceExtension,
} from 'services'
import { FormTable, FormTableHeader } from 'stories/components'
import { confirm, emailObj2Txt, formatTime, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { extensionInputLogic, loanExtensionInputs } from './constant'

const header: FormTableHeader[] = [
  { key: 'id', title: 'Extension ID' },
  {
    key: 'paymentAmount',
    title: 'Fee',
  },
  {
    key: 'feasibilityType',
    title: 'Type',
  },
  {
    key: 'signer',
    title: (
      <span className="whitespace-nowrap">
        Signer Name /<br />
        Email
      </span>
    ),
  },
  { key: 'divStatus', title: 'Status' },
  {
    key: 'createdBy',
    title: (
      <span className="whitespace-nowrap">
        Requested Date /<br />
        By
      </span>
    ),
  },
]

const getNotesInputs = (): Record<string, InputType> => ({
  notes: {
    inputType: 'textarea',
    title: 'Global Notes',
    rows: 4,
    value: '',
    span: 4,
    visible: true,
    required: false,
  },
})

export const LoanExtension = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Array<any>>([])
  const [inputs, setInputs] = useState<Record<string, InputType>>(loanExtensionInputs())
  const [noteInputs, setNoteInputs] = useState<Record<string, InputType>>(getNotesInputs())
  const [stripeIntent, setStripeIntent] = useState<Record<string, any> | null>(null)

  const init = async () => {
    setLoading(true)

    const res = await getLoanServiceExtension()
    if (res.success) {
      setData(res.data)
      onChangeNote('notes', res.globalNotes)
    }

    let temp = cloneDeep(inputs)
    temp = inputLogic(temp)
    setInputs(temp)

    setLoading(false)
  }

  useEffect(() => {
    setLoanNumber()
    init()
  }, [])

  const onChoose = (item: any, index: number) => {
    if (item.status == 'Paid') onOpenHistory(item.intentId, index + 1)
    if (item.status == 'Sent') onSendEmail(item)
  }

  const onOpenHistory = async (intentId: number, index: number) => {
    if (!intentId) return
    setLoading(true)
    const stripeIntent = await getStripeIntent(intentId)
    const { status } = stripeIntent

    if (status == 'requires_action') {
      const url = stripeIntent.response.next_action.verify_with_microdeposits.hosted_verification_url
      var windowReference: any = window.open()
      windowReference.location = url
    } else {
      setStripeIntent({
        index,
        ...stripeIntent,
      })
    }
    setLoading(false)
  }

  const { email } = useSelector((state: any) => state.auth.profile)

  const onSendEmail = async (item: any) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to send email again?
        <br />
        <span className="text-gray-600">Extension ID: {item.id}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    sendEmailForLoanServiceExtension(item.id)
      .then(({ success }) => {
        if (!success) return
        toast('Email is sent successfully', { type: 'info' })
      })
      .finally(() => setLoading(false))
  }

  const getData = useMemo(() => {
    let rlt: any = []
    data.map((item, index) => {
      item.signer = `<div>${item.feasibilitySignerName || ''}</div><div>${emailObj2Txt(
        item.feasibilityEmailToLink,
        email,
      )}</div>`
      item.createdBy = `<div>${formatTime(item.createdAt)}</div><div>${item.by}</div>`
      item.divStatus = (
        <div
          className={`p-1 hover:underline cursor-pointer flex gap-2 items-center rounded justify-center italic font-semibold ${
            item.status === 'Paid' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
          }
      `}
          onClick={() => onChoose(item, index)}
        >
          {item.status} {item.status !== 'Sent' && <EyeIcon className="w-4 h-4"></EyeIcon>}
          {item.status === 'Sent' && <EnvelopeIcon className="w-4 h-4" />}
        </div>
      )
      rlt.push(item)
    })
    return rlt
  }, [data])

  const inputLogic = (_data: any) => {
    return extensionInputLogic(_data)
  }

  const onChangeNote = (key: string, value: string) => {
    const newInputs = cloneDeep(noteInputs)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    setNoteInputs(newInputs)
  }

  const onBlurNote = async (key: string) => {
    const value = noteInputs[key].value
    setLoading(true)
    await postServicingNotes({
      key: 'globalNotes',
      value,
    })
    setLoading(false)
  }

  const onSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise(async (resolve) => {
      setLoading(true)
      if (!currentId) {
        const res = await createLoanServiceExtension(values)
        if (res.success) {
          values.id = res.data.id
          values.status = res.data.status
          values.signer = `<div>${values.feasibilitySignerName}</div><div>${values.feasibilityEmailToLink}</div>`
          values.createdBy = `<div>${formatTime(res.data.createdAt)}</div><div>${res.data.by}</div>`
          init()
        }
      } else {
        const res = await updateLoanServiceExtension(values, currentId)
        if (res.success) {
          init()
        }
      }
      resolve(values)
    })
  }

  return (
    <div className="loan-extension-container">
      <LayoutLoading show={loading} />
      <div className="mb-4">
        <FormTable
          cols={4}
          header={header}
          inputs={inputs}
          inputLogic={inputLogic}
          data={getData}
          onSubmit={onSubmit}
          permission={1.5}
          addText={'Click Here to add New Extension'}
        />
      </div>
      <RenderInput input={noteInputs.notes} Key="notes" onChange={onChangeNote} onBlur={onBlurNote} />
      {stripeIntent && <StripeIntentDialog data={stripeIntent} onClose={() => setStripeIntent(null)} />}
    </div>
  )
}
