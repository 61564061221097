export enum GlobalConfigType {
  LoanStatus = 'LoanStatus',
  LoanFields = 'LoanFields',
  PagePermissions = 'PagePermissions',
  Footer = 'Footer',
  AcceptedFileTypes = 'AcceptFileTypes',
  ResourceVisibility = 'ResourceVisibility',
  ConditionTypes = 'ConditionTypes',
  DocumentCategory = 'DocumentCategory',
  GlobalSettingV2 = 'GlobalSettingV2',
}
