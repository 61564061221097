import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { useEffect, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'

interface IProjectPermissionTemplate {
  key: string
  title: string
  data?: IProjectPermissionTemplate[] | Record<string, string>
}

export enum MembershipType {
  Professional,
  Enterprise,
}

export const PagePermissions = () => {
  const [isLoading, setLoading] = useState(true)
  const [permissionType, setPermissionType] = useState<MembershipType>(MembershipType.Professional)
  const [templates, setTemplates] = useState<IProjectPermissionTemplate[]>([])
  const [visibleStatus, setVisibleStatus] = useState<Record<string, boolean>>({})
  const [isUpdated, setUpdated] = useState(false)

  useEffect(() => {
    getAdminConfig('pagePermissions')
      .then(({ template, data, type }) => {
        setTemplates(template || [])
        setVisibleStatus(data || {})
        setPermissionType(type)
      })
      .finally(() => setLoading(false))
  }, [])

  const isProfessional = permissionType == MembershipType.Professional

  const onSave = () => {
    if (isProfessional) return

    setLoading(true)
    setAdminConfig('pagePermissions', visibleStatus).finally(() => {
      setLoading(false)
      setUpdated(false)
    })
  }

  const onToggle = (key: string) => {
    if (isProfessional) return

    const newValues = cloneDeep(visibleStatus)
    newValues[key] = !(newValues[key] !== false)
    setVisibleStatus(newValues)
    setUpdated(true)
  }

  const renderTemplate = (template: IProjectPermissionTemplate, index: number, parentKey: string = '') => {
    const { key, title, data } = template
    const isTemplates = data instanceof Array
    const thisKey = `${parentKey}-${key}`
    const isActive = visibleStatus[thisKey] !== false
    const EIcon = isActive ? EyeIcon : EyeSlashIcon

    return (
      <div key={key} className={`${isActive ? '' : 'opacity-50'}`}>
        <span
          className={`flex items-center gap-2 ${parentKey == '' ? 'font-bold' : ''} ${isActive ? '' : 'line-through'} ${
            isProfessional ? '' : 'cursor-pointer hover:underline'
          }`}
          onClick={() => onToggle(thisKey)}
        >
          {index + 1}. {title}
          {!isProfessional && <EIcon className="w-4 h-4 text-shade-blue" />}
        </span>

        <div className="ml-2 pl-2 border-l border-dotted">
          {data && isTemplates && data.map((v, i) => renderTemplate(v, i, thisKey))}
          {data &&
            !isTemplates &&
            Object.keys(data).map((key, index) => renderTemplate({ key, title: data[key] }, index, thisKey))}
        </div>
      </div>
    )
  }

  return (
    <div className="newPermissions-container relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold">Page Permissions</h2>
      <div className="mt-4">
        {templates[0] && (
          <div className="grid md:grid-cols-2 gap-4">
            <div>{renderTemplate(templates[0], 0)}</div>
            <div>{templates.map((template, index) => index > 0 && renderTemplate(template, index))}</div>
          </div>
        )}
      </div>

      <SaveChanges show={isUpdated} onSave={onSave} />
    </div>
  )
}
