import { IdentificationIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { INVALID_ALL_INPUTS } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getPartyAdditionalInfo, getVendorOptionsPublic, submitRolodex, updateParties } from 'services'
import { Button, Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { confirmOptions } from 'utils/modals/confirmOptions'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultInputs, defaultValuesIfEmpty, extraFieldGroups, extraInputsOnGroup, inputGroups } from './config'
import { RolodexModal } from './RolodexModal'

export const assignToRoles = accountTypes

export const PartyModal = ({
  item,
  isConfig = false,
  ...props
}: {
  rolodexShow?: boolean
  isConfig?: boolean
  onClose: any
  item: Record<string, any> | null
}) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [groups, setGroups] = useState(inputGroups)
  const [isShowOverlay, setShowOverlay] = useState(false)
  const [isShowRolodexModal, setShowRolodexModal] = useState(false)
  const [obiePartnerId, setObiePartnerId] = useState('')

  if (!item) return null

  const { hasPermission } = usePermissions()

  const profile = useSelector((state: any) => {
    return state.auth.profile
  })

  useEffect(() => {
    getVendorOptionsPublic('obie').then(({ enabled, partnerId }: { enabled: boolean; partnerId: string }) => {
      enabled && setObiePartnerId(partnerId)
    })
  }, [])

  const fillRelatedPartyInfo = async (data: any, type: string) => {
    setLoading(true)
    const res = await getPartyAdditionalInfo(type)
    let resData = res
    setLoading(false)
    if (type === 'Contractor-contractor') {
      let options: Array<any> = []
      res.map((item: any, index: number) => {
        options.push({ name: item.Company, desc: item.FirstName + ' ' + item.LastName, value: index })
      })
      const optionRes: any = await confirmOptions('Choose Contractor...', options)
      if (optionRes === false) return
      resData = res[optionRes]
    }
    switch (type) {
      case 'Contractor-borrower':
      case 'Contractor-contractor':
      case 'Appraiser':
        let tempInput = cloneDeep(data)

        Object.keys(resData).map((key) => {
          tempInput[key].value = resData[key]
          tempInput[key].error = InputValidate(tempInput[key])
        })

        setInputs(convertAdditionalInfo(tempInput))
        break
    }
  }

  const fillHarzardInsuranceCompanyInfo = async () => {
    let tempInput = cloneDeep(inputs)

    Object.keys(tempInput).map((key) => {
      if (tempInput[key].required) tempInput[key].value = 'TBD'
      tempInput[key].error = ''
    })
    tempInput['State'].value = 'AL'
    tempInput['Zip'].value = '11111'
    tempInput['WorkPhone'].value = '111-111-1111'
    tempInput['EMail'].value = 'tbd@tbd.com'

    setInputs(convertAdditionalInfo(tempInput))
  }

  const convertAdditionalInfo = (data: any) => {
    switch (item.Type) {
      case 'Appraiser':
        data['Company'].additionalElements = (
          <span
            className="mx-2 text-shade-blue hover:underline cursor-pointer font-variation-settings-600"
            onClick={() => fillRelatedPartyInfo(data, 'Appraiser')}
          >
            Fill Appraisal Review Info
          </span>
        )
        break
      case 'Contractor':
        data['Company'].additionalElements = (
          <>
            <span
              className="mx-2 text-shade-blue hover:underline cursor-pointer font-variation-settings-600"
              onClick={() => fillRelatedPartyInfo(data, 'Contractor-borrower')}
            >
              Borrower Info
            </span>
            <span
              className="mx-2 text-shade-blue hover:underline cursor-pointer font-variation-settings-600"
              onClick={() => fillRelatedPartyInfo(data, 'Contractor-contractor')}
            >
              Contractor Info
            </span>
          </>
        )
        break
      case 'Hazard Insurance Company':
        data['Company'].additionalElements = (
          <span
            className="mx-2 text-shade-blue hover:underline cursor-pointer font-variation-settings-600"
            onClick={fillHarzardInsuranceCompanyInfo}
          >
            Fill TBD
          </span>
        )
        break
    }
    return data
  }

  useEffect(() => {
    let newInputs = cloneDeep(defaultInputs())
    let newGroups = cloneDeep(inputGroups)
    for (var key in newInputs) {
      newInputs[key].value = item[key]
    }
    if (defaultValuesIfEmpty[item.Type]) {
      Object.keys(defaultValuesIfEmpty[item.Type]).map((key) => {
        if (!item[key]) newInputs[key].value = defaultValuesIfEmpty[item.Type][key]
      })
    }

    if (extraFieldGroups[item.Type] !== undefined) {
      newGroups = {
        ...extraFieldGroups[item.Type],
        ...newGroups,
      }
    }
    if (extraInputsOnGroup[item.Type] !== undefined) {
      Object.keys(extraInputsOnGroup[item.Type]).map((key: string) => {
        Object.keys(extraInputsOnGroup[item.Type][key]).map((field) => {
          newGroups[key].splice(extraInputsOnGroup[item.Type][key][field], 0, field)
        })
      })
    }
    newInputs = convertAdditionalInfo(newInputs)
    setInputs(newInputs)
    setGroups(newGroups)
  }, [item])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(convertAdditionalInfo(newInputs))
  }

  const visibleKeys = useMemo(() => {
    let rlt: any = []
    Object.keys(groups).map((key) => {
      rlt = [...rlt, ...groups[key]]
    })
    return rlt
  }, [groups])

  const onSubmit = async () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = cloneDeep(item)
    for (const key in inputs) {
      if (!isConfig && key === 'borrowerRequired') continue
      if (visibleKeys.includes(key)) {
        newInputs[key].error = InputValidate({
          ...newInputs[key],
          required: isConfig ? false : newInputs[key].required,
        })
        data[key] = newInputs[key].value
        if (newInputs[key].error) hasError = true
      }
    }
    setInputs(convertAdditionalInfo(newInputs))
    if (hasError) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }
    setLoading(true)
    const loanNumber = setLoanNumber()
    updateParties(Number(loanNumber), item.PartyID || 0, data)
      .then(() => {
        toast('Party is updated', { type: 'info' })
        data.Name = [data.FirstName, data.MiddleName, data.LastName].join(' ').trim()
        props.onClose(true, data)
      })
      .catch(() => setLoading(false))
  }

  const onClose = () => {
    props.onClose()
  }

  const onClickIdentification = () => {
    setShowOverlay(true)
  }

  const onSelectFromRolodex = () => {
    setShowOverlay(false)
    setShowRolodexModal(true)
  }

  const onAddToRolodex = () => {
    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = item
    for (const key in inputs) {
      data[key] = newInputs[key].value
    }
    setInputs(convertAdditionalInfo(newInputs))
    setLoading(true)
    data.CategoryID = item.CategoryID
    submitRolodex(0, data)
      .then(() => {
        toast('New Rolodex is added.', { type: 'success' })
        setShowOverlay(false)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const onCloseRolodexModal = (data: Record<string, any>) => {
    setShowOverlay(false)
    setShowRolodexModal(false)
    if (!data) return
    let newInputs = cloneDeep(inputs)
    for (var key in newInputs) {
      if (data[key] !== undefined) newInputs[key].value = data[key]
    }
    setInputs(convertAdditionalInfo(newInputs))
  }

  const onObieClick = async () => {
    ;(window as any).Obie.open({
      partnerId: obiePartnerId,
      // sandbox: true,
    })
  }

  return (
    <>
      <Modal
        title={
          <span className="flex">
            Update {item.Type || ''}
            {props.rolodexShow !== false && (
              <button onClick={onClickIdentification}>
                <IdentificationIcon className="w-7 h-7 ml-2" />
              </button>
            )}
          </span>
        }
        titleOkay={'Update'}
        loading={loading}
        onClose={onClose}
        onOk={onSubmit}
        isOpen
      >
        <div className="text-gray-700 w-[calc(70vw)] text-left">
          {Object.keys(groups).map((title, index: number) => {
            const inputGroup = (groups as any)[title]
            return (
              <div className="mb-5" key={title}>
                <p className="border-b font-bold text-sm w-full mb-2">
                  {title}{' '}
                  {profile.isBorrower && item.Type === 'Hazard Insurance Company' && !index && (
                    <span className="text-shade-blue italic cursor-pointer ml-2" onClick={onObieClick}>
                      Insurance Quote
                    </span>
                  )}
                </p>
                <div className="grid gap-4 xl:grid-cols-3 md:grid-cols-2 grid-cols-1 mb-3">
                  {inputGroup.map((key: string) => {
                    if (!isConfig && key === 'borrowerRequired') return
                    let input = inputs[key] as any
                    return (
                      <div className={`input md:col-span-${input.span || 1}`} key={key}>
                        <RenderInput input={input} Key={key} onChange={onChange} />
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </Modal>
      {isShowOverlay && (
        <>
          <div
            className={`w-100 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-${
              30 + 10
            } outline-none focus:outline-none`}
            onClick={(e) => !e.defaultPrevented && setShowOverlay(false)}
          >
            <div className="bg-white px-10 py-4 rounded" onClick={(e) => e.preventDefault()}>
              <Button link onClick={onSelectFromRolodex}>
                Select from Rolodex...
              </Button>
              {hasPermission('MANAGE_CONDITIONS_TEMPLATES') && (
                <Button link onClick={onAddToRolodex}>
                  <span>
                    Add this to Rolodex as <b>{item.Type || ''}</b>
                  </span>
                </Button>
              )}
            </div>
            {loading && <LayoutLoading show />}
          </div>
          <div className={`opacity-25 fixed inset-0 z-${20 + 10} bg-black`}></div>
        </>
      )}
      {isShowRolodexModal && <RolodexModal type={item.Type} onClose={onCloseRolodexModal} />}
    </>
  )
}
