import { BuildingLibraryIcon, CheckCircleIcon, CreditCardIcon, EyeIcon } from '@heroicons/react/24/outline'
import { AUTH_TOKEN_SET } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import type { InputType } from 'config'
import jwtDecode from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getOrderValuation,
  getStripeIntent,
  PaymentOrderType,
  StripeIntentPaidStatus,
  StripeIntentStatus,
} from 'services'
import { PlainTable } from 'stories/components/PlainTable'
import { formatTime, InputConvert, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultInputs } from './constants'
import { StripeIntentDialog } from './StripeIntentDialog'
import { StripePay } from './StripePay'
import { feasibilityAllKeys, FeasibilityHistory, StripeStatusLabels } from './types'

const feasibilityInputs = () => {
  const inputs = defaultInputs()
  const filteredInputs: Record<string, InputType> = {}
  feasibilityAllKeys.forEach((key) => (filteredInputs[key] = inputs[key]))
  delete filteredInputs['feasibilityTitle']
  return filteredInputs
}

const alertInput: InputType = {
  inputType: 'alert',
  title: '',
  value: `A Feasibility Study (FNF) and/or Budget Review (GUC) is an underwriting requirement as part of your transaction.<br/>Please complete the payment information below so that we may go ahead and place the order with one of our approved vendors.`,
  visible: true,
  span: 3,
}

export default function OrderValuationFeasibilitySign() {
  const urlParams: any = useParams()
  const { token, id } = urlParams
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>(feasibilityInputs())
  const [orgData, setOrgData] = useState<Record<string, any>>({})
  const [orgStatus, setOrgStatus] = useState('')
  const dispatch = useDispatch()
  const [histories, setHistories] = useState<FeasibilityHistory[]>([])
  const [payData, setPayData] = useState<Record<string, any> | null>(null)
  const [appraisalForm, setAppraisalForm] = useState('')
  const [isPaid, setPaid] = useState(false)
  const [status, setStatus] = useState<StripeIntentStatus>()
  const [stripeIntent, setStripeIntent] = useState<Record<string, any> | null>(null)

  useEffect(() => {
    setLoanNumber()
    const payload: any = jwtDecode(token)

    dispatch({
      type: AUTH_TOKEN_SET,
      token,
      user: payload,
    })
    load(payload.refId)
  }, [])

  const orderTitle = useMemo(() => {
    if (!orgData || !orgData.orderType) return ''
    return orgData.orderType.split(' ')[0]
  }, [orgData])

  const renderStatus = (status: string) => {
    if (!StripeStatusLabels[status]) return status
    return <p className={`font-medium text-${StripeStatusLabels[status][0]}-500`}>{StripeStatusLabels[status][1]}</p>
  }

  const historiesTableData = useMemo(
    () =>
      histories.map((history, index) => [
        index + 1,
        <p className="flex gap-1 items-center">
          {history.type == 'ACH' ? (
            <BuildingLibraryIcon className="text-shade-blue w-4 h-4" />
          ) : (
            <CreditCardIcon className="text-shade-blue w-4 h-4" />
          )}
          {history.type}
        </p>,
        renderStatus(history.data.status),
        formatTime(history.createdAt),
        <button
          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
          onClick={() => onOpenHistory(history, index + 1)}
        >
          <EyeIcon className="w-4 h-4" />
        </button>,
      ]),
    [histories],
  )

  useEffect(() => {
    if (!orgStatus) return
    if (['paymentAuthorized', 'paymentReceived'].includes(orgStatus)) {
      disableInputs()
      const lastPayHistory = (histories as any).findLast((history: any) => history.method == 'Pay')
      if (lastPayHistory && StripeIntentPaidStatus.includes(lastPayHistory.data.status)) setPaid(true)
      if (lastPayHistory) setStatus(lastPayHistory.data.status)
    }
  }, [orgStatus])

  const load = async (refId: string) => {
    setLoading(true)
    const { data, histories } = await getOrderValuation(id)

    const newInputs = cloneDeep(inputs)
    Object.keys(data).forEach((key) => {
      if (newInputs[key]) newInputs[key].value = data[key]
    })
    data.refId = refId
    useLogic(newInputs, data.appraisalForm, data)

    setInputs(newInputs)
    setOrgData(data)
    setHistories(histories)
    setOrgStatus(data.status)
    setLoading(false)
    setLoaded(true)
    setAppraisalForm(data.appraisalForm)
  }

  const useLogic = (inputs: Record<string, InputType>, appraisalForm: string, orgData: Record<string, any>) => {
    inputs.feasibilityEmailToLink.disabled = true

    inputs.feasibilityType.visible = true
    inputs.feasibilityEmailToLink.visible = true
    inputs.feasibilityCCStudyFee.visible = true

    setPayData({
      appraisal2Id: id,
      orderType: orgData.orderType,
      type: inputs['feasibilityType'].value,
      email: inputs['feasibilityEmailToLink'].value,
      amount: removeComma(orgData.feasibilityCCStudyFee),
      refId: orgData.refId,
      payOrderType: PaymentOrderType.FeasibilitySign,
    })
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    useLogic(newInputs, appraisalForm, orgData)
    setInputs(newInputs)
  }

  const disableInputs = () => {
    const newInputs = cloneDeep(inputs)
    Object.keys(inputs).forEach((key) => {
      newInputs[key].disabled = true
    })
    setInputs(newInputs)
  }

  const onPay = (result: boolean, intentId: string, status: StripeIntentStatus) => {
    if (!result) return

    setTimeout(async () => {
      const { histories } = await getOrderValuation(id)
      setHistories(histories)
    }, 2000)
    setPaid(true)
    setStatus(status)
  }

  const onCloseIntent = () => {
    setStripeIntent(null)
  }

  const onOpenHistory = async (history: FeasibilityHistory, index: number) => {
    setLoading(true)
    const stripeIntent = await getStripeIntent(history.data.intentId)
    const { status } = stripeIntent

    if (status == 'requires_action') {
      const url = stripeIntent.response.next_action.verify_with_microdeposits.hosted_verification_url
      var windowReference: any = window.open()
      windowReference.location = url
    } else {
      setStripeIntent({
        index,
        ...stripeIntent,
      })
    }
    setLoading(false)
  }

  if (!loaded) return <LayoutLoading show />

  return (
    <TemporaryLayout noOverview={true}>
      <div className="OrderValuationFeasibilityPayment-container">
        <div className="px-2 mt-6">
          <Overview title={`${orderTitle} Payment${orgData.id ? ` #${orgData.id}` : ''}`} hasBackButton={false} />
          <div className="max-w-screen-2xl m-auto">
            <LayoutLoading show={loading} />
            <div className="relative bg-white shadow1 rounded mb-6 p-4">
              <RenderInput input={alertInput} Key="alertInput" onChange={() => {}} />

              <div className="max-w-4xl">
                <div className={`grid gap-4 md:grid-cols-3 grid-cols-1 mb-1`}>
                  {feasibilityAllKeys.map((key) => {
                    let input = inputs[key]
                    if (!input || input.visible === false) return null
                    return (
                      <div className={`input md:col-span-${input.span || 1}`} key={key}>
                        <RenderInput input={input} Key={key} onChange={onChange} />
                      </div>
                    )
                  })}
                </div>

                {!isPaid && payData && <StripePay data={payData} onPay={onPay} />}
                {isPaid && (
                  <div
                    className="my-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-[15px] flex items-center"
                    role="alert"
                  >
                    <CheckCircleIcon className="w-6 h-6"></CheckCircleIcon>
                    <span className="ml-1">
                      {status == StripeIntentStatus.succeeded
                        ? 'Successfully Paid'
                        : `We've received your payment request. This will be completed in 1~2 days.`}
                    </span>
                  </div>
                )}
              </div>

              <div className="mt-6 w-fit">
                <div className="font-bold ml-2">Histories</div>
                <PlainTable header={['No', 'Type', 'Status', 'Created At', 'View']} data={historiesTableData} />
              </div>
            </div>
          </div>
          {stripeIntent && <StripeIntentDialog data={stripeIntent} onClose={onCloseIntent} />}
        </div>
      </div>
    </TemporaryLayout>
  )
}
