import type { BaseFile } from 'config'

export interface ITicket {
  id: number
  title: string
  description: string
  category: string
  loanNumber?: number
  status: TicketStatus
  priority: TicketPriority
  type: TicketType
  hasUncheckedMessages: boolean
  hasUncheckedAdminMessages: boolean
  dueDate: string
  createdAt: string
  updatedAt: string
  project: IProject
}

export interface IProject {
  id: number
  userId: number
  userFirstName: string
  userLastName: string
  name: string
  description: string
  status: ProjectStatus
  environments: Record<string, any>
  settings: Record<string, any>
  createdBy: string
  createdAt: string
  updatedAt: string
  logo: string
  logoURL: string
  icon: string
  iconURL: string
  company: ICompany
}

export interface ICompany {
  companyName: string
  title: string
  dns: string
  nmls: string
  phone: string
  phoneFree: string
  address: string
  companyContactEmail: string
  facebookUrl: string
  twitterUrl: string
  instagramUrl: string
  linkedinUrl: string
  youtubeUrl: string
  appleIconUrl: string
}

export enum ProjectStatus {
  Pending = 0,
  Deploying = 1,
  Deployed = 2,
  Terminating = -1,
  Inactive = -2,
  Delected = -3,
}

export enum TicketStatus {
  Requested = 0,
  InProgress = 1,
  Testing = 2,
  Completed = 3,
  Closed = -1,
}

export enum TicketPriority {
  Normal = 'Normal',
  High = 'High',
  Low = 'Low',
}

export enum TicketType {
  RequiredSupport = 1,
  ReportIssue = 2,
  NewFeature = 3,
  TrainingRequest = 4,
}

export const TicketTypeTexts: Record<TicketType, string> = {
  [TicketType.RequiredSupport]: 'Required Support',
  [TicketType.ReportIssue]: 'Report Issue',
  [TicketType.NewFeature]: 'Submit New Feature',
  [TicketType.TrainingRequest]: 'Training Request',
}

export const TicketStatusColorMap = {
  [TicketStatus.Requested]: 'bg-indigo-400 text-white p-2 rounded-md',
  [TicketStatus.InProgress]: 'bg-yellow-400 text-white  p-2 rounded-md',
  [TicketStatus.Testing]: 'bg-blue-400 text-white  p-2 rounded-md',
  [TicketStatus.Completed]: 'bg-green-400 text-white  p-2 rounded-md',
  [TicketStatus.Closed]: 'bg-gray-500 text-white  p-2 rounded-md',
}

export const TicketStatusText: Record<TicketStatus, string> = {
  [TicketStatus.Requested]: 'Requested',
  [TicketStatus.InProgress]: 'In Progress',
  [TicketStatus.Testing]: 'Testing',
  [TicketStatus.Completed]: 'Completed',
  [TicketStatus.Closed]: 'Closed',
}

export const TicketPriorityText: Record<TicketPriority, string> = {
  [TicketPriority.Normal]: 'Normal',
  [TicketPriority.High]: 'High',
  [TicketPriority.Low]: 'Low',
}

export const TicketPriorityDate: Record<TicketPriority, number> = {
  [TicketPriority.Normal]: 3,
  [TicketPriority.High]: 1,
  [TicketPriority.Low]: 7,
}

export const TicketStatusHoverColorMap = {
  [TicketStatus.Requested]: 'text-indigo-200',
  [TicketStatus.InProgress]: 'text-green-600',
  [TicketStatus.Testing]: 'text-blue-600',
  [TicketStatus.Completed]: 'text-red-500',
}

export const TicketStatusHoverBorderColorMap = {
  [TicketStatus.Requested]: 'border-indigo-200',
  [TicketStatus.InProgress]: 'border-green-600',
  [TicketStatus.Testing]: 'border-blue-600',
  [TicketStatus.Completed]: 'border-red-500',
}
export const TicketPriorityStyles: Record<TicketPriority, string> = {
  [TicketPriority.High]: 'bg-red-700 text-white p-1 rounded-md',
  [TicketPriority.Normal]: 'bg-green-400 text-white p-1 rounded-md',
  [TicketPriority.Low]: 'bg-yellow-400 text-white p-1 rounded-md',
}

export const statusArr = ['Requested', 'In Progress', 'Completed', 'Closed']

export const statusMap: Record<string, TicketStatus> = {
  Requested: TicketStatus.Requested,
  'In Progress': TicketStatus.InProgress,
  Testing: TicketStatus.Testing,
  Completed: TicketStatus.Completed,
  Closed: TicketStatus.Closed,
}

export type ITicketItem = {
  id: number
  ticketId: string
  text: string
  createdBy: string
  isAdmin: boolean
  updatedAt: string
  createdAt: string
  files: BaseFile[]
}
