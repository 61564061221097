import type { InputType } from 'config'
import { TicketTypeTexts } from 'pages/Tickets/types'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    category: {
      inputType: 'text',
      title: 'Category',
      required: true,
    },
    type: {
      title: 'Type',
      inputType: 'select',
      options: TicketTypeTexts,
      error: '',
      required: true,
      hasDefaultOption: true,
    },
    title: {
      inputType: 'text',
      title: 'Subject',
      required: true,
      span: 'full',
    },
    loanNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Loan Number',
      required: false,
      span: 'full',
    },
    description: {
      title: 'Description',
      inputType: 'textarea',
      error: '',
      required: true,
      span: 'full',
    },
  }
}
