import type { InputType } from 'config'

export const EmailGroup: Record<string, string> = {
  loanSubmission: 'Loan Submission',
  loanAction: 'Loan Actions',
  loan: 'Loan',
  user: 'User',
}

export const EmailPurposeOptions: Record<string, string> = {}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    from: {
      inputType: 'select',
      title: 'Email From',
      options: {},
      hasDefaultOption: true,
      required: true,
    },
    purpose: {
      title: 'Email Purpose',
      inputType: 'multiselect',
      required: false,
      options: EmailPurposeOptions,
      sort: true,
      value: [],
      sortKey: 'value',
    },
    to: {
      inputType: 'autocomplete',
      type: 'email',
      title: 'Email To',
      required: false,
    },
    cc: {
      inputType: 'autocomplete',
      type: 'email',
      title: 'Email To CC',
      required: false,
    },
    group: {
      title: 'Email Group',
      inputType: 'Select',
      required: true,
      options: EmailGroup,
      hasDefaultOption: true,
    },
    subject: {
      inputType: 'text',
      title: 'Email Subject',
      required: true,
    },
    title: {
      inputType: 'text',
      title: 'Email Title',
      required: true,
    },
    elements: {
      title: 'Email Elements',
      inputType: 'multiselect',
      required: false,
      options: {},
      value: [],
    },
    content: {
      inputType: 'textarea',
      title: 'Email Content',
      required: false,
      rows: 15,
      span: 'full',
    },
  }
}
