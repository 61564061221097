import cloneDeep from 'clone-deep'
import { COMPANY_NAME, COMPANY_NAME_FC } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getSetting, updateSetting } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'
import { Button, Input2 } from 'stories/components'

export const ResourcesBroadcastSettingKey = 'RESOURCE_BROADCAST'
export const ResourceVisibleAccountTypesKey = 'RESOURCE_VISIBLE_ACCOUNT_TYPES'

const getTypes = () => [
  {
    title: 'Guidelines and Matrices',
    key: 'guideline',
  },
  {
    title: 'Materials',
    key: 'training',
  },
  {
    title: 'Residential Appraisal Management',
    key: 'resisdential',
  },
  {
    title: `${COMPANY_NAME_FC} Fees`,
    key: `${COMPANY_NAME}Fees`,
  },
  {
    title: 'Forms',
    key: 'form',
  },
  {
    title: 'Underwriting Fees',
    key: 'underwritingFees',
  },
]

export const ResourceBroadcast = () => {
  const [action, setAction] = useState('')
  const [content, setContent] = useState<Record<string, string>>({})

  useEffect(() => {
    setAction('setting')
    getSetting(ResourcesBroadcastSettingKey)
      .then(({ value }) => setContent(JSON.parse(value || '{}')))
      .finally(() => setAction(''))
  }, [])

  const onChange = (key: string, value: string) => {
    const newValues = cloneDeep(content)
    newValues[key] = value
    setContent(newValues)
  }

  const onSubmit = async () => {
    setAction('setting')
    await updateSetting(ResourcesBroadcastSettingKey, JSON.stringify(content))
    setAction('')
    toast('Resources Broadcast is saved.', { type: 'info' })
  }

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">
        Resources Broadcast
        {action && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>

      <div className="relative mb-6">
        {getTypes().map(({ key, title }) => (
          <div className="mb-4" key={key}>
            <Input2 title={title} type="text" value={content[key] || ''} onChange={(value) => onChange(key, value)} />
          </div>
        ))}
        <Button className="w-[300px]" loading={action == 'setting'} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </div>
  )
}
