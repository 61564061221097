import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { getInitialLoanFields, SettingKey } from 'config'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services'
import { Button } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

import { IUwSummaryConfig, UwSummarySection } from './types'

export const UnderwritingSummaryConfig = () => {
  const loanInputs = getInitialLoanFields()

  const [isLoading, setLoading] = useState(true)
  const [isChanged, setChanged] = useState<boolean>()
  const [data, setData] = useState<IUwSummaryConfig>({
    [UwSummarySection.UnderwritingInformation]: {},
  })

  useEffect(() => {
    setLoading(true)
    getSetting(SettingKey.UW_SUMMARY_CONFIG)
      .then(({ value }) => {
        value && setData(JSON.parse(value))
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (isLoading) return
    if (isChanged === undefined) {
      setChanged(false)
      return
    }
    setChanged(true)
  }, [data])

  const onVisible = (key: string, visible: boolean) => {
    const newData = cloneDeep(data)
    if (!newData[UwSummarySection.UnderwritingInformation][key])
      newData[UwSummarySection.UnderwritingInformation][key] = { visible }
    newData[UwSummarySection.UnderwritingInformation][key].visible = visible
    setData(newData)
  }

  const onSubmit = async () => {
    setLoading(true)
    await updateSetting(SettingKey.UW_SUMMARY_CONFIG, JSON.stringify(data))
    setLoading(false)
    setChanged(false)
  }

  return (
    <div>
      <LayoutLoading show={isLoading} />

      <RenderInput
        Key="section1"
        input={{ inputType: 'section', title: '1. Underwriting Information' }}
        onChange={() => {}}
      />
      <div className="grid grid-cols-3 mt-4 gap-2 mb-4">
        {Object.keys(loanInputs).map((key, index) => {
          const { visible = true } = data[UwSummarySection.UnderwritingInformation][key] || {}
          const Icon = visible ? EyeIcon : EyeSlashIcon

          return (
            <div
              className={`flex justify-between items-center ${visible ? '' : 'line-through opacity-60'}`}
              onClick={() => onVisible(key, !visible)}
            >
              <p className={`text-sm cursor-pointer`}>
                {index + 1}. {loanInputs[key].title}
              </p>
              <span className="btn-icon">
                <Icon className="w-4 h-4" />
              </span>
            </div>
          )
        })}
      </div>

      <div className="flex flex-row justify-center gap-4">
        <Button onClick={onSubmit} loading={isLoading} disabled={!isChanged}>
          Save
        </Button>
      </div>

      <SaveChanges show={isChanged} label="Save Changes" onSave={onSubmit} />
    </div>
  )
}
