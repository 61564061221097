import { EyeIcon, EyeSlashIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import { SettingKey } from 'config'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services'
import { Modal } from 'stories/components'
import { confirm } from 'utils'
import { IOptionType } from 'utils/general'

export const OptionModal = ({
  title,
  settingKey,
  onClose,
}: {
  title: string
  settingKey: string | SettingKey
  onClose: Function
}) => {
  const [isLoading, setLoading] = useState(true)
  const [values, setValues] = useState<IOptionType[]>([])

  useEffect(() => {
    getSetting(settingKey)
      .then((data) => {
        const { value } = data
        if (!value) return

        setValues(JSON.parse(value))
      })
      .finally(() => setLoading(false))
  }, [])

  const onAdd = () => {
    const newValues = cloneDeep(values)
    newValues.push({ id: Date.now(), value: null, visible: false })
    setValues(newValues)
  }

  const onUpdate = (newValue: string, index: number) => {
    const newValues = cloneDeep(values)
    newValues[index].value = newValue
    setValues(newValues)
  }

  const onChangeOrder = (oldIdx: number, newIdx: number) => {
    const newValues = cloneDeep(values)
    const deletedItem = newValues.splice(oldIdx, 1)
    newValues.splice(newIdx, 0, ...deletedItem)
    setValues(newValues)
  }

  const onVisible = (index: number) => {
    const newValues = cloneDeep(values)
    newValues[index].visible = !newValues[index].visible
    setValues(newValues)
  }

  const onDelete = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete this category?
        <br />
        <span className="text-gray-600">Category: {values[index].value}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
  }

  const onOk = async () => {
    setLoading(true)
    const savingValues = values.filter((value) => value.value !== null)
    await updateSetting(settingKey, JSON.stringify(savingValues))
    setLoading(false)
    onClose(values)
  }

  const sortKeys = Object.keys(Array(values.length).fill(1))

  return (
    <Modal title={title} onClose={() => onClose(null)} titleOkay="Update" onOk={onOk} loading={isLoading} isOpen>
      <div className="min-w-[32rem]">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3 w-[20px]">
                No
              </th>
              <th scope="col" className="px-3 py-3">
                Name
              </th>
              <th scope="col" className="px-3 py-3 w-[50px]">
                Order
              </th>
              <th scope="col" className="px-3 py-3">
                <button
                  className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline"
                  onClick={onAdd}
                >
                  + Add
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {values.map((value, index) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + 1}
                  </td>

                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    <PlainInput
                      value={value.value || ''}
                      content={value.value || ''}
                      className="h-[35px]"
                      onChange={(newValue: string) => onUpdate(newValue, index)}
                    />
                  </td>

                  <td>
                    <select
                      value={index}
                      className="text-sm border-gray-200 py-1"
                      onChange={(e) => onChangeOrder(index, parseInt(e.target.value))}
                    >
                      {[...sortKeys].map((value) => (
                        <option value={value} key={value}>
                          {parseInt(value) + 1}
                        </option>
                      ))}
                    </select>
                  </td>

                  <td className="px-6 py-1">
                    <span className="flex gap-2">
                      <span
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                        onClick={() => onVisible(index)}
                      >
                        {value.visible ? (
                          <EyeIcon className="w-4 h-4"></EyeIcon>
                        ) : (
                          <EyeSlashIcon className="text-gray-300 w-4 h-4"></EyeSlashIcon>
                        )}
                      </span>

                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(index)}>
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}
