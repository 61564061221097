import { ClockIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getExpirationDates, resetExpirationDate, updateExpirationDates } from 'services'
import { Checkbox, Modal } from 'stories/components'
import { confirm, formatDate, formatTime, isBeforeOrEqualToday, validDateMMDDYYYY } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { ExpirationDatesStatus } from './ExpirationDatesStatus'
import { ExpirationDatesUpdate } from './ExpirationDatesUpdate'

const IDkeys = ['driverLicense', 'passport', 'stateId', 'eadCard', 'alienCard']

export interface ExpirationDatesItem {
  title: string
  date: string
  days: number
  expirationDate: string
  type: number // undefined: default, 1: only expiration date
  seasonType: number
  isCoBorrower: number // 0|undefined: default, 1: coBorrower
  startDatePass?: boolean
  requirePass: boolean
  /**
   * True: Overridden
   * False: Active
   */
  status: boolean
  reason: string
  history: {
    type: string
    value: string
    by: string
    date: number
  }[]
}

export default function ExpirationDates({ child = false }) {
  const [loading, setLoading] = useState(false)
  const [isOpenType, setIsOpenType] = useState<string>('')
  const [historyType, setHistoryType] = useState('')
  const [{ includeCoborrower }, borrowers] = useSelector((state: any) => [state.loanDetail, state.borrower])

  const [data, setData] = useState<Record<string, ExpirationDatesItem>>({})
  const [selectedType, setSelectedType] = useState('')

  useEffect(() => {
    setLoanNumber()
    refetch()
  }, [])

  const sortedKeys = useMemo(() => {
    const isIDKey = (key: string) => {
      let rtl = false
      IDkeys.map((idKey: string) => {
        if (key.startsWith(idKey)) rtl = true
      })
      return rtl
    }
    const originKeys = Object.keys(data)
    const rtl = originKeys.filter((key) => !data[key].isCoBorrower && !isIDKey(key))
    rtl.push(...originKeys.filter((key) => data[key].isCoBorrower && !isIDKey(key)))
    rtl.push(...originKeys.filter((key) => !data[key].isCoBorrower && isIDKey(key)))
    rtl.push(...originKeys.filter((key) => data[key].isCoBorrower && isIDKey(key)))
    return rtl
  }, [data])

  const renderIDRestriction = useMemo(() => {
    let exist = false
    IDkeys.map((key) => {
      if (!data[key]?.isCoBorrower && validDateMMDDYYYY(data[key]?.expirationDate)) exist = true
    })
    if (!exist) {
      return (
        <tr key="IDKeys">
          <td colSpan={6} className="bg-red-100 px-4 py-3 text-red-700 font-variation-settings-600">
            Borrower ID (At least 1 of the following EXPIRATION DATE is Required!)
          </td>
        </tr>
      )
    }
    return (
      <tr key="IDKeys">
        <td colSpan={6} className="bg-gray-200 px-4 py-3 text-gray-700 font-variation-settings-600">
          Borrower ID
        </td>
      </tr>
    )
  }, [data])

  const renderIDRestrictionCoBorrower = (index: number) => {
    let exist = false
    IDkeys.map((key) => {
      key = `${key}CoBorrower${index || ''}`
      if (data[key]?.isCoBorrower && validDateMMDDYYYY(data[key]?.expirationDate)) exist = true
    })
    if (!exist) {
      return (
        <tr key="IDKeys-Coborrower">
          <td colSpan={6} className="bg-red-100 px-4 py-3 text-red-700 font-variation-settings-600">
            CoBorrower({index > 0 ? index : index + 1}) ID (At least 1 of the following EXPIRATION DATE is Required!)
          </td>
        </tr>
      )
    }
    return (
      <tr key="IDKeys-Coborrower">
        <td colSpan={6} className="bg-gray-200 px-4 py-3 text-gray-700 font-variation-settings-600">
          CoBorrower ID
        </td>
      </tr>
    )
  }

  const refetch = () => {
    setLoading(true)
    getExpirationDates()
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }

  const onEditDate = (type: string) => {
    setIsOpenType('date')
    setSelectedType(type)
  }

  const onEditDays = (type: string) => {
    setIsOpenType('day')
    setSelectedType(type)
  }

  const onEditExpDate = (type: string) => {
    setIsOpenType('expirationDate')
    setSelectedType(type)
  }

  const onCloseModal = () => {
    setIsOpenType('')
    setSelectedType('')
  }

  const onUpdateDate = (value: string) => {
    onChange(isOpenType, formatDate(value), selectedType)
    onCloseModal()
  }

  const onUpdateDays = (value: number) => {
    onChange('days', value, selectedType)
    onCloseModal()
  }

  const onUpdateReason = async (reason: string, status: boolean, type: string) => {
    if (type === undefined) type = selectedType
    setLoading(true)
    await updateExpirationDates(type, { reason, status })
    setLoading(false)
    const newData = cloneDeep(data)
    newData[type].reason = reason
    newData[type].status = status
    setData(newData)
    onCloseModal()
  }

  const onChange = async (key: string, value: string | number, type: string) => {
    setLoading(true)
    await updateExpirationDates(type, { [key]: value })
    setLoading(false)

    const newData = cloneDeep(data)
    ;(newData[type] as any)[key] = value

    if (!newData[type].type && newData[type].days !== null && newData[type].date && ['date', 'days'].includes(key)) {
      newData[type].expirationDate = moment(new Date(newData[type].date))
        .add(newData[type].days, 'days')
        .format('MM/DD/YYYY')
    }

    setData(newData)
  }

  const onUpdateStatus = async (type: string) => {
    const newData = cloneDeep(data)
    const { status } = newData[type]
    if (!status) {
      setIsOpenType('status')
      setSelectedType(type)
    } else {
      setLoading(true)
      await onUpdateReason('', false, type)
      setLoading(false)
    }
  }

  const historyView = async (type: string) => {
    setHistoryType(type)
  }

  const onReset = async (type: string, key: string) => {
    const item = data[type]
    const title = `${item.title} ${key === 'date' ? 'Date' : 'Expiration Date'}`
    const result = await confirm(`Are you sure to reset ${title}?`, {
      title,
    })
    if (!result) return

    setLoading(true)
    let keys = [key]
    if (key === 'date' && item.days !== null) keys.push('expirationDate')
    await resetExpirationDate(type, keys)
    setLoading(false)

    const newData: any = cloneDeep(data)
    keys.map((key) => {
      newData[type][key] = ''
    })
    setData(newData)
  }

  const renderTable = (seasonType: number = 0) => {
    return (
      <div className="overflow-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Days - Prior to Note Date
              </th>
              <th scope="col" className="px-6 py-3">
                Expiration Date
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Override Validation
              </th>
            </tr>
          </thead>
          <tbody className="text-[15px] text-gray-900">
            {sortedKeys.map((type, index) => {
              const item = data[type]
              if (item.seasonType != seasonType) return null
              if (!includeCoborrower && item.isCoBorrower) return null
              if (item.isCoBorrower && Object.keys(borrowers).length <= (Number(type[type.length - 1]) || 1))
                return null

              return [
                (type === 'driverLicense' && renderIDRestriction) ||
                  (type.includes('driverLicenseCoBorrower') &&
                    includeCoborrower &&
                    renderIDRestrictionCoBorrower(Number(type.replace('driverLicenseCoBorrower', '') || 0))),
                <tr key={type} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                  <td className="px-6 py-3">{item.title}</td>
                  <td className={`px-6 py-3 ${item.startDatePass === false && 'border-[3px] border-red-200'}`}>
                    {!item.type ? (
                      <div className="flex flex-row items-center">
                        <p>{item.date}</p>
                        <span
                          className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded ml-3"
                          onClick={() => onEditDate(type)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>
                        {item.date && (
                          <span
                            className="text-red-700 cursor-pointer hover-shadow1 p-1 rounded ml-3"
                            onClick={() => onReset(type, 'date')}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </span>
                        )}
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {!item.type && item.days !== null ? (
                      <div className="flex flex-row items-center">
                        <p className="w-[24px]">{item.days}</p>
                        <span
                          className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded ml-3"
                          onClick={() => onEditDays(type)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className={`px-6 py-3 ${item.requirePass === false && 'border-[3px] border-red-200'}`}>
                    {!item.type && item.days !== null ? (
                      item.expirationDate
                    ) : (
                      <div className="flex flex-row items-center">
                        <p>{item.expirationDate}</p>
                        <span
                          className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded ml-3"
                          onClick={() => onEditExpDate(type)}
                        >
                          <PencilSquareIcon className="w-4 h-4" />
                        </span>
                        {item.expirationDate && (
                          <span
                            className="text-red-700 cursor-pointer hover-shadow1 p-1 rounded ml-3"
                            onClick={() => onReset(type, 'expirationDate')}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </span>
                        )}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-3">{renderStatus(item)}</td>
                  <td className="px-6 py-3">
                    <span className="flex gap-4 items-center">
                      <Checkbox
                        title="Override"
                        value={item.status}
                        checked={item.status}
                        id={type}
                        onClick={() => onUpdateStatus(type)}
                        className="w-fit"
                      />
                      <span
                        className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
                        onClick={() => historyView(type)}
                      >
                        <ClockIcon className="w-4 h-4"></ClockIcon>
                      </span>
                    </span>
                  </td>
                </tr>,
              ]
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const renderStatus = (item: ExpirationDatesItem) => {
    const { status, reason, date, type, expirationDate, seasonType } = item
    if (status) {
      return (
        <div className="text-[14px] w-[120px]">
          <p className={`px-2 py-1 text-center bg-blue-100`}>Overridden</p>
          {status && <p>Reason: {reason}</p>}
        </div>
      )
    }
    if (!expirationDate || expirationDate.length === 0) return '-'
    const isBefore = isBeforeOrEqualToday(expirationDate)

    let success = false
    if (seasonType == 1 && !isBefore) success = true
    if (seasonType == 0 && isBefore) {
      if (type === 0) success = validDateMMDDYYYY(date) && new Date(date) < new Date(expirationDate)
      else success = true
    }

    if (success) {
      return <div className="text-[14px] px-2 py-1 text-center bg-green-100 w-[120px]">Passed</div>
    }
    return <div className="text-[14px] px-2 py-1 text-center bg-red-100 w-[120px]">Failed</div>
  }

  return (
    <div className={`LoanChangeRequest-container ${!child && 'px-2 py-6'}`}>
      {!child && <Overview title="Expiration Dates" />}
      <div className="max-w-screen-2xl m-auto">
        <div className={`relative bg-white ${!child && 'shadow1 rounded mb-4 p-4'}`}>
          <LayoutLoading show={loading} />
          {!child && (
            <div className="flex flex-wrap justify-between mb-2 mt-2">
              <h1 className="text-xl font-bold flex items-center ml-3">
                <span>- Expiration Dates</span>{' '}
              </h1>
            </div>
          )}
          {renderTable(0)}

          <div className="flex flex-wrap justify-between mb-2 mt-6">
            <h1 className="text-xl font-bold flex items-center ml-3">
              <span>- Seasoning Requirements</span>{' '}
            </h1>
          </div>
          {renderTable(1)}
        </div>
      </div>
      {isOpenType == 'status' && (
        <ExpirationDatesStatus item={data[selectedType]} onClose={onCloseModal} onUpdate={onUpdateReason} />
      )}
      {(isOpenType == 'date' || isOpenType == 'expirationDate') && (
        <ExpirationDatesUpdate
          type="date"
          value={data[selectedType].date}
          title={data[selectedType].title}
          subTitle={isOpenType == 'date' ? 'Date' : 'Expiration Date'}
          onClose={onCloseModal}
          onUpdate={onUpdateDate}
        />
      )}
      {isOpenType == 'day' && (
        <ExpirationDatesUpdate
          type="days"
          value={data[selectedType].days}
          title={data[selectedType].title}
          subTitle="Days"
          onClose={onCloseModal}
          onUpdate={onUpdateDays}
        />
      )}
      <Modal
        title={`History - ${data[historyType]?.title}`}
        titleOkay=""
        onClose={() => setHistoryType('')}
        onOk={() => {}}
        isOpen={historyType !== ''}
        lastUpdatedAt={Date.now()}
      >
        <div className="overflow-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  No
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Value
                </th>
                <th scope="col" className="px-6 py-3">
                  By
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {data[historyType]?.history?.map((item, index: number) => {
                return (
                  <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                    <td className="px-6 py-3">{index + 1}</td>
                    <td className="px-6 py-3">{item.type}</td>
                    <td className="px-6 py-3">{String(item.value)}</td>
                    <td className="px-6 py-3">{item.by}</td>
                    <td className="px-6 py-3">{formatTime(item.date)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  )
}
