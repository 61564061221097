import { usePermissions } from 'hooks/usePermissions'
import { useMemo, useState } from 'react'
import { ButtonGroup } from 'stories/components'

import { Bubbles } from './Bubbles'
import { EmailSetup } from './EmailSetup'
import { ExceptionRequestConfig } from './ExceptionRequest'
import { Features } from './Features'
import { FooterConfiguration } from './Footer'
import { FormDefaultsConfig } from './FormDefaults'
import { GlobalSettings } from './GlobalSettings'
import { HUD1Configuration } from './HUD1'
import { Interface } from './Interface'
import { Links } from './Links'
import { LoanFieldsProperties } from './LoanFieldsProperties'
import { LoanStatusLabel } from './LoanStatusLabel'
import { LoanStatusRestrictions } from './LoanStatusRestrictions'
import { LoanSubmission } from './LoanSubmission'
import { PaymentTermsConditionsConfig } from './PaymentTermsConditionsConfig'
import { RestructureLoanRelease } from './RestructureLoanRelease'
import { RulesConfiguration } from './RulesConfiguration'
import { TextConfiguration } from './TextsConfiguration'
import { UnderwritingSummaryConfig } from './UnderwritingSummary'
import { Workflows } from './Workflows/Workflows'

export const menus = {
  globalSettings: 'Global Settings',
  restructureLoanRelease: 'Restructure Loan Release',
  loanFieldsProperties: 'Loan Field Properties',
  loanAction: 'Loan Actions',
  loanStatusRestrictions: 'Loan Status Restrictions',
  texts: 'Texts',
  emailSetup: 'Email Setup',
  rules: 'Rules',
  links: 'Links',
  features: 'Quick Links',
  bubbles: 'Bubbles',
  exceptionRequests: 'Exceptions Requests',
  loanStatusLabel: 'Loan Status Label',
  interface: 'Interface',
  paymentTerms: 'Payment Terms and Conditions',
  hud1: 'Closing Configuration',
  footer: 'Footer',
  underwritingSummary: 'Underwriting Summary',
  formDefaults: 'Form Defaults',
}

export const Configuration = (props: { setChanged?: Function }) => {
  const { hasPermission } = usePermissions()
  const [selectedMenu, setSelectedMenu] = useState(menus.globalSettings)
  const [changed, _setChanged] = useState(false)

  const onChangeMenu = async (value: any) => {
    if (changed) {
      const isContinue = await confirm(`You've made some changes!\nAre you sure want to leave without Saving?`)
      if (!isContinue) return
    }
    setSelectedMenu(value)
    _setChanged(false)
    props.setChanged && props.setChanged(value)
  }

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.globalSettings:
        return <GlobalSettings />
      case menus.restructureLoanRelease:
        return <RestructureLoanRelease />
      case menus.loanFieldsProperties:
        return <LoanFieldsProperties setChanged={_setChanged} />
      case menus.loanAction:
        return <LoanSubmission />
      case menus.loanStatusRestrictions:
        return (
          <>
            <LoanStatusRestrictions />
            <Workflows />
          </>
        )
      case menus.texts:
        return <TextConfiguration />
      case menus.emailSetup:
        return <EmailSetup />
      case menus.rules:
        return <RulesConfiguration />
      case menus.links:
        return <Links />
      case menus.features:
        return <Features />
      case menus.bubbles:
        return <Bubbles />
      case menus.exceptionRequests:
        return <ExceptionRequestConfig />
      case menus.loanStatusLabel:
        return <LoanStatusLabel />
      case menus.interface:
        return <Interface />
      case menus.paymentTerms:
        return <PaymentTermsConditionsConfig />
      case menus.hud1:
        return <HUD1Configuration />
      case menus.footer:
        return <FooterConfiguration />
      case menus.underwritingSummary:
        return <UnderwritingSummaryConfig />
      case menus.formDefaults:
        return <FormDefaultsConfig />
      default:
        return <></>
    }
  }, [selectedMenu])

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">Configuration</h1>

      <ButtonGroup
        title={Object.values(menus).filter((v) => v != 'Quick Links' || hasPermission('CAN_EDIT_QUICK_LINKS'))}
        value={selectedMenu}
        onChange={(value) => onChangeMenu(value)}
      />

      <div className="p-2">{renderContent}</div>
    </div>
  )
}
