import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate, Num, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import type { ILoanCharge } from './types'

export const defaultInputs = (): Record<string, InputType> => {
  const loanNumber = setLoanNumber()
  return {
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      required: true,
    },
    interestFromDate: {
      inputType: 'text',
      type: 'date',
      title: 'Interest From Date',
      required: false,
    },
    description: {
      inputType: 'textarea',
      type: 'text',
      title: 'Description',
      value: '',
      span: 'full',
      required: false,
    },
    interestRate: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Interest Rate',
      prefix: '%',
      required: false,
    },
    originalBalance: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Original Balance',
      prefix: '$',
      required: true,
    },
    unpaidBalance: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Unpaid Balance',
      prefix: '$',
      required: true,
    },
    accruedInterest: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Accrued Interest',
      prefix: '$',
      required: true,
    },
    totalDue: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Total Due',
      prefix: '$',
      disabled: true,
      required: true,
    },
    deferred: {
      inputType: 'checkbox',
      title: 'Deferred',
      value: false,
      required: true,
    },
    files: {
      inputType: 'FileTable',
      title: 'File',
      required: false,
      acceptFileTypes: '.pdf',
      filePath: `loan/${loanNumber}/loanCharges`,
      span: 'full',
      showCategory: false,
      showStatus: false,
    },
  }
}

export const AddLoanCharge = ({
  index,
  item,
  onClose,
  onUpdate,
}: {
  index: number
  item: ILoanCharge | null
  onClose: Function
  onUpdate: Function
}) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    if (item) {
      const newInputs = cloneDeep(inputs)
      Object.keys(inputs).forEach((key) => {
        if (key == 'date' && (item as any)[key]) (item as any)[key] = (item as any)[key].substring(0, 10)
        newInputs[key].value = (item as any)[key]
      })
      setInputs(newInputs)
    }
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    if (['unpaidBalance', 'accruedInterest'].includes(key))
      newInputs.totalDue.value =
        Num(removeComma(newInputs.unpaidBalance.value)) + Num(removeComma(newInputs.accruedInterest.value))
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return
    ;['interestRate', 'originalBalance', 'unpaidBalance', 'accruedInterest', 'totalDue'].map((key) => {
      data[key] = removeComma(data[key])
    })
    setLoading(true)
    onUpdate(data)
  }

  return (
    <Modal
      title={item ? `Update Loan Charge - No.${index + 1}` : 'Add Loan Charge'}
      titleOkay="Submit"
      loading={loading}
      onClose={() => onClose()}
      onOk={onSubmit}
      childLevel={2}
      isOpen
    >
      <div className="text-gray-900">
        <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
