import { LoanStatusType } from 'config'

export interface ILoanActionConfigBase {
  tasks: Array<any>
  emails: Array<any>
}

export interface ILoanSubmissionActionConfig extends ILoanActionConfigBase {
  statusTo: LoanStatusType | null
  statusToRequireVerify: boolean
  statusListBeforeToUpdate: LoanStatusType[]
}

export enum ActionType {
  LoanStructure = 'loanStructure',
  LoanApplication = 'loanApplication',
  LoanSubmission = 'loanSubmission',
  LoanOrdinary = 'loanOrdinary',
  LoanStatus = 'loanStatus',
}

export const actionTypeTitles = {
  [ActionType.LoanStructure]: 'Loan Structure Actions',
  [ActionType.LoanApplication]: 'Loan Application Actions',
  [ActionType.LoanSubmission]: 'Loan Submission Actions',
  [ActionType.LoanOrdinary]: 'Loan Ordinary Actions',
  [ActionType.LoanStatus]: 'Loan Status Actions',
}

export const actionTypeTitlePrefix: Record<string, string> = {
  [ActionType.LoanStatus]: 'Loan Status',
}

const fullHeaders = ['No', 'Action', 'Loan Status To', 'Actions', 'Order']
export const actionTypeHeaders = {
  [ActionType.LoanStructure]: fullHeaders,
  [ActionType.LoanApplication]: fullHeaders,
  [ActionType.LoanSubmission]: fullHeaders,
  [ActionType.LoanOrdinary]: fullHeaders,
  [ActionType.LoanStatus]: ['No', 'Action', 'Tasks', 'Actions'],
}

export const isActionTypeHasStatusTo = (type: ActionType) => type != ActionType.LoanStatus
