import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType, SettingKey } from 'config'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services'
import { ConvertInputValue, InputConvert, InputValidate, isEmpty } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'

export const FormDefaultsConfig = () => {
  const [action, setAction] = useState('')
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    setAction('setting')
    getSetting(SettingKey.FORM_DEFAULTS)
      .then(({ value }) => {
        const content = JSON.parse(value || '{}')
        let newInputs = cloneDeep(inputs)
        Object.keys(inputs).map((key) => {
          newInputs[key].value = content[key]
        })
        setInputs(newInputs)
      })
      .finally(() => setAction(''))
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
    setChanged(true)
  }

  const onBlur = async () => {
    if (!changed) return
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = ConvertInputValue(newInputs[key], newInputs[key].value)
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setAction('setting')
    await updateSetting(SettingKey.FORM_DEFAULTS, JSON.stringify(data))
    setAction('')
    setChanged(false)
  }

  return (
    <div className="relative">
      <LayoutLoading show={!isEmpty(action)} />

      <div className="relative mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]

          return (
            <div className={`input md:col-span-${input.span || 1} `} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} onBlur={onBlur} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
