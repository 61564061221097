import { ArrowDownTrayIcon, MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage } from 'config'
import { useEffect, useState } from 'react'
import {
  addNewLoanInvoice,
  deleteLoanInvoice,
  downloadInvoices,
  getAccountingAccount,
  getLoanInvoices,
  openS3Document,
  updateLoanInvoice,
} from 'services'
import { Button, Input2, Pagination } from 'stories/components'
import { confirm, formatDate, formatTime, renderHeader, thousandSeperator } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AddInvoice } from './AddInvoice'
import type { IInvoice } from './types'

export function Invoice({ isLoan = true }: { isLoan?: any }) {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<IInvoice[]>([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [editingData, setEditingData] = useState<IInvoice | null>(null)
  const [query, setQuery] = useState('')
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [filters, setFilters] = useState({
    orderBy: 'createdAt',
    orderDir: '-1',
    isLoan: isLoan,
    pageNum: 0,
    isPaid: 'Not Paid',
    count: itemCountPerPage,
  })
  const [totalCount, setTotalCount] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [invoiceNos, setInvoiceNos] = useState([])
  const [accountingAccounts, setAccountingAccounts] = useState<Record<number, any>>({})

  useEffect(() => {
    setLoanNumber()
    getAccountingAccount().then((res) => {
      const rtl: Record<string, any> = {}
      res.map((item: any) => {
        rtl[item.id] = item.name
      })
      setAccountingAccounts(rtl)
    })
  }, [])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !loading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [query])

  useEffect(() => {
    refetch()
  }, [filters])

  const refetch = (newFilters: Record<string, any> | null = null) => {
    setLoading(true)
    getLoanInvoices({ ...(newFilters || filters), query })
      .then((data) => {
        setIsGetUsersOnce(true)
        setData(data.results)
        setTotalCount(data.totalCount)
        setTotalAmount(data.totalAmount)
        setInvoiceNos(data.invoiceNos)
      })
      .finally(() => setLoading(false))
  }

  const onChangeFilter = (key: string, value: any) => {
    const newFilters: any = cloneDeep(filters)
    newFilters[key] = value
    newFilters.pageNum = 0
    setFilters(newFilters)
  }

  const onAdd = () => {
    setEditingData(null)
    setIsOpen(true)
    setSelectedIndex(-1)
  }

  const onCloseModal = (isNeedRefetch: boolean) => {
    setIsOpen(false)
    setEditingData(null)
    if (isNeedRefetch) refetch()
  }

  const onEdit = (id: number, index: number) => {
    setEditingData(data[index])
    setIsOpen(true)
    setSelectedIndex(index)
  }

  const onSubmit = async (newData: Record<string, any>) => {
    if (selectedIndex == -1) {
      await addNewLoanInvoice(newData)
    } else {
      const item = data[selectedIndex]
      await updateLoanInvoice(item.id, newData)
    }
    setIsOpen(false)
    setSelectedIndex(-1)
    refetch()
  }

  const onDelete = async (id: number, index: number) => {
    const content = (
      <div className="mb-4 text-[18px]">
        Do you want to remove this item?
        <br />
        No. {index + 1}
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    setLoading(true)
    await deleteLoanInvoice(id)
    refetch()
  }

  const onSort = (key: string, dir: number) => {
    const newFilters = cloneDeep(filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = `${dir}`
    setFilters(newFilters)
  }

  const onPageNavigate = (num: number) => {
    const newFilters = cloneDeep(filters)
    newFilters.pageNum = num
    setFilters(newFilters)
  }

  const onDownloadCSV = () => {
    setLoading(true)

    downloadInvoices(isLoan).finally(() => setLoading(false))
  }

  const sortableHeaders = [
    { title: 'Loan Number', key: 'loanNumber' },
    { title: 'Date', key: 'date' },
    { title: 'Payee', key: 'payee' },
    { title: 'No', key: 'no' },
    { title: 'Amount', key: 'amount' },
    { title: 'Is Paid', key: 'isPaid' },
    { title: 'Created At', key: 'createdAt' },
  ]

  return (
    <div className={`invoices-container`}>
      <div className="max-w-screen-2xl m-auto">
        <div className={`relative bg-white`}>
          {!isLoan && <h2 className="text-2xl font-bold flex items-center mb-5">Invoices</h2>}
          <LayoutLoading show={loading} />
          <div className="flex gap-4 my-2 justify-between items-center">
            <div className="flex gap-2 items-center">
              <div className="w-72">
                <Input2
                  type="search"
                  title="Search"
                  hasIcon
                  icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
                  value={query}
                  onChange={(value) => setQuery(value)}
                />
              </div>
              <div className="w-48">
                <RenderInput
                  input={{
                    inputType: 'select',
                    title: 'Is Paid',
                    options: ['Paid', 'Not Paid'],
                    hasDefaultOption: true,
                    value: filters.isPaid,
                  }}
                  Key="isPaid"
                  onChange={(key: string, value: any) => onChangeFilter(key, value)}
                />
              </div>
              <p>
                Total Amount: <span className="bold">${thousandSeperator(totalAmount)}</span>
              </p>
            </div>
            <Button onClick={onAdd} className="px-[36px]">
              Add
            </Button>
          </div>

          <div className="parties-container overflow-auto mb-6 mt-6 shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  {sortableHeaders
                    .filter((item) => !isLoan || item.key !== 'loanNumber')
                    .map(({ title, key }, index) =>
                      renderHeader({
                        title,
                        sortable: true,
                        key,
                        sortOrder: filters.orderBy == key ? parseInt(filters.orderDir) : 0,
                        index,
                        onSort: (key: string, dir: number) => onSort(key, dir),
                      }),
                    )}
                  <th scope="col" className="px-6 py-3">
                    Files
                  </th>
                </tr>
              </thead>
              <tbody className="text-[14.5px] text-gray-900">
                {data.map((item, index) => {
                  return (
                    <tr key={index} className={`border-b ${index % 2 ? 'bg-gray-50' : ''}`}>
                      <td className="px-5 py-3">
                        <div className="flex gap-1">
                          <span
                            className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
                            onClick={() => onEdit(item.id, index)}
                          >
                            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                          </span>
                          <span
                            className="text-red-800 cursor-pointer hover-shadow1 p-1 rounded"
                            onClick={() => onDelete(item.id, index)}
                          >
                            <TrashIcon className="w-4 h-4"></TrashIcon>
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-3">{index + 1 + itemCountPerPage * filters.pageNum}</td>
                      {!isLoan && <td className="px-2 py-3">{item.byteproFileName || item.loanNumber}</td>}
                      <td className="px-2 py-3">{formatDate(`${item.date}`.substring(0, 10))}</td>
                      <td className="px-2 py-3">{item.payee}</td>
                      <td className="px-2 py-3">{item.no}</td>
                      <td className="px-2 py-3">{thousandSeperator(item.amount)}</td>
                      <td className="px-2 py-3">{item.isPaid ? 'Paid' : 'Not Paid'}</td>
                      <td className="px-2 py-3">{formatTime(item.createdAt)}</td>
                      <td className="px-2 py-3">
                        {item.files.map((file) => (
                          <p
                            className="flex hover:underline items-center rounded text-shade-blue cursor-pointer"
                            onClick={() => openS3Document(file.fileKey)}
                          >
                            <span className="p-1">
                              <ArrowDownTrayIcon className="w-4 h-4" />
                            </span>
                            {file.name}
                          </p>
                        ))}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {!isLoan && (
            <div className="flex justify-end items-center mt-3 mb-3">
              <Pagination
                totalCount={totalCount}
                itemCountPerPage={itemCountPerPage}
                onNavigate={onPageNavigate}
                pageNum={filters.pageNum}
              />
              <span
                className="mt-1 p-2 text-sm cursor-pointer text-shade-blue hover-shadow1 rounded"
                onClick={() => onDownloadCSV()}
              >
                <div className="flex gap-2 hover:underline">
                  <ArrowDownTrayIcon className="w-4 h-4" />
                </div>
              </span>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <AddInvoice
          onUpdate={onSubmit}
          onClose={onCloseModal}
          item={editingData}
          index={selectedIndex}
          isLoan={isLoan}
          accountingAccounts={accountingAccounts}
          invoiceNos={invoiceNos}
        />
      )}
    </div>
  )
}
