import { accountTypes } from 'components/Modals/CreateUser/config'
import { InputType } from 'config'
import { timesOption } from 'pages/LoanSubmission/NotifyBorrower'
import { EmailAssigneeOptions } from 'pages/Tasks/constants'
import { loanTypeMap } from 'reducers/loanDetail.reducer'

export const FileExtensionOptions = {
  pdf: 'PDF',
  xls: 'Xls',
  xlsx: 'Xlsx',
  xlsm: 'Xlsm',
  msg: 'Msg',
  jpeg: 'JPEG',
  jpg: 'JPG',
  png: 'PNG',
  svg: 'Svg',
  wav: 'Wav',
}

export const getRulesConfigurationFields = (loanGlobalStatus: any) => {
  const rlt: Record<string, InputType> = {
    section1: {
      inputType: 'section',
      title: 'Email Rules',
      span: 'full',
    },
    emailWithdrawnLoans: {
      inputType: 'multiselect',
      title: 'Email Receivers of Withdrawn/Declined Loans',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
    emailSurveyNotification: {
      inputType: 'multiselect',
      title: 'Email Receivers of Survey Notification',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
    emailWeeklyReport: {
      inputType: 'multiselect',
      title: 'Email Receivers of Weekly Loan Registration Report',
      options: EmailAssigneeOptions,
      defaultSelected: false,
      required: true,
    },
    section2: {
      inputType: 'section',
      title: 'Task Generation',
      span: 'full',
    },
    revalidateLoan: {
      inputType: 'multiselect',
      title: 'HUD1 Change - Loan Revalidation',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
    section7: {
      inputType: 'section',
      title: 'Loan Application',
      span: 'full',
    },
    enableWetSign: {
      inputType: 'togglebutton',
      title: 'Enable Wet Signed Loan App Logic',
    },
    enableOverrideSign: {
      inputType: 'togglebutton',
      title: 'Enable Override Loan App Logic',
    },
    section3: {
      inputType: 'section',
      title: 'Loan Submission / Borrower Auto Notification',
      span: 'full',
    },
    borrowerAutoNotifyLoanStatus: {
      inputType: 'multiselect',
      title: 'Loan Status',
      options: loanGlobalStatus,
      defaultSelected: false,
    },
    borrowerAutoNotifyLoanTypes: {
      inputType: 'multiselect',
      title: 'Loan Channel',
      options: loanTypeMap,
      defaultSelected: false,
    },
    borrowerAutoNotifyFrequency: {
      inputType: 'text',
      type: 'number',
      title: 'Days Reminder Frequency',
    },
    borrowerAutoNotifyTime: {
      inputType: 'select',
      title: 'Time',
      options: timesOption,
      allowDefaultOption: false,
      hasDefaultOption: true,
    },
    unClearWhenNewDocument: {
      inputType: 'toggle',
      title: 'Unclear condition for new document upload',
      value: false,
    },
    section4: {
      inputType: 'section',
      title: 'Condition Trigger Rules',
      span: 'full',
    },
    triggerRule: {
      inputType: 'multiselect',
      title: 'Loan Status',
      options: loanGlobalStatus,
      defaultSelected: false,
    },
    section5: {
      inputType: 'section',
      title: 'Contractor/Builder Application',
      span: 'full',
    },
    constructionTrackValidation: {
      title: 'Min Construction Track Records',
      inputType: 'text',
      type: 'number',
    },
    section6: {
      inputType: 'section',
      title: 'Closing Days',
      span: 'full',
    },
    closingDays: {
      title: 'Closing Days',
      inputType: 'text',
      type: 'number',
    },
    section8: {
      inputType: 'section',
      title: 'Document Storage',
      span: 'full',
    },
    fileExtensions: {
      title: 'Allowed Upload File Extension',
      inputType: 'multiselect',
      options: FileExtensionOptions,
    },
  }
  return rlt
}
