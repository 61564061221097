import { ArrowDownTrayIcon, MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage } from 'config'
import { useEffect, useState } from 'react'
import { addNewLoanCharge, deleteLoanCharge, getLoanCharges, openS3Document, updateLoanCharge } from 'services'
import { Button, Input2, Pagination } from 'stories/components'
import { confirm, formatDate, renderHeader, thousandSeperator } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AddLoanCharge } from './AddLoanCharge'
import type { ILoanCharge } from './types'

export function LoanCharges({ isLoan = true }: { isLoan?: any }) {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<ILoanCharge[]>([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [editingData, setEditingData] = useState<ILoanCharge | null>(null)
  const [query, setQuery] = useState('')
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)
  const [filters, setFilters] = useState({
    orderBy: 'createdAt',
    orderDir: '-1',
    isLoan: isLoan,
    pageNum: 0,
    count: itemCountPerPage,
  })
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    setLoanNumber()
  }, [])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !loading && refetch(), 700)
    return () => clearTimeout(timeOutId)
  }, [query])

  useEffect(() => {
    refetch()
  }, [filters])

  const refetch = (newFilters: Record<string, any> | null = null) => {
    setLoading(true)
    getLoanCharges({ ...(newFilters || filters), query })
      .then((data) => {
        setIsGetUsersOnce(true)
        setData(data.results)
        setTotalCount(data.totalCount)
        setTotalAmount(data.totalAmount)
      })
      .finally(() => setLoading(false))
  }

  const onAdd = () => {
    setEditingData(null)
    setIsOpen(true)
    setSelectedIndex(-1)
  }

  const onCloseModal = (isNeedRefetch: boolean) => {
    setIsOpen(false)
    setEditingData(null)
    if (isNeedRefetch) refetch()
  }

  const onEdit = (id: number, index: number) => {
    setEditingData(data[index])
    setIsOpen(true)
    setSelectedIndex(index)
  }

  const onSubmit = async (newData: Record<string, any>) => {
    setLoading(true)
    if (selectedIndex == -1) {
      await addNewLoanCharge(newData)
    } else {
      const item = data[selectedIndex]
      await updateLoanCharge(item.id, newData)
    }
    setIsOpen(false)
    setSelectedIndex(-1)
    refetch()
  }

  const onDelete = async (id: number, index: number) => {
    const content = (
      <div className="mb-4 text-[18px]">
        Do you want to remove this item?
        <br />
        No. {index + 1}
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    setLoading(true)
    await deleteLoanCharge(id)
    refetch()
  }

  const onSort = (key: string, dir: number) => {
    const newFilters = cloneDeep(filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = `${dir}`
    setFilters(newFilters)
  }

  const onPageNavigate = (num: number) => {
    const newFilters = cloneDeep(filters)
    newFilters.pageNum = num
    setFilters(newFilters)
  }

  const sortableHeaders = [
    { title: 'Loan Number', key: 'loanNumber' },
    { title: 'Date', key: 'date' },
    { title: 'Description', key: 'description' },
    { title: 'Interest Rate', key: 'interestRate' },
    { title: 'Interest From Date', key: 'interestFromDate' },
    { title: 'Deferred', key: 'deferred' },
    { title: 'Original Balance', key: 'originalBalance' },
    { title: 'Unpaid Balance', key: 'unpaidBalance' },
    { title: 'Accrued Interest', key: 'accruedInterest' },
    { title: 'Total Due', key: 'totalDue' },
  ]

  return (
    <div className={`invoices-container`}>
      <div className="max-w-screen-2xl m-auto">
        <div className={`relative bg-white`}>
          {!isLoan && <h2 className="text-2xl font-bold flex items-center mb-5">Loan Charges</h2>}
          <LayoutLoading show={loading} />
          <div className="flex justify-between items-center">
            <p>
              Total Amount: <span className="bold">${thousandSeperator(totalAmount)}</span>
            </p>
            {isLoan ? (
              <div className="flex justify-end items-center">
                <Button onClick={onAdd} className="px-[36px]">
                  Add
                </Button>
              </div>
            ) : (
              <div className="w-72">
                <Input2
                  type="search"
                  title="Search"
                  hasIcon
                  icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
                  value={query}
                  onChange={(value) => setQuery(value)}
                />
              </div>
            )}
          </div>
          <div className="parties-container overflow-auto mb-6 mt-6 shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  {sortableHeaders
                    .filter((item) => !isLoan || item.key !== 'loanNumber')
                    .map(({ title, key }, index) =>
                      renderHeader({
                        title,
                        sortable: true,
                        key,
                        sortOrder: filters.orderBy == key ? parseInt(filters.orderDir) : 0,
                        index,
                        onSort: (key: string, dir: number) => onSort(key, dir),
                      }),
                    )}
                  <th scope="col" className="px-6 py-3">
                    Files
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-[14.5px] text-gray-900">
                {data.map((item, index) => {
                  return (
                    <tr key={index} className={`border-b ${index % 2 ? 'bg-gray-50' : ''}`}>
                      <td className="px-6 py-3">{index + 1 + itemCountPerPage * filters.pageNum}</td>
                      {!isLoan && <td className="px-2 py-3">{item.byteproFileName || item.loanNumber}</td>}
                      <td className="px-2 py-3">{formatDate(item.date)}</td>
                      <td className="px-2 py-3">{item.description}</td>
                      <td className="px-2 py-3">{thousandSeperator(item.interestRate)}</td>
                      <td className="px-2 py-3">{formatDate(item.interestFromDate)}</td>
                      <td className="px-2 py-3">{item.deferred ? 'Yes' : 'No'}</td>
                      <td className="px-2 py-3">{thousandSeperator(item.originalBalance)}</td>
                      <td className="px-2 py-3">{thousandSeperator(item.unpaidBalance)}</td>
                      <td className="px-2 py-3">{thousandSeperator(item.accruedInterest)}</td>
                      <td className="px-2 py-3">{thousandSeperator(item.totalDue)}</td>
                      <td className="px-2 py-3">
                        {item.files.map((file) => (
                          <p
                            className="flex hover:underline items-center rounded text-shade-blue cursor-pointer"
                            onClick={() => openS3Document(file.fileKey)}
                          >
                            <span className="p-1">
                              <ArrowDownTrayIcon className="w-4 h-4" />
                            </span>
                            {file.name}
                          </p>
                        ))}
                      </td>
                      <td className="px-5 py-3">
                        <div className="flex gap-1">
                          <span
                            className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
                            onClick={() => onEdit(item.id, index)}
                          >
                            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                          </span>
                          <span
                            className="text-red-800 cursor-pointer hover-shadow1 p-1 rounded"
                            onClick={() => onDelete(item.id, index)}
                          >
                            <TrashIcon className="w-4 h-4"></TrashIcon>
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {!isLoan && (
              <div className="flex justify-end items-center mt-3 mb-3">
                <Pagination
                  totalCount={totalCount}
                  itemCountPerPage={itemCountPerPage}
                  onNavigate={onPageNavigate}
                  pageNum={filters.pageNum}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpen && <AddLoanCharge onUpdate={onSubmit} onClose={onCloseModal} item={editingData} index={selectedIndex} />}
    </div>
  )
}
