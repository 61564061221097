import { GlobalConfigType } from 'actions'
import { store } from 'reducers'

export const hasPagePermissions = (pageIds: string[] = [], pageParams: Record<string, any> = {}) => {
  const { globalConfigReducer } = store.getState()
  const { data } = globalConfigReducer[GlobalConfigType.PagePermissions]

  if (!pageIds || !pageIds.length || !data) return true

  let hasPermission = true
  let pageKey = ''

  pageIds.forEach((pageId) => {
    if (!hasPermission) return
    if (pageId.startsWith(':')) pageId = pageParams[pageId.substring(1, pageParams.length)]

    pageKey += `-${pageId}`
    const value = data[pageKey]
    if (value === false) hasPermission = false
  })

  return hasPermission
}

export const removeNonPagePermissions = (menus: Record<string, string>, pageIds: string[]) => {
  Object.keys(menus).forEach((menu) => {
    if (!hasPagePermissions([...pageIds, ':menu'], { menu })) delete menus[menu]
  })
  return menus
}
