import { EmailTo } from 'components/EmailTo'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal } from 'stories/components'

import { confirm } from '../../../../utils'

export const SendDnsInfoModal = ({ onClose, _onSubmit }: { onClose: Function; _onSubmit: Function }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ party: string; email: string }[]>([])
  const [selectedEmails, setSelectedEmails] = useState<Record<string, 'to' | 'cc' | 'bcc' | ''>>({})

  useEffect(() => {
    setData([])
    setSelectedEmails({})
  }, [])

  const onSubmit = async () => {
    const hasToEmail = !!Object.keys(selectedEmails).filter((email) => selectedEmails[email] == 'to').length
    if (!hasToEmail) {
      toast('Please choose one more TO email.', { type: 'error' })
      return
    }

    const emails: string[] = Object.keys(selectedEmails)

    const content = (
      <div>
        <div className="font-semibold mb-4">Are you sure you want to email the following?</div>
        <div className="text-left mb-5">
          <div className="border-b">- Emails</div>
          <div className="mt-3 flex flex-wrap gap-2 ml-3 italic text-gray-600 text-[14px]">{emails.join(', ')}</div>
        </div>
      </div>
    )
    const isConfirmed = await confirm(content)
    if (!isConfirmed) return

    setLoading(true)
    await _onSubmit(selectedEmails)
    setLoading(false)
  }

  return (
    <Modal
      title={`Send Emails to ...`}
      titleOkay="Send Email"
      isOpen
      loading={loading}
      disabled={!Object.keys(selectedEmails).length}
      lastUpdatedAt={Date.now()}
      onClose={() => onClose()}
      onOk={() => onSubmit()}
    >
      <div className="w-144">
        <EmailTo Emails={selectedEmails} Data={data} onChange={(data: any) => setSelectedEmails(data)} />
      </div>
    </Modal>
  )
}
