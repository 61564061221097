import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { IAccountingVendor } from './types'

export const defaultInputs = (categories: Record<number, string>): Record<string, InputType> => {
  return {
    name: {
      inputType: 'text',
      title: 'Name',
      required: true,
      span: 3,
    },
    address: {
      inputType: 'Map',
      title: 'Address',
      required: true,
      span: 3,
    },
    ein: {
      inputType: 'text',
      type: 'entityTaxID',
      title: 'Tax ID / EIN',
      required: true,
      span: 3,
    },
    type: {
      inputType: 'select',
      title: 'Vendor Type',
      options: categories,
      hasDefaultOption: true,
      sort: true,
      sortKey: 'value',
      required: true,
      span: 3,
    },
    section1: {
      inputType: 'section',
      title: 'Payment Information',
      span: 'full',
    },
    isWire: {
      inputType: 'checkbox',
      title: 'Wire',
      span: 2,
    },
    isACH: {
      inputType: 'checkbox',
      title: 'ACH',
      span: 2,
    },
    isCheck: {
      inputType: 'checkbox',
      title: 'Check',
      span: 2,
    },
    wireGroup: {
      inputType: 'group',
      title: 'Wire Information',
      span: 'full',
    },
    wireBankAddress: {
      inputType: 'map',
      title: 'Bank Address',
      span: 3,
    },
    wireAccountHolder: {
      inputType: 'text',
      title: 'Account Holder Name',
      span: 3,
    },
    wireRoutingNo: {
      inputType: 'text',
      type: 'number',
      title: 'Routing Number',
      span: 3,
      decimalStep: 0,
    },
    wireAccountNo: {
      inputType: 'text',
      type: 'number',
      title: 'Account Number',
      span: 3,
      decimalStep: 0,
    },
    achGroup: {
      inputType: 'group',
      title: 'ACH Information',
      span: 'full',
    },
    achBankAddress: {
      inputType: 'map',
      title: 'Bank Address',
      span: 3,
    },
    achAccountHolder: {
      inputType: 'text',
      title: 'Account Holder Name',
      span: 3,
    },
    achRoutingNo: {
      inputType: 'text',
      type: 'number',
      title: 'Routing Number',
      span: 3,
      decimalStep: 0,
    },
    achAccountNo: {
      inputType: 'text',
      type: 'number',
      title: 'Account Number',
      span: 3,
      decimalStep: 0,
    },
    note: {
      inputType: 'textarea',
      type: 'text',
      title: 'Note',
      value: '',
      span: 'full',
      required: false,
    },
    files: {
      inputType: 'FileTable',
      title: 'File',
      required: false,
      acceptFileTypes: '.pdf',
      filePath: `loan/accounting/vendors`,
      span: 'full',
      showCategory: false,
      showStatus: false,
    },
  }
}

export const AddVendor = ({
  index,
  item,
  categories,
  onClose,
  onUpdate,
}: {
  index: number
  item: IAccountingVendor | null
  categories: Record<number, string>
  onClose: Function
  onUpdate: Function
}) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs(categories))

  useEffect(() => {
    if (item) {
      const newInputs = cloneDeep(inputs)
      Object.keys(inputs).forEach((key) => {
        if (key == 'date' && (item as any)[key]) (item as any)[key] = (item as any)[key].substring(0, 10)
        newInputs[key].value = (item as any)[key]
      })
      setInputs(newInputs)
    }
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    onUpdate(data)
  }

  return (
    <Modal
      title={item ? `Update Vendor - No.${index + 1}` : 'Add Vendor'}
      titleOkay="Submit"
      loading={loading}
      onClose={() => onClose()}
      onOk={onSubmit}
      childLevel={2}
      isOpen
    >
      <div className="text-gray-900">
        <div className="w-144 grid gap-4 md:grid-cols-6 grid-cols-1 mb-3 mt-2">
          {Object.keys(inputs).map((key, index) => {
            if ((key.includes('wire') && !inputs.isWire.value) || (key.includes('ach') && !inputs.isACH.value)) return
            let input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
