import { ArrowLeftCircleIcon, BellAlertIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { EmailTo } from 'components/EmailTo'
import { LayoutLoading } from 'components/LayoutLoading'
import type { InputType } from 'config'
import { IBrokerCondition } from 'pages/Admin'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { partiesMap } from 'reducers/loanDetail.reducer'
import { getLoanSubmissionNotify, sendLoanSubmissionNotify, setLoanSubmissionNotify } from 'services'
import { Button, PlainTable } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

import type { LoanSubmissionCondition, LoanSubmissionDocument } from './types'

export const timesOption = {
  '0': '12:00 am',
  '1': '01:00 am',
  '2': '02:00 am',
  '3': '03:00 am',
  '4': '04:00 am',
  '5': '05:00 am',
  '6': '06:00 am',
  '7': '07:00 am',
  '8': '08:00 am',
  '9': '09:00 am',
  '10': '10:00 am',
  '11': '11:00 am',
  '12': '12:00 pm',
  '13': '01:00 pm',
  '14': '02:00 pm',
  '15': '03:00 pm',
  '16': '04:00 pm',
  '17': '05:00 pm',
  '18': '06:00 pm',
  '19': '07:00 pm',
  '20': '08:00 pm',
  '21': '09:00 pm',
  '22': '10:00 pm',
  '23': '11:00 pm',
}

const defaultInputs = (): Record<string, InputType> => {
  return {
    note: {
      inputType: 'textarea',
      title: 'Note',
      rows: 3,
    },
  }
}

let isUpdated = false

export const NotifyBorrowerSlide = ({
  conditions,
  brokerProfile,
  brokerConditions,
  templateNumber,
  documents,
}: {
  conditions: LoanSubmissionCondition[]
  brokerProfile: boolean
  brokerConditions: IBrokerCondition[]
  templateNumber: number
  documents: LoanSubmissionDocument[]
}) => {
  const [isShowSlide, setShowSlide] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(true)
  const [action, setAction] = useState('')
  const [selectedEmails, setSelectedEmails] = useState<Record<string, 'to' | 'cc' | 'bcc' | ''>>({})
  const [emails, setEmails] = useState<{ party: string; email: string }[]>([])

  const { loanDetail, borrower } = useSelector((state: any) => ({
    loanDetail: state.loanDetail,
    borrower: state.borrower.borrower,
  }))
  const { parties } = loanDetail

  const outstandingConditions = useMemo(() => {
    let rlt: any = []
    if (templateNumber === 0) {
      brokerConditions
        .filter(({ required, requested }) => !!required && !!requested)
        .map((cond) => {
          const document = documents.find((item) => item.conditionNo === cond.no)
          if (!document) rlt.push([cond.no, cond.name])
        })
    } else {
      conditions.map((cond) => {
        if (cond.requested && !cond.cleared) {
          rlt.push([cond.no, cond.name])
        }
      })
    }
    return rlt
  }, [conditions])

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    const emails: { party: string; email: string }[] = [{ party: 'Borrower', email: borrower.email }]
    Object.keys(parties).map((key) => {
      let { email } = parties[key]
      email = key === 'creator' ? email : loanDetail.parties[key].email
      if (!email) return

      emails.push({
        party: partiesMap[key],
        email,
      })
    })
    setEmails(emails)
  }, [parties, borrower])

  const refetch = () => {
    setLoading(true)
    getLoanSubmissionNotify()
      .then((data: { notifyNote: string; notifyEmail: any }) => {
        if (data.notifyEmail) {
          const newInputs = cloneDeep(inputs)
          newInputs.note.value = data.notifyNote
          Object.keys(newInputs).map((key) => {
            newInputs[key].disabled = brokerProfile
          })
          setInputs(newInputs)
          setSelectedEmails(data.notifyEmail)
        }
      })
      .finally(() => setLoading(false))
  }

  const onShowSlide = () => {
    setShowSlide(!isShowSlide)
    if (!isShowSlide) refetch()
  }

  const onChange = (key: string, v: any) => {
    const newInputs = cloneDeep(inputs)
    if (key === 'period') {
      if (v < 0) v = 0
    }
    newInputs[key].value = v
    setInputs(newInputs)
    isUpdated = true
  }

  const onSave = () => {
    if (!isUpdated) return
    const data = {
      notifyNote: inputs.note.value,
    }
    setLoading(true)
    setLoanSubmissionNotify(data).finally(() => setLoading(false))
    isUpdated = false
  }

  const onSubmit = async () => {
    setAction('notify')
    sendLoanSubmissionNotify()
      .then(() => toast('Notification is sent to Borrower.', { type: 'info' }))
      .finally(() => setAction(''))
  }

  const updateEmails = async (data: any) => {
    await setLoanSubmissionNotify({
      notifyEmail: data,
    })
    return true
  }

  const hasToEmail = useMemo(() => {
    let rlt = false
    try {
      Object.keys(selectedEmails).map((key: string) => {
        if (selectedEmails[key] === 'to') rlt = true
      })
    } catch {}
    return rlt
  }, [selectedEmails])

  return (
    <>
      <div className="fixed left-0 top-[calc(20%+60px)] z-20">
        <button
          className="p-3 rounded-l-none ring-[1px] ring-shade-blue bg-shade-blue text-white border hover:bg-white border-shade-blue hover:text-shade-blue rounded flex items-center gap-2 border-l-0"
          onClick={onShowSlide}
        >
          <BellAlertIcon className="w-5 h-5" />
          Notification
        </button>
      </div>
      {isShowSlide && (
        <div
          className={`z-50 shadow1 fixed bg-white border rounded-l left-0 top-[5vh] bottom-0 transition-[left] w-[24rem] h-[90vh] overflow-auto ease-in-out ${
            isShowSlide ? 'left-0' : '-left-[24rem]'
          }`}
        >
          <LayoutLoading show={isLoading} />
          <div className="w-[24rem] z-20 rounded-t flex justify-between p-3 font-bold text-sm place-items-center items-center text-shade-blue border-b bg-white">
            <div className="flex items-center">
              <p className="text-lg text-gray-800">Notify Borrower</p>
            </div>
            <button className="hover:underline hover-shadow1 p-1 rounded" onClick={onShowSlide}>
              <ArrowLeftCircleIcon className="w-5 h-5" />
            </button>
          </div>
          <div className="h-fit overflow-auto relative text-sm p-3 pb-0"></div>
          <div className="px-3">
            <EmailTo
              Type={'show'}
              CanEdit={!brokerProfile}
              Emails={selectedEmails}
              Data={emails}
              onChange={(data: any) => setSelectedEmails(data)}
              onUpdate={updateEmails}
            />
          </div>
          <div className="flex justify-center px-4 mt-4">
            <Button
              className="w-full"
              loading={action == 'notify'}
              disabled={!outstandingConditions.length || !hasToEmail}
              onClick={onSubmit}
            >
              <span>Notify Now</span>
            </Button>
          </div>

          <div className="p-4">
            <RenderInput Key="note" input={inputs.note} onChange={onChange} onBlur={onSave} />
          </div>

          <div className="p-4">
            <p className="font-semibold mb-2 border-b italic text-[15px]">
              Requested Outstanding Conditions
              <span className="ml-1">({outstandingConditions.length})</span>
            </p>
            <div>
              <PlainTable
                header={['Condition #', 'Description']}
                data={outstandingConditions}
                tdClass="px-4 py-2 break-spaces text-[13px]"
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
