import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { BaseFile, IS_MAIN_COMPANY } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { uploadFiles } from 'services'
import { createTicket } from 'services/apis/ticket'
import { Button, InputFile } from 'stories/components'
import { InputConvert, InputValidate, toFileArray } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'

export const CreateTicket = () => {
  const navigate = useHistory()
  const auth = useSelector((state: any) => state.auth)
  const [inputs, setInputs] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(false)
  const [baseFiles, setBaseFiles] = useState<BaseFile[]>([])
  const [files, setFiles] = useState<any>()

  useEffect(() => {
    if (IS_MAIN_COMPANY || !auth.isAuthenticated) navigate.push('/')
  }, [])

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)

    const input = newInputs[key]
    input.value = InputConvert(input, value)
    input.error = ''

    // if (key === 'priority') {
    //   const daysToAdd = value === TicketPriority.High ? 1 : value === TicketPriority.Normal ? 3 : 7

    //   newInputs['dueDate'].value = new Date(new Date().setDate(new Date().getDate() + daysToAdd))
    // }

    setInputs(newInputs)
  }

  const onSubmit = () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      const { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value || ''
    })
    setInputs(newInputs)
    if (hasError) return false

    values['userName'] = auth.profile.name
    values['userId'] = auth.profile.id
    values['userEmail'] = auth.profile.email
    values['userPhone'] = auth.profile.phone ?? ''

    if (baseFiles.length > 0) values['files'] = baseFiles
    setLoading(true)
    createTicket(values)
      .then(() => {
        setLoading(false)
        navigate.goBack()
      })
      .catch(() => setLoading(false))
  }

  const goBackToTickets = () => {
    navigate.goBack()
  }

  const onFileUpload = (value: any) => {
    setFiles(value)
    if (value !== null) {
      const data = {
        path: 'nexys/ticket',
      }

      let acceptedFiles: any = []
      toFileArray(value).map((file) => {
        const { name } = file
        const params = name.toLowerCase().split('.')
        const length = params.length
        const ext = params[length - 1]
        console.log('ext', ext)

        acceptedFiles.push(file)
      })
      setLoading(true)
      uploadFiles(data, acceptedFiles).then(async (keys: Array<string>) => {
        const newDocs: BaseFile[] = []
        const id = Date.now()
        keys.forEach((key, index) => {
          newDocs.push({
            id: id + index,
            name: acceptedFiles[index].name,
            fileKey: key,
            createdAt: Date.now(),
          })
        })
        setBaseFiles(newDocs)

        setLoading(false)
      })
    }
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="gap-2 mb-3 flex items-center justify-between">
          <h1 className="text-2xl font-variation-settings-600 flex items-center gap-2" onClick={goBackToTickets}>
            <ArrowLeftIcon className="w-4 h-4" />
            <span>Submit a Ticket</span>
          </h1>
        </div>
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.keys(inputs).map((key, index) => {
              const input = inputs[key]
              return (
                <div className={input.span ? `col-span-${input.span}` : ''} key={index}>
                  <RenderInput input={input} Key={key} onChange={onChange} />
                </div>
              )
            })}
          </div>
        </div>
        <p className="text-sm mt-2">*Provide detailed explanations with as many screenshots as possible for clarity.</p>
        <div className="mt-2">
          <InputFile
            multiple={true}
            onChange={onFileUpload}
            title="Files"
            acceptFileTypes="application/*,image/*"
            value={files}
          />
        </div>
        <div className="flex justify-end mt-6">
          <Button onClick={onSubmit}>Submit a Ticket</Button>
        </div>
      </div>
    </div>
  )
}
