export interface IClosingCostSetup {
  id: number
  lineId: number
  hudNo: number
  lineName: string
  lineNameGFE: string
  tridBlock: number
  gfeBlock: number
  type: number
  paidToType: number
  responsiblePartyType: number
  poc: boolean
  ppfc: boolean
  optional: boolean
  pointsAndFeesDirective: number
  nonRefundable: boolean
  netFromWire: boolean
  glCode: string
  status: number
  notes: string
  hudNoLocked: boolean
  lineNameLocked: boolean
  tridBlockLocked: boolean
  gfeBlockLocked: boolean
  typeLocked: boolean
  paidToTypeLocked: boolean
  responsiblePartyTypeLocked: boolean
  ppfcLocked: boolean
  optionalLocked: boolean
  lineNameApplyType: number
  tridBlockApplyType: number
  addTermSheet: boolean
  channels: Array<string>
  placeholderType: ClosingCostPlaceholderType
  createdAt: Date
  updatedAt: Date
}

export enum ClosingCostPlaceholderType {
  Amount = 0,
  Percentage = 1,
  Both = 2,
}

export const ClosingCostPlaceholderMap: Record<ClosingCostPlaceholderType, string> = {
  [ClosingCostPlaceholderType.Amount]: 'Dollar Amount',
  [ClosingCostPlaceholderType.Percentage]: 'Percentage',
  [ClosingCostPlaceholderType.Both]: 'Both',
}
