import {
  DocumentDuplicateIcon,
  EyeIcon,
  EyeSlashIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { useEffect, useState } from 'react'
import { deleteTemplate, getConditions, updateTemplateShow } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button, Input2 } from 'stories/components'
import { confirm } from 'utils'

import type { ICondition, IConditionTemplate } from '../Conditions'
import { TemplateDetails } from './details'

export function UnderwriterTemplates() {
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState(0)
  const [conditions, setConditions] = useState<Array<ICondition>>([])
  const [templates, setTemplates] = useState<Array<IConditionTemplate>>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDuplicated, setIsDuplicated] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [selectedIndex, setSelectedIndex] = useState(-1)

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    setIsLoading(true)
    getConditions()
      .then(({ data, templates }) => {
        setTotal(templates.length)
        setConditions(data)
        setTemplates(templates)
      })
      .finally(() => setIsLoading(false))
  }

  if (isEditing)
    return (
      <TemplateDetails
        lastUpdatedAt={lastUpdatedAt}
        conditions={conditions}
        templates={templates}
        isDuplicated={isDuplicated}
        defaults={selectedIndex == -1 ? null : templates[selectedIndex]}
        onBack={() => setIsEditing(false)}
        onComplete={refresh}
      />
    )

  const onAdd = () => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(-1)
    setIsEditing(true)
    setIsDuplicated(false)
  }

  const onEdit = (index: number) => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(index)
    setIsEditing(true)
    setIsDuplicated(false)
  }

  const onTrash = async (index: number) => {
    const { id, no } = templates[index]
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this template?
        <br />
        <span className="text-gray-600">Template No: {no}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setIsLoading(true)
    deleteTemplate(id)
      .then(refresh)
      .catch(() => setIsLoading(false))
  }

  const onDuplicate = (index: number) => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(index)
    setIsEditing(true)
    setIsDuplicated(true)
  }

  const onUpdateShow = async (index: number, value: boolean) => {
    const { id } = templates[index]
    setIsLoading(true)
    await updateTemplateShow(id, value)
    const temp = cloneDeep(templates)
    temp[index].show = value
    setTemplates(temp)
    setIsLoading(false)
  }

  return (
    <div className="Templates-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        UW Condition Templates
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap">
          <div className="md:w-96 w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={query}
              className="w-96"
              onChange={(value) => setQuery(value)}
            />
          </div>
          <p className="ml-5">- {total} templates</p>
        </div>
        <div className="w-32">
          <Button full onClick={onAdd}>
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="px-3 py-3">
                Name
              </th>
              <th scope="col" className="px-3">
                Conditions
              </th>
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {templates.map((template, index) => {
              if (query && !template.name.toLowerCase().includes(query.trim().toLowerCase())) return null
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td scope="row" className="px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {template.no}
                  </td>
                  <td className={`px-3 ${template.show ? '' : 'line-through'}`}>{template.name}</td>
                  <td className="px-3">{template.conditions.length}</td>
                  <td className="px-3">
                    <span className="flex gap-1">
                      <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(index)}>
                        <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                      </span>

                      <span
                        className="text-blue-800 p-1 hover-shadow1 cursor-pointer"
                        onClick={() => onDuplicate(index)}
                      >
                        <DocumentDuplicateIcon className="w-4 h-4"></DocumentDuplicateIcon>
                      </span>
                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onTrash(index)}>
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                      <span
                        className="p-1 hover-shadow1 cursor-pointer"
                        onClick={() => onUpdateShow(index, !template.show)}
                      >
                        {template.show ? <EyeIcon className="w-4 h-4" /> : <EyeSlashIcon className="w-4 h-4" />}
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
