import { toast } from 'react-toastify'

export function copyClipboard(value: string) {
  // text area method
  let textArea = document.createElement('textarea')
  textArea.value = value
  // make the textarea out of viewport
  textArea.style.position = 'fixed'
  textArea.style.left = '-999999px'
  textArea.style.top = '-999999px'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  textArea.setSelectionRange(0, 99999)

  if (navigator.clipboard) {
    navigator.clipboard.writeText(value)
  } else {
    document.execCommand('copy')
  }
  textArea.remove()
  toast('Copied to your Clipboard!', { type: 'success' })
}

export const downloadCSV = (data: Array<Array<any>>, fileName: string) => {
  const csvContent = data.map((row) => row.join(',')).join('\n')
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
