import { TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import React from 'react'
// eslint-disable-next-line no-duplicate-imports
import { useEffect, useState } from 'react'
import {
  createAccountingVendorCategory,
  deleteAccountingVendorCategory,
  getAccountingVendorCategories,
  updateAccountingVendorCategory,
} from 'services'
import { svgLoading } from 'stories/assets'
import { confirm, prompt } from 'utils'

export const AccountningVendorCategories = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<Array<Record<string, any>>>([])

  useEffect(() => {
    initData()
  }, [])

  const initData = async () => {
    setLoading(true)
    const res = await getAccountingVendorCategories()
    setData(res)
    setLoading(false)
  }

  const onAdd = async () => {
    const value = await prompt('Add New Vendor Category')
    if (!value) return

    setLoading(true)
    const category = await createAccountingVendorCategory({ value })
    const temp = cloneDeep(data)
    temp.push(category)
    setData(temp)
    setLoading(false)
  }

  const onUpdate = async (id: number, value: string) => {
    if (!value) return
    setLoading(true)
    await updateAccountingVendorCategory(id, value)
    const temp = cloneDeep(data)
    const existingIdx = temp.findIndex((item) => item.id === id)
    if (existingIdx !== -1) {
      temp[existingIdx].value = value
      setData(temp)
    }
    setLoading(false)
  }

  const onDelete = async (id: number) => {
    const isContinue = await confirm('Are you sure you want to delete this category?')
    if (!isContinue) return
    setLoading(true)
    await deleteAccountingVendorCategory(id)
    const temp = cloneDeep(data)
    const existingIdx = temp.findIndex((item) => item.id === id)
    if (existingIdx !== -1) {
      temp.splice(existingIdx, 1)
      setData(temp)
    }
    setLoading(false)
  }

  const renderRow = (item: any, index: number) => {
    return (
      <tr
        className={`${item.invalid ? 'bg-red-100' : 'bg-white'} border-b dark:bg-gray-800 dark:border-gray-700`}
        key={index}
      >
        <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
          {index + 1}
        </td>

        <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
          <PlainInput
            value={item.value || ''}
            content={item.value || ''}
            className={`h-[30px]`}
            onChange={(newValue: string) => onUpdate(item.id, newValue)}
          />
        </td>

        <td className="px-6 py-1">
          <div className="flex gap-2">
            <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item.id)}>
              <TrashIcon className="w-4 h-4"></TrashIcon>
            </span>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">
        Vendor Categories
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>

      <div className="relative overflow-auto">
        <LayoutLoading show={isLoading} />
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3 w-[20px]">
                No
              </th>
              <th scope="col" className="px-3 py-3">
                Name
              </th>
              <th scope="col" className="px-3 py-3">
                <button
                  className={`font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline`}
                  onClick={() => onAdd()}
                >
                  + Add
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <React.Fragment key={index}>{renderRow(item, index)}</React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
