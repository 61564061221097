import { convertSelectRangeOptionsToMap } from 'pages/Admin/AdminTools/Configuration/LoanFieldsProperties/utils'
import type { IRange } from 'pages/Resources'
import { isEmpty } from 'utils'

import type { InputType } from './input.type.constants'
import { IVisibleProp } from './loan.input.visibility.type'

export const finresiMapConstants: Record<string, any> = {
  bankruptcy: {
    None: 'None',
    BKActive: 'BK Active',
    BK7ZeroPlus: 'BK7 Discharged 1-11 Months',
    BK7OnePlus: 'BK7 Discharged 12-23 Months',
    BK7TwoPlus: 'BK7 Discharged 24-35 Months',
    BK7ThreePlus: 'BK7 Discharged 36-47 Months',
    BK7FourPlus: 'BK7 Discharged 48+ Months',
    BK13ZeroPlus: 'BK13 Discharged 1-11 Months',
    BK13OnePlus: 'BK13 Discharged 12-23 Months',
    BK13TwoPlus: 'BK13 Discharged 24-35 Months',
    BK13ThreePlus: 'BK13 Discharged 36-47 Months',
    BK13FourPlus: 'BK13 Discharged 48+ Months',
  },
  citizen: {
    UsCitizen: 'US Citizen',
    PermanentResidentAlien: 'Permanent Resident',
    NonPermanentResidentAlien: 'Non Permanent Resident',
    ForeignNational: 'Foreign National',
    ITIN: 'ITIN',
  },
  mortgageLates: {
    NoOccurrences: '0X30X12',
    OneOccurrences30: '1X30X12',
    OneMoreOccurrences30: '0x60x12',
    OneOrMoreOccurrences60: '>= 1X60X12',
  },
}

export const EscrowTypeLabels: Record<string, string> = {
  notWaived: 'Not Waived',
  waiveBoth: 'Waive Both',
  waiveInsurance: 'Waive Insurance',
}

export const DscrRangeOptions: Record<number, string> = {
  1: '0 - 0.74',
  2: '0.75 - 0.99',
  3: '1 - 1.09',
  4: '1.1 - 1.14',
  5: '1.15 - 1.25',
  6: '1.26 - 1.49',
  7: '1.5 - 10',
}

export const DscrRangeMapping: Record<number, IRange> = {
  1: { from: 0, to: 0.74 },
  2: { from: 0.75, to: 0.99 },
  3: { from: 1, to: 1.09 },
  4: { from: 1.1, to: 1.14 },
  5: { from: 1.15, to: 1.25 },
  6: { from: 1.26, to: 1.49 },
  7: { from: 1.5, to: 10 },
}

export const MINVALUE: number = 999999999

export const enum ProductTypes {
  Bridge = 'Bridge',
  FixAndFlip = 'Fix and Flip',
  GroundUpConstruction = 'Ground Up Construction',
  DSCR = 'DSCR (Long Term Rental)',
}

export const productTypeOptions = ['Bridge', 'Fix and Flip', 'Ground Up Construction', 'DSCR (Long Term Rental)']

export const productTypeOptionsMap: any = {
  Bridge: 'Bridge',
  'Fix and Flip': 'Fix & Flip / Fix to Rent',
  'Ground Up Construction': 'Ground Up Construction',
  'DSCR (Long Term Rental)': 'DSCR (Rental Loan)',
}

export const lockTypeOptions = ['Locked', 'Float', 'Expired']

export const channelTypeOptions = ['Wholesale', 'Correspondent', 'Retail', 'Table Funder', 'Brokered']

export const transactionTypeOptions = ['Purchase', 'Refinance']

export const howHearAboutUsOpions = ['Rehab Wallet', 'Networking Event', 'Referral', 'Social Media/Website', 'Other']

export const preferredCommunicationOptions = ['Phone', 'Email', 'Text', 'Web Portal']

export const loanCustomRangeOptionFields: string[] = [
  'foreclosure',
  'deedInLieu',
  'shortSale',
  'experience',
  'forbearanceLoanModification',
  'bankruptcy',
  'mortgageLates',
]

export const loanCustomSelectOptionFields: string[] = ['loanTerm']

export const loanOptionsConstants = {
  entityType: [
    'Limited Liability Company',
    'Limited Liability Partnership',
    'Corporation',
    'Partnership',
    'Individual',
  ],
  productType: productTypeOptionsMap,
  transactionType: transactionTypeOptions,
  loanTerm: {
    6: '6 Months',
    12: '12 Months',
    18: '18 Months',
    24: '24 Months',
  },
  escrowType: ['Not Waived', 'Waive Both', 'Waive Insurance'],
  borrowerType: ['LLC', 'LLP', 'Corp', 'Partnership', 'Revocable Trust', 'Individual'],
  occupancy: ['Owner Occupied', 'Non Owner Occupied', 'Partially Owner Occupied', 'Vacant', 'Partially Vacant'],
  prepaymentLong: {
    0: '0 years',
    12: '1 years',
    24: '2 years',
    36: '3 years',
    48: '4 years',
    60: '5 years',
  },
  propertyTypeBigList: [
    'SFR-Detached',
    'SFR-Attached',
    'Condo',
    '2-4 Units',
    'PUD',
    'Mixed-Use',
    'Modular',
    // 'MF (5-8 units)',
    // 'MF (9-30 units)',
    // 'MF (31-100 units)',
    'Multifamily 5+',
    'Industrial',
    'Manufactured',
    'Self Storage',
    'Office',
    'Retail',
    'Warehouse',
    'Automotive',
  ],
  bankruptcy: {
    'null-null-null': 'None',
    '0-null-null': 'BK Active',
    '7-1-11': 'BK7 Discharged 1-11 Months',
    '7-12-23': 'BK7 Discharged 12-23 Months',
    '7-24-35': 'BK7 Discharged 24-35 Months',
    '7-36-47': 'BK7 Discharged 36-47 Months',
    '7-48-null': 'BK7 Discharged 48+ Months',
    '13-1-11': 'BK13 Discharged 1-11 Months',
    '13-12-23': 'BK13 Discharged 12-23 Months',
    '13-24-35': 'BK13 Discharged 24-35 Months',
    '13-36-47': 'BK13 Discharged 36-47 Months',
    '13-48-null': 'BK13 Discharged 48+ Months',
  },
  // loannex1: {
  //   None: 'None',
  //   BKActive: 'BK Active',
  //   BK7ZeroPlus: 'BK7 Discharged 1-11 Months',
  //   BK7OnePlus: 'BK7 Discharged 12-23 Months',
  //   BK7TwoPlus: 'BK7 Discharged 24-35 Months',
  //   BK7ThreePlus: 'BK7 Discharged 36-47 Months',
  //   BK7FourPlus: 'BK7 Discharged 48+ Months',
  //   BK13ZeroPlus: 'BK13 Discharged 1-11 Months',
  //   BK13OnePlus: 'BK13 Discharged 12-23 Months',
  //   BK13TwoPlus: 'BK13 Discharged 24-35 Months',
  //   BK13ThreePlus: 'BK13 Discharged 36-47 Months',
  //   BK13FourPlus: 'BK13 Discharged 48+ Months',
  // },
  loannex2: {
    'null-null': 'None',
    '1-11': '1-11 Months',
    '12-23': '12-23 Months',
    '24-35': '24-35 Months',
    '36-47': '36-47 Months',
    '48-null': '48+ Months',
  },
  // loannex2: {
  //   None: 'None',
  //   '0+ years': '1-11 Months',
  //   '1+ years': '12-23 Months',
  //   '2+ years': '24-35 Months',
  //   '3+ years': '36-47 Months',
  //   '4+ years': '48+ Months',
  // },
  mortgageLates: {
    '0-0-30-12': '0X30X12',
    '1-1-30-12': '1X30X12',
    '0-0-60-12': '0X60X12',
    '1-null-60-12': '>= 1X60X12',
  },
  // mortgageLates: ['0X30X12', '1X30X12', '0x60x12', '>= 1X60X12'],
  fullMortgageLates: {
    '1X30X12': '30 days late in the last 12 months.',
    '>= 2X30X12': '30 days late multiple times in the last 12 months.',
    '1X60X12': '60 days late in the last 12 months.',
    '>= 2X60X12': '60 days late multiple times in the last 12 months.',
    '1X90X12': 'More than 60 days late in the last 12 months.',
    '>= 2X90X12': 'More than 60 days late multiple times in the last 12 months.',
    '1X30X24': '30 days late in the last 24 months.',
    '>= 2X30X24': '30 days late multiple times in the last 24 months.',
    '1X60X24': '60 days late in the last 24 months.',
    '>= 2X60X24': '60 days late multiple times in the last 24 months.',
    '1X90X24': 'More than 60 days late in the last 24 months.',
    '>= 2X90X24': 'More than 60 days late multiple times in the last 24 months.',
  },
  // forbearance: ['None', '<=6 Months', '6-24 Months', '>24 Months'],
  forbearance: { 'null-null': 'None', 'null-6': '<=6 Months', '7-24': '7-24 Months', '25-null': '25+ Months' },
  states: [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'MA',
    'ME',
    'MD',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VI',
    'VT',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  hmda: {
    1: 'Provided By Borrower',
    2: 'Not Provided In Mail Telephone Or Internet Application',
    3: 'Collected On The Basis Of Visual Observation Or Surname',
  },
  citizenship: ['US Citizen', 'Permanent Resident', 'Non Permanent Resident', 'Foreign National', 'ITIN'],
  experience: {
    '0-0': '0 Transaction',
    '1-1': '1 Transaction',
    '2-2': '2 Transactions',
    '3-3': '3 Transactions',
    '4-4': '4 Transactions',
    '5-null': '5+ Transactions',
  },
  // experience: {
  //   '0-3': '0-3 Transactions',
  //   '4-6': '4-6 Transactions',
  //   '7-9': '7-9 Transactions',
  //   '10-null': '10+ Transactions',
  // },
}

export const convertValueToLabel = (key: string, value: any, fieldProperties: Record<string, IVisibleProp>) => {
  let label = isEmpty(value) ? '' : String(value)

  // Ensure fieldProperties[key] exists before accessing its properties
  const fieldOptions =
    fieldProperties && fieldProperties[key]?.options ? convertSelectRangeOptionsToMap(fieldProperties[key].options) : {}

  switch (key) {
    case 'productType':
      label = (loanOptionsConstants.productType as any)[value]
      break
    case 'loanTerm':
      label = fieldOptions[value] || (loanOptionsConstants.loanTerm as any)[value]
      break
    case 'experience':
      label = fieldOptions[value] || (loanOptionsConstants.experience as any)[value]
      break
    case 'foreclosure':
    case 'deedInLieu':
    case 'shortSale':
      label = fieldOptions[value] || (loanOptionsConstants.loannex2 as any)[value]
      break
    case 'mortgageLates':
      label = fieldOptions[value] || (loanOptionsConstants.mortgageLates as any)[value]
      break
    case 'forbearanceLoanModification':
      label = fieldOptions[value] || (loanOptionsConstants.forbearance as any)[value]
      break
    case 'prepaymentPenalty':
      label = (loanOptionsConstants.prepaymentLong as any)[value]
      break
    case 'bankruptcy':
      label = fieldOptions[value] || (loanOptionsConstants.bankruptcy as any)[value]
      break
    case 'propertyUnderConstruction':
    case 'isPropertyInLeasableState':
    case 'firstTimeHomeBuyer':
    case 'firstTimeHomeInvestor':
    case 'ruralProperty':
    case 'propertyHasBeenListed':
    case 'isShortTermRental':
    case 'decliningMarketProperty':
    case 'isCEMATransaction':
    case 'isNewConstructionProperty':
    case 'changeToSquareFootage':
    case 'isNoScore':
      label = value ? 'Yes' : 'No'
      break
  }

  return label
}

export const getInitialLoanFields = (forPdf: boolean = false) => {
  let rlt: Record<string, InputType> = {
    productType: {
      title: 'Product Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.productType,
      error: '',
      required: true,
    },
    transactionType: {
      title: 'Transaction Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.transactionType,
      error: '',
      required: true,
    },
    propertyUnderConstruction: {
      title: 'Property Under Construction?',
      inputType: 'check',
      error: '',
      required: true,
    },
    isPropertyInLeasableState: {
      title: 'Can property be rented as is?',
      inputType: 'check',
      error: '',
      required: true,
    },
    isShortTermRental: {
      title: 'Is the property a short term rental?',
      inputType: 'check',
      error: '',
      required: true,
    },
    decliningMarketProperty: {
      title: 'Is the property located in a declining market?',
      inputType: 'check',
      error: '',
      required: true,
    },
    isNewConstructionProperty: {
      title: 'Is the property a new construction?',
      inputType: 'check',
      error: '',
      required: true,
    },
    changeToSquareFootage: {
      title: 'Will there be a change to Square Footage?',
      inputType: 'check',
      error: '',
      required: true,
    },
    proposedOccupancy: {
      title: 'Occupancy',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.occupancy,
      error: '',
      required: true,
    },
    loanTerm: {
      title: 'Loan Term',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.loanTerm,
      error: '',
      required: true,
    },
    borrowerType: {
      title: 'Borrower Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.borrowerType,
      error: '',
      required: true,
    },
    proposedLoanAmount: {
      title: 'Proposed Loan Amount',
      inputType: 'text',
      key: 'proposedLoanAmount',
      type: 'thousandSepNoDecimal',
      error: '',
      prefix: '$',
      required: true,
    },
    propertyPurchasePrice: {
      title: 'Property Purchase Price',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    asIsValue: {
      title: 'As Is Appraised Value', // 'As Is Value', Original Label
      key: 'asIsValue',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    afterRepairValue: {
      title: 'After Repair Value',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    rehabBudget: {
      title: 'Construction Budget',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    constructionReserve: {
      title: 'Construction Amount Financed',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    interestReserve: {
      title: 'Interest Reserve',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
      tooltip: 'The dollar amount of interest payment(s) being financed into the loan amount.',
    },
    experience: {
      title: 'Experience',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.experience,
      error: '',
      required: true,
    },
    howManyExperiences: {
      title: 'How Many Experiencies?',
      inputType: 'select',
      hasDefaultOption: true,
      options: {},
      error: '',
      required: true,
    },
    floodInsurance: {
      title: 'Flood Insurance',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    schoolTax: {
      title: 'School Tax',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    otherTax: {
      title: 'Other Tax',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyTaxes: {
      title: 'Proposed Monthly Taxes',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyInsurance: {
      title: 'Proposed Monthly Insurance',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyRent: {
      title: 'Proposed Monthly Rent',
      key: 'proposedMonthlyRent',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyHoaDues: {
      title: 'Proposed Monthly HOA Dues',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    propertyType: {
      title: 'Property Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.propertyTypeBigList,
      error: '',
      required: true,
    },
    condoType: {
      title: 'Condo Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: ['Warrantable', 'Non-Warrantable', 'Condotel'],
      error: '',
      required: true,
    },
    prepaymentPenalty: {
      title: 'Prepayment Penalty',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.prepaymentLong,
      error: '',
      required: true,
    },
    propertyHasBeenListed: {
      title: 'Has property been listed in the last 6 months?',
      inputType: 'check',
      error: '',
      length: 1,
      required: true,
    },
    residency: {
      title: 'Citizenship',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.citizenship,
      error: '',
      required: true,
    },
    amortizationType: {
      title: 'Amortization Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: ['ARM', 'Fixed', 'IO - ARM', 'IO - Fixed'],
      error: '',
      required: true,
    },
    escrowType: {
      title: 'Escrow Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.escrowType,
      error: '',
      required: true,
    },
    firstTimeHomeBuyer: {
      title: 'First Time Home Buyer?',
      inputType: 'check',
      error: '',
      required: true,
    },
    firstTimeHomeInvestor: {
      title: 'First Time Home Investor?',
      inputType: 'check',
      error: '',
      required: true,
    },
    ruralProperty: {
      title: 'Rural Property?',
      inputType: 'check',
      error: '',
      required: true,
    },
    bankruptcy: {
      title: 'Bankruptcy',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.bankruptcy,
      error: '',
      required: true,
    },
    foreclosure: {
      title: 'Foreclosure',
      inputType: 'select',
      hasDefaultOption: true,
      options: { ...loanOptionsConstants.loannex2 },
      error: '',
      required: true,
    },
    deedInLieu: {
      title: 'Deed-In-Lieu',
      inputType: 'select',
      hasDefaultOption: true,
      options: { ...loanOptionsConstants.loannex2 },
      error: '',
      required: true,
    },
    shortSale: {
      title: 'Short Sale',
      inputType: 'select',
      hasDefaultOption: true,
      options: { ...loanOptionsConstants.loannex2 },
      error: '',
      required: true,
    },
    mortgageLates: {
      title: 'Mortgage Lates',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.mortgageLates,
      error: '',
      required: true,
    },
    forbearanceLoanModification: {
      title: 'Forbearance / Loan Modification',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.forbearance,
      error: '',
      required: true,
    },
    secondLien: {
      title: 'Second Lien',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    secondLienPayment: {
      title: 'Second Lien Payment',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    lienPayoff: {
      title: 'Lien Payoff',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    closingCostEstimate: {
      title: 'Closing Cost Estimate',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    monthsReserve: {
      title: 'Months Reserve',
      inputType: 'text',
      type: 'number',
      error: '',
      required: true,
    },
    estimatedDscr: {
      title: 'DSCR',
      inputType: 'select',
      hasDefaultOption: true,
      options: DscrRangeOptions,
      error: '',
      required: true,
      visible: false,
    },
    numberOfUnits: {
      title: 'Number of Units',
      inputType: 'text',
      type: 'number',
      error: '',
      required: true,
    },
    borrowerFirstName: {
      title: 'Guarantor First Name',
      inputType: 'text',
      error: '',
      required: true,
    },
    borrowerMiddleName: {
      title: 'Guarantor Middle Name',
      inputType: 'text',
      error: '',
    },
    borrowerLastName: {
      title: 'Guarantor Last Name',
      inputType: 'text',
      error: '',
      required: true,
    },
    isNoScore: {
      title: 'No Score',
      inputType: 'checkbox',
      color: 'gray',
      className: 'mt-6',
      error: '',
    },
    estimatedCreditScore: {
      title: 'Credit Score',
      key: 'estimatedCreditScore',
      inputType: 'text',
      type: 'creditScore',
      error: '',
      required: true,
    },
    propertyState: {
      title: 'State',
      inputType: 'select',
      hasDefaultOption: true,
      options: [...loanOptionsConstants.states],
      error: '',
      required: true,
    },
    isCEMATransaction: {
      title: 'Is this transaction a CEMA?',
      inputType: 'check',
      error: '',
      required: true,
    },
    subjectPropertyAddress: {
      title: 'Subject Property Address',
      inputType: 'Map',
      sitexPro: true,
      error: '',
      required: true,
      length: 1,
    },
    additionalPropertyAddress: {
      title: 'Additional Subject Property Address',
      inputType: 'AutoComplete',
      autoCompleteType: 'map',
      error: '',
      length: 1,
    },
    subjectPropertyMap: {
      title: 'Subject Property Address Map',
      inputType: 'GoogleMap',
      length: 1,
    },
    ltvAdjustment: {
      title: 'LTV Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
    cltvAdjustment: {
      title: 'CLTV Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
    aivLtvAdjustment: {
      title: 'AIV-LTV Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
    arvLtvAdjustment: {
      title: 'ARV-LTV Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
    ltcAdjustment: {
      title: 'LTC Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
    ltpAdjustment: {
      title: 'LTP Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
  }
  if (forPdf) {
    Object.keys(rlt).forEach((key) => {
      if (rlt[key].inputType.toLowerCase() === 'select') {
        rlt[key].inputType = 'text'
      }
    })
  }
  return rlt
}

export const fieldsByTransactionTypeAndProductTypeConstant: { [key: string]: { [key1: string]: string[] } } = {
  Bridge: {
    Purchase: [
      'proposedOccupancy',
      'loanTerm',
      'borrowerType',
      'proposedLoanAmount',
      'propertyPurchasePrice',
      'asIsValue',
      'interestReserve',
      'closingCostEstimate',
      'experience',
      'howManyExperiences',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'propertyUnderConstruction',
      'isPropertyInLeasableState',
      'decliningMarketProperty',
      'isNewConstructionProperty',
      'ruralProperty',
      'borrowerFirstName',
      'borrowerMiddleName',
      'borrowerLastName',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'subjectPropertyMap',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
    Refinance: [
      'proposedOccupancy',
      'loanTerm',
      'borrowerType',
      'proposedLoanAmount',
      'asIsValue',
      'interestReserve',
      'lienPayoff',
      'closingCostEstimate',
      'experience',
      'howManyExperiences',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'propertyUnderConstruction',
      'isPropertyInLeasableState',
      'decliningMarketProperty',
      'isNewConstructionProperty',
      'ruralProperty',
      'borrowerFirstName',
      'borrowerMiddleName',
      'borrowerLastName',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'subjectPropertyMap',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
  },
  'Fix and Flip': {
    Purchase: [
      'proposedOccupancy',
      'loanTerm',
      'borrowerType',
      'proposedLoanAmount',
      'propertyPurchasePrice',
      'asIsValue',
      'interestReserve',
      'closingCostEstimate',
      'experience',
      'howManyExperiences',
      'afterRepairValue',
      'rehabBudget',
      'constructionReserve',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'changeToSquareFootage',
      'borrowerFirstName',
      'borrowerMiddleName',
      'borrowerLastName',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'subjectPropertyMap',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
    Refinance: [
      'proposedOccupancy',
      'loanTerm',
      'borrowerType',
      'proposedLoanAmount',
      'asIsValue',
      'interestReserve',
      'lienPayoff',
      'closingCostEstimate',
      'experience',
      'howManyExperiences',
      'afterRepairValue',
      'rehabBudget',
      'constructionReserve',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'changeToSquareFootage',
      'borrowerFirstName',
      'borrowerMiddleName',
      'borrowerLastName',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'subjectPropertyMap',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
  },
  'Ground Up Construction': {
    Purchase: [
      'proposedOccupancy',
      'loanTerm',
      'borrowerType',
      'proposedLoanAmount',
      'propertyPurchasePrice',
      'asIsValue',
      'interestReserve',
      'closingCostEstimate',
      'experience',
      'howManyExperiences',
      'afterRepairValue',
      'rehabBudget',
      'constructionReserve',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'borrowerFirstName',
      'borrowerMiddleName',
      'borrowerLastName',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'subjectPropertyMap',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
    Refinance: [
      'proposedOccupancy',
      'loanTerm',
      'borrowerType',
      'proposedLoanAmount',
      'asIsValue',
      'interestReserve',
      'lienPayoff',
      'closingCostEstimate',
      'experience',
      'howManyExperiences',
      'afterRepairValue',
      'rehabBudget',
      'constructionReserve',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'borrowerFirstName',
      'borrowerMiddleName',
      'borrowerLastName',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'subjectPropertyMap',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
  },
  'DSCR (Long Term Rental)': {
    Purchase: [
      'proposedOccupancy',
      'borrowerType',
      'proposedLoanAmount',
      'propertyPurchasePrice',
      'asIsValue',
      'closingCostEstimate',
      'propertyType',
      'numberOfUnits',
      'prepaymentPenalty',
      'residency',
      'amortizationType',
      'escrowType',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'isNewConstructionProperty',
      'isPropertyInLeasableState',
      'isShortTermRental',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'monthsReserve',
      'estimatedDscr',
      'proposedMonthlyRent',
      'proposedMonthlyTaxes',
      'proposedMonthlyInsurance',
      'proposedMonthlyHoaDues',
      'floodInsurance',
      'schoolTax',
      'otherTax',
      'borrowerFirstName',
      'borrowerMiddleName',
      'borrowerLastName',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'subjectPropertyMap',
      'ltvAdjustment',
      'cltvAdjustment',
    ],
    Refinance: [
      'proposedOccupancy',
      'borrowerType',
      'proposedLoanAmount',
      'asIsValue',
      'lienPayoff',
      'closingCostEstimate',
      'propertyType',
      'numberOfUnits',
      'prepaymentPenalty',
      'propertyHasBeenListed',
      'residency',
      'amortizationType',
      'escrowType',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'isNewConstructionProperty',
      'isPropertyInLeasableState',
      'isShortTermRental',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'monthsReserve',
      'estimatedDscr',
      'proposedMonthlyRent',
      'proposedMonthlyTaxes',
      'proposedMonthlyInsurance',
      'proposedMonthlyHoaDues',
      'floodInsurance',
      'schoolTax',
      'otherTax',
      'borrowerFirstName',
      'borrowerMiddleName',
      'borrowerLastName',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'subjectPropertyMap',
      'ltvAdjustment',
      'cltvAdjustment',
    ],
  },
}

export const groupedFields = [
  [
    'productType',
    'transactionType',
    'propertyUnderConstruction',
    'isPropertyInLeasableState',
    'isShortTermRental',
    'decliningMarketProperty',
    'isNewConstructionProperty',
    'changeToSquareFootage',
  ],
  ['proposedOccupancy', 'loanTerm', 'prepaymentPenalty', 'propertyHasBeenListed'],
  [
    'propertyPurchasePrice',
    'asIsValue',
    'afterRepairValue',
    'interestReserve',
    'secondLien',
    'secondLienPayment',
    'lienPayoff',
    'rehabBudget',
    'constructionReserve',
    'closingCostEstimate',
    'proposedLoanAmount',
  ],
  ['experience', 'howManyExperiences'],
  ['amortizationType', 'escrowType'],
  ['isNoScore', 'propertyState', 'estimatedCreditScore', 'isCEMATransaction'],
  ['propertyType', 'condoType', 'numberOfUnits'],
  ['firstTimeHomeBuyer', 'firstTimeHomeInvestor', 'ruralProperty'],
  [
    'bankruptcy',
    'foreclosure',
    'deedInLieu',
    'shortSale',
    'mortgageLates',
    'forbearanceLoanModification',
    'residency',
    'monthsReserve',
  ],
  ['borrowerType', 'borrowerFirstName', 'borrowerMiddleName', 'borrowerLastName'],
  [
    'proposedMonthlyRent',
    'proposedMonthlyTaxes',
    'proposedMonthlyInsurance',
    'proposedMonthlyHoaDues',
    'floodInsurance',
    'schoolTax',
    'otherTax',
    'estimatedDscr',
  ],
  ['subjectPropertyAddress', 'additionalPropertyAddress', 'subjectPropertyMap'],
  ['ltvAdjustment', 'cltvAdjustment', 'aivLtvAdjustment', 'arvLtvAdjustment', 'ltcAdjustment', 'ltpAdjustment'],
]

export const OwnershipTypes = ['Own', 'Rent', 'No Primary Housing Expense']

export const YesNoOptions = {
  yes: 'Yes',
  no: 'No',
}

export const YearOptions = {
  '1': '1 year',
  '2': '2 years',
  '3': '3 years',
  '4': '4 years',
  '5': '5 years',
  '6': '6 years',
  '7': '7 years',
  '8': '8 years',
  '9': '9 years',
  '10': '10+ years',
}

export const OwnRentYearOptions = {
  '1': '< 2 Years',
  '2': '>= 2 Years',
}

export const MaritalStatusTypes: Record<string, string> = {
  Married: 'Married (includes registered domestic partners)',
  Unmarried: 'Unmarried (includes divorced, widow)',
  Single: 'Single (never been married)',
  Separated: 'Separated',
}

export const priceLoanRelatedFields = [
  'transactionType',
  'productType',
  'loanTerm',
  'proposedOccupancy',
  'proposedLoanAmount',
  'propertyPurchasePrice',
  'asIsValue',
  'afterRepairValue',
  'rehabBudget',
  'interestReserve',
  'constructionReserve',
  'escrowType',
  'propertyType',
  'numberOfUnits',
  'propertyHasBeenListed',
  'firstTimeHomeBuyer',
  'firstTimeHomeInvestor',
  'propertyUnderConstruction',
  'isPropertyInLeasableState',
  'isShortTermRental',
  'ruralProperty',
  'decliningMarketProperty',
  'isCEMATransaction',
  'isNewConstructionProperty',
  'changeToSquareFootage',
  'bankruptcy',
  'foreclosure',
  'deedInLieu',
  'shortSale',
  'mortgageLates',
  'forbearanceLoanModification',
  'secondLien',
  'secondLienPayment',
  'monthsReserve',
  'estimatedDscr',
  'numberOfUnits',
  'condoType',
  'prepaymentPenalty',
  'residency',
  'amortizationType',
  'subjectPropertyAddress',
  'additionalPropertyAddress',
  'isNoScore',
  'estimatedCreditScore',
  'propertyState',
  'experience',
  'howManyExperiences',
  'borrowerType',
  'proposedMonthlyRent',
  'proposedMonthlyTaxes',
  'proposedMonthlyInsurance',
  'proposedMonthlyHoaDues',
  'floodInsurance',
  'schoolTax',
  'otherTax',
  'ltvAdjustment',
  'cltvAdjustment',
  'aivLtvAdjustment',
  'arvLtvAdjustment',
  'ltcAdjustment',
  'ltpAdjustment',
]

export const priceLimitRelatedFields = [
  'transactionType',
  'isNoScore',
  'estimatedCreditScore',
  'productType',
  'experience',
  'howManyExperiences',
  'proposedLoanAmount',
  'propertyPurchasePrice',
  'asIsValue',
  'afterRepairValue',
  'rehabBudget',
  'constructionReserve',
  'propertyHasBeenListed',
  'firstTimeHomeInvestor',
  'firstTimeHomeBuyer',
  'ruralProperty',
  'propertyUnderConstruction',
  'isPropertyInLeasableState',
  'isShortTermRental',
  'decliningMarketProperty',
  'isCEMATransaction',
  'isNewConstructionProperty',
  'lienPayoff',
  'closingCostEstimate',
  'interestReserve',
  'residency',
  'bankruptcy',
  'foreclosure',
  'deedInLieu',
  'shortSale',
  'mortgageLates',
  'forbearanceLoanModification',
  'propertyType',
  'subjectPropertyAddress',
  'proposedOccupancy',
  'ltvAdjustment',
  'cltvAdjustment',
  'aivLtvAdjustment',
  'arvLtvAdjustment',
  'ltcAdjustment',
  'ltpAdjustment',
]

export const calcLTVFields = [
  'transactionType',
  'propertyPurchasePrice',
  'asIsValue',
  'constructionReserve',
  'proposedLoanAmount',
]

export const calcCLTVFields = [...calcLTVFields, 'secondLien']

export const sandBoxNotEditableFields = [
  'borrowerType',
  'borrowerFirstName',
  'borrowerMiddleName',
  'borrowerLastName',
  // 'estimatedCreditScore',
  'proposedMonthlyTaxes',
  'proposedMonthlyInsurance',
  'proposedMonthlyRent',
  'proposedMonthlyHoaDues',
  'floodInsurance',
  'schoolTax',
  'otherTax',
]

export const dscrRelatedFields = [
  'productType',
  'proposedLoanAmount',
  'secondLien',
  'secondLienPayment',
  'proposedMonthlyInsurance',
  'proposedMonthlyTaxes',
  'proposedMonthlyHoaDues',
  'proposedMonthlyRent',
  'floodInsurance',
  'schoolTax',
  'otherTax',
]

export const DataVerifyTaxAvailableYears = [2022, 2021, 2020, 2019]
