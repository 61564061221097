import { Overview } from 'components/Overview'
import { AccountType } from 'config'
import { removeNonPagePermissions } from 'hooks/hasPagePermissions'
import { usePermissions } from 'hooks/usePermissions'
import Notes from 'pages/Loan/Servicing/Notes/Notes'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isVendorEnabled } from 'services'

import AppraisalInformation from './AppraisalInformation'
import { FraudGaurd, Lexis, SSA, Tax } from './Dataverify'
import { EscrowHoldbackAgreement } from './EscrowHoldbackAgreement'
import ExpirationDates from './ExpirationDates/index'
import { NYTransactions } from './NYTransactions'
import ReviewForm from './ReviewForm/ReviewForm'
import UnderwritingSummary from './UnderwritingSummary'

export default function Underwriting() {
  const [selectedMenu, setSelectedMenu] = useState('')
  const [menuItems, setMenuItems] = useState<any>({})
  const [vendorStatus, setVendorStatus] = useState<Record<string, boolean>>({})

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
  }

  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  const { hasPermission } = usePermissions()

  useEffect(() => {
    loadVendors()
  }, [])

  const loadVendors = async () => {
    const [lexisnexis, dataverify] = await Promise.all([isVendorEnabled('Lexisnexis'), isVendorEnabled('Dataverify')])
    setVendorStatus({ lexisnexis, dataverify })
  }

  useEffect(() => {
    let temp: any = {}
    if (hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')) {
      temp['appraisalReview'] = 'Appraisal Review'
      temp['expirationDates'] = 'Expiration Dates'
      temp['underWritingSummary'] = 'Underwriting Summary'
      if (hasPermission('MANAGE_APPRAISAL_INFORMATION')) temp['appraisalInformation'] = 'Appraisal Information'
      if (auth.profile.accountType !== AccountType.ACCOUNT_EXECUTIVE) {
        if (vendorStatus.dataverify) {
          temp['dataverifySSA'] = 'DataVerify SSA'
          temp['dataverify4506'] = 'DataVerify 4506'
          temp['dataverifyDrive'] = 'DataVerify Drive'
        }
        if (vendorStatus.lexisnexis) temp['lexis'] = 'SmartLinx Person Report'
      }
      temp['nyTransactions'] = 'NY Transactions'
      temp['escrowHoldbackAgreement'] = 'Escrow Holdback Agreement'
      temp['notes'] = 'Notes'
    }
    removeNonPagePermissions(temp, ['pipeline', 'underwriting'])
    setMenuItems(temp)
    if (!selectedMenu) setSelectedMenu(Object.keys(temp)[0])
  }, [auth, vendorStatus])

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'appraisalReview':
        return <ReviewForm child={true} />
      case 'appraisalInformation':
        return <AppraisalInformation child={true} />
      case 'dataverifySSA':
        return <SSA />
      case 'dataverify4506':
        return <Tax />
      case 'dataverifyDrive':
        return <FraudGaurd />
      case 'lexis':
        return <Lexis />
      case 'expirationDates':
        return <ExpirationDates child={true} />
      case 'underWritingSummary':
        return <UnderwritingSummary child={true} />
      case 'notes':
        return <Notes child={true} />
      case 'nyTransactions':
        return <NYTransactions />
      case 'escrowHoldbackAgreement':
        return <EscrowHoldbackAgreement />
      default: {
        return <div></div>
      }
    }
  }, [selectedMenu])

  const renderMenu = (menus: Record<string, string>) => {
    return (
      <ul className="sidebar-items flex flex-col p-4 pb-10">
        {Object.keys(menus).map((item: string, index) => {
          const isActive = selectedMenu === item
          return (
            <li
              key={index}
              onClick={() => {
                setMenu(item)
              }}
              className="py-2"
            >
              <p className={`hover:underline cursor-pointer ${isActive ? 'border px-4 py-1 bg-zinc-100' : 'py-1'}`}>
                {index + 1}. {menus[item]}
              </p>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className="py-6 px-2">
      <Overview title="Underwriting" />
      <div className="max-w-screen-2xl m-auto grid grid-cols-12 md:gap-6">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded mb-4">
          {renderMenu(menuItems)}
        </div>
        <div className="relative h-fit content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1 mb-4">
          <h2 className="text-2xl font-bold flex items-center mb-5">{menuItems[selectedMenu]}</h2>
          {renderFragment}
        </div>
      </div>
    </div>
  )
}
