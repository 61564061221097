import { ArrowRightIcon, EnvelopeIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { GlobalConfigType } from 'actions'
import { COMPANY_DNS, COMPANY_LOGO, COMPANY_TITLE, IS_MAIN_COMPANY } from 'config'
import moment from 'moment'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { requestNewsletter } from 'services'
import { svgLoading } from 'stories/assets'
import { Input2 } from 'stories/components'
import { getOnlyNumber } from 'utils'
import { validateEmail } from 'utils/validator'

export const Footer = () => {
  const [isLoading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const footerConfig = useSelector((state: any) => {
    return state.globalConfigReducer[GlobalConfigType.Footer]
  })

  const {
    showNewsletter,
    aboutUsDescription,
    contactUsDescription,
    phone,
    tollFree,
    email: _email,
    facebook,
    instagram,
    twitter,
    linkedin,
    youtube,
    links,
    officeAddress,
  } = footerConfig || {}

  const onChangeEmail = (value: string) => {
    setEmail(value)
    setEmailError('')
  }

  const onSubmitNewsletter = () => {
    if (!email) {
      setEmailError('Required')
      return
    }
    if (!validateEmail(email)) {
      setEmailError('Email address is not in a valid format.')
      return
    }

    setLoading(true)
    requestNewsletter(email)
      .then(() => {
        toast('Newsletter is submitted', { type: 'info' })
        setEmail('')
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="footer-container bg-slate-50 w-full relative">
      <div className="max-w-screen-2xl m-auto bg-slate-50 bottom-0 pt-10 pb-6">
        <div className="grid md:grid-cols-4 sm:grid-cols-2 md:gap-8 sm:gap-4 border-b px-4">
          <div className="logo-newslater-container mb-5">
            <img src={COMPANY_LOGO} className="max-w-[20rem] max-h-11" />
            {showNewsletter && (
              <div className="mt-8 flex">
                <div className="flex-1">
                  <Input2
                    name="newsletter"
                    title="Newsletter Signup"
                    value={email}
                    type="email"
                    error={emailError}
                    required
                    onChange={onChangeEmail}
                  />
                </div>
                <button
                  disabled={isLoading}
                  className="ml-2 mt-2 h-fit text-white rounded p-[7px] bg-shade-blue hover:bg-sky-600 cursor-pointer"
                  onClick={onSubmitNewsletter}
                >
                  {isLoading && <img src={svgLoading} className="h-5 w-5 animate-spin" />}
                  {!isLoading && <ArrowRightIcon className="h-7 w-5" aria-hidden="true" />}
                </button>
              </div>
            )}
          </div>
          <div className="about-us-container mb-5">
            <div className="font-variation-settings-600 text-[16px] mb-3 text-shade-blue">About Us</div>
            {aboutUsDescription?.visible && (
              <div className="text-slate-700 text-[15px]">{aboutUsDescription.value}</div>
            )}
            {links
              ?.filter((link: any) => link?.visible && link.url && link.label)
              ?.map((link: any, index: number) => (
                <div className="link font-variation-settings-600 text-[14px] mt-3 hover:underline" key={index}>
                  <a href={link.url} rel="noopener noreferrer" target="_blank">
                    {link.label}
                  </a>
                </div>
              ))}
          </div>
          <div className="contact-us-container mb-5">
            <div className="font-variation-settings-600 text-[16px] mb-3 text-shade-blue">Contact Us</div>
            {contactUsDescription?.visible && (
              <div className="text-slate-700 text-[15px]">{contactUsDescription.value}</div>
            )}
            {phone?.visible && (
              <div className="link font-variation-settings-600 text-[14px] mt-3 hover:underline">
                <a href={`tel:+1${getOnlyNumber(phone.value)}`} className="flex items-center">
                  <PhoneIcon className="h-4 w-4" aria-hidden="true" />
                  <span className="ml-2">{phone.value}</span>
                </a>
              </div>
            )}
            {tollFree?.visible && tollFree?.value && (
              <div className="link font-variation-settings-600 text-[14px] mt-1 hover:underline">
                <a href={`tel:+1${getOnlyNumber(tollFree.value)}`} className="flex items-center">
                  <PhoneIcon className="h-4 w-4" aria-hidden="true" />
                  <span className="ml-2">{tollFree.value}</span>
                  <span className="text-slate-700 text-[13px] ml-1 font-normal">(Toll free)</span>
                </a>
              </div>
            )}
            {_email?.visible && (
              <div className="link font-variation-settings-600 text-[14px] mt-1 hover:underline">
                <a href={`mailto:${_email.value}`} className="flex items-center">
                  <EnvelopeIcon className="h-4 w-4" aria-hidden="true" />
                  <span className="ml-2">{_email.value}</span>
                </a>
              </div>
            )}
            <div className="link font-variation-settings-600 text-[14px] mt-3 hover:underline">
              {facebook?.visible && facebook?.value && (
                <a href={facebook.value} rel="noopener noreferrer" target="_blank">
                  <button className="bg-blue-500 p-1 font-semibold text-white inline-flex items-center space-x-2 rounded">
                    <svg
                      className="w-5 h-5 fill-current"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                    </svg>
                  </button>
                </a>
              )}
              {linkedin?.visible && linkedin?.value && (
                <a href={linkedin.value} rel="noopener noreferrer" target="_blank" className="ml-3">
                  <button className="bg-sky-600 p-1 font-semibold text-white inline-flex items-center space-x-2 rounded">
                    <svg
                      className="w-5 h-5 fill-current"
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22.23 0H1.77C.79 0 0 .774 0 1.726v20.547C0 23.225.79 24 1.77 24h20.46c.98 0 1.77-.775 1.77-1.727V1.726C24 .774 23.21 0 22.23 0zM7.12 20.452H3.56V9h3.56v11.452zM5.34 7.527c-1.14 0-2.06-.924-2.06-2.065 0-1.14.92-2.06 2.06-2.06 1.14 0 2.06.92 2.06 2.06 0 1.14-.92 2.065-2.06 2.065zM20.45 20.452h-3.56v-5.575c0-1.33-.028-3.04-1.854-3.04-1.854 0-2.14 1.45-2.14 2.95v5.665H9.34V9h3.42v1.56h.048c.48-.9 1.65-1.854 3.4-1.854 3.64 0 4.24 2.395 4.24 5.51v6.235z" />
                    </svg>
                  </button>
                </a>
              )}
              {youtube?.visible && youtube?.value && (
                <a href={youtube.value} rel="noopener noreferrer" target="_blank" className="ml-3">
                  <button className="bg-red-600 p-1 font-semibold text-white inline-flex items-center space-x-2 rounded">
                    <svg
                      className="w-5 h-5 fill-current"
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M23.498 6.186a2.956 2.956 0 0 0-2.08-2.08C19.19 3.5 12 3.5 12 3.5s-7.19 0-9.418.606a2.956 2.956 0 0 0-2.08 2.08C0 8.414 0 12 0 12s0 3.586.502 5.814a2.956 2.956 0 0 0 2.08 2.08C4.81 20.5 12 20.5 12 20.5s7.19 0 9.418-.606a2.956 2.956 0 0 0 2.08-2.08C24 15.586 24 12 24 12s0-3.586-.502-5.814zM9.75 15.569V8.431L15.818 12 9.75 15.569z" />
                    </svg>
                  </button>
                </a>
              )}
              {twitter?.visible && twitter?.value && (
                <a href={twitter.value} rel="noopener noreferrer" target="_blank" className="ml-3">
                  <button className="bg-gray-700 p-1 font-semibold text-white inline-flex items-center space-x-2 rounded">
                    <svg
                      className="w-5 h-5 fill-current"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.6 2H21L13.5 10.5 22 22h-5.4l-5.8-7.3L5.2 22H1l8.1-9.2L1 2h5.5l5.3 6.7L16.6 2Z" />
                    </svg>
                  </button>
                </a>
              )}
              {instagram?.visible && instagram?.value && (
                <a href={instagram.value} rel="noopener noreferrer" target="_blank" className="ml-3">
                  <button className="bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 p-1 font-semibold text-white inline-flex items-center space-x-2 rounded">
                    <svg
                      className="w-5 h-5 fill-current"
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2.163c3.204 0 3.584.012 4.849.07 1.366.062 2.633.336 3.608 1.311.975.975 1.249 2.242 1.311 3.608.058 1.265.069 1.645.069 4.849s-.012 3.584-.07 4.849c-.062 1.366-.336 2.633-1.311 3.608-.975.975-2.242 1.249-3.608 1.311-1.265.058-1.645.069-4.849.069s-3.584-.012-4.849-.07c-1.366-.062-2.633-.336-3.608-1.311-.975-.975-1.249-2.242-1.311-3.608-.058-1.265-.069-1.645-.069-4.849s.012-3.584.07-4.849c.062-1.366.336-2.633 1.311-3.608.975-.975 2.242-1.249 3.608-1.311 1.265-.058 1.645-.069 4.849-.069m0-2.163C8.741 0 8.332.012 7.05.072 5.756.134 4.59.42 3.516 1.494 2.442 2.568 2.156 3.734 2.094 5.028 2.034 6.311 2.022 6.72 2.022 12s.012 5.689.072 6.972c.062 1.294.348 2.46 1.422 3.534 1.074 1.074 2.24 1.36 3.534 1.422C8.332 23.988 8.741 24 12 24s3.668-.012 4.951-.072c1.294-.062 2.46-.348 3.534-1.422 1.074-1.074 1.36-2.24 1.422-3.534.06-1.283.072-1.692.072-6.972s-.012-5.689-.072-6.972c-.062-1.294-.348-2.46-1.422-3.534C19.411.42 18.245.134 16.951.072 15.668.012 15.259 0 12 0zM12 5.838a6.162 6.162 0 1 0 0 12.324A6.162 6.162 0 0 0 12 5.838m0 10.162a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM18.406 4.594a1.44 1.44 0 1 0 0 2.88 1.44 1.44 0 0 0 0-2.88z" />
                    </svg>
                  </button>
                </a>
              )}
            </div>
          </div>
          <div className="office-container mb-5">
            <div className="font-variation-settings-600 text-[16px] mb-3 text-shade-blue">Corporate Office</div>
            {officeAddress?.visible && (
              <div className="text-slate-700 text-[15px] flex items-center">
                <MapPinIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                <span className="flex-1">{officeAddress.value}</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap justify-between mt-3 px-3">
          <div className="text-slate-700 text-[15px] mb-3">
            Copyright {moment().format('YYYY')} @ {COMPANY_TITLE}. All right reserved.
          </div>
          <div className={`${IS_MAIN_COMPANY ? 'flex' : 'hidden'} flex-wrap gap-2 md:gap-8`}>
            <Link to="/faqs" className="hover:underline">
              FAQs
            </Link>
            <Link to="/careers" className="hover:underline">
              Careers
            </Link>
            <a
              href={`https://${COMPANY_DNS}/#/privacy-policy`}
              rel="noopener noreferrer"
              target="_blank"
              className="ml-3 hover:underline"
            >
              Privacy Policy
            </a>
            <a
              href={`https://${COMPANY_DNS}/#/terms-and-conditions`}
              rel="noopener noreferrer"
              target="_blank"
              className="ml-3 hover:underline"
            >
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
