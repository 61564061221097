import { ArrowDownTrayIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { downloadBrokerAgreementPdf, getAdminConfig, setAdminConfig } from 'services'
import { Button, ButtonGroup } from 'stories/components'
import { capitalizeFirstLetter, confirm } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { BrokerAgreementPlaceholders } from './constants'

const options = {
  broker: 'Broker',
  tablefunder: 'Table Funder',
  correspondent: 'Correspondent',
}

export const BrokerAgreement = () => {
  const [action, setAction] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [changed, setChanged] = useState(false)
  const [data, setData] = useState<Record<string, any>>([])
  const [template, setTemplate] = useState('broker')

  const initData = async () => {
    setIsLoading(true)
    const res = await getAdminConfig('brokerAgreementTemplate', { template })
    setData(res)
    setIsLoading(false)
    setChanged(false)
    setAction('')
  }
  useEffect(() => {
    initData()
  }, [template])

  const onChange = (key: string, value: any) => {
    const params = key.split('.')
    const temp = cloneDeep(data)
    temp[params[0]][params[1]] = value
    setData(temp)
    setChanged(true)
  }

  const onChangeTemplate = async (value: string) => {
    if (changed) await onSubmit('changeTemplate')
    setTemplate(value)
  }

  const onSubmit = async (action?: string) => {
    if (action === 'changeTemplate') {
      const result = await confirm('Do you want to save the changes before switching to the new template?')
      if (!result) return
    }
    setIsLoading(true)
    await setAdminConfig('brokerAgreementTemplate', { data, template })
    toast('Successfully submitted', { type: 'success' })
    setIsLoading(false)
    setChanged(false)
  }

  const onAddClick = () => {
    const temp = cloneDeep(data)
    temp.push({ title: 'New Title', content: 'Please enter content here...', isSign: false, isNewPage: false })
    setData(temp)
  }

  const onDelete = async (index: number) => {
    const result = await confirm('Are you sure you want to delete this section?')
    if (!result) return
    const temp = cloneDeep(data)
    temp.splice(index, 1)
    setData(temp)
  }
  const onDownloadBrokerAgreementPdf = () => {
    downloadBrokerAgreementPdf(0, template)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-4">Broker Agreement Template</h2>

      <div className="mb-6">
        <p className="ml-3 text-lg font-medium mb-2">- Placeholders</p>
        <div className="p-3 border rounded-md">
          <div className="flex gap-x-1 gap-y-3 flex-wrap justify-between">
            {BrokerAgreementPlaceholders.sort().map((item, index) => {
              return (
                <pre key={index} className="w-[240px]">
                  {capitalizeFirstLetter(item)}
                </pre>
              )
            })}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <ButtonGroup title={options} value={template} onChange={(v) => onChangeTemplate(v)} className="mb-4" />
        <Button link onClick={onDownloadBrokerAgreementPdf} loading={action === 'download'}>
          <div className="flex gap-2 items-center">
            Download template
            <ArrowDownTrayIcon className="w-4 h-4" />
          </div>
        </Button>
      </div>
      {data?.map((item: any, index: number) => {
        return (
          <div className="mb-6 relative">
            <div className="grid md:grid-cols-2 grid-cols-1">
              <RenderInput
                input={{
                  inputType: 'text',
                  title: 'Title',
                  value: item.title || '',
                }}
                Key={`${index}.title`}
                onChange={onChange}
              />
              <div className="flex justify-end items-center gap-4 ">
                <RenderInput
                  input={{
                    inputType: 'toggle',
                    title: 'Signature',
                    value: item.isSign || false,
                  }}
                  Key={`${index}.isSign`}
                  onChange={onChange}
                />
                <RenderInput
                  input={{
                    inputType: 'toggle',
                    title: 'New Page',
                    value: item.isNewPage || false,
                  }}
                  Key={`${index}.isNewPage`}
                  onChange={onChange}
                />
                <Button link onClick={() => onDelete(index)}>
                  <div className="flex items-center gap-1 text-red-600">
                    <TrashIcon className="w-4 h-4"></TrashIcon>
                    Delete
                  </div>
                </Button>
              </div>
            </div>

            <RenderInput
              input={{
                inputType: 'textarea',
                title: 'Text',
                value: item.content,
                rows: 15,
              }}
              Key={`${index}.content`}
              onChange={onChange}
            />
          </div>
        )
      })}
      <Button link onClick={onAddClick}>
        <div className="flex gap-2 items-center">
          <PlusIcon className="w-4 h-4"></PlusIcon> Add Section
        </div>
      </Button>
      <div className="flex justify-center mt-4">
        <Button onClick={onSubmit} loading={isLoading}>
          Submit
        </Button>
      </div>
    </div>
  )
}
