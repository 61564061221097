import { getPrice3decimal, isEmpty, isNull } from 'utils'

import type {
  IBankruptcyRange,
  ICommercialDscr,
  ICommercialOnlyProgrammed,
  IMortgageLatesRange,
  IRange,
} from './interface'

export const NoUnitPropertyTypes = [
  'SFR-Detached',
  'SFR-Attached',
  'Condo-Warrantable',
  'Condo-NonWarrantable',
  'Condotel',
  'PUD',
  'Modular',
  'Industrial',
  'Manufactured',
  'Self Storage',
  'Office',
  'Retail',
  'Warehouse',
  'Automotive',
]

export const convertNullValue = (value: any): string => {
  const rlt = isEmpty(value) ? 'N/A' : String(value)

  return rlt
}

export const convertNullToBlank = (value: any): string => {
  const rlt = isEmpty(value) || isNaN(value) ? '' : String(value)

  return rlt
}

export const convertNullToNoMax = (value: any) => {
  return isEmpty(value) || isNaN(value) ? 'No Max' : getPrice3decimal(value)
}

export const convertNullToNoScore = (value: any) => {
  return isEmpty(value) || isNaN(value) ? 'No Score' : value
}

export const convertNullToPlus = (value: any) => {
  return isEmpty(value) || isNaN(value) ? '+' : getPrice3decimal(value)
}

export const convertNullToBlankValueToDecimal = (value: any) => {
  return isEmpty(value) || isNaN(value) ? '' : getPrice3decimal(value)
}

export const getPropertyTypeLabel = (value: string, numberOfUnits: IRange) => {
  let unitsRange = ''
  const { from, to } = numberOfUnits
  if (!isEmpty(from) && !isEmpty(to)) {
    if (from === to) unitsRange = ` (${from} units)`
    else unitsRange = ` (${from}-${to} units)`
  } else if (isEmpty(from) && !isEmpty(to)) unitsRange = ` (< ${to} units)`
  else if (!isEmpty(from) && isEmpty(to)) unitsRange = ` (${from}+ units)`
  return value + unitsRange
}

export const convertRangeToString = (value: IRange, key: string = '') => {
  const { from, to } = value
  let suffix = ''
  switch (key) {
    case 'Experience':
      suffix = (from === 0 && to === 0) || (from === 1 && to === 1) ? ' Transaction' : ' Transactions'
      break
    case 'Loan Term':
    case 'Months Reserve':
    case 'Forbearance / Loan Modification':
    case 'FC/SS/DIL':
    case 'Foreclosure':
    case 'Short Sale':
    case 'Deed In Lieu':
      suffix = ' Months'
      break
  }

  if (isNull(from) && isNull(to)) return `None`
  else if (!isNull(from) && isNull(to)) return `${getPrice3decimal(from)}+${suffix}`
  else if (isNull(from) && !isNull(to)) return `<= ${getPrice3decimal(to)}${suffix}`
  else {
    if (from === to) return `${from}${suffix}`
    return `${getPrice3decimal(from)} - ${getPrice3decimal(to)}${suffix}`
  }
}

export const getBankruptcyLabel = (value: IBankruptcyRange) => {
  const { chapter, from, to } = value

  if (isNull(chapter)) return 'None'
  else if (isNull(from) && isNull(to)) return 'BK Active'
  else if (!isNull(from) && isNull(to)) return `BK${chapter} Discharged ${getPrice3decimal(from)}+ Months`
  else if (isNull(from) && !isNull(to)) return `BK${chapter} Discharged <= ${getPrice3decimal(to)} Months`
  else {
    if (from === to) return `BK${chapter} Discharged ${from} Months`
    return `BK${chapter} Discharged ${getPrice3decimal(from)} - ${getPrice3decimal(to)} Months`
  }
}

export const getMortgageLatesLabel = (value: IMortgageLatesRange) => {
  const { days, months, from, to } = value

  if (isNull(days) && isNull(months)) return 'None'
  else if (isNull(from) && isNull(to)) return 'None'
  else if (!isNull(from) && isNull(to)) return `>= ${from}X${days}X${months}`
  else if (isNull(from) && !isNull(to)) return `<= ${to}X${days}X${months}`
  else {
    if (from === to) return `${from}X${days}X${months}`
    return `${from}-${to}X${days}X${months}`
  }
}

export const getNonDscrTableData = (data: ICommercialOnlyProgrammed) => {
  let rlt: any[] = []

  const {
    baseRates,
    basePrices,
    rateSpreads,
    exitFees,
    ltvLimits,
    ficos,
    loanPurposeAdjustment,
    loanAmountRanges,
    loanAmountAdjustment,
    monthsReserve,
    monthsReserveAdjustment,
    experience,
    propertyTypes,
    citizenships,
    bankruptcy,
    foreclosure,
    deedInLieu,
    shortsale,
    mortgageLates,
    forbearance,
    rehabBudgetTypes,
    rehabBudgetTypesAdjustment,
    state,
    stateAdjustment,
    maxRatePriceAdjustment,
    experienceAdjustment,
    loanTerm,
    loanTermAdjustment,
    aivLtvs,
    aivLtvAdjustment,
    ltcs,
    ltcAdjustment,
    ficoAdjustment,
    propertyTypeAdjustment,
    citizenshipAdjustment,
    bankruptcyAdjustment,
    foreclosureAdjustment,
    deedInLieuAdjustment,
    shortsaleAdjustment,
    mortgageLatesAdjustment,
    forbearanceAdjustment,
    loanAmount,
    initialLoanAmount,
  } = data

  const stackCols = [5, 6, 7, 8]
  const titleRows = [2, 7]
  const titleCols = [0, 1, 2, 3, 4, 5, 6]
  const titleColsSpan2 = [0, 1, 2, 3]
  const colSpan2Cols = [1, 2, 3]
  const colSpan2Rows = [0, 1]

  const rightAlignRows = [3, 4, 5]

  const titles: string[] = ['0-0', '1-0', '8-0']
  const rightAlignCells: string[] = []
  const colSpan2: string[] = []

  rlt.push([
    'Min/Max Loan Amount',
    isEmpty(loanAmount.from) ? 'N/A' : '$ ' + getPrice3decimal(loanAmount.from),
    isEmpty(loanAmount.to) ? 'N/A' : '$ ' + getPrice3decimal(loanAmount.to),
    '',
  ])
  rlt.push([
    'Min/Max Initial Loan Amount',
    isEmpty(initialLoanAmount.from) ? 'N/A' : '$ ' + getPrice3decimal(initialLoanAmount.from),
    isEmpty(initialLoanAmount.to) ? 'N/A' : '$ ' + getPrice3decimal(initialLoanAmount.to),
    '',
  ])

  rlt.push(['Max LTV Limits', 'AIV-LTV', 'ARV-LTV', 'LTC', 'LTP', '', ''])

  rlt.push([
    'Purchase',
    convertNullValue(ltvLimits.purchase.aivLtv),
    convertNullValue(ltvLimits.purchase.arvLtv),
    convertNullValue(ltvLimits.purchase.ltc),
    convertNullValue(ltvLimits.purchase.ltp),
    '',
    '',
  ])

  rlt.push([
    'No-Cashout',
    convertNullValue(ltvLimits.nocashout.aivLtv),
    convertNullValue(ltvLimits.nocashout.arvLtv),
    convertNullValue(ltvLimits.nocashout.ltc),
    convertNullValue(ltvLimits.nocashout.ltp),
    '',
    '',
  ])
  rlt.push([
    'Cashout',
    convertNullValue(ltvLimits.cashout.aivLtv),
    convertNullValue(ltvLimits.cashout.arvLtv),
    convertNullValue(ltvLimits.cashout.ltc),
    convertNullValue(ltvLimits.cashout.ltp),
    '',
    '',
  ])

  rlt.push(['', '', '', '', '', '', ''])
  let posY = 7

  // Rate & Price Adjustment Label Rows
  titleRows.push(posY)
  colSpan2Rows.push(posY)
  titleRows.push(posY + 1)

  const rowPriceAdjFirstRow = posY

  rlt.push(['Rate & Price Adjustments', 'Purchase', 'No-Cashout', 'Cashout'])
  rlt.push([
    '',
    'Rate Adjustment',
    'Price Adjustment',
    'Rate Adjustment',
    'Price Adjustment',
    'Rate Adjustment',
    'Price Adjustment',
  ])
  posY += 2

  // Max Rate & Price Adjustment Row
  titles.push(`${posY}-0`)
  rlt.push([
    'Max Rate & Price Adjustment',
    convertNullValue(maxRatePriceAdjustment.purchase.rate),
    convertNullValue(maxRatePriceAdjustment.purchase.price),
    convertNullValue(maxRatePriceAdjustment.nocashout.rate),
    convertNullValue(maxRatePriceAdjustment.nocashout.price),
    convertNullValue(maxRatePriceAdjustment.cashout.rate),
    convertNullValue(maxRatePriceAdjustment.cashout.price),
  ])
  posY += 2
  rlt.push(['', '', '', '', '', '', ''])

  // Loan Purpose Adjustment Row
  titles.push(`${posY}-0`)
  rlt.push([
    'Loan Purpose',
    convertNullValue(loanPurposeAdjustment.purchase.rate),
    convertNullValue(loanPurposeAdjustment.purchase.price),
    convertNullValue(loanPurposeAdjustment.nocashout.rate),
    convertNullValue(loanPurposeAdjustment.nocashout.price),
    convertNullValue(loanPurposeAdjustment.cashout.rate),
    convertNullValue(loanPurposeAdjustment.cashout.price),
  ])
  posY += 2
  rlt.push(['', '', '', '', '', '', ''])

  // Title Row
  titleRows.push(posY)
  rlt.push(['Property Type', '', '', '', '', '', ''])
  posY++

  // Adjustment Rows
  propertyTypes.forEach((item, index) => {
    rlt.push([
      getPropertyTypeLabel(item.value, item.numberOfUnits),
      convertNullValue(propertyTypeAdjustment.purchase[index].rate),
      convertNullValue(propertyTypeAdjustment.purchase[index].price),
      convertNullValue(propertyTypeAdjustment.nocashout[index].rate),
      convertNullValue(propertyTypeAdjustment.nocashout[index].price),
      convertNullValue(propertyTypeAdjustment.cashout[index].rate),
      convertNullValue(propertyTypeAdjustment.cashout[index].price),
    ])

    rightAlignRows.push(posY)
    posY++
  })
  posY++
  rlt.push(['', '', '', '', '', '', ''])

  const ratePriceAdjArr = [
    {
      title: 'Loan Term',
      data: loanTerm,
      adjustment: loanTermAdjustment,
      isRange: true,
    },
    {
      title: 'Loan Amount',
      data: loanAmountRanges,
      adjustment: loanAmountAdjustment,
      isRange: true,
    },
    {
      title: 'Experience',
      data: experience,
      adjustment: experienceAdjustment,
      isRange: true,
    },
    {
      title: 'AIV-LTV',
      data: aivLtvs,
      adjustment: aivLtvAdjustment,
      isRange: true,
    },
    {
      title: 'LTC',
      data: ltcs,
      adjustment: ltcAdjustment,
      isRange: true,
    },
    {
      title: 'FICO',
      data: ficos,
      adjustment: ficoAdjustment,
      isRange: true,
    },
    {
      title: 'Months Reserve',
      data: monthsReserve,
      adjustment: monthsReserveAdjustment,
      isRange: true,
    },
    {
      title: 'Foreclosure',
      data: foreclosure,
      adjustment: foreclosureAdjustment,
      isRange: true,
    },
    {
      title: 'Bankruptcy',
      data: bankruptcy,
      adjustment: bankruptcyAdjustment,
      isRange: false,
      isBankruptcy: true,
    },
    {
      title: 'Short Sale',
      data: shortsale,
      adjustment: shortsaleAdjustment,
      isRange: true,
    },
    {
      title: 'Deed In Lieu',
      data: deedInLieu,
      adjustment: deedInLieuAdjustment,
      isRange: true,
    },
    {
      title: 'Mortgage Lates',
      data: mortgageLates,
      adjustment: mortgageLatesAdjustment,
      isRange: false,
      isMortgageLates: true,
    },
    {
      title: 'Forbearance / Loan Modification',
      data: forbearance,
      adjustment: forbearanceAdjustment,
      isRange: true,
    },
    {
      title: 'State',
      data: state,
      adjustment: stateAdjustment,
      isRange: false,
    },
    {
      title: 'Citizenship',
      data: citizenships,
      adjustment: citizenshipAdjustment,
      isRange: false,
    },
    {
      title: 'Rehab Budget Type',
      data: rehabBudgetTypes,
      adjustment: rehabBudgetTypesAdjustment,
      isRange: false,
    },
  ]

  ratePriceAdjArr.forEach((v) => {
    if (v.data) {
      // Title Row
      titleRows.push(posY)
      rlt.push([v.title, '', '', '', '', '', ''])
      posY++

      // Adjustment Rows
      v.data.forEach((item: any, index) => {
        rlt.push([
          v.title === 'FICO' && item == 'No Score'
            ? 'No Score'
            : v?.isMortgageLates
            ? getMortgageLatesLabel(item)
            : v?.isBankruptcy
            ? getBankruptcyLabel(item)
            : v.isRange
            ? convertRangeToString(item, v.title)
            : item?.label
            ? item.label
            : item,
          convertNullValue(v.adjustment.purchase[index].rate),
          convertNullValue(v.adjustment.purchase[index].price),
          convertNullValue(v.adjustment.nocashout[index].rate),
          convertNullValue(v.adjustment.nocashout[index].price),
          convertNullValue(v.adjustment.cashout[index].rate),
          convertNullValue(v.adjustment.cashout[index].price),
        ])

        rightAlignRows.push(posY)
        posY++
      })
      posY++
      rlt.push(['', '', '', '', '', '', ''])
    }
  })
  rlt.pop()

  // Draw Rate & Price
  rlt[0] = [...rlt[0], '', 'Base Rate', 'Base Price', 'Rate Spread', 'Exit Fee']

  baseRates.forEach((_, index: number) => {
    rlt[index + 1] = [
      ...rlt[index + 1],
      '',
      convertNullValue(baseRates[index]),
      convertNullValue(basePrices[index]),
      convertNullValue(rateSpreads[index]),
      convertNullValue(exitFees[index]),
    ]
  })

  let totalRows =
    ficos.length +
    monthsReserve.length +
    loanAmountRanges.length +
    experience.length +
    loanTerm.length +
    aivLtvs.length +
    ltcs.length +
    propertyTypes.length +
    citizenships.length +
    bankruptcy.length +
    foreclosure.length +
    deedInLieu.length +
    shortsale.length +
    mortgageLates.length +
    forbearance.length +
    state.length +
    44

  totalRows += rehabBudgetTypes ? rehabBudgetTypes.length + 2 : 0

  for (let i = baseRates.length + 1; i < totalRows; i++) {
    rlt[i] = [...rlt[i], '', '', '', '', '']
  }

  colSpan2Rows.forEach((y) => {
    colSpan2Cols.forEach((x) => {
      colSpan2.push(`${y}-${x}`)
    })
  })

  titleRows.forEach((y) => {
    if (colSpan2Rows.includes(y)) {
      titleColsSpan2.forEach((x) => {
        titles.push(`${y}-${x}`)
      })
    } else
      titleCols.forEach((x) => {
        titles.push(`${y}-${x}`)
      })
  })

  stackCols.forEach((x) => {
    titles.push(`0-${x}`)
  })

  rightAlignRows.forEach((y) => {
    rightAlignCells.push(`${y}-0`)
  })

  return {
    table: rlt,
    style: {
      titles: titles,
      rightAlignCells: rightAlignCells,
      colSpan2: colSpan2,
      rowPriceAdjFirstRow: rowPriceAdjFirstRow,
    },
  }
}

export const getCommercialDscrTableData = (data: ICommercialDscr) => {
  let rlt: any[] = []

  Object.keys(data)
    .sort()
    .map((key) => {
      const {
        loanAmount,
        minDscr,
        monthsReserve,
        ficos,
        purchaseLTVs,
        refinanceLTVs,
        purchaseRates,
        refinanceRates,
        purchasePrice,
        refinancePrice,
      } = data[key]

      let row = [key, 'No Income', `Price at ${purchasePrice}`, '', `Price at ${refinancePrice}`]
      rlt.push(row)

      row = [
        'Loan Amount',
        'Reserves',
        'Min DSCR',
        'FICO',
        'Purchase LTV',
        'Purchase Rates',
        'Refinance LTV',
        'Refinance Rates',
      ]
      rlt.push(row)

      ficos.map((fico: number, index: number) => {
        row = [
          '',
          '',
          '',
          '',
          convertNullValue(fico),
          convertNullValue(purchaseLTVs[index]),
          convertNullValue(purchaseRates[index]),
          convertNullValue(refinanceLTVs[index]),
          convertNullValue(refinanceRates[index]),
        ]

        if (index === 0) {
          row[0] = '$' + getPrice3decimal(loanAmount.from)
          row[1] = '$' + getPrice3decimal(loanAmount.to)
          row[2] =
            convertNullValue(monthsReserve) === 'N/A'
              ? convertNullValue(monthsReserve)
              : convertNullValue(monthsReserve) + 'mo'
          row[3] = convertNullValue(minDscr)
        }

        rlt.push(row)
      })

      rlt.push(['', '', '', '', '', '', '', '', ''])
    })

  rlt.splice(rlt.length - 1, 1)

  return rlt
}
