import { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    section1: {
      inputType: 'section',
      title: 'SS-89',
      span: 'full',
    },
    ss89CompanyName: {
      inputType: 'text',
      title: 'Company Name',
    },
    ss89CompanyAddr: {
      inputType: 'map',
      title: 'Company Address',
    },
    ss89AgentName: {
      inputType: 'text',
      title: 'Agent Name',
    },
    ss89AgentAddr: {
      inputType: 'map',
      title: 'Agent Address',
    },
    ss89ConsentDays: {
      inputType: 'text',
      type: 'number',
      title: 'Days consent is valid',
    },
  }
}
