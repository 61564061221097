import { ArrowDownIcon, ArrowUpIcon, EyeIcon, EyeSlashIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { accountTypes, accountTypeWithBorrower } from 'components/Modals/CreateUser/config'
import { InputType, IVisibleProp, loanCustomRangeOptionFields, loanCustomSelectOptionFields } from 'config'
import {
  FieldOrigin,
  ILoanFieldPos,
  isCustomOrigin,
  ISelectRangeOption,
  ISelectValueOption,
} from 'config/loan.input.visibility.type'
import { convertNullToBlank } from 'pages/Resources/GuidelineOverlay/logic'
import { useEffect, useMemo, useState } from 'react'
import { Input2, Modal } from 'stories/components'
import { InputConvert, InputValidate, isNull } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { hasRequiredProps, isInputTypeChangableFieldOrigins } from './inputs'
import { convertSelectRangeOptionsToMap, generateTitleFromRange } from './utils'

interface IVisiblePropertyModalProps {
  sectionOptions: Record<string, string> | null
  data: IVisibleProp
  field: ILoanFieldPos
  inputProps: InputType
  onClose: Function
}

const isVisibleAccountTypesFieldOrigins = (fieldOrigin: FieldOrigin) =>
  [
    FieldOrigin.LoanStructure,
    FieldOrigin.BorrowerInformation,
    FieldOrigin.PropertyInformation,
    FieldOrigin.DeclarationsHMDA,
  ].includes(fieldOrigin) || isCustomOrigin(fieldOrigin)

const defaultInputs = (): Record<string, InputType> => ({
  visibleAccountTypes: {
    inputType: 'multiselect',
    title: 'Visible by Account Type',
    options: {
      self_creator: '[Loan] Creator',
      ...accountTypeWithBorrower,
    },
    defaultSelected: true,
    value: accountTypes,
    sort: false,
  },
  section: {
    inputType: 'select',
    title: 'Section',
    required: false,
    hasDefaultOption: true,
    options: {},
  },
  title: {
    inputType: 'text',
    title: 'Title',
    required: false,
    value: true,
  },
  inputType: {
    inputType: 'Select',
    title: 'Input Component Type',
    options: {
      Text: 'Text',
      ToggleButton: 'Toggle Button',
    },
    required: true,
    hasDefaultOption: true,
    visible: false,
  },
  type: {
    inputType: 'select',
    title: 'Input Text Format',
    options: {
      thousandSep: 'Number with Thousand Separator',
      thousandSepNoDecimal: 'Number with Thousand Separator without Decimal',
      date: 'Date',
      time: 'Time',
      email: 'Email',
      phone: 'Phone',
      number: 'Number',
      password: 'Password',
      text: 'Text',
      ssn: 'SSN',
      creditScore: 'Credit Score',
      // 'entityTaxID': 'Tax ID', // Default is 'text'
      color: 'Color',
    },
    required: true,
    hasDefaultOption: true,
    visible: false,
  },
  tooltip: {
    inputType: 'textarea',
    title: 'Tooltip',
    rows: 3,
    required: false,
    prefix: '',
    value: '',
  },
  required: {
    inputType: 'ToggleButton',
    title: 'Required',
    required: false,
    value: true,
  },
  visibleLogic: {
    inputType: 'visibleLogic',
    title: '',
    required: false,
    value: [],
  },
})

export const VisiblePropertyModal = ({
  sectionOptions,
  data: _data,
  field,
  inputProps,
  onClose,
}: IVisiblePropertyModalProps) => {
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())
  const [data, setData] = useState(_data)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [showError, setShowError] = useState(false)
  const { fieldOrigin, fieldKey } = field

  const showCustomRangeInput = useMemo(() => loanCustomRangeOptionFields.includes(fieldKey), [fieldKey])
  const showCustomSelectInput = useMemo(() => loanCustomSelectOptionFields.includes(fieldKey), [fieldKey])
  const isBankruptcy = useMemo(() => fieldKey === 'bankruptcy', [fieldKey])
  const isMortgageLates = useMemo(() => fieldKey === 'mortgageLates', [fieldKey])

  const isShowType =
    isInputTypeChangableFieldOrigins(fieldOrigin) &&
    ['text', 'Text', 'ToggleButton', 'togglebutton', undefined].includes(inputProps.inputType)

  useEffect(() => {
    if (!data) return

    let newInputs = cloneDeep(inputs)
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].value = (data as any)[key]
    })
    newInputs.required.visible = hasRequiredProps(fieldOrigin)
    newInputs.required.value = data.required === undefined ? inputProps.required : data.required
    if (!data.title) newInputs.title.value = field.title

    if (isCustomOrigin(fieldOrigin) || fieldOrigin == FieldOrigin.DeclarationsHMDA) newInputs.title.required = true
    if (isShowType) {
      newInputs.inputType.value = data.inputType || inputProps.inputType
      newInputs.inputType.visible = true
      newInputs.type.value = data.type || inputProps.type
      newInputs.type.visible = true
    } else {
      newInputs.inputType.visible = false
      newInputs.type.visible = false
    }

    setInputs(newInputs)
  }, [])

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    let newData: any = cloneDeep(data) || {}
    Object.keys(inputs).map((key) => {
      const input = newInputs[key]
      if (input.visible === false) return

      newInputs[key].error = InputValidate({ ...input, value: input.value })
      if (newInputs[key].error?.length) hasError = true
      newData[key] = newInputs[key].value
    })
    setInputs(newInputs)

    const newErrors: Record<string, string> = {}
    if (
      showCustomRangeInput &&
      data.options &&
      Object.keys(convertSelectRangeOptionsToMap(data.options)).length != data.options.length
    ) {
      newErrors['customRangeSelectOptions'] = 'Duplicated option exists.'
      hasError = true
    }
    setErrors(newErrors)

    data.options?.map((item) => {
      if (!item.title) hasError = true
    })
    setShowError(true)

    if (hasError) return

    if (
      !isCustomOrigin(fieldOrigin) &&
      fieldOrigin != FieldOrigin.DeclarationsHMDA &&
      (newData.title == field.title || !newData.title)
    )
      delete newData.title
    if (newData.type != data.type) delete newData.defaultValue
    onClose(newData)
  }

  const renderCustomRangeSelectOptions = () => {
    if (!showCustomRangeInput && !showCustomSelectInput) return null

    const onAddOption = () => {
      let newData = cloneDeep(data)
      if (!newData.options) newData.options = []

      if (isMortgageLates) {
        let newFromValue = NaN
        let newToValue = NaN
        let newDays = NaN
        let newMonths = NaN
        if (newData.options.length) {
          const { from, to, days, months } = newData.options[newData.options.length - 1] as ISelectRangeOption
          if (!isNull(from)) newFromValue = Number(from) + 1
          if (!isNull(to)) newToValue = Number(to) + 1
          if (!isNull(days) && days !== undefined) newDays = days
          if (!isNull(months) && months !== undefined) newMonths = months
        }
        ;(newData.options as ISelectRangeOption[]).push({
          title: '',
          days: newDays,
          months: newMonths,
          from: newFromValue,
          to: newToValue,
        } as ISelectRangeOption)
      } else if (isBankruptcy) {
        let newFromValue = NaN
        let newChapter = NaN
        if (newData.options.length) {
          const { to, chapter } = newData.options[newData.options.length - 1] as ISelectRangeOption
          if (!isNull(to)) newFromValue = Number(to) + 1
          if (!isNull(chapter) && chapter !== undefined) newChapter = chapter
        }
        ;(newData.options as ISelectRangeOption[]).push({
          title: '',
          chapter: newChapter,
          from: newFromValue,
          to: NaN,
        } as ISelectRangeOption)
      } else if (showCustomRangeInput) {
        let newFromValue = NaN
        if (newData.options.length) {
          const { to } = newData.options[newData.options.length - 1] as ISelectRangeOption
          if (!isNull(to)) newFromValue = Number(to) + 1
        }
        ;(newData.options as ISelectRangeOption[]).push({
          title: '',
          from: newFromValue,
          to: NaN,
        } as ISelectRangeOption)
      } else
        (newData.options as ISelectValueOption[]).push({
          title: '',
          value: NaN,
        } as ISelectValueOption)

      const lastIndex = newData.options.length - 1
      newData.options[lastIndex].title = generateTitleFromRange(newData.options[lastIndex], fieldKey)

      setData(newData)
    }

    const onChangeControlOption = (
      idx: number,
      newValue:
        | { [P in keyof ISelectRangeOption]?: NonNullable<ISelectRangeOption[P]> }
        | { [P in keyof ISelectValueOption]?: NonNullable<ISelectValueOption[P]> }
        | null,
    ) => {
      const newData = cloneDeep(data)
      if (!newData.options) return

      if (!newValue) newData.options.splice(idx, 1)
      else
        newData.options[idx] = {
          ...newData.options[idx],
          ...newValue,
        }

      if (newValue?.title === undefined && newValue !== null && ![-1, 1].includes(newValue as any)) {
        newData.options[idx].title = generateTitleFromRange(newData.options[idx], fieldKey)
      }

      setData(newData)
    }

    const onChangeControlOptionOrder = (idx: number, dir: 1 | -1) => {
      const newData = cloneDeep(data)
      if (!newData.options) return

      const newIdx = idx + dir
      if (newIdx < 0 || newIdx >= newData.options.length) return
      else {
        let temp = newData.options[idx]
        newData.options[idx] = newData.options[newIdx]
        newData.options[newIdx] = temp
      }

      setData(newData)
    }

    const { options } = data

    return (
      <div className="grid gap-2">
        <div className="flex justify-between">
          <p className="font-bold text-sm">Custom Range Options</p>
          <span className="btn-icon" onClick={onAddOption}>
            <PlusIcon className="w-4 h-4" />
          </span>
        </div>
        {options &&
          options.map((opt, idx) => {
            const { title, chapter, from, to, days, months, visible, value } = opt as any
            const isVisible = !!visible || visible === undefined
            const Icon = isVisible ? EyeIcon : EyeSlashIcon

            return (
              <div
                className={`grid ${
                  isMortgageLates ? 'grid-cols-6' : isBankruptcy ? 'grid-cols-5' : 'grid-cols-4'
                } gap-2`}
              >
                <div className="col-span-2">
                  <Input2
                    title={`${idx + 1}. ${
                      isMortgageLates
                        ? `${convertNullToBlank(from)}-${convertNullToBlank(to)}-${convertNullToBlank(
                            days,
                          )}-${convertNullToBlank(months)}`
                        : isBankruptcy
                        ? `${convertNullToBlank(chapter)}-${convertNullToBlank(from)}-${convertNullToBlank(to)}`
                        : showCustomRangeInput
                        ? `${convertNullToBlank(from)}-${convertNullToBlank(to)}`
                        : `Title ${idx + 1}`
                    }`}
                    value={title}
                    key={`Option-${idx}`}
                    placeholder={title}
                    className={isVisible ? 'opacity-100' : 'opacity-30'}
                    onChange={(value: string) => onChangeControlOption(idx, { title: value })}
                    readOnly={true}
                    additionalElements={() => (
                      <div className="flex gap-2">
                        <Icon
                          className="w-4 h-4 cursor-pointer"
                          onClick={() => onChangeControlOption(idx, { visible: !isVisible })}
                        />
                        <TrashIcon
                          className="w-4 h-4 cursor-pointer text-red-500"
                          onClick={() => onChangeControlOption(idx, null)}
                        />
                        <ArrowUpIcon
                          className="w-4 h-4 cursor-pointer text-shade-blue"
                          onClick={() => onChangeControlOptionOrder(idx, -1)}
                        />
                        <ArrowDownIcon
                          className="w-4 h-4 cursor-pointer text-shade-blue"
                          onClick={() => onChangeControlOptionOrder(idx, 1)}
                        />
                      </div>
                    )}
                    required
                    error={showError && !title ? 'This field is required.' : ''}
                  />
                </div>
                {showCustomRangeInput ? (
                  <>
                    {isBankruptcy && (
                      <Input2
                        type="text"
                        title={`Chapter`}
                        value={convertNullToBlank(chapter)}
                        key={`Option-${idx}-chapter`}
                        placeholder={'Chapter'}
                        className={isVisible ? 'opacity-100' : 'opacity-30'}
                        onChange={(value: any) => {
                          if (value == '') value = NaN
                          else value = isNaN(Number(value)) ? NaN : Number(value)
                          onChangeControlOption(idx, { chapter: value })
                        }}
                      />
                    )}
                    <Input2
                      type="text"
                      title={`${isMortgageLates ? 'Times ' : ''}From`}
                      value={convertNullToBlank(from)}
                      key={`Option-${idx}-from`}
                      placeholder={'From'}
                      className={isVisible ? 'opacity-100' : 'opacity-30'}
                      onChange={(value: any) => {
                        if (value == '') value = NaN
                        else value = isNaN(Number(value)) ? NaN : Number(value)
                        onChangeControlOption(idx, { from: value })
                      }}
                    />
                    <Input2
                      type="text"
                      title={`${isMortgageLates ? 'Times ' : ''}To`}
                      value={convertNullToBlank(to)}
                      key={`Option-${idx}-to`}
                      placeholder={'To'}
                      className={isVisible ? 'opacity-100' : 'opacity-30'}
                      onChange={(value: any) => {
                        if (value == '') value = NaN
                        else value = isNaN(Number(value)) ? NaN : Number(value)
                        onChangeControlOption(idx, { to: value })
                      }}
                    />

                    {isMortgageLates && (
                      <>
                        <Input2
                          type="text"
                          title={`Late Days`}
                          value={convertNullToBlank(days)}
                          key={`Option-${idx}-days`}
                          placeholder={'Days'}
                          className={isVisible ? 'opacity-100' : 'opacity-30'}
                          onChange={(value: any) => {
                            if (value == '') value = NaN
                            else value = isNaN(Number(value)) ? NaN : Number(value)
                            onChangeControlOption(idx, { days: value })
                          }}
                        />
                        <Input2
                          type="text"
                          title={`Time Period`}
                          value={convertNullToBlank(months)}
                          key={`Option-${idx}-months`}
                          placeholder={'Months'}
                          className={isVisible ? 'opacity-100' : 'opacity-30'}
                          onChange={(value: any) => {
                            if (value == '') value = NaN
                            else value = isNaN(Number(value)) ? NaN : Number(value)
                            onChangeControlOption(idx, { months: value })
                          }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <div className="col-span-2">
                    <Input2
                      type="text"
                      title={`Value`}
                      value={convertNullToBlank(value)}
                      key={`Option-${idx}-to`}
                      placeholder={'Value'}
                      className={isVisible ? 'opacity-100' : 'opacity-30'}
                      onChange={(value: any) => {
                        if (value == '') value = NaN
                        else value = isNaN(Number(value)) ? NaN : Number(value)
                        onChangeControlOption(idx, { value: value })
                      }}
                    />
                  </div>
                )}
              </div>
            )
          })}
        {errors['customRangeSelectOptions'] && (
          <p className="text-sm text-rose-700">{errors['customRangeSelectOptions']}</p>
        )}
      </div>
    )
  }

  return (
    <Modal
      isOpen
      title={field.title || 'New Field'}
      titleClass={`${isMortgageLates ? 'w-[600px]' : isBankruptcy ? 'w-144' : 'w-120'}`}
      titleOkay="Submit"
      onClose={() => onClose()}
      onOk={onSubmit}
    >
      <div className={`${isMortgageLates ? 'w-[600px]' : isBankruptcy ? 'w-144' : 'w-120'}`}>
        {Object.keys(inputs).map((key, index) => {
          if (key == 'type') inputs[key].visible = inputs.inputType.value == 'Text'
          if (inputs[key].visible === false) return null
          if (key == 'visibleAccountTypes' && !isVisibleAccountTypesFieldOrigins(fieldOrigin)) return null
          if (key == 'section')
            if (!sectionOptions) return null
            else (inputs[key] as any).options = sectionOptions

          return (
            <div key={index} className="mb-2">
              <RenderInput input={inputs[key]} Key={key} onChange={onChange} />
            </div>
          )
        })}
        {renderCustomRangeSelectOptions()}
      </div>
    </Modal>
  )
}
