import { GlobalConfigType } from 'actions/global.config.type'
// import { borrowerDocCategory } from 'pages/Borrower/ManageBorrowers/constants'
import { store } from 'reducers'
import { getOptionValues } from 'utils/general'

export const actionConditionNos = {
  SSN: 1050,
  FLOOD_DETERMINATION: 1029,
}

export const exceptionStatus = {
  0: '',
  1: 'Approved By Lender',
  2: 'Approved By Investor',
  3: 'Denied By Lender',
  4: 'Denied By Investor',
  5: 'Waived - Not Required',
  6: 'Pending With Lender',
  7: 'Pending With Investor',
}

export const docStatus = [
  'Not Reviewed',
  'Approved',
  'Inactive',
  'Incomplete',
  'Missing Pages',
  'Unacceptable',
  'Duplicate',
  'Illegible',
  'Reviewed',
]

export const docCategory = () => {
  const { globalConfigReducer } = store.getState()
  return getOptionValues(globalConfigReducer[GlobalConfigType.DocumentCategory])
}
// [
//   '',
//   'Appraisal Docs',
//   'Assets',
//   'AUS',
//   'CD REQUEST',
//   'CLOSING PACKAGE',
//   'CLOSING REQUEST',
//   'Credit Report',
//   'Disclosures',
//   'Flood/IRS transcripts',
//   'GOV',
//   'HOI/HO6',
//   `ID's`,
//   'Income',
//   'INVOICES',
//   'JUNK',
//   'LE/CD - Compliance',
//   'LOCK',
//   `MISC / VOE's`,
//   'POST CLOSING CONDITIONS',
//   'Property Docs',
//   'TITLE Docs',
//   'TRAILING DOCS',
//   'UW MISC',
//   'UW PACKAGE',
//   'WIRE REQUEST',
//   ...borrowerDocCategory,
// ]

export const LoanProcessRushPaymentRequestTypeTitles: Record<string, string> = {
  entire: 'Rush File Review Request',
  appraisal: 'Transferred Appraisal Review Request',
  both: 'Rush Review and Transferred Appraisal Review Request',
  diligence: 'Commitment Fee',
  creditReport: 'Credit Report Fee',
  commitmentCreditReport: 'Commitment and Credit Report Fee',
  custom: '',
}
