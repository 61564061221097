import { GlobalConfigType, setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { FieldOrigin, LoanFieldProps } from 'config'
import { store } from 'reducers'
import { InputValidate } from 'utils'
import { removeInvisibleFields } from 'utils/loan'

import { stepApplicationKeys } from '../logic'
import { defaultInputs } from './constants'

const logicFields1 = [
  'informationProvidedVia',
  'sex',
  'sexCompletedBy',
  'ethnicity',
  'ethnicityCompletedBy',
  'race',
  'raceCompletedBy',
]

const logicFields2 = [
  'isHadOwnershipInterest',
  'isPurchaseTransaction',
  'isBorrowingMoney',
  'isMortageLoan',
  'isNewCredit',
  'isSubjectLien',
  'isCoSigner',
  'isPreForeclosure',
  'bankruptcyType',
]

export const isCustomInputKey = (key: string) => key == `${Number(key)}`

export const declarationsHmdaValidate = (
  borrowerSeperator: string,
  validateOnly = false,
  updateStore = false,
  loanNumber: number = -1,
  afterRemoved = false,
) => {
  const fields = defaultInputs()
  const { borrower, step, loanDetail, globalConfigReducer } = store.getState()
  const loanFieldProperties = globalConfigReducer[GlobalConfigType.LoanFields] as LoanFieldProps
  if (((loanFieldProperties as any)['visibility'] || {})[FieldOrigin.DeclarationsHMDA] === false) {
    if (validateOnly) return true
    else return {}
  }
  const fieldProps = loanFieldProperties[FieldOrigin.DeclarationsHMDA]

  const data = borrower[borrowerSeperator]
  if (!data) {
    if (validateOnly) {
      let success: any = false
      return success
    }
    return fields
  }
  if (loanNumber === -1) loanNumber = loanDetail.loanNumber

  const getValue = (key: string) => {
    if (borrower[borrowerSeperator][key] !== undefined) return borrower[borrowerSeperator][key]
    else return ''
  }

  const isPrimaryResidence = getValue('isPrimaryResidence')
  const isHadOwnershipInterest = getValue('isHadOwnershipInterest')
  fields.isHadOwnershipInterest.required = isPrimaryResidence
  fields.isHadOwnershipInterest.disabled = !isPrimaryResidence
  ;['propertyOwn', 'holdTitle'].forEach((key) => {
    fields[key].required = isPrimaryResidence || isHadOwnershipInterest
    fields[key].disabled = !isPrimaryResidence || !isHadOwnershipInterest
  })

  const isBorrowingMoney = getValue('isBorrowingMoney')
  fields.borrowingMoneyAmount.required = isBorrowingMoney
  fields.borrowingMoneyAmount.disabled = !isBorrowingMoney

  const isBankrupt = getValue('isBankrupt')
  fields.bankruptcyType.required = isBankrupt
  fields.bankruptcyType.disabled = !isBankrupt

  const required = data.isBorrower === 'Yes'
  logicFields1.forEach((key: string) => (fields[key].required = required))

  const disabled = data.isBorrower === 'No'
  logicFields1.forEach((key: string) => (fields[key].disabled = disabled))

  let success = true

  let isPrevLoan = true
  if (loanNumber >= 1431 && loanNumber < 3000000) isPrevLoan = false
  if (isPrevLoan) {
    logicFields2.map((key) => {
      fields[key].required = false
    })
  }

  const visibleFields = removeInvisibleFields(FieldOrigin.DeclarationsHMDA, Object.keys(fields))

  Object.keys(fields).map((key) => {
    if (!visibleFields.includes(key)) {
      delete fields[key]
      return
    }
    if (fieldProps && fieldProps[key] && fieldProps[key].visible === false) return
    let value = borrower[borrowerSeperator][key]
    if (isCustomInputKey(key))
      value = borrower[borrowerSeperator].customInformation?.[FieldOrigin.DeclarationsHMDA]?.[key]

    if (fieldProps[key]?.required !== undefined) fields[key].required = fieldProps[key].required
    const error = InputValidate({ ...fields[key], value })
    fields[key].error = error
    if (error.length) success = false
    if (fieldProps && fieldProps[key] && fieldProps[key].tooltip) fields[key].tooltip = fieldProps[key].tooltip
  })

  if (afterRemoved) success = false
  if (updateStore) {
    let application = cloneDeep(step.application)
    const key = stepApplicationKeys[borrowerSeperator].hmda
    application[key] = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }

  if (validateOnly) return success

  return fields
}
