import { capitalizeFirstLetter } from 'utils'

export const COMPANY_ID = process.env.REACT_APP_COMPANY_ID
export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME as string
export const COMPANY_NAME_C = `${COMPANY_NAME}`.toUpperCase()
export const COMPANY_NAME_FC = capitalizeFirstLetter(COMPANY_NAME)

export const COMPANY_TITLE = process.env.REACT_APP_COMPANY_TITLE as string

export const COMPANY_DNS = process.env.REACT_APP_COMPANY_DNS as string
export const COMPANY_WEBSITE_URL = COMPANY_DNS

export const COMPANY_LOGO = process.env.REACT_APP_COMPANY_LOGO

export const COMPANY_ICON = process.env.REACT_APP_SITE_ICON

export const COMPANY_LANDING_URL = (process.env.REACT_APP_API_URL || '')
  .replace('api.commercial.', '')
  .replace('-', '.')

export const IS_MAIN_COMPANY = COMPANY_ID == 'nextres'
export const IS_FINRESI_SYNC = COMPANY_ID == 'nextres'
