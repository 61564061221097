import { CheckIcon } from '@heroicons/react/24/outline'

export const PageNavBar = ({
  titles,
  activeIndex = 0,
  maxActiveIndex = 0,
  onMove = null,
  activeIndexs = [],
  isAllClickable = false,
}: {
  titles: string[]
  activeIndex?: number
  maxActiveIndex?: number
  onMove?: Function | null
  activeIndexs?: number[]
  isAllClickable?: boolean
}) => {
  const length = titles.length

  return (
    <div className="sm:flex relative sm:mb-4">
      {titles.map((v, index) => (
        <div key={index} className="relative h-12 flex-1">
          {index != length - 1 && <div className="block sm:hidden bg-gray-800/50 w-0.5 h-full ml-8 rounded-full" />}

          <div className="hidden sm:flex items-center">
            <div
              className={`block ${
                index == 0 ? 'bg-white' : 'bg-gray-800/50'
              } w-0.5 h-full ml-[7px] sm:m-0 sm:h-0.5 sm:w-[50%] rounded-full`}
            />
            <div
              className={`block ${
                index == length - 1 ? 'bg-white' : 'bg-gray-800/50'
              } w-0.5 h-full ml-[7px] sm:m-0 sm:h-0.5 sm:w-[50%] rounded-full`}
            />
          </div>

          <div
            className={`w-8 h-8 ring-2 ${
              index < activeIndex || activeIndexs.includes(index)
                ? 'bg-shade-blue ring-shade-blue'
                : index == activeIndex
                ? 'bg-white ring-shade-blue'
                : 'bg-gray-400 ring-gray-400'
            } rounded-full absolute -top-2 left-8 sm:left-[50%] -ml-4 sm:relative sm:-mt-[8px] sm:mb-2 ${
              (isAllClickable || (index <= maxActiveIndex && index != activeIndex)) && onMove
                ? 'hover:bg-blue-600 cursor-pointer'
                : ''
            }`}
            onClick={() => {
              if (!isAllClickable && (index > maxActiveIndex || index == activeIndex)) return
              onMove && onMove(index)
            }}
          >
            {index < maxActiveIndex ? (
              <p className={`text-center p-1.5`}>
                <CheckIcon className={`w-5 h-5 ${index == activeIndex ? 'text-shade-blue' : 'text-white'}`} />
              </p>
            ) : (
              <p className={`text-center ${index == activeIndex ? 'text-shade-blue' : 'text-white'} pt-1`}>
                {index + 1}
              </p>
            )}
          </div>

          <p
            className={`text-xs ${
              index == activeIndex ? 'text-shade-blue' : 'text-gray-400'
            } absolute top-0 ml-14 sm:relative sm:m-0 text-center`}
          >
            {v}
          </p>
        </div>
      ))}
    </div>
  )
}
