import {
  ArrowDownCircleIcon,
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUpTrayIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  EnvelopeIcon,
  EyeIcon,
  InboxArrowDownIcon,
  LockClosedIcon,
  PencilSquareIcon,
  PrinterIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { PlainInput2 } from 'components/PlainInput/PlainInput2'
import { SaveChanges } from 'components/SaveChanges'
import { AccountType, appApiUrl, InputType } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import type { IDrawRequest } from 'pages/Borrower'
import { ScrollTop } from 'pages/LoanSubmission/ScrollTop'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { store } from 'reducers'
import {
  borrowerConfirmBudget,
  createBudgetTemplateList,
  deleteBudgetTemplate,
  downloadBudgetQA,
  getBudgetTemplate,
  getBudgetTemplateList,
  loanOverviewSection,
  openS3Document,
  parseBudgetTemplateExcel,
  sendBudgetToBorrower,
  updateBudgetTemplate,
  updateBudgetTemplateItem,
} from 'services'
import { Button, ButtonGroup, Checkbox, Modal, Select, TextArea } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { confirm, formatTime, isEmpty, prompt, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { BorroserEmailDialog } from './BorroserEmailDialog'
import { defaultInputs, plainTextCostTypeItems } from './constants'
import { DownloadTypeDialog } from './DownloadTypeDialog'
import { DrawRequestsDialog } from './DrawRequestsDialog'
import {
  calcBudgetValues,
  convertContent,
  convertContent2,
  convertPercent,
  convertPercentContent,
  convertValue,
} from './logic'
import type { IBudgetData, IBudgetItem, IBudgetItemData, IBudgetItemValues } from './types'

export * from './BorrowerEditBudget'

const BudgetScopeOfWork = ({
  isBorrower = false,
  noOverview = false,
}: {
  isBorrower?: boolean
  noOverview?: boolean
}) => {
  const [loading, setLoading] = useState(true)
  const [addressMap, setAddressMap] = useState<Record<string, string>>({})
  const [currentId, setCurrentId] = useState(0)
  const [addable, setAddable] = useState(true)

  const auth = useSelector((state: any) => state.auth)
  const formCount = useMemo(() => Object.keys(addressMap).length, [addressMap])

  const isAddressSelectable = !isBorrower || !auth.profile.budgetId

  useEffect(() => {
    if (isBorrower && auth.profile.budgetId) {
      setCurrentId(auth.profile.budgetId)
      setLoading(false)
    } else fetchList()
  }, [])

  const fetchList = () => {
    setLoading(true)

    getBudgetTemplateList()
      .then(async ({ addresses, addable }: { addresses: Record<string, string>; addable: boolean }) => {
        setAddressMap(addresses)
        let ids = Object.keys(addresses)
        if (ids.length == 0) {
          ;({ addresses, addable } = await createBudgetTemplateList())
          ids = Object.keys(addresses)
        }

        setCurrentId(Number(ids[0]))
        setAddable(addable)
      })
      .finally(() => setLoading(false))
  }

  const onCreateForm = () => {
    setLoading(true)

    createBudgetTemplateList()
      .then(({ addresses, addable }: { addresses: Record<string, string>; addable: boolean }) => {
        setAddressMap(addresses)
        const ids = Object.keys(addresses)
        setCurrentId(Number(ids[ids.length - 1]))
        setAddable(addable)
      })
      .finally(() => setLoading(false))
  }

  const onDeleteForm = async () => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure to remove this form?
        <br />
        <span className="text-gray-600">Address: {addressMap[currentId]}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    await deleteBudgetTemplate(currentId)
    setCurrentId(0)
    await fetchList()
  }

  return (
    <div className="px-2 my-6">
      {!noOverview && <Overview title="Budget & Scope of Work" hasBackButton={!isBorrower} />}

      <div className="relative max-w-screen-2xl shadow1 rounded m-auto px-4 py-6">
        <LayoutLoading show={loading} />

        {isAddressSelectable && (
          <div className="flex items-center justify-between mb-4 flex-4">
            {!!formCount && <ButtonGroup title={addressMap} value={`${currentId}`} onChange={(v) => setCurrentId(v)} />}
            {!isBorrower && !!formCount && (
              <div className="flex items-center flex-4">
                <Button onClick={onCreateForm} disabled={!addable}>
                  Add
                </Button>
                <Button color="red" disabled={formCount == 1} onClick={onDeleteForm}>
                  Delete
                </Button>
              </div>
            )}
          </div>
        )}

        <BudgetScopeOfWorkItem id={currentId} isBorrower={isBorrower} />
      </div>
      <ScrollTop />
    </div>
  )
}

const BudgetScopeOfWorkItem = ({ id, isBorrower = false }: { id: number; isBorrower?: boolean }) => {
  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  const [loanStatus, setLoanStatus] = useState<string>('')
  const [servicingStatus, setServicingStatus] = useState<string>('')
  const [isDownloadTypeDialog, showDownloadTypeDialog] = useState(false)

  const servicingPipeline = !!servicingStatus
  const isFunded = ['funded'].includes(loanStatus) || servicingPipeline

  const alwaysEditable = [
    AccountType.ADMIN,
    AccountType.UW_MANAGER,
    AccountType.UNDERWRITER,
    AccountType.LOCK_DESK,
    AccountType.DRAW_SPECIALIST,
    AccountType.FEASIBILITY_ANALYST,
  ].includes(auth.profile.accountType)

  const fileInputRef = useRef<HTMLInputElement>(null)
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [submitModal, setSubmitModal] = useState(false)
  const [histories, setHistories] = useState<any>([])
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [budget, setBudget] = useState<IBudgetData>()
  const [total, setTotal] = useState<IBudgetItemData>({})
  const [loading, setLoading] = useState(false)
  const [isEditing, setEditing] = useState('')
  const [budgetItemMapping, setBudgetItemMapping] = useState<Record<string, string>>({})
  const [drawRequests, setDrawRequests] = useState<IDrawRequest[]>([])
  const [showDrawRequests, setShowDrawRequests] = useState<{
    value: string
    title: string
    requests: IDrawRequest[]
  } | null>(null)
  const [rehabFinancedPercent, setRehabFinancedPercent] = useState(1)
  const [isBorrowerEmailDialog, showBorrowerEmailDialog] = useState(false)
  const [submitForm, setSubmitForm] = useState(false)

  const stateData = store.getState()
  const urlParams: any = useParams()
  const { loanNumber } = urlParams

  const { hasPermission } = usePermissions()

  const brokerProfile = useMemo(() => {
    return !hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION') || isBorrower
  }, [isBorrower])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        init()
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const onDownload = () => {
    showDownloadTypeDialog(true)
  }

  const init = async () => {
    if (id == 0) return

    let newInputs = cloneDeep(defaultInputs())

    setLoading(true)
    const res = await getBudgetTemplate(id)
    setLoading(false)

    const newBudget = calcBudgetValues(res.budget.data) as Record<string, any>
    Object.keys(newInputs).forEach((key) => {
      if (newBudget[key] !== undefined) newInputs[key].value = String(newBudget[key])
    })

    onUpdateTotal(newBudget as IBudgetData)

    setBudgetItemMapping(res.itemMapping)
    setDrawRequests(res.drawRequests)
    setBudget(newBudget as IBudgetData)
    setHistories(res.budget.histories)
    setRehabFinancedPercent(res.rehabFinancedPercent)
    setInputs(newInputs)

    let allLocked = true
    newBudget.items.map((item: any) => {
      Object.keys(item.data).map((key) => {
        const data = item.data[key]
        if (data.lockData !== true) {
          allLocked = false
        }
      })
    })
    let lastAction = ''
    res.budget.histories?.map((history: any) => {
      lastAction = history.action
    })
    setSubmitForm(!allLocked || lastAction !== 'Lock All Items')
  }

  useEffect(() => {
    loanOverviewSection().then((data) => {
      setLoanStatus(data.loanStatus)
      setServicingStatus(data.servicingStatus)
    })
  }, [])

  useEffect(() => {
    init()
  }, [id])

  const onUpdateTotal = (data: IBudgetData | undefined = budget) => {
    const newTotal = cloneDeep(total)

    newTotal['total'] = {
      rehabCostAlreadyPrepaid: 0,
      rehabCostBorrowerEstimated: 0,
      rehabCostLenderApproved: 0,
      lenderDisbursedAmount: 0,
      borrowerResponsibility: 0,
      costType: '',
      description: '',
    }

    data?.items.forEach((item) => {
      const newTotalValue: IBudgetItemValues = {
        rehabCostAlreadyPrepaid: 0,
        rehabCostBorrowerEstimated: 0,
        rehabCostLenderApproved: 0,
        lenderDisbursedAmount: 0,
        borrowerResponsibility: 0,
        costType: '',
        description: '',
      }

      Object.keys(item.data).forEach((key) => {
        const itemValues = item.data[key]

        newTotalValue.rehabCostBorrowerEstimated += isNaN(itemValues.rehabCostBorrowerEstimated)
          ? 0
          : Number(itemValues.rehabCostBorrowerEstimated)

        if (item.value === 'miscellaneous' && plainTextCostTypeItems.includes(key)) {
        } else {
          newTotalValue.rehabCostAlreadyPrepaid += isNaN(itemValues.rehabCostAlreadyPrepaid)
            ? 0
            : Number(itemValues.rehabCostAlreadyPrepaid)
        }

        newTotalValue.rehabCostLenderApproved += isNaN(itemValues.rehabCostLenderApproved)
          ? 0
          : Number(itemValues.rehabCostLenderApproved)
        newTotalValue.borrowerResponsibility += Number(itemValues.borrowerResponsibility)
        newTotalValue.lenderDisbursedAmount += Number(itemValues.lenderDisbursedAmount || 0)
      })

      newTotal['total'].rehabCostBorrowerEstimated += Number(newTotalValue.rehabCostBorrowerEstimated)
      newTotal['total'].rehabCostAlreadyPrepaid += Number(newTotalValue.rehabCostAlreadyPrepaid)
      newTotal['total'].rehabCostLenderApproved += Number(newTotalValue.rehabCostLenderApproved)
      newTotal['total'].borrowerResponsibility += Number(newTotalValue.borrowerResponsibility)
      newTotal['total'].lenderDisbursedAmount += Number(newTotalValue.lenderDisbursedAmount)

      newTotal[item.value] = newTotalValue
    })

    setTotal(newTotal)

    return newTotal
  }

  const onChangeItemValues = async (
    value: string,
    group: string,
    label: string,
    key: string,
    data?: IBudgetItemValues,
  ) => {
    if (!budget) return
    const nBudget = cloneDeep(budget)
    let newInputs = cloneDeep(inputs)

    setEditing('')

    try {
      let needSave = false

      const index = nBudget?.items.findIndex((item) => item.value === group)
      if (index === -1) return

      const item = nBudget.items[index]
      const itemData = item.data[label]
      switch (key) {
        case 'rehabCostBorrowerEstimated':
        case 'rehabCostLenderApproved':
        case 'rehabCostAlreadyPrepaid':
        case 'borrowerResponsibility':
        case 'lenderDisbursedAmount':
          if (key == 'lenderDisbursedAmount' && data) {
            const maxValue = data.rehabCostLenderApproved * rehabFinancedPercent
            if (Number(value) > maxValue) {
              value = String(data.rehabCostLenderApproved * rehabFinancedPercent)
              confirm(
                <p className="text-sm">
                  The amount entered exceeds the maximum pro rated amount financed. Based on the amount of the budget
                  financed being <span className="font-semibold">${rehabFinancedPercent * 100}%</span>, the maximum
                  lender disbursed amount should not exceed{' '}
                  <span className="font-semibold">{convertContent(maxValue)}</span>. Please confirm you wish to proceed
                  with <span className="font-semibold">{convertContent(value)}</span> for this field.
                </p>,
                {
                  titleYes: false,
                  titleNo: 'Ok',
                },
              )
            }
          }
          if (itemData[key] !== ((isEmpty(value) ? null : Number(value)) as number)) {
            needSave = true
            itemData[key] = (isEmpty(value) ? null : Number(value)) as number
            if (
              key == 'rehabCostLenderApproved' &&
              itemData.rehabCostLenderApproved == itemData.rehabCostBorrowerEstimated &&
              !itemData.lockData
            )
              itemData.lockData = true
          }
          break
        case 'costType':
          if (itemData.costType !== value) {
            needSave = true
            itemData.costType = value
          }
          break
        case 'title':
        case 'description':
          if (itemData[key] !== value) {
            needSave = true
            itemData[key] = value
          }
          break
        default:
          return
      }
      if (!needSave) return

      let newTotal = onUpdateTotal(nBudget)

      const newBudget = calcBudgetValues(nBudget as IBudgetData) as Record<string, any>
      newTotal = onUpdateTotal(newBudget as IBudgetData)

      updateBudgetData('item', group, label, key, (itemData as Record<string, any>)[key])

      Object.keys(newInputs).forEach((key) => {
        newInputs[key].value = String(newBudget[key])
      })

      setTotal(newTotal)
      setBudget(newBudget as IBudgetData)
      setInputs(newInputs)
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeDescription = (value: string, group: string, label: string) => {
    const nBudget = cloneDeep(budget)

    setEditing('')

    nBudget?.items.forEach((item) => {
      if (item.value === group) {
        if (item.data[label].description !== value) item.data[label].description = value
      }
    })

    setBudget(nBudget)
  }

  const onChangeNote = (value: string, group: string, label: string) => {
    const nBudget = cloneDeep(budget)

    nBudget?.items.forEach((item) => {
      if (item.value === group) {
        if (item.data[label].note !== value) item.data[label].note = value
      }
    })

    setBudget(nBudget)
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    const nBudget = cloneDeep(budget) as Record<string, any>

    if (['constructionManagementFee'].includes(key)) nBudget[key] = isEmpty(value) ? null : removeComma(value)
    else nBudget[key] = value

    const newBudget = calcBudgetValues(nBudget as IBudgetData) as Record<string, any>
    const newTotal = onUpdateTotal(newBudget as IBudgetData)

    Object.keys(newInputs).forEach((key) => {
      newInputs[key].value = String(newBudget[key])
    })

    setTotal(newTotal)
    setBudget(newBudget as IBudgetData)
    setInputs(newInputs)
  }

  const onBlur = async (key: string) => {
    await updateBudgetData('input', '', '', key, (budget as any)[key])

    if (key == 'BathroomCount') init()
  }

  const onBlurItem = (group: string, label: string, key: string) => {
    if (!budget) return

    const index = budget.items.findIndex((item) => item.value === group)
    if (index === -1) return

    const item = budget.items[index]

    updateBudgetData('item', group, label, key, (item.data[label] as Record<string, any>)[key])
  }

  const editInternalDesc = async (group: string, intDes: string = '', key: string) => {
    const value: any = await prompt(budgetItemMapping[key] + ' Internal Note', {
      value: intDes,
    })
    if (value === false) return
    if (value === intDes) return

    const nBudget: any = cloneDeep(budget)
    const index = nBudget.items.findIndex((item: any) => item.value === group)
    const item = nBudget.items[index]
    item.data[key].intDes = value
    setBudget(nBudget as IBudgetData)
    updateBudgetData('item', group, key, 'intDes', value)
  }

  const editNarrativeNote = async () => {
    const value: any = await prompt('Narrative Note', {
      value: budget?.narrativeNote,
    })
    if (value === false) return
    if (value === budget?.narrativeNote) return
    const nBudget = cloneDeep(budget) as Record<string, any>
    nBudget.narrativeNote = value
    setBudget(nBudget as IBudgetData)
    updateBudgetData('input', '', '', 'narrativeNote', value)
  }

  const onSendToBorrower = async () => {
    let requests: any = []
    budget?.items.map((item) => {
      Object.keys(item.data)
        .sort()
        .map((key) => {
          const { highlight, note } = item.data[key]
          if (highlight) {
            requests.push({
              title: budgetItemMapping[key],
              note: note,
            })
          }
        })
    })

    const content = (
      <div className="mb-4 text-left">
        <div
          className="bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded relative mb-4 text-[14px]"
          role="alert"
        >
          <div className="mb-2 font-semibold">The borrower will have to update the items that have been checked:</div>
          {requests.map((request: any, index: number) => {
            return (
              <div className="md:col-span-1" key={index}>
                <div>- {request.title}</div>
                {request.note && <div className="ml-3 border border-gray-200 p-2 italic mb-4">{request.note}</div>}
              </div>
            )
          })}
        </div>
      </div>
    )

    if (requests.length > 0) {
      const result = await confirm(content, { titleYes: 'Continue' })
      if (!result) return
    }

    showBorrowerEmailDialog(true)
  }

  const onBorrowerEmailDialogClose = async (result: boolean, emails: Record<string, string>, note: string = '') => {
    if (!result) {
      showBorrowerEmailDialog(false)
      return
    }
    await sendBudgetToBorrower(id, emails, note)
    showBorrowerEmailDialog(false)
  }

  const errors = useMemo(() => {
    if (!inputs.narrative) return []

    let rlt: any = []
    budget?.items.map((item) => {
      Object.keys(item.data)
        .sort()
        .map((key) => {
          const data = item.data[key]
          const costBorrowerEstimatedError = data.highlight && removeComma(data.rehabCostBorrowerEstimated) === 0
          const descriptionError =
            removeComma(data.rehabCostBorrowerEstimated) > 0 && String(data.description).trim().length === 0

          if (costBorrowerEstimatedError || descriptionError) {
            rlt.push({ title: budgetItemMapping[key], note: data.note })
          }
        })
    })
    if (inputs.narrative.value?.trim().length > 0) {
    } else {
      rlt.push({
        title:
          'Please provide a narrative of what is transpiring in the project. Include details such as what exact work is being performed inside and outside of the structure. Is square footage being added? Will room count change? Etc.',
      })
    }
    return rlt
  }, [budget?.items, inputs.narrative?.value])

  const isAllLockData = useMemo(() => {
    if (!budget?.items) return false
    const hasUnlockData = !!budget?.items.filter((item) => {
      return !!Object.keys(item.data).filter((itemKey) => {
        return !item.data[itemKey].lockData
      }).length
    }).length
    return !hasUnlockData
  }, [budget?.items])

  const onConfirmAndSubmit = async () => {
    setLoading(true)

    await borrowerConfirmBudget(id)

    const nBudget = cloneDeep(budget)
    nBudget?.items.forEach((item) => {
      Object.keys(item.data).forEach((itemKey) => {
        item.data[itemKey]['lockData'] = true
      })
    })
    setBudget(nBudget)

    setLoading(false)

    setSubmitModal(false)

    setSubmitForm(false)
  }

  const onChangeCheckBoxAllItem = (objKey: 'lockData', value: boolean) => {
    setEditing('')
    const data = {
      type: 'itemAll',
      group: '',
      key: '',
      objKey,
      value,
      isBorrower,
    }

    const nBudget = cloneDeep(budget)
    nBudget?.items.forEach((item) => {
      Object.keys(item.data).forEach((itemKey) => {
        item.data[itemKey][objKey] = value
      })
    })
    setBudget(nBudget)

    setLoading(true)

    updateBudgetTemplateItem(id, data)
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }

  const onChangeCheckBoxItem = async (group: string, itemKey: string, objKey: 'highlight' | 'lockData') => {
    if (!budget) return
    const nBudget = cloneDeep(budget)

    const index = nBudget.items.findIndex((item) => item.value === group)
    if (index === -1) return

    const item = nBudget.items[index]
    if (item.data[itemKey]) item.data[itemKey][objKey] = !item.data[itemKey][objKey]
    if (objKey === 'highlight' && item.data[itemKey][objKey]) {
      item.data[itemKey]['lockData'] = false
    }
    setBudget(nBudget)
    updateBudgetData('item', group, itemKey, objKey, item.data[itemKey][objKey])
  }

  const updateBudgetData = (type: 'input' | 'item', group: string, key: string, objKey: string, value: any) => {
    setEditing('')
    const data = {
      type,
      group,
      key,
      objKey,
      value,
      isBorrower,
    }

    return new Promise((resolve) => {
      setLoading(true)
      updateBudgetTemplateItem(id, data)
        .then(() => resolve(true))
        .catch((error) => console.log(error))
        .finally(() => setLoading(false))
    })
  }

  const importExcel = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0]

    setLoading(true)
    const json = { file }
    const jsonData: string[][] = await parseBudgetTemplateExcel(json)

    if (!budget) {
      setLoading(false)
      return
    }

    try {
      const newBudget = cloneDeep(budget)

      const titleRow = jsonData.find((item) => item[0] === 'No')
      if (!titleRow) {
        setLoading(false)
        return
      }
      const titleIndex = titleRow.findIndex((item) => item === 'Description')
      const borrowerEstimatedIndex = titleRow.findIndex(
        (item) => item?.replaceAll('\r\n', ' ') === 'Rehab Cost Borrower Estimated',
      )
      const lenderApprovedIndex = titleRow.findIndex(
        (item) => item?.replaceAll('\r\n', ' ') === 'Rehab Cost Lender Approved',
      )
      const alreadyPrepaidByBorrowerIndex = titleRow.findIndex(
        (item) => item?.replaceAll('\r\n', ' ') === 'Rehab Cost Already Prepaid By Borrower',
      )
      const costTypeIndex = titleRow.findIndex((item) => item?.replaceAll('\r\n', ' ') === 'Cost Type')
      const descriptionIndex = titleRow.findIndex((item) => item?.replaceAll('\r\n', ' ') === 'Work to Be Performed')

      if (
        borrowerEstimatedIndex === -1 ||
        alreadyPrepaidByBorrowerIndex === -1 ||
        costTypeIndex === -1 ||
        descriptionIndex === -1
      ) {
        let unknowColumn = ''
        if (borrowerEstimatedIndex === -1) unknowColumn = 'Rehab Cost Borrower Estimated'
        else if (alreadyPrepaidByBorrowerIndex === -1) unknowColumn = 'Rehab Cost Already Prepaid By Borrower'
        else if (costTypeIndex === -1) unknowColumn = 'Cost Type'
        else if (descriptionIndex === -1) unknowColumn = 'Work to Be Performed'
        toast(`Excel template doesn't have '${unknowColumn}' coulumn`, { type: 'error' })
        setLoading(false)
        if (fileInputRef.current) fileInputRef.current.value = ''
        return
      }

      if (isFunded && lenderApprovedIndex === -1) {
        toast("Excel template doesn't have 'Rehab Cost Lender Approved' coulumn", { type: 'error' })
        setLoading(false)
        if (fileInputRef.current) fileInputRef.current.value = ''
        return
      }

      let addedBathMore = false
      let isBath = false
      let targetIndex = -1
      let totalImportableRows = 0
      let totalUnknownRows = 0
      let unknownRows: Array<Array<any>> = []

      jsonData.forEach((item) => {
        if (!item.length) return
        if (item[0] === null || item[0] === '') {
          targetIndex = newBudget.items.findIndex((d) => d.label.trim() === item[titleIndex])

          if (item[titleIndex]?.toLowerCase().includes('bath')) isBath = true
          else isBath = false

          // Add Bath section
          if (isBath && targetIndex === -1) {
            addedBathMore = true
            const insertIndex = newBudget.items.findIndex((d) => d.value === `bath${newBudget.BathroomCount}`) + 1

            const newBudgetItem: IBudgetItem = {
              label: `BATH ${Number(newBudget.BathroomCount) + 1}`,
              value: `bath${Number(newBudget.BathroomCount) + 1}`,
              data: {},
            }
            newBudget.BathroomCount = `${Number(newBudget.BathroomCount) + 1}`
            newBudget.items.splice(insertIndex, 0, newBudgetItem)
            targetIndex = insertIndex
          }
        }
        if (typeof item[0] === 'number' && targetIndex !== -1) {
          const title = item[titleIndex]
          let key =
            Object.keys(budgetItemMapping)[Object.values(budgetItemMapping).findIndex((v) => v.trim() === title.trim())]

          // Show incorrect titles on modal after imported
          if (!key) {
            unknownRows.push(item)
            totalUnknownRows++
            console.log('Skipped the unknown title row:', title)
            return
          }

          if (isBath && key.toLowerCase().includes('kitchen')) {
            let idx = -1
            Object.values(budgetItemMapping).forEach((k, id) => {
              if (k === title) idx = id
            })

            if (idx !== -1) key = Object.keys(budgetItemMapping)[idx]
          }

          if (!key) {
            unknownRows.push(item)
            totalUnknownRows++
            console.log('Skipped the unknown title row:', title)
            return
          }

          totalImportableRows++

          if (!newBudget.items[targetIndex].data[key]) {
            newBudget.items[targetIndex].data[key] = {
              borrowerResponsibility: NaN,
              costType: '',
              description: '',
              highlight: false,
              lockData: false,
              rehabCostAlreadyPrepaid: NaN,
              rehabCostBorrowerEstimated: NaN,
              rehabCostLenderApproved: NaN,
              lenderDisbursedAmount: NaN,
            }
          }

          if (!brokerProfile || !newBudget.items[targetIndex].data[key].lockData) {
            if (!brokerProfile && isFunded)
              newBudget.items[targetIndex].data[key].rehabCostLenderApproved = item[lenderApprovedIndex]
                ? removeComma(item[lenderApprovedIndex])
                : NaN

            if (!isBorrower) newBudget.items[targetIndex].data[key].costType = item[costTypeIndex]

            newBudget.items[targetIndex].data[key].rehabCostBorrowerEstimated = item[borrowerEstimatedIndex]
              ? removeComma(item[borrowerEstimatedIndex])
              : NaN
            newBudget.items[targetIndex].data[key].rehabCostAlreadyPrepaid = item[alreadyPrepaidByBorrowerIndex]
              ? removeComma(item[alreadyPrepaidByBorrowerIndex])
              : NaN
            newBudget.items[targetIndex].data[key].description = item[descriptionIndex]
          }
        }
      })

      let html = (
        <>
          <div
            className="my-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-[15px] flex items-center"
            role="alert"
          >
            {totalImportableRows} / {totalImportableRows + totalUnknownRows} rows are importable.
          </div>
          {!!totalUnknownRows && (
            <>
              <div className="text-left text-red-500 font-semibold">
                We can't find below {totalUnknownRows} Rows, Are you sure you want to import?
              </div>
              <div className="max-h-120 overflow-auto">
                <table className="table relative w-full text-sm">
                  <thead className="text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      {titleRow.map((v) => (
                        <th className={`p-2 ${v == 'Description' && 'w-[20%]'}`}>{v}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {unknownRows.map((row, index: number) => (
                      <tr className={`${index % 2 == 1 && 'bg-slate-100'}`}>
                        {titleRow.map((v, i: number) => (
                          <td className="text-left p-1">
                            <span className={`h-16 overflow-auto flex ${i < titleRow.length - 1 && 'items-center'}`}>
                              {row[i] === undefined || row[i] === null ? '' : row[i]}
                            </span>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )
      const confirmResult: any = await confirm(
        html,
        { maxWidth: totalUnknownRows ? '6xl' : '2xl', titleYes: 'Import' },
        2,
      )
      if (confirmResult === false) {
        if (fileInputRef.current) fileInputRef.current.value = ''
        setLoading(false)
        return
      }

      let newTotal = onUpdateTotal(newBudget)

      const nBudget = calcBudgetValues(newBudget)
      newTotal = onUpdateTotal(nBudget)

      let newInputs = cloneDeep(inputs)

      Object.keys(newInputs).forEach((key) => {
        newInputs[key].value = String((nBudget as any)[key])
      })

      const data = {
        budget: nBudget,
        isBorrower: isBorrower,
      }

      await updateBudgetTemplate(id, data)

      if (addedBathMore) await updateBudgetData('input', '', '', 'BathroomCount', (nBudget as any)['BathroomCount'])

      setTotal(newTotal)
      setBudget(nBudget)
      setInputs(newInputs)

      toast('Excel data has been successfully imported', { type: 'info' })
    } catch (error) {
      console.log('Imporing excel error: ', error)
    }

    if (fileInputRef.current) fileInputRef.current.value = ''
    setLoading(false)
  }

  const onExportQA = async (type: 'pdf' | 'docx') => {
    const highlightItems: Record<string, any>[] = []
    budget?.items.forEach((v) => {
      Object.keys(v.data).forEach((key) => {
        const item = v.data[key]
        if (item.highlight)
          highlightItems.push({
            title: budgetItemMapping[key],
            note: item.note,
            description: item.description,
            rehabCostBorrowerEstimated: item.rehabCostBorrowerEstimated,
          })
      })
    })
    const narrativeNote = inputs.narrative.value || ''
    setLoading(true)
    await downloadBudgetQA(highlightItems, narrativeNote, type, `Budget & Scope QA - ${loanNumber}.${type}`)
    setLoading(false)
  }

  const renderInputs = useCallback(() => {
    return (
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        {Object.keys(inputs).map((key: string) => {
          let input = inputs[key]
          if (input.visible === false) return null
          return (
            <div className={`input md:col-span-1`} key={key}>
              <RenderInput
                input={{ ...input, disabled: isBorrower ? true : false }}
                Key={key}
                onChange={onChange}
                onBlur={() => onBlur(key)}
              />
            </div>
          )
        })}
      </div>
    )
  }, [inputs, isBorrower])

  const renderBudgetItemChild = (item: IBudgetItem, index: number, totalIndex: number, key: string, id: number) => {
    const data = item.data[key]
    const costBorrowerEstimatedError = data.highlight && removeComma(data.rehabCostBorrowerEstimated) === 0
    const descriptionError =
      removeComma(data.rehabCostBorrowerEstimated) > 0 && String(data.description).trim().length === 0

    let cellEditable = true
    if (data.lockData && !alwaysEditable) {
      cellEditable = false
    }

    const foundDrawRequests = drawRequests
      ? drawRequests.filter((v) => !!v.types.filter((v) => item.value == v.value && v.key == key).length)
      : []
    const hasDrawRequests = !!foundDrawRequests.length

    const indexTd = (
      <td className={`px-2 py-0 border ${data.lockData && 'bg-gray-200/75'} ${data.highlight && 'bg-yellow-100'}`}>
        {totalIndex}
        {data.lockData && (
          <span>
            <br />
            <LockClosedIcon className="w-4 h-4 ml-1"></LockClosedIcon>
          </span>
        )}
      </td>
    )

    let title = budgetItemMapping[key] || ''
    if (!budgetItemMapping[key]) console.log('Error', key)
    const matches = title.match(/Other(\s[\w|\s]+): Please Describe/)
    let isOtherRow = !!matches
    if (matches) {
      title = data.title || title.replace(matches[1], '')
    }
    if (!cellEditable) isOtherRow = false

    const showDrawRequestButton = (
      <div
        className="relative z-10 cursor-pointer hover-shadow1 p-1 rounded"
        onClick={() =>
          setShowDrawRequests({
            value: item.label,
            title,
            requests: foundDrawRequests,
          })
        }
      >
        <ArrowTopRightOnSquareIcon className="w-4 h-4" />
      </div>
    )

    const typeTd = (
      <td
        className={`relative group px-2 py-1 border text-left ${data.highlight && 'bg-yellow-100'} ${
          plainTextCostTypeItems.includes(key) ? 'bg-gray-100 font-bold' : ''
        }`}
      >
        {isBorrower ? (
          <div>
            {title}
            <div className="absolute top-[2px] right-[2px] items-center gap-2">
              {hasDrawRequests && showDrawRequestButton}
            </div>
          </div>
        ) : (
          <div className="">
            <div className="flex items-center">
              <div className="w-auto">
                <Checkbox
                  color="gray"
                  id={`highlight-${key}`}
                  title={isOtherRow ? '' : title}
                  key={key}
                  value={data.highlight as boolean}
                  onChange={() => {
                    !isBorrower && cellEditable && onChangeCheckBoxItem(item.value, key, 'highlight')
                  }}
                />
              </div>
              {isOtherRow && (
                <PlainInput2
                  value={title}
                  content={title}
                  type="text"
                  className="italic -mt-1 -ml-1"
                  isEditing={cellEditable && isEditing === `${index}-${id}-${item.value}-${key}-title`}
                  editable={cellEditable}
                  onChange={(value: string) => onChangeItemValues(value, item.value, key, 'title')}
                />
              )}
            </div>
            {!isBorrower && cellEditable && (
              <div className="absolute top-[2px] right-[2px] items-center gap-2 flex">
                <div
                  className="relative z-10 cursor-pointer hover-shadow1 p-1 rounded hidden group-hover:block"
                  onClick={() => setEditing(`${index}-${id}-${item.value}-${key}-titleNotes`)}
                >
                  <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                </div>

                {hasDrawRequests && showDrawRequestButton}
              </div>
            )}
            {isEditing === `${index}-${id}-${item.value}-${key}-titleNotes` && (
              <TextArea
                value={data.note}
                rows={2}
                onChange={(value: string) => onChangeNote(value, item.value, key)}
                onBlur={() => onBlurItem(item.value, key, 'note')}
                className="text-[13.5px] p-[2px]"
              />
            )}
          </div>
        )}
        {data.note && data.note?.length > 0 && (
          <div className="italic mt-1 ml-2 text-right text-[13.5px] break-words w-96">- {data.note}</div>
        )}
      </td>
    )

    const lockTd = alwaysEditable && (
      <td className="px-2 py-1 border cursor-pointer" onClick={() => onChangeCheckBoxItem(item.value, key, 'lockData')}>
        <Checkbox
          color="gray"
          id={`lockData-${key}`}
          title={''}
          key={key}
          value={data.lockData as boolean}
          onChange={() => {}}
        />
      </td>
    )

    return (
      <tr key={`${index}-${id}`} className={``}>
        {indexTd}
        {typeTd}

        {/* Rehab Cost Borrower Estimated */}
        <td
          className={`px-2 py-0 border ${cellEditable ? 'cursor-pointer' : 'cursor-not-allowed'} ${
            isBorrower ? 'bg-green-50' : ''
          }`}
          onClick={() => setEditing(`${index}-${id}-${item.value}-${key}-rehabCostBorrowerEstimated`)}
        >
          <PlainInput2
            value={convertValue(data.rehabCostBorrowerEstimated)}
            content={convertContent(data.rehabCostBorrowerEstimated)}
            type="number"
            isEditing={cellEditable && isEditing === `${index}-${id}-${item.value}-${key}-rehabCostBorrowerEstimated`}
            editable={cellEditable}
            onChange={(value: string) => onChangeItemValues(value, item.value, key, 'rehabCostBorrowerEstimated')}
          />
          {costBorrowerEstimatedError && (
            <span
              className={`text-red-900 px-4 ${
                isEditing !== `${index}-${id}-${item.value}-${key}-rehabCostBorrowerEstimated` && 'py-2 bg-red-200'
              } rounded`}
            >
              Required
            </span>
          )}
        </td>

        {/* Lender Financed Amount */}
        <td className={`px-2 py-0 border`}>{convertContent(data.rehabCostBorrowerEstimated * rehabFinancedPercent)}</td>

        {/* Rehab Cost Lender Approved Percentage */}
        {servicingPipeline && (
          <td
            className={`relative group ${
              brokerProfile ? 'cursor-not-allowed' : 'cursor-pointer'
            } px-2 py-0 border cursor-pointer ${
              removeComma(data.rehabCostLenderApproved) !== 0 &&
              removeComma(data.rehabCostLenderApproved) != removeComma(data.rehabCostBorrowerEstimated) &&
              'border-l-4 border-l-red-400'
            }`}
            onClick={() => setEditing(`${index}-${id}-${item.value}-${key}-rehabCostLenderApprovedPercent`)}
          >
            <PlainInput2
              value={convertPercent(data.rehabCostBorrowerEstimated, data.rehabCostLenderApproved)}
              content={convertPercent(data.rehabCostBorrowerEstimated, data.rehabCostLenderApproved, true)}
              type="number"
              isEditing={
                cellEditable &&
                isEditing === `${index}-${id}-${item.value}-${key}-rehabCostLenderApprovedPercent` &&
                !brokerProfile
              }
              editable={alwaysEditable}
              onChange={(value: string) => {
                onChangeItemValues(
                  ((data.rehabCostBorrowerEstimated * Math.min(Number(value), 100)) / 100).toFixed(2),
                  item.value,
                  key,
                  'rehabCostLenderApproved',
                )
              }}
            />
          </td>
        )}

        {/* Rehab Cost Lender Approved */}
        {servicingPipeline && (
          <td
            className={`relative group ${
              brokerProfile ? 'cursor-not-allowed' : 'cursor-pointer'
            } px-2 py-0 border cursor-pointer ${
              removeComma(data.rehabCostLenderApproved) !== 0 &&
              removeComma(data.rehabCostLenderApproved) != removeComma(data.rehabCostBorrowerEstimated) &&
              'border-l-4 border-l-red-400'
            }`}
            onClick={() => setEditing(`${index}-${id}-${item.value}-${key}-rehabCostLenderApproved`)}
          >
            <PlainInput2
              value={convertValue(data.rehabCostLenderApproved)}
              content={convertContent(data.rehabCostLenderApproved)}
              type="number"
              isEditing={
                cellEditable &&
                isEditing === `${index}-${id}-${item.value}-${key}-rehabCostLenderApproved` &&
                !brokerProfile
              }
              editable={alwaysEditable}
              onChange={(value: string) => onChangeItemValues(value, item.value, key, 'rehabCostLenderApproved')}
            />
            {alwaysEditable &&
              convertValue(data.rehabCostBorrowerEstimated).length > 0 && ( // !isBorrower && cellEditable
                <div className="absolute top-[2px] right-[2px] hidden group-hover:flex">
                  <div
                    className="relative z-10 cursor-pointer hover-shadow1 p-1 rounded"
                    onClick={(e) => {
                      e.stopPropagation()
                      onChangeItemValues(
                        convertValue(data.rehabCostBorrowerEstimated),
                        item.value,
                        key,
                        'rehabCostLenderApproved',
                      )
                    }}
                  >
                    <ClipboardDocumentCheckIcon className="w-[15px] h-[15px]"></ClipboardDocumentCheckIcon>
                  </div>
                </div>
              )}
          </td>
        )}

        {/* Lender Disbursed Amount */}
        {isFunded && (
          <td
            className={`relative group ${
              brokerProfile ? 'cursor-not-allowed' : 'cursor-pointer'
            } px-2 py-0 border cursor-pointer`}
            onClick={() => setEditing(`${index}-${id}-${item.value}-${key}-lenderDisbursedAmount`)}
          >
            <PlainInput2
              value={convertValue(data.lenderDisbursedAmount)}
              content={convertContent(data.lenderDisbursedAmount)}
              type="number"
              isEditing={
                cellEditable &&
                isEditing === `${index}-${id}-${item.value}-${key}-lenderDisbursedAmount` &&
                !brokerProfile
              }
              editable={alwaysEditable}
              onChange={(value: string) => onChangeItemValues(value, item.value, key, 'lenderDisbursedAmount', data)}
            />
            {alwaysEditable &&
              convertValue(data.rehabCostLenderApproved).length > 0 && ( // !isBorrower && cellEditable
                <div className="absolute top-[2px] right-[2px] hidden group-hover:flex">
                  <Tooltip message="Fill Max Value">
                    <div
                      className="relative z-10 cursor-pointer hover-shadow1 p-1 rounded"
                      onClick={(e) => {
                        e.stopPropagation()
                        onChangeItemValues(
                          convertValue(data.rehabCostLenderApproved * rehabFinancedPercent),
                          item.value,
                          key,
                          'lenderDisbursedAmount',
                          data,
                        )
                      }}
                    >
                      <ClipboardDocumentCheckIcon className="w-[15px] h-[15px]"></ClipboardDocumentCheckIcon>
                    </div>
                  </Tooltip>
                </div>
              )}
          </td>
        )}

        {/* Rehab Cost already Prepaid by Borrower */}
        <td
          className={`px-2 py-0 border ${cellEditable ? 'cursor-pointer' : 'cursor-not-allowed'} ${
            isBorrower ? 'bg-green-50' : ''
          }`}
          onClick={() => setEditing(`${index}-${id}-${item.value}-${key}-rehabCostAlreadyPrepaid`)}
        >
          <PlainInput2
            value={convertValue(data.rehabCostAlreadyPrepaid)}
            content={convertContent(data.rehabCostAlreadyPrepaid)}
            type="number"
            isEditing={cellEditable && isEditing === `${index}-${id}-${item.value}-${key}-rehabCostAlreadyPrepaid`}
            editable={cellEditable}
            onChange={(value: string) => onChangeItemValues(value, item.value, key, 'rehabCostAlreadyPrepaid')}
          />
        </td>

        {/* Borrower Responsibility of Budget Item */}
        <td
          className="px-2 py-0 border cursor-not-allowed"
          onClick={() => setEditing(`${index}-${id}-${item.value}-${key}-borrowerResponsibility`)}
        >
          <span className="text-black font-variation-settings-600">{convertContent2(data.borrowerResponsibility)}</span>
        </td>

        {/* Cost Type */}
        <td
          className="px-2 py-1 border cursor-pointer"
          onClick={() => setEditing(`${index}-${id}-${item.value}-${key}-costType`)}
        >
          {isBorrower ? (
            <span tabIndex={0} className="text-black font-variation-settings-600">
              {data.costType}
            </span>
          ) : plainTextCostTypeItems.includes(key) ? (
            <PlainInput2
              value={data.costType}
              content={data.costType}
              isEditing={cellEditable && isEditing === `${index}-${id}-${item.value}-${key}-costType`}
              editable={isBorrower ? false : cellEditable}
              onChange={(value: string) => onChangeItemValues(value, item.value, key, 'costType')}
            />
          ) : (
            <Select
              id="cost-type"
              size={3}
              options={['Soft costs', 'Hard costs']}
              value={data.costType}
              hasDefaultOption={true}
              disabled={!cellEditable}
              className="min-w-[110px] mb-[-16px] border-none"
              onChange={(value: string) => onChangeItemValues(value, item.value, key, 'costType')}
            />
          )}
        </td>

        <td
          className={`relative group px-2 py-1 border cursor-pointer min-w-[200px] ${descriptionError && 'bg-red-200'}`}
        >
          <TextArea
            value={data.description}
            rows={2}
            disabled={!cellEditable}
            onChange={(value: string) => onChangeDescription(value, item.value, key)}
            onBlur={() => onBlurItem(item.value, key, 'description')}
            className="text-[13.5px]"
          />
          {descriptionError && <span className="text-red-900">Required</span>}
          {!brokerProfile && data.intDes && (
            <div className="text-right italic text-[13.5px] break-all">{data.intDes}</div>
          )}
          {!isBorrower && !brokerProfile && (
            <div className="absolute top-[2px] right-[2px] hidden group-hover:flex">
              <div
                className="relative z-10 cursor-pointer hover-shadow1 p-1 rounded"
                onClick={() => editInternalDesc(item.value, data.intDes, key)}
              >
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              </div>
            </div>
          )}
        </td>

        {lockTd}
      </tr>
    )
  }

  const renderBudgetItem = (item: IBudgetItem, index: number, totalIndex: number) => {
    if (item.value.startsWith('bath')) {
      const bathCount = Number(inputs['BathroomCount']?.value || 3)
      const bathIndex = Number(item.value.replace('bath', ''))
      if (bathCount < bathIndex) return null
    }

    const totalItem = total[item.value]
    return (
      <Fragment key={index}>
        <tr className="bg-gray-100 font-semibold">
          <td className="px-2 py-1 border"></td>
          <td className="px-2 py-1 border text-left">{item.label}</td>
          <td className="px-2 py-1 border">{convertContent(totalItem.rehabCostBorrowerEstimated)}</td>
          <td className="px-2 py-1 border">
            {convertContent(totalItem.rehabCostBorrowerEstimated * rehabFinancedPercent)}
          </td>
          {servicingPipeline && (
            <>
              <td className="px-2 py-1 border">
                {convertPercentContent(
                  (totalItem.rehabCostLenderApproved / totalItem.rehabCostBorrowerEstimated) * 100,
                )}
              </td>
              <td className="px-2 py-1 border">{convertContent(totalItem.rehabCostLenderApproved)}</td>
            </>
          )}
          {isFunded && <td className="px-2 py-1 border">{convertContent(totalItem.lenderDisbursedAmount)}</td>}
          <td className="px-2 py-1 border">{convertContent(totalItem.rehabCostAlreadyPrepaid)}</td>
          <td className="px-2 py-1 border">{convertContent2(totalItem.borrowerResponsibility)}</td>
          <td className="px-2 py-1 border"></td>
          <td className="px-2 py-1 border"></td>
          {alwaysEditable && <td className="px-2 py-1 border"></td>}
        </tr>

        {Object.keys(item.data)
          .sort()
          .map((key, id) => renderBudgetItemChild(item, index, totalIndex++, key, id))}
      </Fragment>
    )
  }

  const renderBudgetTotal = () => {
    const {
      rehabCostBorrowerEstimated,
      rehabCostLenderApproved,
      lenderDisbursedAmount,
      rehabCostAlreadyPrepaid,
      borrowerResponsibility,
    } = total['total']

    return (
      <tr className="bg-gray-100 font-semibold">
        <td className="px-2 py-1 border"></td>
        <td className="px-2 py-1 border text-left uppercase">Grand Total</td>
        <td className="px-2 py-1 border">{convertContent(rehabCostBorrowerEstimated)}</td>
        <td className="px-2 py-1 border">{convertContent(rehabCostBorrowerEstimated * rehabFinancedPercent)}</td>
        {servicingPipeline && (
          <>
            <td className="px-2 py-1 border">
              {convertPercentContent((rehabCostLenderApproved / rehabCostBorrowerEstimated) * 100)}
            </td>
            <td className="px-2 py-1 border">{convertContent(rehabCostLenderApproved)}</td>
          </>
        )}
        {isFunded && <td className="px-2 py-1 border">{convertContent(lenderDisbursedAmount)}</td>}
        <td className="px-2 py-1 border">{convertContent(rehabCostAlreadyPrepaid)}</td>
        <td className="px-2 py-1 border">{convertContent2(borrowerResponsibility)}</td>
        <td className="px-2 py-1 border"></td>
        <td className="px-2 py-1 border"></td>
        {alwaysEditable && (
          <td className="px-2 py-1 border" onClick={() => onChangeCheckBoxAllItem('lockData', !isAllLockData)}>
            <Checkbox color="gray" id={`lockData-all`} title={''} value={isAllLockData} onChange={() => {}} />
          </td>
        )}
      </tr>
    )
  }

  const renderBudget = useCallback(() => {
    let headers = [
      'No',
      'Description',
      'Rehab Cost Borrower Estimated',
      'Lender Financed Amount',
      'Rehab Cost Lender Approved Percentage',
      'Rehab Cost Lender Approved',
      'Lender Disbursed Amount',
      `Rehab Cost already Prepaid by Borrower`,
      'Borrower Responsibility of Budget Item',
      'Cost Type',
      'Work to be performed',
      'Lock Data',
    ]

    if (!servicingPipeline) {
      let index = headers.indexOf('Rehab Cost Lender Approved Percentage')
      if (index != -1) headers.splice(index, 1)
      index = headers.indexOf('Rehab Cost Lender Approved')
      if (index != -1) headers.splice(index, 1)
    }

    if (!isFunded) {
      let index = headers.indexOf('Lender Disbursed Amount')
      if (index != -1) headers.splice(index, 1)
    }

    if (!alwaysEditable) {
      const index = headers.indexOf('Lock Data')
      headers.splice(index, 1)
    }

    let totalIndex = 1
    return (
      <div>
        <div className="flex justify-between items-center gap-4 my-4">
          <div
            className="bg-sky-100 border border-sky-400 text-sky-700 px-4 py-3 rounded relative text-[15px]"
            role="alert"
          >
            The Budget must only include work that has not been completed as part of the project. Any completed work,
            must not be included.
          </div>
          <div className="flex items-center gap-2">
            <Tooltip message="Export QA">
              <span className="p-1 hover-shadow1 cursor-pointer rounded" onClick={onDownload}>
                <PrinterIcon className="w-5 h-5 text-shade-blue" />
              </span>
            </Tooltip>

            <Tooltip message="Import Excel">
              <>
                <label className="p-1 hover-shadow1 cursor-pointer rounded" htmlFor="importExcel">
                  <ArrowUpTrayIcon className="w-5 h-5 text-shade-blue" />
                </label>

                <input
                  className="hidden"
                  id="importExcel"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  multiple={false}
                  ref={fileInputRef}
                  onChange={importExcel}
                />
              </>
            </Tooltip>

            <Tooltip message="Export Excel">
              <a
                href={`${appApiUrl}/loan/budget/exportExcel/${loanNumber}/${id}?token=${stateData.auth.token}`}
                download
                className="p-1 hover-shadow1 cursor-pointer rounded"
              >
                <ArrowDownTrayIcon className="w-5 h-5 text-shade-blue" />
              </a>
            </Tooltip>

            <Tooltip message="Export PDF">
              <a
                href={`${appApiUrl}/loan/budget/exportPdf/${loanNumber}/${id}?token=${stateData.auth.token}`}
                download
                className="p-1 hover-shadow1 cursor-pointer rounded"
              >
                <InboxArrowDownIcon className="w-5 h-5 text-shade-blue" />
              </a>
            </Tooltip>
          </div>
        </div>

        <div className="overflow-auto relative">
          <table className="table relative w-full text-sm text-center">
            <thead className="">
              <tr className="bg-gray-100">
                {headers.map((item, index) => {
                  return (
                    <th className="sticky top-0 z-10 bg-gray-100 py-3 px-2 border" key={index}>
                      <span>
                        <div dangerouslySetInnerHTML={{ __html: item }}></div>
                      </span>
                    </th>
                  )
                })}
              </tr>
            </thead>

            {budget && !isEmpty(total) && (
              <tbody>
                {renderBudgetTotal()}

                {budget.items.map((item, index) => {
                  const prevTotalIndex = totalIndex
                  totalIndex += Object.keys(item.data).length
                  return renderBudgetItem(item, index, prevTotalIndex)
                })}

                {renderBudgetTotal()}
              </tbody>
            )}
          </table>

          {isDownloadTypeDialog && (
            <DownloadTypeDialog
              onSubmit={(type: 'pdf' | 'docx') => onExportQA(type)}
              onClose={() => showDownloadTypeDialog(false)}
            />
          )}
        </div>
      </div>
    )
  }, [
    budget,
    total,
    isEditing,
    isBorrower,
    servicingPipeline,
    isFunded,
    inputs.BathroomCount?.value,
    isDownloadTypeDialog,
  ])

  const renderHistoryModal = () => {
    return (
      <Modal
        button={
          <div
            className="hover:underline text-shade-blue cursor-pointer flex items-center gap-1 text-[14px]"
            onClick={() => setShowHistoryModal(true)}
          >
            <ClockIcon className="h-4 w-4"></ClockIcon>
            History
          </div>
        }
        title={'History'}
        titleOkay=""
        isOpen={showHistoryModal}
        lastUpdatedAt={Date.now()}
        onClose={() => setShowHistoryModal(false)}
        onOk={() => {}}
      >
        <div className="max-w-[720px]">
          <table className={`text-sm text-left`}>
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className={`px-4 py-2`}>
                  No
                </th>
                <th scope="col" className={`px-4 py-2`}>
                  Date
                </th>
                <th scope="col" className={`px-4 py-2`}>
                  Action
                </th>
                <th scope="col" className={`px-4 py-2`}>
                  By
                </th>
              </tr>
            </thead>
            <tbody className="text-[14.5px] text-gray-900">
              {histories.map((item: any, index: number) => {
                return (
                  <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                    <td className={`px-4 py-2`}>{index + 1}</td>
                    <td className={`px-4 py-2`}>{formatTime(item.time)}</td>
                    <td className={`px-4 py-2`}>
                      <span>
                        <div className="flex gap-2">
                          {item.action}
                          {item.action === 'Borrower Submission' && (
                            <span className="cursor-pointer" onClick={() => openS3Document(item.pdfKey)}>
                              <EyeIcon className="w-4 h-4"></EyeIcon>
                            </span>
                          )}
                        </div>
                      </span>
                    </td>
                    <td className={`px-4 py-2`}>{item.email}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    )
  }

  const renderSubmitModal = () => {
    return (
      <Modal
        button={<span></span>}
        title={'Submit Budget & Scope of Work'}
        titleOkay="Sign Scope of Work"
        isOpen={submitModal}
        lastUpdatedAt={Date.now()}
        onClose={() => setSubmitModal(false)}
        onOk={onConfirmAndSubmit}
        disabled={errors.length > 0}
        loading={loading}
      >
        <div className="max-w-[720px]">
          <div className="text-left">
            {errors.length > 0 && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[14px]"
                role="alert"
              >
                <div className="mb-2 font-semibold">Please provide the required information:</div>
                {errors.map((error: any, index: number) => {
                  return (
                    <div className="md:col-span-1" key={index}>
                      <div>- {error.title}</div>
                      {error.note && <div className="ml-3 border border-gray-200 p-2 italic mb-4">{error.note}</div>}
                    </div>
                  )
                })}
              </div>
            )}
            <div>
              After submitting this information, you <span className="font-bold">cannot edit</span> it anymore, so
              please ensure everything is entered correctly.
            </div>
            <div className="flex mt-4 text-[14px] font-semibold gap-2 items-center w-fit hover:underline cursor-pointer text-shade-blue">
              <span>
                <a
                  href={`${appApiUrl}/loan/budget/exportPdf/${loanNumber}/${id}?token=${stateData.auth.token}`}
                  download
                >
                  View PDF of Scope of Work
                </a>
              </span>
              <EyeIcon className="w-4 h-4"></EyeIcon>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <>
      <LayoutLoading show={loading} />

      {!isBorrower && (
        <div className="flex flex-wrap justify-between items-center gap-4 mb-2">
          <div>
            <Button onClick={onSendToBorrower}>
              <div className="flex items-center">
                <EnvelopeIcon className="h-5 w-5 mr-1" />
                <span>Send to Parties</span>
              </div>
            </Button>
          </div>
          <div className="flex flex-wrap gap-4 items-center">{alwaysEditable && renderHistoryModal()}</div>
        </div>
      )}

      {renderInputs()}
      <div
        className={`${
          budget?.narrativeNote?.length
            ? 'bg-yellow-100  border-yellow-400 text-yellow-700'
            : 'bg-gray-100  border-gray-400 text-gray-700'
        } border px-4 py-3 rounded relative text-[16px] mt-6`}
        role="alert"
      >
        <div className="flex gap-4 items-center mb-1">
          <span>
            - Please provide a narrative of what is transpiring in the project. Include details such as what exact work
            is being performed inside and outside of the structure. Is square footage being added? Will room count
            change? Etc.
          </span>
          {!isBorrower && (
            <span className="p-[4px] rounded hover-shadow1 cursor-pointer" onClick={editNarrativeNote}>
              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
            </span>
          )}
        </div>
        {budget?.narrativeNote?.length ? <div className="ml-2 mb-1 italic">{budget.narrativeNote}</div> : null}
        <RenderInput
          input={{ ...inputs['narrative'] }}
          Key={'narrative'}
          onChange={onChange}
          onBlur={() => onBlur('narrative')}
        />
      </div>

      {renderBudget()}

      {isBorrower && (
        <div className="flex justify-center mt-6">
          <Button disabled={!submitForm} onClick={() => setSubmitModal(true)}>
            <div className="flex gap-2">
              <svg className="animate-bounce w-6 h-6">
                <ArrowDownCircleIcon className="w-5 h-5"></ArrowDownCircleIcon>
              </svg>
              Submit Form
            </div>
          </Button>
        </div>
      )}
      {isBorrower && submitForm && <SaveChanges show={true} label="Submit Form" onSave={() => setSubmitModal(true)} />}

      {renderSubmitModal()}
      {isBorrowerEmailDialog && <BorroserEmailDialog onClose={onBorrowerEmailDialogClose} />}
      {showDrawRequests && <DrawRequestsDialog {...showDrawRequests} onClose={() => setShowDrawRequests(null)} />}
    </>
  )
}

export default BudgetScopeOfWork
