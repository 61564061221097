import { getInitialLoanFields, InputType } from 'config'
import { InputTypeOfLoans } from 'config/loan.input.fields.constants'
import { FieldOrigin, fieldOriginOptions, isCustomOrigin, LoanFieldProps } from 'config/loan.input.visibility.type'
import { defaultInputs as borrowerInformationInputs } from 'pages/LoanApplication/BorrowerInformation/constants'
import { defaultInputs as DeclarationHMDAInputs } from 'pages/LoanApplication/DeclarationsHmda/constants'
import { defaultInputs as propertyInformationInputs } from 'pages/LoanApplication/PropertyInformation/constants'
import { Input2, ToggleButton } from 'stories/components'

export const assetLiabilityInputs = () => {
  return {
    assetInformation: {
      inputType: 'ToggleButton',
      title: 'Asset Information',
    },
    totalCredits: {
      inputType: 'ToggleButton',
      title: 'Total Credits',
    },
    liabilityInformation: {
      inputType: 'ToggleButton',
      title: 'Liability Information',
    },
  }
}

export const TrackRecordInputs = () => {
  const data: Record<string, string> = {
    purchaseDateBeforeLoanDate: `Purchase Date must be before Loan Date.`,
    addressDifferentPropertyAddr: `Address cannot be the same as Subject Property Address.`,
    purchaseDateBeforeSoldDate: `Purchase Date must be before Sold Date.`,
    soldDateBeforeLoanDate: `Sold Date must be before Loan Date.`,
  }
  let result: Record<string, InputType> = {
    sectionForNo: {
      inputType: 'Section',
      title: 'Retained As Rental? - No',
      span: 'full',
    },
  }
  Object.keys(data).forEach((key) => {
    result[key] = {
      inputType: 'ToggleButton',
      title: data[key],
    }
  })

  const additionalData: Record<string, string> = {
    soldDateWithin30monthsLoanDate: `Sold Date must be within {value} months of Loan Date.`, // 36
    purchaseDateWithin24monthsSoldDate: `Purchase Date must be within {value} months of Sold Date.`, // 24
    soldPriceLessthan75AfterRepairValue: `Sold Price, cannot be less than {value}% of After Repair Value.`, // 75
  }
  const defaultValues: Record<string, number> = {
    soldDateWithin30monthsLoanDate: 36,
    purchaseDateWithin24monthsSoldDate: 24,
    soldPriceLessthan75AfterRepairValue: 75,
  }

  const addAdditionalData = (key: string, title: string, defaultValue: number) => {
    result[key] = {
      inputType: 'custom',
      title,
      render: (value: any, onChange: Function) => {
        if (!value)
          value = {
            enabled: true,
            value: defaultValue,
          }
        return (
          <div className="flex items-center gap-2">
            <ToggleButton
              title={title.replace('{value}', value.value)}
              value={value.enabled}
              onChange={(v) => onChange({ ...value, enabled: v })}
              id={key}
            />
            <div className="w-20">
              <Input2 title="Value" value={value.value} onChange={(v) => onChange({ ...value, value: v })} />
            </div>
          </div>
        )
      },
    }
  }
  Object.keys(additionalData).forEach((key) => addAdditionalData(key, additionalData[key], defaultValues[key]))

  result['sectionForYes'] = {
    inputType: 'Section',
    title: 'Retained As Rental? - Yes',
    span: 'full',
  }
  ;['purchaseDateBeforeLoanDate', 'addressDifferentPropertyAddr'].forEach((key) => {
    result[`${key}Yes`] = {
      inputType: 'ToggleButton',
      title: data[key],
    }
  })
  result = {
    ...result,
    purchasePrice: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Purchase Price',
      required: true,
      prefix: '$',
    },
    purchaseDate: {
      inputType: 'text',
      type: 'date',
      title: 'Purchase Date',
      required: true,
    },
    renovationBudget: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Renovation Budget',
      required: true,
      prefix: '$',
    },
  }

  const additionalDataYes: Record<string, string> = {
    purchaseDateWithin24monthsExistingClosingDate: `Purchase Date must be within {value} months of existing closing date.`, // 24
    purchasePriceLessthan75TransactionPurPrice: `Purchase Price must be within {value}% of transaction Purchase Price.`, // 75
    purchaseDateFrom24monthsExistingClosingDate: `Purchase Date must be at least {value} months from existing closing date.`, // 12
  }
  const defaultValuesYes: Record<string, number> = {
    purchaseDateWithin24monthsExistingClosingDate: 24,
    purchasePriceLessthan75TransactionPurPrice: 75,
    purchaseDateFrom24monthsExistingClosingDate: 12,
  }
  Object.keys(additionalDataYes).forEach((key) => addAdditionalData(key, additionalDataYes[key], defaultValuesYes[key]))

  return result
}

export const addCustomInputs = (inputs: InputTypeOfLoans, loanFieldsProps: LoanFieldProps) => {
  const oldCustomInfoOriginKeys = Object.keys(inputs).filter((origin) => isCustomOrigin(origin))
  oldCustomInfoOriginKeys.forEach((customOriginKey) => delete (inputs as any)[customOriginKey])

  const customInfoOriginKeys = Object.keys(loanFieldsProps).filter((origin) => isCustomOrigin(origin))

  customInfoOriginKeys.push(FieldOrigin.DeclarationsHMDA)

  customInfoOriginKeys.forEach((customOriginKey) => {
    const customFields = loanFieldsProps[customOriginKey] || {}
    const customInputs: Record<string, InputType> = (inputs as any)[customOriginKey] || {}
    Object.keys(customFields).forEach((fieldKey) => {
      if (customInputs[fieldKey]) return
      // const inputType =
      //   customOriginKey == FieldOrigin.DeclarationsHMDA &&
      //   customFields[fieldKey].section &&
      //   Number(customFields[fieldKey].section) <= 3
      //     ? 'ToggleButton'
      //     : 'text'
      customInputs[fieldKey] = {
        inputType: 'text',
        ...customFields[fieldKey],
      } as any
    })
    ;(inputs as any)[customOriginKey] = customInputs
  })

  return inputs
}

export const getInputs = (loanFieldsProps: LoanFieldProps, customInputs = true): InputTypeOfLoans => {
  const result = {
    [FieldOrigin.LoanStructure]: getInitialLoanFields(),
    [FieldOrigin.BorrowerInformation]: borrowerInformationInputs(),
    [FieldOrigin.PropertyInformation]: propertyInformationInputs(),
    [FieldOrigin.AssetLiability]: assetLiabilityInputs(),
    [FieldOrigin.TrackRecord]: TrackRecordInputs(),
    [FieldOrigin.DeclarationsHMDA]: DeclarationHMDAInputs(loanFieldsProps),
    ['sign']: {},
    ['credit']: {},
    ['additionalMember']: {},
  } as any

  if (!customInputs) return result

  return addCustomInputs(result, loanFieldsProps)
}

export const visibilitySections = {
  [FieldOrigin.BorrowerInformation]: 'borrower',
  [FieldOrigin.PropertyInformation]: 'property',
  [FieldOrigin.AssetLiability]: 'asset',
  [FieldOrigin.TrackRecord]: 'track',
  [FieldOrigin.DeclarationsHMDA]: 'hmda',
  sign: 'sign',
  credit: 'credit',
  additionalMember: 'additionalMember',
}

export const extendedFieldOriginOptions = {
  ...fieldOriginOptions,
  sign: 'Acknowledgement & Sign',
  credit: 'Credit Score',
  additionalMember: 'Additional Entity Members',
}

export const isInputTypeChangableFieldOrigins = (fieldOrigin: FieldOrigin) =>
  [
    FieldOrigin.LoanStructure,
    FieldOrigin.BorrowerInformation,
    FieldOrigin.PropertyInformation,
    FieldOrigin.DeclarationsHMDA,
  ].includes(fieldOrigin) || isCustomOrigin(fieldOrigin)

export const hasRequiredProps = isInputTypeChangableFieldOrigins
