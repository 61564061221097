import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { PageNavBar } from 'components/PageNavBar'
import { AccountType, LoanStatusType } from 'config'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAdminConfig, setAdminConfig } from 'services'
import { Toggle, ToggleButton } from 'stories/components'
import { capitalizeLettersSeparatedBySlash } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { workflowRestrictions } from './constants'

type VisualAccountTypes = AccountType | 'self_creator'

export interface IWorkflowConfig {
  visibleAccountTypes?: VisualAccountTypes[]
  isActive: boolean
  disabledAtStatus?: LoanStatusType[]
  restrictions?: string[]
  enabled: boolean
}

export type TWorkflowConfig = Record<string, Record<string, IWorkflowConfig>>

export const Workflows = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Record<string, Record<string, IWorkflowConfig>>>({})
  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])

  const initData = async () => {
    setIsLoading(true)
    const res = await getAdminConfig('workflows')
    setData(res)
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  const onChange = async (
    titleKey: string,
    key: string,
    subKey: 'enabled' | 'visibleAccountTypes' | 'disabledAtStatus' | 'isActive' | 'restrictions',
    value: any,
  ) => {
    const temp = cloneDeep(data)
    temp[titleKey][key][subKey] = value
    if (subKey == 'enabled' && Object.keys(temp[titleKey][key]).includes('isActive'))
      temp[titleKey][key]['isActive'] = value

    setData(temp)
    setIsLoading(true)
    await setAdminConfig('workflows', temp)
    setIsLoading(false)
  }

  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />

      {Object.keys(data).map((titleKey, titleIndex: number) => {
        const item = data[titleKey]
        return (
          <React.Fragment key={titleKey}>
            <div className="text-xl font-bold mb-8">
              {titleIndex + 2}. {capitalizeLettersSeparatedBySlash(titleKey)}
            </div>
            <PageNavBar
              titles={Object.keys(item).map((key) => capitalizeLettersSeparatedBySlash(key))}
              activeIndex={-1}
              activeIndexs={Object.keys(item)
                .map((key, index: number) => {
                  return { key, index }
                })
                .filter(({ key }) => item[key].enabled)
                .map(({ index }) => index)}
              onMove={(index: number) => {
                const key = Object.keys(item)[index]
                onChange(titleKey, key, 'enabled', !item[key].enabled)
              }}
              isAllClickable
            />
            {Object.keys(item).map((key) => (
              <div className="mb-4" key={key}>
                <RenderInput
                  Key={key}
                  key={key}
                  input={{
                    inputType: 'section',
                    title: capitalizeLettersSeparatedBySlash(key),
                    additionalElements: (
                      <Toggle
                        id={key}
                        value={item[key].enabled}
                        onChange={(value) => onChange(titleKey, key, 'enabled', value)}
                        className="pr-4"
                      />
                    ),
                  }}
                  onChange={() => {}}
                />
                <div className="flex flex-wrap gap-2 items-center justify-between mt-4 py-2">
                  {item[key].visibleAccountTypes !== undefined && (
                    <div className="max-w-[400px] w-full">
                      <RenderInput
                        Key={key}
                        key={key}
                        input={{
                          inputType: 'multiselect',
                          title: 'Visible For',
                          options: { self_creator: '[Loan] Creator', ...accountTypes },
                          value: item[key].visibleAccountTypes,
                          defaultSelected: false,
                        }}
                        onChange={(key: string, value: any) => onChange(titleKey, key, 'visibleAccountTypes', value)}
                      />
                    </div>
                  )}
                  {item[key].disabledAtStatus !== undefined && (
                    <div className="max-w-[400px] w-full">
                      <RenderInput
                        Key={key}
                        key={key}
                        input={{
                          inputType: 'multiselect',
                          title: 'Disabled At',
                          options: loanGlobalStatus,
                          value: item[key].disabledAtStatus,
                          sort: true,
                          defaultSelected: false,
                        }}
                        onChange={(key: string, value: any) => onChange(titleKey, key, 'disabledAtStatus', value)}
                      />
                    </div>
                  )}
                  {Object.keys(item[key]).includes('isActive') && (
                    <div className="flex items-center gap-2">
                      <p>Is Active</p>
                      <ToggleButton
                        id={`${key}-isActive`}
                        value={item[key]['isActive']}
                        onChange={(value) => onChange(titleKey, key, 'isActive', value)}
                        disabled={!item[key]['enabled']}
                      />
                    </div>
                  )}
                </div>

                {key != 'submit_cTC_review' && (
                  <div>
                    <RenderInput
                      Key={key}
                      key={key}
                      input={{
                        inputType: 'multiselect',
                        title: 'Conditions',
                        options: workflowRestrictions,
                        value: item[key].restrictions || [],
                        defaultSelected: false,
                        prefix: 'alphabet',
                      }}
                      onChange={(key: string, value: any) => onChange(titleKey, key, 'restrictions', value)}
                    />
                  </div>
                )}
              </div>
            ))}
          </React.Fragment>
        )
      })}
    </div>
  )
}
