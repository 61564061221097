import { GlobalConfigType, SET_GLOBAL_CONFIG } from 'actions'

import { createReducer } from '../utils'

const getInitialState = (): Record<GlobalConfigType, any> => {
  return {
    [GlobalConfigType.LoanStatus]: {},
    [GlobalConfigType.LoanFields]: {},
    [GlobalConfigType.PagePermissions]: {},
    [GlobalConfigType.Footer]: {},
    [GlobalConfigType.AcceptedFileTypes]: [],
    [GlobalConfigType.ResourceVisibility]: {},
    [GlobalConfigType.ConditionTypes]: [],
    [GlobalConfigType.DocumentCategory]: [],
    [GlobalConfigType.GlobalSettingV2]: {},
  }
}

export const globalConfigReducer = createReducer(getInitialState(), {
  [SET_GLOBAL_CONFIG]: (state: any, { payload }: { payload: { type: GlobalConfigType; value: any } }) => {
    return {
      ...state,
      [payload.type]: payload.value,
    }
  },
})
