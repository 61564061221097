import cloneDeep from 'clone-deep'
import { accountTypes } from 'components/Modals/CreateUser/config'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const assignToRoles = accountTypes

export const SelectModal = ({
  options,
  value,
  title,
  multiple,
  ...props
}: {
  title: string
  options: Record<string, string>
  value: string[] | string
  multiple: boolean
  onSubmit: Function
  onClose: Function
}) => {
  const [inputs, setInputs] = useState<Record<string, InputType>>({})

  useEffect(() => {
    const tempInputs: Record<string, InputType> = {}

    Object.keys(options).forEach((key) => {
      tempInputs[key] = {
        inputType: 'CheckBox',
        title: options[key],
        value: Array.isArray(value) ? value.includes(key) : options[key] === value,
      }
    })

    setInputs(tempInputs)
  }, [options])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)

    // Choose only one item
    if (!multiple) {
      if (newInputs[key].value) {
        Object.keys(newInputs).forEach((v) => {
          if (v !== key) newInputs[v].value = false
        })
      }
    }

    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const value = Object.keys(inputs)
      .map((key) => {
        if (inputs[key].value) return key
        return null
      })
      .filter((value) => !!value)

    props.onSubmit(multiple ? value : value[0])
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal title={title} titleOkay="Confirm" onClose={onClose} onOk={onSubmit} isOpen childLevel={1}>
      <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]
          return (
            <div className={`input md:col-span-${input.span || 1} border-b`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
