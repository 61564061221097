export interface LoanSubmissionCondition {
  id: number
  no: number
  name: string
  type: string
  intext: boolean
  category: string
  createdAt: string
  updatedAt: string
  documentCategory: string
  description: string
  brokerDescription?: string
  responsibility: string
  note?: string
  borrowerNote?: string
  internalNote?: string
  ConditionID?: number
  cleared?: boolean
  clearedBy?: string
  clearedDate?: string

  exception?: boolean
  exceptionBy?: string
  exceptionDate?: string
  exceptionStatus?: number

  requested?: boolean
  requestedBy?: string
  requestedDate?: string

  [key: string]: any
}

export interface LoanSubmissionDocument {
  DocumentID?: number
  key: string
  name: string
  status: string
  category: string
  createdAt: number
  isEditing?: boolean
  conditionNo: number
  generated?: boolean
  intext?: boolean
  borrower?: boolean
  createdBy?: string
  uploadedByBorrower?: boolean
}

export enum AllowCashOutType {
  YES = '1',
  YES_WITH_RESTRICTION = '2',
  YES_WITH_REQUIREMENT = '3',
  NO = '4',
}

export const AllowCashOutTypeText: Record<AllowCashOutType, string> = {
  [AllowCashOutType.YES]: 'Yes',
  [AllowCashOutType.YES_WITH_RESTRICTION]: 'Yes, W/ % Restriction on Cash Out',
  [AllowCashOutType.YES_WITH_REQUIREMENT]: 'Yes, W/ % Requirement on Assets',
  [AllowCashOutType.NO]: 'No',
}
