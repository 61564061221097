import { LayoutLoading } from 'components/LayoutLoading'
import { IS_MAIN_COMPANY, itemCountPerPage } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getTickets } from 'services/apis/ticket'
import { svgSearch } from 'stories/assets'
import { Button, Input2, Pagination, Select2 } from 'stories/components'
import { formatTime } from 'utils'
import { useTitle } from 'utils/pageTitle'
import { renderHeader } from 'utils/table'

import { type ITicket, TicketStatusColorMap, TicketStatusText, TicketTypeTexts } from './types'

export const ManageTickets = () => {
  useTitle(`Manage Tickets`)

  const navigate = useHistory()
  const auth = useSelector((state: any) => state.auth)
  const [isLoading, setLoading] = useState(false)
  const [pageNum, setPageNum] = useState(0)
  const [total, setTotal] = useState(0)
  const [values, setValues] = useState<ITicket[]>([])
  const [filterQuery, setFilterQuery] = useState('')

  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'createdAt',
    orderDir: -1,
    status: '',
  })
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)

  useEffect(() => {
    if (IS_MAIN_COMPANY || !auth.isAuthenticated) navigate.push('/')
  }, [])
  useEffect(() => {
    if (isLoading) return
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [pageNum])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && (filterData(filters, 0), setPageNum(0)), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const onChangeFilter = (key: string, value: any) => {
    if (!isGetUsersOnce) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else {
      filterData(newFilters, 0)
      setPageNum(0)
    }
  }

  const filterData = async (filters: any, _pageNum: number = -1) => {
    if (isLoading) return
    if (_pageNum === -1) _pageNum = pageNum
    const filterData = {
      ...filters,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    }
    if (filters.status === '') {
      delete filterData.status
    }
    setLoading(true)
    const { data, total } = await getTickets(filterData)
    setValues(data)
    setTotal(total)
    setLoading(false)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onSort = (key: string, sortOrder: number) => {
    if (sortOrder == 0) sortOrder = -1
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)

    filterData(newFilters)
  }

  const goToMessages = (value: ITicket) => {
    const params = value
    navigate.push(`/tickets/${params.id}`, params)
  }

  const onAdd = () => {
    navigate.push('/create-ticket')
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="gap-2 mb-3 flex items-center justify-between">
          <h1 className="text-2xl font-variation-settings-600">Tickets</h1>

          <Button onClick={onAdd}>Submit a Ticket</Button>
        </div>

        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-3">
          <Input2
            type="search"
            title="Search ..."
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
          <Select2
            id="status"
            title="Status"
            options={TicketStatusText}
            value={filters.status}
            className="capitalize"
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('status', value)}
          />
        </div>

        <div className="table-container relative overflow-x-auto shadow-md sm:rounded-lg mb-6">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="font-variation-settings-600">
                <th scope="col" className="px-2 py-3 w-[40px]">
                  No
                </th>

                <th scope="col" className="py-2 px-2">
                  <div className="border-b w-fit mb-1 border-gray-300">Category</div>
                </th>

                <th scope="col" className="py-2 px-2">
                  <div className="border-b w-fit mb-1 border-gray-300">Loan Number</div>
                </th>

                {renderHeader({
                  title: 'Subject',
                  key: 'title',
                  index: 2,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'title' ? parseInt(filters.orderDir) : 0,
                })}

                <th scope="col" className="py-2 px-2">
                  <div className="border-b w-fit mb-1 border-gray-300">Type</div>
                </th>

                {renderHeader({
                  title: 'Status',
                  key: 'status',
                  index: 3,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'status' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Created At',
                  key: 'createdAt',
                  index: 4,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'createdAt' ? parseInt(filters.orderDir) : 0,
                })}
              </tr>
            </thead>

            <tbody className="text-[14px] text-gray-900">
              {values.map((value, index) => {
                return (
                  <tr key={`${value.id}-${index}`} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="pl-3 py-3">{index + 1 + pageNum * itemCountPerPage}</td>
                    <td className="px-2 py-2">{value.category}</td>
                    <td className="px-2 py-2">{value.loanNumber}</td>
                    <td
                      className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3 text-base"
                      onClick={() => goToMessages(value)}
                    >
                      {value.title}
                    </td>
                    <td className="px-2 py-2">{TicketTypeTexts[value.type]}</td>

                    <td className="px-2 py-2">
                      <span className={`${TicketStatusColorMap[value.status]}`}>{TicketStatusText[value.status]}</span>
                    </td>

                    <td className="px-2 py-2">{formatTime(value.createdAt)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end items-center mt-3">
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={pageNum}
          />
        </div>
      </div>
    </div>
  )
}
