import { GlobalConfigType } from 'actions'
import cloneDeep from 'clone-deep'
import { COMPANY_TITLE } from 'config'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { downloadFile, getPostClosingFields, submitPostClosingPDF } from 'services'
import { Button } from 'stories/components'
import { formatDateYMD, InputConvert } from 'utils'
import { renderParagraph } from 'utils/RenderParagraph'

import { confirmGenerate } from '../ConfirmGenerateDialog'
import { ServicerChoose } from '../ServicerChoose'
import { defaultInputs } from './constants'
import { paragraph } from './paragraph'

export function ServicingTransfer(props: any) {
  const companyConfig = useSelector((state: any) => state.globalConfigReducer[GlobalConfigType.Footer])

  const [download, setDownload] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const paragraphObj = paragraph(inputs)

  const inputLogic = (nInputs: any) => {
    let rlt = cloneDeep(nInputs)
    try {
      var previousDay = moment(nInputs['transferEffectiveDate'].value, 'YYYY/MM/DD').subtract(1, 'days')
      rlt['stopPaymentReceivedate'].value = previousDay.format('YYYY-MM-DD')
    } catch {}
    return rlt
  }

  useEffect(() => {
    ;(async () => {
      const nInputs = cloneDeep(inputs)
      props.setLoading(true)

      const data = await getPostClosingFields('servicingTransfer')

      Object.keys(nInputs).forEach((key) => {
        nInputs[key].value = data[key] || ''
      })
      nInputs['date'].value = formatDateYMD()
      nInputs['lender'].value = COMPANY_TITLE
      nInputs['currentServicerPhone'].value = companyConfig?.tollFree.value
      nInputs['currentServicerAddress'].value = companyConfig?.officeAddress.value
      setInputs(inputLogic(nInputs))
      setLoaded(true)
      props.setLoading(false)
    })()
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].value = value
    if (key === 'transferEffectiveDate') newInputs = inputLogic(newInputs)
    setInputs(newInputs)
  }

  const onGenerate = async () => {
    const result = await confirmGenerate('Servicing Transfer')
    if (!result) return

    const values: Record<string, any> = {}

    Object.keys(inputs).forEach((key) => {
      const { value } = inputs[key]
      values[key] = value
    })

    setDownload(true)
    props.setLoading(true)

    values.result = result
    const res = await submitPostClosingPDF('servicingTransfer', values)
    if (result !== 'email') downloadFile(`Notice of Servicing Transfer Letter - ${values.loanNumber}.pdf`, res)
    toast('Successfully completed', { type: 'success' })

    setDownload(false)
    props.setLoading(false)
  }

  const initServicerChoose = (servicer: any) => {
    let newInputs = cloneDeep(inputs)
    newInputs['servicingCompany'].value = servicer.servicerName
    newInputs['servicingCompanyAddress'].value = servicer.paymentAddress
    newInputs['newServicerPhone'].value = servicer.servicerPhone
    newInputs['newServicerPhoneHours'].value = servicer.servicerPhoneHours
    newInputs['newServicerAddress'].value = servicer.businessAddress
    newInputs['servicingAttn'].value = servicer.servicerPhoneContact

    setInputs(newInputs)
  }

  return (
    <div className="p-4">
      {loaded && (
        <div className="mb-5">
          <ServicerChoose initServicerChoose={initServicerChoose} />
        </div>
      )}
      <div>
        <div className="text-lg font-bold text-center mb-8">NOTICE OF SERVICING TRANSFER</div>

        <div className="grid grid-cols-3 gap-4 mb-5">{renderParagraph(paragraphObj, 0, onChange)}</div>

        <div className="flex flex-wrap items-end mb-5">{renderParagraph(paragraphObj, 1, onChange)}</div>
        <div className="flex flex-wrap items-end mb-5">{renderParagraph(paragraphObj, 2, onChange)}</div>
        <div className="flex flex-wrap items-end mb-5">{renderParagraph(paragraphObj, 3, onChange)}</div>
        <div className="flex flex-wrap items-end mb-5">{renderParagraph(paragraphObj, 4, onChange)}</div>
        <div className="flex flex-wrap items-end mb-5">{renderParagraph(paragraphObj, 5, onChange)}</div>
        <div className="flex flex-wrap items-end mb-12">{renderParagraph(paragraphObj, 6, onChange)}</div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">{renderParagraph(paragraphObj, 7, onChange)}</div>

        <div className="mb-5">
          Under federal law, during the 60-day period following the effective date of the transfer of the loan
          servicing, a loan payment received by your old servicer on or before its due date may not be treated by the
          new servicer as late, and a late fee may not be imposed on you.
        </div>

        <div className="block text-center -mb-2">
          <Button onClick={onGenerate} loading={download} className="px-10">
            Generate Document
          </Button>
        </div>
      </div>
    </div>
  )
}
