export interface EmailTemplate {
  id: number
  group: string
  subject: string
  title: string
  content: string
  links: IEmailButton[]
}

export interface IEmailButton {
  label: string
  url: string
  type: 'button' | 'href'
}

export const IButtonType: Record<string, any> = { button: 'Button', href: 'Link' }
