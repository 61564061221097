import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAdminConfig, setAdminConfig } from 'services'
import { MultiSelect, ToggleButton } from 'stories/components'
import { capitalizeFirstLetter, getCharFromIndex } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import {
  LoanStatusRectrictionConfigType,
  LoanStatusRestriction,
  LoanStatusRestrictionTextMap,
  LoanStatusRestrictionType,
  LoanStatusRestrictionTypes,
} from './types'

export const LoanStatusRestrictions = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<LoanStatusRectrictionConfigType>()
  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    setLoading(true)
    getAdminConfig('loanStatusRestriction')
      .then((values) => setData(values))
      .finally(() => setLoading(false))
  }

  const onChange = async (
    type: LoanStatusRestrictionType,
    key: string,
    value: LoanStatusRestriction,
    updateDb = true,
  ) => {
    if (!data) return

    if (updateDb) {
      setLoading(true)
      await setAdminConfig('loanStatusRestriction', {
        type,
        key,
        data: value,
      })
    }

    const newData = cloneDeep(data)
    const index = data[type].findIndex((v) => v.key === key)
    newData[type][index] = value
    setData(newData)
    setLoading(false)
  }

  return (
    <div className="relative px-3 py-1.5 min-h-[100vh]">
      <LayoutLoading show={isLoading} />

      {data && (
        <div className="w-full">
          <div className="text-xl font-bold mb-4">1. Loan Status</div>
          {LoanStatusRestrictionTypes.map((type) => (
            <div className="mb-4">
              <RenderInput
                Key={type}
                key={type}
                input={{ inputType: 'section', title: capitalizeFirstLetter(type) }}
                onChange={() => {}}
              />
              {data[type]
                ?.filter((v) => !!LoanStatusRestrictionTextMap[v.key])
                .map((restriction, index) => (
                  <div
                    className={`flex justify-between items-center p-2 pl-4 ${index % 2 && 'bg-slate-100'}`}
                    key={`${type}-${index}`}
                  >
                    <div className="flex-1">
                      <p className="text-sm mb-2">
                        {getCharFromIndex(index)}. {LoanStatusRestrictionTextMap[restriction.key]}
                      </p>
                      <div className="ml-4">
                        <MultiSelect
                          id={`${type}-${index}-select`}
                          options={loanGlobalStatus}
                          className="max-w-xl w-full"
                          title="Restricted Loan Status"
                          defaultSelected={false}
                          value={restriction.loanStatus}
                          onChange={(value) =>
                            onChange(type, restriction.key, { ...restriction, loanStatus: value }, false)
                          }
                          onBlur={() => onChange(type, restriction.key, restriction)}
                        />
                      </div>
                    </div>
                    <ToggleButton
                      id={`${type}-${index}`}
                      value={restriction.enabled}
                      onChange={(value) => onChange(type, restriction.key, { ...restriction, enabled: value })}
                    />
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
