import { GlobalConfigType } from 'actions'
import cloneDeep from 'clone-deep'
import { COMPANY_TITLE } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { downloadFile, getPostClosingFields, submitPostClosingPDF } from 'services'
import { Button } from 'stories/components'
import { formatDateYMD, InputConvert } from 'utils'
import { renderParagraph } from 'utils/RenderParagraph'

import { confirmGenerate } from '../ConfirmGenerateDialog'
import { ServicerChoose } from '../ServicerChoose'
import { defaultInputs } from './constants'
import { paragraph } from './paragraph'

export function TransferLetter(props: any) {
  const params: Record<string, any> = useParams()

  const companyConfig = useSelector((state: any) => state.globalConfigReducer[GlobalConfigType.Footer])

  const [inputs, setInputs] = useState(defaultInputs())
  const [download, setDownload] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const paragraphObj = paragraph(inputs)

  useEffect(() => {
    ;(async () => {
      const nInputs = cloneDeep(inputs)
      props.setLoading(true)

      const data = await getPostClosingFields('transferLetter')

      Object.keys(nInputs).forEach((key) => {
        nInputs[key].value = data[key] || ''
      })

      nInputs['date'].value = formatDateYMD()
      setInputs(nInputs)
      setLoaded(true)
      props.setLoading(false)
    })()
  }, [])

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)

    setInputs(newInputs)
  }

  const onGenerate = async () => {
    const result = await confirmGenerate('Transfer Letter')
    if (!result) return

    const values: Record<string, any> = {}

    Object.keys(inputs).forEach((key) => {
      const { value } = inputs[key]
      values[key] = value
    })

    setDownload(true)
    props.setLoading(true)

    values.result = result
    const res = await submitPostClosingPDF('transferLetter', values)
    if (result !== 'email') downloadFile(`HOI Transfer letter - ${params.loan_number}.pdf`, res)
    toast('Successfully completed', { type: 'success' })

    setDownload(false)
    props.setLoading(false)
  }

  const initServicerChoose = (servicer: any) => {
    let newInputs = cloneDeep(inputs)
    newInputs['servicerName'].value = servicer.lossPayeeName
    newInputs['lenderAddress'].value = servicer.lossPayeeAddress
    setInputs(newInputs)
  }

  return (
    <div className="p-4">
      {loaded && (
        <div className="mb-5">
          <ServicerChoose initServicerChoose={initServicerChoose} />
        </div>
      )}
      <div className="text-center text-lg font-bold mb-6">HAZARD INSURANCE – TRANSFER LETTER</div>

      <div className="flex flex-wrap mb-6">{renderParagraph(paragraphObj, 0, onChange)}</div>
      <div className="mb-6">{renderParagraph(paragraphObj, 1, onChange)}</div>

      <p className="mb-6">RE:</p>
      <div className="grid grid-cols-4 gap-6 mb-10 pl-10">{renderParagraph(paragraphObj, 2, onChange)}</div>

      <p className="mb-10">
        The servicing of the above referenced loan has been transferred. Please forward all future renewal billing to
        the address listed below and issue an endorsement to correct the mortgagee clause to read:
      </p>

      <div className="flex flex-col justify-center items-center mb-5">{renderParagraph(paragraphObj, 3, onChange)}</div>
      <div className="mb-4">Sincerely,</div>
      <div className="">
        {COMPANY_TITLE} NMLS {companyConfig?.nmls.value}
      </div>
      <div className="">{companyConfig?.officeAddress.value}</div>
      <div className="mb-4">Office: {companyConfig?.tollFree.value}</div>
      <div className="block text-center -mb-2">
        <Button onClick={onGenerate} loading={download} className="px-10">
          Generate Document
        </Button>
      </div>
    </div>
  )
}
