import { useState } from 'react'

import { Pagination } from '../Pagination/Pagination'

interface PlainTableProps {
  /**
   * Header of Table
   */
  header: string[]
  /**
   * Table data
   */
  data?: Array<Array<string | number | JSX.Element | undefined> | null>

  classNames?: string[]

  widthClass?: string

  thClass?: string
  tdClass?: string

  trClasses?: Record<number, string>
  isLastColumnStatic?: boolean
  hasPagination?: boolean
  itemCountPerPage?: number
}

export const PlainTable = ({
  header = [],
  data = [],
  classNames = [],
  widthClass = 'w-full',
  thClass = '',
  tdClass = 'px-6 py-4 whitespace-nowrap',
  trClasses = {},
  isLastColumnStatic = false,
  hasPagination = false,
  itemCountPerPage = 10,
}: PlainTableProps) => {
  const [pageNum, setPageNum] = useState(0)

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  return (
    <>
      <div className="relative overflow-auto mb-6 shadow-md rounded">
        <table className={`${widthClass} text-sm text-left text-gray-900 dark:text-gray-400 pl-6`}>
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {header.map((item, index) => (
                <th
                  scope="col"
                  className={`px-6 py-3 ${thClass} ${classNames[index]} ${
                    isLastColumnStatic && header.length - 1 == index
                      ? 'sticky right-0 z-10 bg-gray-200 dark:bg-gray-800'
                      : ''
                  }`}
                  key={item}
                >
                  <span dangerouslySetInnerHTML={{ __html: item }} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-[14.5px] text-gray-900">
            {data.map((row, index) => {
              if (hasPagination && (index < pageNum * itemCountPerPage || index >= (pageNum + 1) * itemCountPerPage))
                return
              return (
                <tr key={index} className={`${trClasses[index] || ''} ${index % 2 ? 'bg-gray-50' : ''}`}>
                  {row &&
                    row.map((item, rIndex) => {
                      if ((item as any) === true) item = 'Y'
                      if ((item as any) === false) item = 'N'

                      return (
                        <td
                          className={`${tdClass} ${rIndex == 0 && 'font-medium text-gray-900 dark:text-white'} ${
                            isLastColumnStatic && row.length - 1 == rIndex
                              ? `sticky right-0 z-10 ${index % 2 ? 'bg-gray-200' : 'bg-white'}`
                              : ''
                          }`}
                          key={rIndex}
                        >
                          {item}
                        </td>
                      )
                    })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {hasPagination && (
        <Pagination
          totalCount={data.length}
          itemCountPerPage={itemCountPerPage}
          onNavigate={onPageNavigate}
          pageNum={pageNum}
        />
      )}
    </>
  )
}
