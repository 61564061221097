import { type InputType } from 'config'

export const emailInputs = (): Record<string, InputType> => {
  return {
    emailTo: {
      inputType: 'multiselect',
      title: `Email to`,
      value: [],
      options: [],
      defaultSelected: false,
      required: true,
    },
    type: {
      inputType: 'select',
      title: `Email Subject`,
      tooltip: 'Conditions & Template / Email Templates',
      value: '',
      options: {},
      defaultSelected: false,
      hasDefaultOption: true,
      required: true,
      sort: true,
      sortKey: 'value',
    },
    content: {
      inputType: 'textarea',
      title: 'Email Content',
      rows: 10,
    },
    includes: {
      inputType: 'multiselect',
      title: `Include Loan Info`,
      value: [],
      options: {
        LoanNumber: 'Loan Number',
        BorrowerName: 'Borrower Name',
        EntityName: 'Entity Name',
        SubjectPropertyAddress: 'Property Address',
      },
      defaultSelected: false,
    },
    links: {
      inputType: 'custom',
      title: '',
      render: () => {},
      span: 'full',
    },
  }
}

export const defaultInputs = (loanGlobalStatus: any): Record<string, InputType> => {
  return {
    section1: {
      inputType: 'section',
      title: 'Loan Status',
      span: 'full',
    },
    statusTo: {
      inputType: 'select',
      title: 'Status Change To',
      options: loanGlobalStatus,
      hasDefaultOption: true,
      defaultOptionText: '- No Change -',
      required: false,
    },
    statusToRequireVerify: {
      inputType: 'check',
      title: 'Require Validation',
      tooltip:
        'Check all statuses of Loan (depending on the status):<br/>expiration dates, whether all conditions are cleared',
    },
    statusListBeforeToUpdate: {
      inputType: 'multiselect',
      title: `Update Loan status when below`,
      options: { ...loanGlobalStatus, noStatus: 'No Status' },
      tooltip: 'Update Loan Status when is in the status list',
      defaultSelected: false,
      required: false,
    },
    section2: {
      inputType: 'section',
      title: 'Emails',
      span: 'full',
    },
    section3: {
      inputType: 'section',
      title: 'Chain of Tasks',
      span: 'full',
    },
  }
}
