import { ArrowTopRightOnSquareIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { USER_ATTETION_VIEWED } from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal } from 'stories/components'
import { formatDate, isPartyFilled, thousandSeperator } from 'utils'

export const BorrowerAttentionModal = ({
  loans,
  conditions,
  documents,
  signedStatus,
  assetStatus,
  borrowerRequiredParties,
  onClose,
}: {
  loans: Record<string, any>[]
  conditions: Record<number, string[]>
  documents: Record<number, any[]>
  signedStatus: Record<number, Record<string, any>>
  assetStatus: Record<number, boolean>
  borrowerRequiredParties: Record<number, any>
  onClose: () => void
}) => {
  const [loanGlobalStatus, vendor] = useSelector((state: any) => [state.loanGlobalStatus, state.vendor])
  const dispatch = useDispatch()
  const navigate = useHistory()

  const renderHeader = (title: string | JSX.Element, key: string) => {
    return (
      <th scope="col" className="py-3 px-2" key={key}>
        {title}
      </th>
    )
  }

  const renderAttentions = (loanNumber: number) => {
    const attentions: Array<Array<any>> = []
    if (signedStatus[loanNumber].signRequestSent || !signedStatus[loanNumber].signedDate)
      attentions.push(['Sign Loan Application', `/borrowerPipeline/application/${loanNumber}`, 0])
    if (!assetStatus[loanNumber] && (vendor.Plaid || vendor.Snappt))
      attentions.push([
        'Asset Verification',
        `/assetInformationVerification/_/${loanNumber}`,
        assetStatus[loanNumber] ? 1 : 0,
      ])

    conditions[loanNumber] &&
      conditions[loanNumber].forEach((v: any) => {
        if (v.cleared) return
        const conditionDocs = documents[loanNumber]?.filter(({ conditionNo }) => conditionNo === v.no)
        let isPendingReview = true
        if (!conditionDocs?.length) {
          isPendingReview = false
        } else {
          for (const item of conditionDocs) {
            if (item.status === 'Not Reviewed') {
              isPendingReview = true
              break
            }
            if (!['Not Reviewed', 'Approved', ''].includes(item.status)) isPendingReview = false
          }
        }
        attentions.push([v.name, `/borrowerPipeline/submit/${loanNumber}`, isPendingReview ? 2 : 0])
      })

    borrowerRequiredParties[loanNumber] &&
      Object.keys(borrowerRequiredParties[loanNumber]).map((key) => {
        if (isPartyFilled(borrowerRequiredParties[loanNumber][key])) return
        attentions.push([`Enter ${key} Information`, `/borrowerPipeline/parties/${loanNumber}?edit=${key}`, 0])
      })

    return (
      <ul className="text-sm list-disc">
        {attentions.map((v, index) => (
          <li className="cursor-pointer hover:underline" key={index}>
            <div className=" flex items-center justify-between gap-2">
              <span onClick={() => completeAttention(v[1])}>{v[0]}</span>
              {v[2] === 1 && (
                <span className="ml-1 mb-0.5 text-shade-blue" key={0}>
                  <CheckIcon className="w-4 h-4"></CheckIcon>
                </span>
              )}
              {v[2] === 0 && (
                <span className="ml-1 mb-0.5 text-red-800" key={0}>
                  <XMarkIcon className="w-4 h-4"></XMarkIcon>
                </span>
              )}
              {v[2] === 2 && <span className="bg-yellow-100 italic ml-2 rounded pl-1">Pending Review</span>}
            </div>
          </li>
        ))}
      </ul>
    )
  }

  const completeAttention = (link: string) => {
    dispatch({
      type: USER_ATTETION_VIEWED,
    })
    navigate.push(link)
  }

  return (
    <Modal isOpen title="Outstanding loans require action" onClose={onClose} titleOkay="" titleCancel="Close">
      <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {renderHeader('Loan #', 'no')}
            <th scope="col" className="py-2 px-2">
              <span>Property Address</span>
            </th>
            <th scope="col" className="py-2 px-2">
              <span>
                <div className="border-b w-fit mb-1 border-gray-300">Purpose</div>
                Product
              </span>
            </th>
            <th scope="col" className="py-2 px-2">
              <span>
                <div className="border-b w-fit mb-1 border-gray-300">Status</div>
                As Of
              </span>
            </th>
            {renderHeader('Loan Amount', 'proposedLoanAmount')}
            {renderHeader('Items that Require Attention', 'attentions')}
          </tr>
        </thead>
        <tbody className="text-[14px] text-gray-900">
          {loans.map((item: any, index: number) => {
            return (
              <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                <td className="pl-3">
                  <span
                    className="font-bold text-shade-blue hover:underline cursor-pointer"
                    onClick={() => completeAttention(`/borrowerPipeline/overview/${item.no}`)}
                  >
                    <span className="flex items-center gap-2">
                      <div>{item.byteproFileName || item.no}</div>
                      <ArrowTopRightOnSquareIcon className="w-3 h-3" />
                    </span>
                  </span>
                </td>
                <td className="px-2 py-2 min-w-[170px]">
                  <span>{item.subjectPropertyAddress}</span>
                </td>
                <td className="px-2 py-2 min-w-[170px]">
                  <span>
                    <div className="border-b w-fit mb-1 border-gray-300">{item.transactionType}</div>
                    {item.productType}
                  </span>
                </td>
                <td className="px-2 py-2">
                  <span>
                    <div className="border-b w-fit mb-1 border-gray-300">{loanGlobalStatus[item.loanStatus]}</div>
                    {formatDate(item.asOf)}
                  </span>
                </td>
                <td className="px-2 py-2">{thousandSeperator(item.proposedLoanAmount)}</td>
                <td className="px-2 py-2">{renderAttentions(item.no)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Modal>
  )
}
