import { companyName } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTitle } from 'utils/pageTitle'

import { CredentialConfig } from './CredentialConfig'
import { DataVerifySSA } from './DataVerifySSA'
import { NationalFloodTab } from './NationalFlood'

type menuType = {
  [key: string]: string //fix this
}

const leftMenuItems: menuType = {
  cicMeridianLink: 'Credit Report (MeridianLink CIC)',
  ctiMeridianLink: 'Credit Report (MeridianLink CtiNetwork)',
  xactus: 'Credit Report (Xactus360)',
  dataverifySSA: 'Dataverify SSA',
  dataverifyTAX: 'Dataverify 4506',
  nationalFlood: 'ServiceLink National Flood',
  rtltrader: 'RTL Trader',
  bytepro: 'Bytepro',
  appraisalNation: 'Appraisal Nation',
  vsamc: 'Valuation Services AMC (VSAMC)',
  nationWide: 'Nationwide Appraisal Network (NAN)',
  naan: 'Nationwide AMC (NAAN)',
  valutrac: 'Elite Valuation',
  dataverify: 'Data Verify',
  serviceLink: 'Service Link',
  // docgen: 'Docgen',
  trinity: 'Trinity',
  stripe: 'Stripe',
  plaid: 'Plaid',
  snappt: 'Snappt',
  // usps: 'USPS',
  lexisnexis: 'SmartLinx Person Report',
  obie: 'Obie',
  sitexPro: 'SitexPro',
  fci: 'FCI',
  afx: 'Afx Research',
  lightningDocs: 'Lightning Docs',
  middesk: 'Middesk',
}

export * from './NationalFlood/NationalFlood'

export default function Vendors() {
  useTitle(`Vendors - ${companyName}`)

  const navigate = useHistory()
  const [selectedMenu, setSelectedMenu] = useState(Object.keys(leftMenuItems)[0])

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
    navigate.push(`/vendors/${menuItem}`)
  }

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'dataverifySSA':
        return <DataVerifySSA type="ssa" key="ssa" />
      case 'dataverifyTAX':
        return <DataVerifySSA type="tax" key="tax" />
      case 'nationalFlood':
        return <NationalFloodTab />
      default:
        return <CredentialConfig type={selectedMenu} title={leftMenuItems[selectedMenu]} key={selectedMenu} />
    }
  }, [selectedMenu])

  useEffect(() => {
    setMenu(Object.keys(leftMenuItems)[0])
  }, [])

  return (
    <div className="vendors-container py-6">
      <div className="vendors-wrapper max-w-screen-2xl m-auto grid grid-cols-12 gap-6 px-2">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded">
          <ul className="sidebar-items flex flex-col p-4 pb-20">
            {Object.keys(leftMenuItems)
              .sort((key1, key2) => (leftMenuItems[key1] > leftMenuItems[key2] ? 1 : -1))
              .map((item: string, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      setMenu(item)
                    }}
                    className="border-b py-2"
                  >
                    <p
                      className={`hover:underline cursor-pointer ${
                        selectedMenu === item ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                      }`}
                    >
                      {index + 1}. {leftMenuItems[item as keyof typeof leftMenuItems]}
                    </p>
                  </li>
                )
              })}
          </ul>
        </div>
        <div className="content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1">{renderFragment}</div>
      </div>
    </div>
  )
}
