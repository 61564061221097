import { ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { createLoanDataVerify, getLoanDataVerifyCondition, openS3Document, updateLoanDataVerify } from 'services'
import { Button, PlainTable, Select, TextArea } from 'stories/components'
import { formatTime } from 'utils'

import { DVComponentScore, DVConditionResolution, DVResponse, SeverityColors, StatusOptions } from './constants'

export const FraudGaurd = () => {
  const auth = useSelector((state: any) => state.auth)
  const [action, setAction] = useState<string | null>(null)
  const [isLoaded, setLoaded] = useState(false)
  const [data, setData] = useState<null | DVResponse>(null)
  const [currentResolutions, setCurrentResolutions] = useState<DVConditionResolution[]>([])

  useEffect(() => {
    getConditions()
  }, [])

  const getScores = (scores: DVComponentScore[]) => {
    const IDScore = scores.find((score) => score.ScoreType == 'IDVerify')
    const AppScore = scores.find((score) => score.ScoreType == 'AppVerify')
    const PropScore = scores.find((score) => score.ScoreType == 'PropertyVerify')
    return {
      IDScore,
      AppScore,
      PropScore,
    }
  }

  const docSubmissions = useMemo(() => {
    if (!data || !data.submissions) return
    return data.submissions.map((submission, index) => {
      const { attributes } = submission
      const scores = getScores(submission.scores)
      return [
        `${index + 1}`,
        <span className="capitalize">{submission.type}</span>,
        submission.createdBy,
        formatTime(submission.createdAt),
        `Drive: ${attributes.DRIVEAdjustedScore || attributes.DRIVEScore || ''}, ID: ${
          scores.IDScore?.AdjustedScore || scores.IDScore?.Score || ''
        }, App: ${scores.AppScore?.AdjustedScore || scores.AppScore?.Score || ''}, Property: ${
          scores.PropScore?.AdjustedScore || scores.PropScore?.Score || ''
        }`,
        submission.fileKey && (
          <span className="flex">
            <span
              className="p-1 hover-shadow1 text-shade-blue rounded cursor-pointer"
              onClick={() => openS3Document(submission.fileKey || '')}
            >
              <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
            </span>
          </span>
        ),
      ]
    })
  }, [data])

  const docScore = useMemo(() => {
    if (!data || !data.scores) return
    const { attributes } = data
    const scores = getScores(data.scores) as any
    const scoreMaps: Record<string, string> = {
      'ID Verify': 'IDScore',
      'App Verify': 'AppScore',
      'Property Verify': 'PropScore',
    }
    const results = [['DRIVE Score', attributes.DRIVEScore, attributes.DRIVEAdjustedScore]]
    Object.keys(scoreMaps).forEach((text) => {
      const key = scoreMaps[text]
      if (scores[key] && scores[key]?.Score !== undefined)
        results.push([text, scores[key]?.Score || '', scores[key]?.AdjustedScore || ''])
    })
    return results
  }, [data])

  const pendingConditionIndexes = useMemo(() => {
    return currentResolutions.map((v, index) => (v.Comment && v.Status ? index : -1)).filter((v) => v != -1)
  }, [currentResolutions])

  const pendingConditionData = useMemo(() => {
    if (!data) return []
    return pendingConditionIndexes.map((index) => [
      `${data.conditions[index].Code} (${data.conditions[index].Severity})`,
      `${auth.profile.name}: ${currentResolutions[index].Comment}`,
      currentResolutions[index].Status,
      <button
        className="hover-shadow1 p-1 cursor-pointer text-red-500 rounded"
        onClick={() => {
          const resolution = cloneDeep(currentResolutions)
          resolution[index].Comment = ''
          resolution[index].Status = ''
          setCurrentResolutions(resolution)
        }}
      >
        <TrashIcon className="w-4 h-4" />
      </button>,
    ])
  }, [pendingConditionIndexes])

  const getConditions = () => {
    setAction('conditions')
    getLoanDataVerifyCondition()
      .then((data) => setConditionResponse(data))
      .finally(() => {
        setAction(null)
        setLoaded(true)
      })
  }

  const setConditionResponse = (data: DVResponse) => {
    setData(data)
    setCurrentResolutions(
      data.conditions.map(() => ({
        Comment: '',
        Status: '',
      })),
    )
  }

  const onCreateRequest = () => {
    setAction('loan')
    createLoanDataVerify()
      .then((data) => setConditionResponse(data))
      .finally(() => setAction(''))
  }

  const onUpdateValue = (key: 'Status' | 'Comment', value: string, index: number) => {
    const resolution = cloneDeep(currentResolutions)
    resolution[index][key] = value
    setCurrentResolutions(resolution)
  }

  const onUpdate = () => {
    if (!data || !pendingConditionIndexes.length) return
    setAction('conditions')

    const requestData = pendingConditionIndexes.map((index) => ({
      code: data.conditions[index].Code,
      status: currentResolutions[index].Status,
      comment: currentResolutions[index].Comment,
    }))
    updateLoanDataVerify(requestData)
      .then((response) => setConditionResponse(response))
      .finally(() => setAction(null))
  }

  const onSaveScroll = () => {
    const element = document.getElementById('saveChanges')

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <div className="DV-container relative mb-6">
      {/* <h2 className="text-xl font-bold flex items-center mb-3">DataVerify Drive</h2> */}
      <LayoutLoading show={!!action} />

      {!isLoaded ? (
        <></>
      ) : (
        <div className="mt-4">
          <div className="mb-6">
            <Button color="gray" className="mb-[0px]" onClick={onCreateRequest} loading={action == 'loan'}>
              <>{!data ? 'Create' : 'Update'} Loan on DataVerify</>
            </Button>
          </div>
          {data && (
            <>
              <div className="mb-6">
                <div className="border-b mb-2 font-variation-settings-600">- Submissions</div>
                <PlainTable header={['No', 'Type', 'Requested by', 'Date', 'Scores', 'PDF']} data={docSubmissions} />
              </div>
              <div className="mb-6">
                <div className="border-b mb-2 font-variation-settings-600">- DRIVE Scoring Final Results</div>
                <div className="w-fit">
                  <PlainTable header={['Type', 'Origin Score', 'Adjusted Score']} data={docScore} tdClass="px-6 py-2" />
                </div>
              </div>
              <div className="overflow-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-3 py-3">
                        Code
                      </th>
                      <th scope="col" className="px-3 py-3">
                        Subject
                      </th>
                      <th scope="col" className="px-3 py-3">
                        Description
                      </th>
                      <th scope="col" className="px-3 py-3">
                        Action(s) to Resolve
                      </th>
                      <th scope="col" className="px-3 py-3">
                        Scoring Analysis
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.conditions?.map((item, index: number) => {
                      const { ConditionResolutions } = item
                      const color = (SeverityColors as any)[item.Severity]
                      const borderClass = `border-l-[5px] border-l-${color}-400`
                      let trBack = `${index % 2 ? 'bg-gray-50' : ''}`
                      const lastResolution = ConditionResolutions.length ? ConditionResolutions[0] : null
                      const currentResolution = currentResolutions[index]
                      if (lastResolution && lastResolution.Status === 'Cleared') trBack = 'bg-green-100'

                      return (
                        <Fragment key={index}>
                          <tr className={`${trBack}`} key={index}>
                            <td className={`px-3 py-3 capitalize ${borderClass}`}>
                              {item.Code} <p className={`text-${color}-500`}>({item.Severity})</p>
                            </td>
                            <td className="px-3 py-3">{item.Subject}</td>
                            <td className="px-3 py-3">{item.Description}</td>
                            <td className="px-3 py-3">{item.ActionStep}</td>
                            <td className="px-3 py-3">{item.ScoringAnalysis}</td>
                          </tr>

                          {ConditionResolutions.map((resolution, index) => (
                            <tr className={`${trBack}`} key={index}>
                              <td className={borderClass}></td>
                              <td className="px-3 border-t">
                                <div className="flex flex-1 gap-2">{formatTime(resolution.DateTime)}</div>
                              </td>
                              <td className="px-3 border-t">
                                <div className="flex flex-1 gap-2">{resolution.Status}</div>
                              </td>
                              <td colSpan={2} className={`px-2 py-3 border-t`}>
                                <div
                                  className="flex flex-1 gap-2"
                                  dangerouslySetInnerHTML={{ __html: resolution.Comment }}
                                />
                              </td>
                            </tr>
                          ))}

                          {currentResolution && (
                            <tr
                              className={`${trBack} border-b-[3px] ${
                                pendingConditionIndexes.includes(index) && 'bg-yellow-50'
                              }`}
                            >
                              <td className={borderClass}></td>
                              <td colSpan={4} className={`pt-2 border-t`}>
                                <div className="flex w-full gap-4 px-3 items-baseline">
                                  <Select
                                    id="status"
                                    title="Status"
                                    className="mb-2 w-[180px]"
                                    options={StatusOptions}
                                    hasDefaultOption
                                    required
                                    value={currentResolution.Status}
                                    onChange={(value) => onUpdateValue('Status', value, index)}
                                  />
                                  <div className="flex-1">
                                    <TextArea
                                      title="Comment"
                                      className="mb-2"
                                      rows={0}
                                      required
                                      value={currentResolution.Comment}
                                      onChange={(value) => onUpdateValue('Comment', value, index)}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      )}
      <SaveChanges show={pendingConditionIndexes.length > 0} onSave={onSaveScroll} />
      {pendingConditionIndexes.length ? (
        <div className="mt-6" id="saveChanges">
          <h2 className="text-xl font-bold flex items-center mb-3">
            Pending Condition Updates
            <span className="font-normal text-[15px] ml-1">({pendingConditionIndexes.length})</span>
          </h2>

          <PlainTable
            header={['Code', 'Comment', 'Status', 'Action']}
            classNames={['w-32', '', 'w-32', 'w-32']}
            data={pendingConditionData}
            tdClass="px-4 py-2"
          />
          <Button className="w-[180px]" onClick={onUpdate} loading={action == 'conditions'}>
            Save
          </Button>
        </div>
      ) : null}
    </div>
  )
}
