import { InputType } from 'config'

export const defaultFooterConfigInputs = () => {
  const rlt: Record<string, InputType> = {
    section1: {
      inputType: 'section',
      title: 'Newsletter Signup',
      span: 'full',
    },
    showNewsletter: {
      inputType: 'togglebutton',
      title: 'Show Newsletter Signup',
    },
    section2: {
      inputType: 'section',
      title: 'About Us',
      span: 'full',
    },
    aboutUsDescription: {
      inputType: 'text',
      title: 'Description',
      span: 'full',
    },
    section3: {
      inputType: 'section',
      title: 'Contact Us',
      span: 'full',
    },
    contactUsDescription: {
      inputType: 'text',
      title: 'Description',
      span: 'full',
    },
    phone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone Number',
    },
    tollFree: {
      inputType: 'text',
      type: 'phone',
      title: 'Toll Free',
    },
    email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
    },
    section4: {
      inputType: 'section',
      title: 'Corporate Office',
      span: 'full',
    },
    officeAddress: {
      inputType: 'map',
      title: 'Office Address',
      span: 1,
    },
    nmls: {
      inputType: 'text',
      title: 'Company NMLS',
    },
    section5: {
      inputType: 'section',
      title: 'Social Website URLs',
      span: 'full',
    },
    facebook: {
      inputType: 'text',
      title: 'Facebook',
    },
    linkedin: {
      inputType: 'text',
      title: 'LinkedIn',
    },
    youtube: {
      inputType: 'text',
      title: 'Youtube',
    },
    twitter: {
      inputType: 'text',
      title: 'Twitter',
    },
    instagram: {
      inputType: 'text',
      title: 'Instagram',
    },
  }

  return rlt
}
