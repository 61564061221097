import {
  AccountType,
  API_BORROWER_ACTIVITIES,
  API_USER_ACTIVITIES,
  API_USER_ACTIVITIES_DOWNLOAD_CSV,
  API_USER_ACTIVITIES_DOWNLOAD_PDF,
  API_USER_ACTIVITY_FILTER,
  API_USER_ADMIN_EMAILS,
  API_USER_AUTO_COMPLETE,
  API_USER_BROKER_PAYMENT,
  API_USER_BROKER_VERIFICATION,
  API_USER_BROKER_VERIFICATION_BY_USER,
  API_USER_BY_COMPANY,
  API_USER_BY_ID,
  API_USER_CHILD_BROKER,
  API_USER_CHILDREN,
  API_USER_CONFIGURATION,
  API_USER_CREATE,
  API_USER_DELETE,
  API_USER_EXPORT_HISTORY,
  API_USER_FILTER,
  API_USER_FILTER_MASTER_EMAILS,
  API_USER_GET_LOGS,
  API_USER_LOGIN_PAGE_MODALS,
  API_USER_NOTES,
  API_USER_PROFILE,
  API_USER_PROPS,
  API_USER_RECOVER,
  API_USER_ROLES,
  API_USER_SIGN_BROKER_VERIFICATION_PDF,
  API_USER_TREE,
  API_USER_UNSUBSCRIBE,
  API_USER_UPDATE,
  API_USER_UPDATE_PWD,
  API_USER_UPDATE_STATUS,
  API_USER_VALIDATE_BROKER_VERIFICATION_SIGNATURE,
  baseUrl,
  UserActivityFilterQuery,
  UserFilterQuery,
} from 'config'
import type { ChildBroker } from 'pages/ManageAccounts'
import { store } from 'reducers'
import Api from 'services/api'

import { downloadFile } from './documents'

const API_USER_ACCOUNT_EXECUTIVES = '/auth/getAccountExecutives'

export const requestUserRoles = (accountType: AccountType, userId = 0): Promise<Record<number, string>> => {
  return Api.get(API_USER_ROLES, { accountType, userId })
}

export const getAccountExecutives = (): Promise<Record<number, string>> => {
  return Api.get(API_USER_ACCOUNT_EXECUTIVES)
}

export const requestUserProps = (userId: number): Promise<Record<string, string>> => {
  return Api.get(API_USER_PROPS, {}, { userId })
}

export const submitUser = (userId: number, data: Record<string, string | number | boolean>) => {
  if (!userId) return Api.post(API_USER_CREATE, data)

  return Api.put(API_USER_UPDATE, data, { userId })
}

export const userSearchAutoComplete = (data: any) => {
  return Api.post(API_USER_AUTO_COMPLETE, data)
}

export const filterUsers = (filterQuery: UserFilterQuery = {}) => {
  return Api.get(API_USER_FILTER, filterQuery)
}

export const getExportHistory = (skip: number, count: number) => {
  return Api.get(API_USER_EXPORT_HISTORY, { skip, count })
}

export const getUserById = (id: number) => {
  return Api.get(API_USER_BY_ID, {}, { id })
}

export const getUserConfiguration = (userId: number) => {
  return Api.get(API_USER_CONFIGURATION, {}, { userId })
}

export const updateUserConfiguration = (userId: number, data: Record<string, string | number | boolean>) => {
  return Api.post(API_USER_CONFIGURATION, data, { userId })
}

export const updateUserStatus = (userId: number, status: boolean) => {
  return Api.put(API_USER_UPDATE_STATUS, { status }, { userId })
}

export const deleteUser = (userId: number) => {
  return Api.delete(API_USER_DELETE, {}, { userId })
}

export const recoverUser = (userId: number) => {
  return Api.put(API_USER_RECOVER, {}, { userId })
}

export const updatePassword = (email: string, password: string) => {
  return Api.put(API_USER_UPDATE_PWD, { email, password })
}

export const getChildUsers = (userId: number) => {
  return Api.get(API_USER_CHILDREN, {}, { userId })
}

export const updateChildUserTypes = (userId: number, values: Record<string, string>) => {
  return Api.put(API_USER_CHILDREN, { values }, { userId })
}

export const updateChildLoanUsers = (userId: number, values: Record<string, string>) => {
  return Api.put(`${API_USER_CHILDREN}/loan`, { values }, { userId })
}

export const getUserTree = () => {
  return Api.get(API_USER_TREE, {}, {})
}

export const getUserLogs = (userId: number, fieldName: string) => {
  return Api.get(API_USER_GET_LOGS, {}, { userId, fieldName })
}

export const getUserByCompany = (companyName: string) => {
  return Api.get(API_USER_BY_COMPANY, { companyName })
}

export const updateProfile = (data: Record<string, string | number | boolean>) => {
  return Api.put(API_USER_PROFILE, data)
}

export const filterUserActivity = (filterQuery: UserActivityFilterQuery = {}): Promise<Record<string, any>[]> => {
  return Api.get(API_USER_ACTIVITY_FILTER, filterQuery)
}

export const filterUserActivities = (id: number, filter: any = {}) => {
  return Api.get(API_USER_ACTIVITIES, filter, { userId: id })
}

export const filterBorrowerActivities = (id: number, filter: any = {}) => {
  return Api.get(API_BORROWER_ACTIVITIES, filter, { userId: id })
}

export const downloadCSVfilterUserActivities = async (id: number | undefined, filter: any = {}) => {
  const res: Blob = await Api.get(API_USER_ACTIVITIES_DOWNLOAD_CSV, filter, { userId: id }, { responseType: 'blob' })
  downloadFile(`User Activity.csv`, res)
}

export const downloadPDFfilterUserActivities = async (id: number | undefined, filter: any = {}) => {
  const res: Blob = await Api.get(API_USER_ACTIVITIES_DOWNLOAD_PDF, filter, { userId: id }, { responseType: 'blob' })
  downloadFile(`User Activity.pdf`, res)
}

export const getAdminEmails = (): Promise<string[]> => {
  return Api.get(API_USER_ADMIN_EMAILS)
}

export const filterMasterEmails = (emails: string[]) => {
  return Api.post(API_USER_FILTER_MASTER_EMAILS, { emails })
}

export const updateChildBroker = (userId: number, emails: string[]): Promise<ChildBroker[]> => {
  return Api.put(API_USER_CHILD_BROKER, { emails }, { userId })
}

export const validateBrokerVerificationSignature = (data: object) => {
  return Api.post(API_USER_VALIDATE_BROKER_VERIFICATION_SIGNATURE, data)
}

export const submitBrokerVerification = (data: any[]) => {
  return Api.post(API_USER_BROKER_VERIFICATION, data)
}

export const getBrokerVerification = () => {
  return Api.get(API_USER_BROKER_VERIFICATION)
}

export const getBrokerVerificationByUser = (userId: number) => {
  return Api.get(API_USER_BROKER_VERIFICATION_BY_USER, {}, { userId })
}

export const sendCounterApprovalPaymentData = (userId: number, data: Record<string, any>) => {
  return Api.post(API_USER_BROKER_PAYMENT, data, { userId })
}

export const signBrokerVerificationPdfForUser = () => {
  return Api.post(API_USER_SIGN_BROKER_VERIFICATION_PDF, {}, {}, { timeout: 150000 })
}

export const downloadBrokerAgreementPdf = (userId: number, template?: string) => {
  const { token } = store.getState().auth
  let url = `${baseUrl}/user/downloadBrokerAgreementPdf/${userId}${template ? '/' + template : ''}?token=${token}`
  window.location.href = url
}

export const getUserNotes = (userId: number, isBorrower: boolean) => {
  return Api.get(API_USER_NOTES, {}, { userId, isBorrower })
}

export const createUserNote = (userId: number, data: Record<string, any>) => {
  return Api.post(API_USER_NOTES, data, { userId, isBorrower: data.isBorrower })
}

export const unsubscribeEmail = (data: any) => {
  return Api.post(API_USER_UNSUBSCRIBE, data, {})
}

export const getLoginPageModal = () => {
  return Api.get(API_USER_LOGIN_PAGE_MODALS)
}
