export enum FieldOrigin {
  LoanStructure = 'LoanStructure',
  BorrowerInformation = 'BorrowerInformation',
  PropertyInformation = 'PropertyInformation',
  CustomInformation = 'CustomInformation',
  AssetLiability = 'AssetLiability',
  TrackRecord = 'TrackRecord',
  DeclarationsHMDA = 'DeclarationsHMDA',
  Other = 'Other',
  Custom = 'Custom',
}

export const isCustomOrigin = (fieldOrigin: FieldOrigin | string) =>
  fieldOrigin.startsWith(FieldOrigin.CustomInformation)

export const fieldOriginOptions: Record<FieldOrigin, string> = {
  [FieldOrigin.LoanStructure]: 'Loan Structure',
  [FieldOrigin.BorrowerInformation]: 'Borrower Information',
  [FieldOrigin.PropertyInformation]: 'Property Information',
  [FieldOrigin.CustomInformation]: 'Custom Information',
  [FieldOrigin.AssetLiability]: 'Asset & Liability',
  [FieldOrigin.TrackRecord]: 'Track Record',
  [FieldOrigin.DeclarationsHMDA]: 'Declarations & HMDA',
  [FieldOrigin.Other]: 'Other',
  [FieldOrigin.Custom]: 'Custom',
}

export interface IVisibleLogic {
  fieldOrigin: FieldOrigin
  fieldKey: string
  value: string | number | boolean
}

export interface ILoanFieldPos {
  fieldOrigin: FieldOrigin
  fieldKey: string
  [x: string]: any
}

export interface ISelectRangeOption {
  title: string
  from: number
  to?: number
  chapter?: number
  days?: number
  months?: number
  visible?: boolean
}

export interface ISelectValueOption {
  title: string
  value: number
  visible?: boolean
}

export interface IVisibleProp {
  title?: string
  inputType?: string
  type?: string
  section?: string
  tooltip?: string
  defaultValue?: any
  visible?: boolean
  required?: boolean
  visibleLogic?: IVisibleLogic[][]
  visibleAccountTypes?: Record<string, boolean>
  options?: ISelectRangeOption[] | ISelectValueOption[]
}

export interface ILoanOriginSection {
  id: number
  title?: string
  fields: string[]
}

export type LoanFieldProps = {
  // [P in FieldOrigin]: Record<string, IVisibleProp>
  [x: string]: Record<string, IVisibleProp>
} & {
  sections?: {
    [T in FieldOrigin]?: ILoanOriginSection[]
  }
  order?: FieldOrigin[]
  customTitle?: Record<FieldOrigin, string>
  visibility?: Record<FieldOrigin, string>
}
