import { GlobalConfigType, setLoanData } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { PlainInput } from 'components/PlainInput'
import { SaveChanges } from 'components/SaveChanges'
import {
  FieldOrigin,
  getInitialLoanFields,
  INVALID_ALL_INPUTS,
  IVisibleProp,
  loanCustomRangeOptionFields,
  loanCustomSelectOptionFields,
  SettingKey,
} from 'config'
import { convertSelectRangeOptionsToMap } from 'pages/Admin/AdminTools/Configuration/LoanFieldsProperties/utils'
import { IUwSummaryConfig, UwSummarySection } from 'pages/Admin/AdminTools/Configuration/UnderwritingSummary/types'
import { loanOverviewAndStepLogic } from 'pages/LoanOverview/loanOverviewAndStepLogic'
import type { ILoan } from 'pages/LoanStructure/interfaces'
import { visibleLoansLogic } from 'pages/LoanStructure/Logic'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getGeoLocation, getSetting, getUnderwritingSummary, updateUnderwritingSummary } from 'services'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, openAuditLog, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import {
  defaultInputs,
  inputGroups,
  recordingInfoFields,
  RecordingInformationOptions,
  sourceOfBorrowersInterestOptions,
} from './constants'

export default function UnderwritingSummary({ child = false }) {
  const { loan, fieldProperties } = useSelector((state: any) => {
    return {
      loan: state.loan as ILoan,
      fieldProperties: ((state.globalConfigReducer[GlobalConfigType.LoanFields] || {})[FieldOrigin.LoanStructure] ||
        {}) as Record<string, IVisibleProp>,
    }
  })

  const navigate = useHistory()
  const [inputs, setInputs] = useState(defaultInputs())
  const loanInputs = getInitialLoanFields()
  const [isLoading, setLoading] = useState(false)
  const [init, setInit] = useState(true)
  const [changed, setChanged] = useState(false)
  const [recordingInfo, setRecordingInfo] = useState({
    book: '',
    page: '',
    instrument: '',
  })
  const [recordingInfoError, setRecordingInfoError] = useState({
    book: '',
    page: '',
    instrument: '',
  })
  const [latLong, setLatLong] = useState({ lat: 0, lng: 0 })
  const [config, setConfig] = useState<IUwSummaryConfig>({
    [UwSummarySection.UnderwritingInformation]: {},
  })

  const dispatch = useDispatch()

  useEffect(() => {
    initLoad()
  }, [])

  const initLoad = async () => {
    const nInputs = cloneDeep(inputs)
    const newRecordingInfo = cloneDeep(recordingInfo)

    if (loan.transactionType === 'Purchase') {
      delete nInputs.BorrowersInterestSourceTypeInSubjectProperty
      delete nInputs.BorrowersInterestSourceTitleInSubjectProperty
      delete nInputs.RecordingInfo
      delete nInputs.BorrowersInterestSourceDateInSubjectProperty
      nInputs.purchasePrice.disabled = true
    } else {
      nInputs.purchasePrice.disabled = false
    }

    setLoading(true)

    const loanNumber = setLoanNumber()
    if (loan.no != loanNumber) {
      const { success } = await loanOverviewAndStepLogic(loanNumber, '')
      if (!success) {
        return navigate.push('/pipeline')
      }
    }

    const { value } = await getSetting(SettingKey.UW_SUMMARY_CONFIG)
    setConfig(value ? JSON.parse(value) : {})

    getUnderwritingSummary()
      .then((data) => {
        Object.keys(nInputs).forEach((key) => {
          if (key === 'RecordingInfo') {
            if (Object.keys(RecordingInformationOptions).includes(data[key])) nInputs[key].value = data[key]
            else nInputs[key].value = ''
          } else nInputs[key].value = data[key]
          nInputs[key].error = InputValidate(nInputs[key])
        })
        ;['book', 'page', 'instrument'].forEach((key) => {
          if (!!data[key]) (newRecordingInfo as any)[key] = data[key]
          else (newRecordingInfo as any)[key] = ''
        })

        setInputs(nInputs)
        setRecordingInfo(newRecordingInfo)
        setLoading(false)
        setInit(false)
      })
      .catch(() => setLoading(false))
  }

  useEffect(() => {
    const geoLocation = loan.geoLocation
    if (geoLocation && geoLocation.lat && geoLocation.lng) {
      setLatLong(geoLocation)
    } else {
      getGeoLocation().then((res) => {
        dispatch(setLoanData({ key: 'geoLocation', data: res }))
        setLatLong(res)
      })
    }
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])

    if (key === 'BorrowersInterestSourceTypeInSubjectProperty') {
      if (value === 'other') newInputs['BorrowersInterestSourceTitleInSubjectProperty'].value = ''
      else newInputs['BorrowersInterestSourceTitleInSubjectProperty'].value = sourceOfBorrowersInterestOptions[value]
    }

    setChanged(true)
    setInputs(newInputs)
  }

  const validateRecordingInfo = () => {
    const newRecordingInfoError = cloneDeep(recordingInfoError)

    let hasError = false

    if (recordingInfoFields[inputs.RecordingInfo.value])
      recordingInfoFields[inputs.RecordingInfo.value].forEach((key) => {
        if (!(recordingInfo as any)[key]) {
          hasError = true
          ;(newRecordingInfoError as any)[key] = 'Required field'
        } else {
          ;(newRecordingInfoError as any)[key] = ''
        }
      })
    else hasError = true

    setRecordingInfoError(newRecordingInfoError)
    return hasError
  }

  const onSave = () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    let data: Record<string, any> = {}

    Object.keys(newInputs).forEach((key) => {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    })

    setChanged(false)
    setInputs(newInputs)

    if (loan.transactionType === 'Refinance') {
      if (inputs.RecordingInfo.value)
        recordingInfoFields[inputs.RecordingInfo.value].forEach((key) => {
          data[key] = (recordingInfo as any)[key]
        })

      hasError = validateRecordingInfo()
    }

    if (hasError) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }

    setLoading(true)

    updateUnderwritingSummary(data)
      .then(() => {
        toast('Successfully updated', { type: 'info' })
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'Underwriting',
      field: key,
      keys: {
        field: key,
      },
    }
    openAuditLog(options)
  }

  const onChangeRecordingInfo = (key: 'book' | 'page' | 'instrument', value: string) => {
    const newRecordingInfo = cloneDeep(recordingInfo)

    newRecordingInfo[key] = value
    setRecordingInfo(newRecordingInfo)
  }

  const renderRecordingInformation = useMemo(() => {
    if (loan.transactionType === 'Purchase') return <></>

    switch (inputs.RecordingInfo.value) {
      case 'bookAndPageOnly':
        return (
          <div className="flex items-center gap-2">
            <span>Book</span>
            <div className={`border-b w-40 ${recordingInfoError.book && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.book}
                content={recordingInfo.book}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('book', value)}
              />
            </div>
            <span>and</span>
            <span>Page</span>
            <div className={`border-b w-16 ${recordingInfoError.page && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.page}
                content={recordingInfo.page}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('page', value)}
              />
            </div>
          </div>
        )
      case 'instrumentOnly':
        return (
          <div className="flex items-center gap-2">
            <span>Instrument #</span>
            <div className={`border-b w-60 ${recordingInfoError.instrument && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.instrument}
                content={recordingInfo.instrument}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('instrument', value)}
              />
            </div>
          </div>
        )
      case 'bookAndPageWithInstrument':
        return (
          <div className="flex items-center flex-wrap gap-2">
            <span>Book</span>
            <div className={`border-b w-40 ${recordingInfoError.book && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.book}
                content={recordingInfo.book}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('book', value)}
              />
            </div>
            <span>and</span>
            <span>Page</span>
            <div className={`border-b w-16 ${recordingInfoError.page && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.page}
                content={recordingInfo.page}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('page', value)}
              />
            </div>
            <span>under</span>
            <span>Instrument #</span>
            <div className={`border-b w-60 ${recordingInfoError.instrument && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.instrument}
                content={recordingInfo.instrument}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('instrument', value)}
              />
            </div>
          </div>
        )
      default:
        return <></>
    }
  }, [inputs.RecordingInfo, recordingInfo, recordingInfoError, loan])

  const renderLoanInputs = () => {
    const visibleInputs = visibleLoansLogic()
    return (
      <>
        {visibleInputs.map((key) => {
          if (key == 'subjectPropertyMap') return null
          if (
            config?.[UwSummarySection.UnderwritingInformation]?.[key] &&
            config?.[UwSummarySection.UnderwritingInformation]?.[key]?.visible === false
          )
            return null

          const input = { ...loanInputs[key], disabled: true }
          input.value = (loan as any)[key]

          input.title =
            fieldProperties[key] && fieldProperties[key].title ? fieldProperties[key].title || '' : input.title

          input.type = (
            fieldProperties[key] && fieldProperties[key].type ? fieldProperties[key].type : input.type
          ) as any

          if (
            fieldProperties[key] &&
            !!fieldProperties[key]?.options &&
            !!fieldProperties[key].options?.length &&
            [...loanCustomRangeOptionFields, ...loanCustomSelectOptionFields].includes(key)
          ) {
            ;(input as any).options = convertSelectRangeOptionsToMap(fieldProperties[key]?.options || [])
          }

          return (
            <div className={`input md:col-span-${input.span || 1}`} key={key}>
              <RenderInput Key={key} input={input} onChange={() => {}} />
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className={`underwriting-summary-container ${!child && 'px-2'}`}>
      {!child && <Overview title="Underwriting Summary" />}
      <SaveChanges show={changed} onSave={onSave} />
      <div className="max-w-screen-2xl m-auto">
        <div className={`bg-white rounded ${!child && 'shadow1 mb-6 p-4'}`}>
          <div className="">
            <LayoutLoading show={isLoading} />
            {!init && (
              <div className="grid grid-cols-1 gap-5">
                {Object.keys(inputGroups[loan.transactionType]).map((key, index) => {
                  const inputGroup = inputGroups[loan.transactionType][key]

                  return (
                    <div key={index} className="md:col-span-1">
                      <div
                        className={`border-b-2 border-shade-blue w-full italic flex justify-between items-center mb-2`}
                      >
                        {index + 1}. {key}
                      </div>

                      <div className="grid gap-4 md:grid-cols-4 grid-cols-1 mb-3">
                        {inputGroup.map((item: string) => {
                          if (
                            item === 'BorrowersInterestSourceTitleInSubjectProperty' &&
                            inputs['BorrowersInterestSourceTypeInSubjectProperty'].value !== 'other'
                          )
                            return <div></div>
                          let input = inputs[item]

                          if (!!input?.key)
                            if (
                              fieldProperties[input.key] &&
                              !!fieldProperties[input.key]?.options &&
                              !!fieldProperties[input.key].options?.length &&
                              [...loanCustomRangeOptionFields, ...loanCustomSelectOptionFields].includes(input.key)
                            ) {
                              ;(input as any).options = convertSelectRangeOptionsToMap(
                                fieldProperties[input.key]?.options || [],
                              )
                            }

                          if (item === 'constructionCompanyAddressMap') {
                            return (
                              <>
                                {index == 0 && renderLoanInputs()}
                                <div className={`input md:col-span-${input.span || 2} h-[400px]`} key={item}>
                                  <RenderInput
                                    input={{
                                      ...input,
                                      value: latLong as any,
                                    }}
                                    Key={key}
                                    onChange={onChange}
                                  />
                                </div>
                              </>
                            )
                          }
                          input.history = !input.disabled
                          input.className = 'flex items-center'

                          if (item === 'AIV2MedianRate')
                            input.value =
                              Math.round(
                                ((Number(loan.asIsValue) * 100) /
                                  Number(removeComma(inputs['MedianHomePrice'].value))) *
                                  100,
                              ) / 100
                          if (item === 'ARV2MedianRate')
                            input.value =
                              Math.round(
                                ((Number(loan.afterRepairValue) * 100) /
                                  Number(removeComma(inputs['MedianHomePrice'].value))) *
                                  100,
                              ) / 100

                          return (
                            <div className={`input md:col-span-${input.span || 1}`} key={item}>
                              <RenderInput input={input} Key={item} onChange={onChange} showHistory={showHistory} />
                            </div>
                          )
                        })}
                      </div>
                      {key === 'Source of Borrowers Interest In Subject Property' && (
                        <div className="p-2">{renderRecordingInformation}</div>
                      )}
                    </div>
                  )
                })}

                <div className="flex justify-center">
                  <Button className="w-[240px]" loading={isLoading} onClick={onSave}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
