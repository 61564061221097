import { EyeIcon, EyeSlashIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'
import { Button } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

import { defaultFooterConfigInputs } from './constants'

export const FooterConfiguration = () => {
  const [data, setData] = useState<Record<string, any>>({})
  const [isLoading, setLoading] = useState(false)
  const [changed, setChanged] = useState(false)

  const inputs = defaultFooterConfigInputs()

  useEffect(() => {
    setLoading(true)
    getAdminConfig('footer')
      .then((value) => {
        setData(value || {})
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: any, inputType: string) => {
    const temp = cloneDeep(data)
    if (['togglebutton', 'section'].includes(inputType)) temp[key] = value
    else {
      if (!temp[key]) temp[key] = {}
      temp[key].value = value
    }
    setData(temp)
    setChanged(true)
  }

  const onChangeLink = (key: string, value: any, index: number) => {
    const temp = cloneDeep(data)
    if (!temp.links) return
    temp.links[index][key] = value
    setData(temp)
    setChanged(true)
  }

  const onVisible = (key: string, value: boolean, index?: number) => {
    const temp = cloneDeep(data)
    if (key === 'links') {
      temp[key][index!].visible = value
    } else {
      if (!temp[key]) temp[key] = {}
      temp[key].visible = value
    }
    setData(temp)
    setChanged(true)
  }

  const onSubmit = async () => {
    setLoading(true)
    await setAdminConfig('footer', data)
    setLoading(false)
    setChanged(false)
  }

  const addLink = () => {
    const temp = cloneDeep(data)
    if (!temp.links) temp.links = []
    temp.links.push({ label: `Link ${data.links.length + 1}`, url: '', visible: true })
    setData(temp)
    setChanged(true)
  }

  const removeLink = (index: number) => {
    const temp = cloneDeep(data)
    if (temp.links) temp.links.splice(index, 1)
    setData(temp)
    setChanged(true)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />

      <div className="grid grid-cols-1 md:grid-cols-2 sm:gird-cols-2 gap-3">
        {Object.keys(inputs).map((key) => {
          const input: InputType = inputs[key]
          input.value = data[key]
          let visible = true
          if (!['togglebutton', 'section'].includes(input.inputType)) {
            input.value = data[key]?.value
            visible = !!data[key]?.visible
            input.additionalElements = (
              <span className="cursor-pointer" onClick={() => onVisible(key, !visible)}>
                {visible ? <EyeIcon className="w-4 h-4" /> : <EyeSlashIcon className="w-4 h-4" />}
              </span>
            )
          }

          return (
            <>
              <div className={`col-span-${input.span || 1} ${visible ? 'opacity-100' : 'opacity-30 z-10'}`} key={key}>
                <RenderInput
                  input={input}
                  Key={key}
                  key={key}
                  onChange={(key: string, value: any) => onChange(key, value, input.inputType)}
                />
              </div>
              {key === 'aboutUsDescription' && (
                <>
                  {data.links?.map((link: Record<string, any>, index: number) => {
                    const input: InputType = {
                      inputType: 'text',
                      title: ``,
                      value: link.url,
                    }

                    input.additionalElements = (
                      <>
                        <input
                          className="border-gray-300 border-[1px] px-1"
                          value={link.label}
                          onChange={(e: any) => onChangeLink('label', e.target.value, index)}
                        />
                        <span className="cursor-pointer" onClick={() => onVisible('links', !link.visible, index)}>
                          {!!link.visible ? <EyeIcon className="w-4 h-4" /> : <EyeSlashIcon className="w-4 h-4" />}
                        </span>
                        <span className="cursor-pointer text-red-800" onClick={() => removeLink(index)}>
                          <TrashIcon className="w-4 h-4" />
                        </span>
                      </>
                    )

                    return (
                      <div
                        className={`col-span-${input.span || 1} ${link.visible ? 'opacity-100' : 'opacity-30 z-10'}`}
                        key={index}
                      >
                        <RenderInput
                          input={input}
                          Key={`link-${index}`}
                          key={`link-${index}`}
                          onChange={(key: string, value: any) => onChangeLink('url', value, index)}
                        />
                      </div>
                    )
                  })}
                  <div className="col-span-full">
                    <Button link onClick={addLink}>
                      + Add Link
                    </Button>
                  </div>
                </>
              )}
            </>
          )
        })}
      </div>
      <div className="flex justify-center mt-4">
        <Button onClick={onSubmit} loading={isLoading}>
          Submit
        </Button>
      </div>
      <SaveChanges show={changed} label="Save Changes" onSave={onSubmit} />
    </div>
  )
}
