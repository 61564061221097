import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { submitClosingCostSetup } from 'services'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { closingCostSetupInputs } from './constant'
import { IClosingCostSetup } from './type'

export const lineKeys = [
  ['lineId'],
  ['hudNo', 'hudNoLocked'],
  ['lineName', 'lineNameApplyType', 'lineNameLocked'],
  ['lineNameGFE'],
  ['tridBlock', 'tridBlockApplyType', 'tridBlockLocked'],
  ['gfeBlock', 'gfeBlockLocked'],
  ['type', 'typeLocked'],
  ['paidToType', 'paidToTypeLocked'],
  ['responsiblePartyType', 'responsiblePartyTypeLocked'],
  ['poc'],
  ['ppfc', 'ppfcLocked'],
  ['optional', 'optionalLocked'],
  ['pointsAndFeesDirective'],
  ['nonRefundable'],
  ['netFromWire'],
  ['glCode'],
  ['status'],
  ['notes'],
  ['addTermSheet', 'channels', 'placeholderType'],
]

export const ClosingCostSetupDetails = (props: {
  defaults: IClosingCostSetup | null
  onBack: Function
  onComplete: Function
}) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const { defaults = null } = props

  useEffect(() => {
    if (!defaults) {
      setInputs(closingCostSetupInputs())
      setLoading(false)
    }

    const newStats = cloneDeep(closingCostSetupInputs()) as Record<string, any>
    for (const key in newStats) {
      newStats[key].value = (defaults as any)[key]
      if (key === 'channels' && (newStats[key].value == null || newStats[key].value == undefined))
        newStats[key].value = []
    }
    setInputs(newStats)
  }, [defaults])

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      console.log(error)

      if (!disabled && value !== undefined) {
        if (inputs[key].type == 'number') data[key] = removeComma(value)
        else data[key] = value
      }
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)

    const id = !defaults ? 0 : defaults.id
    const lineId = defaults?.lineId
    if (defaults) {
      delete (defaults as any).id
      delete (defaults as any).lineId
    }
    submitClosingCostSetup(id, data)
      .then(() => {
        if (id == 0) toast('New Closing Cost is added.', { type: 'success' })
        else toast(`Closing Cost ${lineId} is updated.`, { type: 'success' })

        props.onComplete()
        onBack()
      })
      .catch(() => setLoading(false))
  }

  return (
    <div className="ClosingCostSetup-container">
      <LayoutLoading show={isLoading} />
      <Button link onClick={onBack}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-2" /> <p>Return to Closing Cost Setup</p>
        </div>
      </Button>

      {Object.keys(inputs).length &&
        lineKeys.map((line, index) => {
          return (
            <div
              className={`grid md:grid-cols-12 grid-cols-1 gap-4 p-3 ${index % 2 == 0 ? 'bg-slate-100' : ''}`}
              key={index}
            >
              {line.map((key) => {
                if (['channels', 'placeholderType'].includes(key) && !inputs['addTermSheet'].value) return
                const input: InputType = inputs[key]
                const isLock = input.title == 'Lock'
                return (
                  <div
                    className={`input ${input.span ? `md:col-span-${input.span}` : ''} ${
                      isLock && 'md:flex justify-end'
                    }`}
                    key={key}
                  >
                    <RenderInput input={input} Key={key} onChange={onChange} />
                  </div>
                )
              })}
            </div>
          )
        })}
      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          <>{!defaults ? 'Add' : 'Update'}</>
        </Button>
      </div>
    </div>
  )
}
