import { setLoanGroupData } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getServicingNotes, postServicingNotes, updateLoanFields } from 'services'
import { TextArea, Toggle } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { openAuditLog } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

const sections = ['Default Information', 'Additional Information']
export const ServicingNote = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>({})
  const [orgData, setOrgData] = useState<any>({})
  const [order, setOrder] = useState<Array<string>>([])
  const [additionalOrder, setAdditionalOrder] = useState<Array<string>>([])
  const [comment, setComment] = useState('')
  const [orgComment, setOrgComment] = useState('')
  const [templates, setTemplates] = useState<Array<Record<string, any>>>([])

  const dispatch = useDispatch()

  const { loan, loanDetail } = useSelector((state: any) => {
    return {
      loan: state.loan,
      loanDetail: state.loanDetail,
    }
  })

  useEffect(() => {
    setLoading(true)
    setLoanNumber()
    getServicingNotes()
      .then((res: any) => {
        if (res.success) {
          setData(res.info)
          setOrgData(res.info)
          setComment(res.comment)
          setOrgComment(res.comment)
          setOrder(res.order)
          setAdditionalOrder(res.additionalOrder)
          setTemplates(res.templates)
        }
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = async (key: string, value: string) => {
    const temp = cloneDeep(data)
    temp[key].value = value
    setData(temp)
  }

  const onBlur = async (key: string, _value?: any) => {
    const value = data[key].value
    if (!_value && value === orgData[key].value) return
    setLoading(true)
    await postServicingNotes({
      key,
      value: _value || value,
    })
    setOrgData(data)
    setLoading(false)
  }

  const changeComment = (value: string) => {
    setComment(value)
  }

  const onBlurComment = async () => {
    if (comment === orgComment) return
    setLoading(true)
    await postServicingNotes({
      key: 'comment',
      value: comment,
    })
    setOrgComment(comment)
    setLoading(false)
  }

  const showHistory = async (key: string, fieldLabel?: string) => {
    const options = {
      table: 'Servicing Information',
      field: key,
      fieldLabel,
      keys: {
        field: key,
      },
      renderValue: (data: any) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    }
    openAuditLog(options)
  }

  const fillRate = () => {
    const rate = loanDetail.rateData.rate
    const temp = cloneDeep(data)
    let rateKey = ''
    Object.keys(temp).map((key) => {
      if (temp[key].note === 'Original Interest Rate') {
        temp[key].value = rate
        rateKey = key
      }
    })
    setData(temp)
    onBlur(rateKey, rate)
  }

  const onLoanFieldChange = (key: string) => {
    const json: Record<string, any> = { [key]: loan.activeFci ? 0 : 1 }
    updateLoanFields(json).then(() => {
      dispatch(setLoanGroupData(json))
    })
  }

  return (
    <div className="servicing-note-container">
      <LayoutLoading show={loading} />
      <div className="w-full">
        <div className="grid grid-cols-3 gap-2">
          {[order, additionalOrder].map((_order, index: number) => {
            return (
              <>
                <div className="col-span-full">
                  <RenderInput
                    input={{
                      inputType: 'section',
                      title: sections[index],
                    }}
                    Key={`section-${sections[index]}`}
                    onChange={() => {}}
                  />
                </div>
                {_order.map((key, index) => {
                  if (!data[key]) return null
                  let { note, value, type } = data[key]
                  if (value === undefined || value === null) value = ''
                  const input: InputType = {
                    inputType: 'text',
                    type,
                    title: note,
                    value,
                    history: true,
                  }

                  const templateKey = templates.find((item) => item.note === note)?.key
                  if (note === 'Original Interest Rate')
                    input.additionalElements = (
                      <span
                        className="mx-2 text-shade-blue hover:underline cursor-pointer font-variation-settings-600"
                        onClick={fillRate}
                      >
                        <Tooltip message="Fill Original Interest Rate">
                          <span>Fill</span>
                        </Tooltip>
                      </span>
                    )
                  if (['servicerLoanNumber', 'servicerLoanNumber2'].includes(templateKey))
                    input.additionalElements = (
                      <Toggle
                        id="active-fci"
                        title=""
                        value={
                          (templateKey === 'servicerLoanNumber' && loan.activeFci === 0) ||
                          (templateKey === 'servicerLoanNumber2' && loan.activeFci === 1)
                        }
                        onChange={() => onLoanFieldChange('activeFci')}
                      />
                    )
                  return (
                    <div key={index} className="col-span-1">
                      <RenderInput
                        input={input}
                        Key={key}
                        onChange={(key: string, value: any) => onChange(key, value)}
                        onBlur={(key: string) => onBlur(key)}
                        showHistory={() => showHistory(key, note)}
                      />
                    </div>
                  )
                })}
              </>
            )
          })}
        </div>
        <div className="mt-4">
          <TextArea
            title="Comment"
            rows={4}
            value={comment}
            onChange={(v) => changeComment(v)}
            onBlur={onBlurComment}
            showHistory={() => showHistory('comment')}
            history={true}
          />
        </div>
      </div>
    </div>
  )
}
