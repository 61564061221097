import { GlobalConfigType, setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { FieldOrigin, LoanFieldProps } from 'config'
import { store } from 'reducers'
import { InputValidate } from 'utils'
import { removeInvisibleFields } from 'utils/loan'

import { BorrowerSeperator } from '../CreditScore/constants'
import { stepApplicationKeys } from '../logic'
import { defaultInputs } from './constants'

export const visibleBorrowerFieldsLogic = (borrowerSeperator: string) => {
  const { borrower } = store.getState()

  let visibleFields: string[] = [
    'residency',
    'firstName',
    'middleName',
    'lastName',
    'email',
    'ssn',
    'phone',
    'dob',
    'presentAddress',
    'mailingAddress',
    'ownership',
    'ownedRentedYears',
    'maritalStatus',
    'hasEntityTitle',
  ]

  if (borrowerSeperator === BorrowerSeperator.BORROWER) visibleFields.push('titleVesting')

  if (borrower[borrowerSeperator].hasEntityTitle) {
    if (borrower[borrowerSeperator].borrowerType !== 'Individual') {
      visibleFields.push('borrowerPosition')
    }
    visibleFields.push('borrowerType')
    visibleFields.push('ownershipPercentage')
    visibleFields.push('entityTitle')
    visibleFields.push('entityAddress')
    visibleFields.push('entityTaxID')
    visibleFields.push('entityState')
  }

  if (borrower[borrowerSeperator].ownedRentedYears === '1') {
    visibleFields.push('formerAddress')
    visibleFields.push('formerOwnership')
    visibleFields.push('formerOwnedRentedYears')
  }

  return removeInvisibleFields(FieldOrigin.BorrowerInformation, visibleFields)
}

export const borrowerInfoValidate = (
  borrowerSeperator: string,
  visibles: any,
  fields: any,
  validateOnly = false,
  updateStore = false,
  afterRemoved = false,
) => {
  try {
    const { borrower, step, globalConfigReducer, loanDetail } = store.getState()
    const loanFieldProperties = globalConfigReducer[GlobalConfigType.LoanFields] as LoanFieldProps
    if (((loanFieldProperties as any)['visibility'] || {})[FieldOrigin.BorrowerInformation] === false) {
      if (validateOnly) return true
      else return {}
    }
    if (validateOnly) {
      visibles = visibleBorrowerFieldsLogic(borrowerSeperator)
      fields = defaultInputs()
    }
    const fieldProps = loanFieldProperties[FieldOrigin.BorrowerInformation]

    let success = true
    fields = cloneDeep(fields)
    Object.keys(fields).map((key) => {
      if (visibles.indexOf(key) !== -1) {
        if (fieldProps && fieldProps[key] && fieldProps[key]?.required !== undefined)
          fields[key].required = fieldProps[key].required

        const error = InputValidate({ ...fields[key], value: borrower[borrowerSeperator][key] })
        fields[key].error = error
        if (error.length > 0) {
          success = false
        }
        if (fieldProps && fieldProps[key] && fieldProps[key].tooltip) fields[key].tooltip = fieldProps[key].tooltip
      }
    })

    if (borrowerSeperator === 'borrower') {
      if (borrower[borrowerSeperator].hasEntityTitle === true) {
        if (borrower[borrowerSeperator].borrowerType === 'Individual') {
          fields['borrowerType'].error = `Can not be 'Individual' when Has Entity Name.`
          success = false
        }
      }

      if (Number(loanDetail.rateData?.price) > 0) {
        ;['borrowerType', 'residency'].map((key) => {
          fields[key].disabled = true
          fields[key].tooltip = [
            'After selecting a price, you can only change it on the loan structure page.',
            fields[key].tooltip,
          ]
            .filter((v) => !!v)
            .join('\n\n')
        })
      }
    }

    if (fields['dob'].value) {
      const dob = new Date(fields['dob'].value)
      const now = new Date()
      if (now.getFullYear() - dob.getFullYear() < 18) fields['dob'].error = 'Borrower must be at least 18 years old.'
    }

    if (afterRemoved) success = false
    if (updateStore) {
      let application = cloneDeep(step.application)
      const key = stepApplicationKeys[borrowerSeperator].borrower
      application[key] = success ? 1 : -1
      store.dispatch(setApplicationStep(application))
    }
    if (validateOnly) return success

    console.log('Before Finish', fields['titleVesting'].value)
    return fields
  } catch (e) {}
}
