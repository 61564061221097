import { EyeIcon, EyeSlashIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { setMenu } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAdminConfig, setAdminConfig } from 'services'
import { confirm, convertToTitleCase } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { InterfaceAddSectionModal } from './InterfaceAddSectionModal'

interface InterfaceItem {
  title: string
  link?: string
  value: boolean
  order: number
  internal?: boolean
}

export const Interface = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Record<string, Record<string, InterfaceItem>>>({})
  const [selectedPortal, setSelectedPortal] = useState<string>('')
  const [editData, setEditData] = useState<{ key: string; data: InterfaceItem } | undefined>(undefined)

  const initData = async () => {
    setIsLoading(true)
    const res = await getAdminConfig('interface')

    // Define portal order
    const portalOrder = ['homePortal', 'brokerPortal', 'borrowerPortal', 'investorPortal']

    // Create new ordered object
    const orderedRes: Record<string, Record<string, InterfaceItem>> = {}
    portalOrder.forEach((portal) => {
      if (res[portal]) {
        Object.keys(res[portal]).map(
          (key) => (res[portal][key].internal = !res[portal][key].link?.startsWith('https://')),
        )
        orderedRes[portal] = res[portal]
      }
    })

    // Add any remaining portals not in order list
    Object.keys(res).forEach((portal) => {
      if (!portalOrder.includes(portal)) {
        orderedRes[portal] = res[portal]
      }
    })

    // Initialize order if not present
    Object.keys(orderedRes).forEach((section) => {
      const items = Object.keys(orderedRes[section])
      items.forEach((key, idx) => {
        if (!orderedRes[section][key].order) {
          orderedRes[section][key].order = idx + 1
        }
      })
    })

    setData(orderedRes)
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  const onClose = () => {
    initData()
    setSelectedPortal('')
    setEditData(undefined)
  }

  const onChange = async (
    key: string,
    subjKey: string,
    value: boolean | number,
    field: 'value' | 'order' = 'value',
  ) => {
    const temp = cloneDeep(data)

    if (field === 'order') {
      // Update orders when an item's order changes
      const currentOrder = temp[key][subjKey].order
      const newOrder = value as number

      Object.keys(temp[key]).forEach((itemKey) => {
        const itemOrder = temp[key][itemKey].order
        if (newOrder > currentOrder) {
          if (itemOrder > currentOrder && itemOrder <= newOrder) {
            temp[key][itemKey].order--
          }
        } else {
          if (itemOrder >= newOrder && itemOrder < currentOrder) {
            temp[key][itemKey].order++
          }
        }
      })
      temp[key][subjKey].order = newOrder
    } else {
      temp[key][subjKey].value = value as boolean
    }

    setIsLoading(true)
    await setAdminConfig('interface', temp)
    dispatch(setMenu(temp))
    setIsLoading(false)
    setData(temp)
  }

  const onDelete = async (section: string, item: any) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure to remove this section?
        <br />
      </div>
    )
    const result = await confirm(content)
    if (!result) {
      return
    } else {
      const temp = cloneDeep(data)
      const deletedOrder = temp[section][item.key].order
      delete temp[section][item.key]

      // Reorder remaining items
      Object.keys(temp[section]).forEach((key) => {
        if (temp[section][key].order > deletedOrder) {
          temp[section][key].order--
        }
      })

      setData(temp)
      setAdminConfig('interface', temp)
      dispatch(setMenu(temp))
    }
  }

  const getSortedItems = (section: string) => {
    return Object.entries(data[section])
      .map(([key, value]) => ({ key, ...(value as InterfaceItem) }))
      .sort((a, b) => a.order - b.order)
  }

  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />
      {Object.keys(data).map((section) => {
        const sectionTitle = convertToTitleCase(section)
        const items = getSortedItems(section)
        const maxOrder = items.length

        return (
          <div className="mb-4" key={section}>
            <RenderInput
              input={{
                inputType: 'section',
                title: sectionTitle,
                additionalElements: (
                  <div className="flex gap-2">
                    <p className="btn-icon" onClick={() => setSelectedPortal(section)}>
                      <PlusIcon className="w-4 h-4" />
                    </p>
                  </div>
                ),
              }}
              Key={sectionTitle}
              onChange={() => {}}
            />
            {items.map((item: any, index: number) => {
              return (
                <div
                  className={`flex items-center justify-between pr-0 pl-2 py-1 ${index % 2 && 'bg-slate-100'} ${
                    item.value === false && 'opacity-60'
                  }`}
                  key={item.key}
                >
                  <div>
                    <div className="mt-1 flex items-center gap-4">
                      <>
                        <p className="mb-2">{item.title}</p>
                        <p
                          className="mb-2 underline cursor-pointer text-shade-blue"
                          onClick={() => {
                            if (item.link && item.link.length > 0) window.open(item.link)
                          }}
                        >
                          {item.link ?? ''}
                        </p>
                      </>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <select
                      className="py-1 border-gray-300 text-[15px]"
                      value={item.order}
                      onChange={(e) => onChange(section, item.key, parseInt(e.target.value), 'order')}
                    >
                      {Array.from({ length: maxOrder }, (_, i) => i + 1).map((num) => (
                        <option key={num} value={num} disabled={num === item.order}>
                          {num}
                        </option>
                      ))}
                    </select>
                    <span
                      className="btn-icon cursor-pointer"
                      onClick={() => {
                        setSelectedPortal(section)
                        setEditData({ key: item.key, data: item })
                      }}
                    >
                      <PencilSquareIcon className="w-4 h-4" />
                    </span>
                    <span className="btn-icon cursor-pointer" onClick={() => onChange(section, item.key, !item.value)}>
                      {item.value ? <EyeIcon className="w-4 h-4" /> : <EyeSlashIcon className="w-4 h-4" />}
                    </span>

                    <p className="btn-icon !text-red-400" onClick={() => onDelete(section, item)}>
                      <TrashIcon className="w-4 h-4" />
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
      {selectedPortal !== '' && (
        <InterfaceAddSectionModal
          portal={selectedPortal}
          origin={data}
          onClose={() => onClose()}
          editData={editData}
          isEdit={!!editData}
        />
      )}
    </div>
  )
}
