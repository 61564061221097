import { TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { loanStatusConstants } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getAdminConfig, setAdminConfig } from 'services'
import { Button } from 'stories/components'
import { confirm } from 'utils'
import { RenderInput } from 'utils/RenderInput'

const defaultData = { nonServicing: [], servicing: [] }

enum BubbleType {
  nonServicing = 'nonServicing',
  servicing = 'servicing',
  borrower = 'borrower',
}
export const Bubbles = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Record<string, any>>(defaultData)
  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])

  let defaultNonServicingStatus: Record<string, boolean> = {},
    defaultServicingStatus: Record<string, boolean> = {}
  Object.keys(loanGlobalStatus).map((v: string) => {
    defaultNonServicingStatus[v] = false
  })
  Object.keys(loanStatusConstants.servicingStatus).map((v: string) => {
    defaultServicingStatus[v] = false
  })

  const initData = async () => {
    setIsLoading(true)
    const res = await getAdminConfig('bubbles')
    setData(res)
    setIsLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  const onChange = (type: BubbleType, index: number, key: string, value: any) => {
    const temp = cloneDeep(data)
    if (key == 'order') {
      temp[type].map((item: any) => {
        if (item.order >= value && item.order < temp[type][index].order)
          item.order = (Number(item.order) + 1).toString()
        else if (item.order > temp[type][index].order && item.order <= value)
          item.order = (Number(item.order) - 1).toString()
      })
    }
    temp[type][index][key] = value
    setData(temp)
  }

  const addBubble = (type: BubbleType) => {
    const temp = cloneDeep(data)
    temp[type].push({ status: {}, color: '#000000', order: data[type].length + 1 })
    setData(temp)
  }

  const onDelete = async (type: BubbleType, index: number) => {
    const res: any = await confirm('Do you want to delete this bubble?')
    if (res === false) return
    const temp = cloneDeep(data)
    temp[type].splice(index, 1)
    for (let i = index; i < temp[type].length; i++) temp[type][i].order = temp[type][i].order - 1
    setData(temp)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    await setAdminConfig('bubbles', data)
    toast('Successfully submitted', { type: 'success' })
    setIsLoading(false)
  }

  const renderStatusConfig = (statusData: Record<string, any>, type: BubbleType) => {
    const isServicing = type == 'servicing'
    return (
      <>
        {statusData
          ?.sort((a: any, b: any) => (Number(a.order) > Number(b.order) ? 1 : -1))
          .map((item: Record<string, any>, index: number) => {
            return (
              <div className="flex items-center justify-between gap-4 mb-4">
                <span>{index + 1}.</span>
                <div className="flex-1 grid grid-cols-3 gap-4">
                  <div className="col-span-1">
                    <RenderInput
                      input={{
                        inputType: 'multiselect',
                        title: 'Loan Status',
                        options: isServicing ? loanStatusConstants.servicingStatus : loanGlobalStatus,
                        value: {
                          ...(isServicing ? defaultServicingStatus : defaultNonServicingStatus),
                          ...item.status,
                        },
                      }}
                      onChange={(key: string, value: any) => onChange(type, index, key, value)}
                      Key="status"
                    />
                  </div>
                  <div className="col-span-1">
                    <RenderInput
                      input={{ inputType: 'text', title: 'Color', type: 'color', value: item.color }}
                      Key="color"
                      onChange={(key: string, value: any) => onChange(type, index, key, value)}
                    />
                  </div>
                  <div className="col-span-1">
                    <RenderInput
                      input={{
                        inputType: 'select',
                        title: 'Order',
                        options: statusData.map((v: any, index: number) => (index + 1).toString()),
                        value: item.order,
                      }}
                      Key="order"
                      onChange={(key: string, value: any) => onChange(type, index, key, value)}
                    />
                  </div>
                </div>
                <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(type, index)}>
                  <TrashIcon className="w-4 h-4"></TrashIcon>
                </span>
              </div>
            )
          })}
      </>
    )
  }
  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />

      <div className="text-xl font-bold mb-4">Bubbles</div>
      <div className="mb-4">
        <RenderInput
          input={{ inputType: 'section', title: 'Origination Pipeline' }}
          Key={'non-servicing'}
          onChange={onChange}
        />
      </div>
      {renderStatusConfig(data.nonServicing, BubbleType.nonServicing)}
      <Button link onClick={() => addBubble(BubbleType.nonServicing)}>
        Click here to add
      </Button>
      <div className="mb-4">
        <RenderInput
          input={{ inputType: 'section', title: 'Servicing Pipeline' }}
          Key={'servicing'}
          onChange={onChange}
        />
      </div>
      {renderStatusConfig(data.servicing, BubbleType.servicing)}
      <Button link onClick={() => addBubble(BubbleType.servicing)}>
        Click here to add
      </Button>
      <div className="mb-4">
        <RenderInput
          input={{ inputType: 'section', title: 'Borrower Pipeline ' }}
          Key={'borrower'}
          onChange={onChange}
        />
      </div>
      {renderStatusConfig(data.borrower, BubbleType.borrower)}
      <Button link onClick={() => addBubble(BubbleType.borrower)}>
        Click here to add
      </Button>
      <div className="flex justify-center mt-4">
        <Button onClick={onSubmit} loading={isLoading}>
          Submit
        </Button>
      </div>
    </div>
  )
}
