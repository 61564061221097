import {
  ClosingCostApplyType,
  ClosingCostsMap,
  ClosingCostStatus,
  GFEBlockMap,
  InputType,
  PaidToMap,
  PointsAndFeesMap,
  ResponsiblePartyMap,
  TridBlockMap,
} from 'config'
import { loanTypeMap } from 'reducers/loanDetail.reducer'

import { ClosingCostPlaceholderMap } from './type'

export const closingCostSetupInputs = (): Record<string, InputType> => {
  return {
    lineId: {
      inputType: 'text',
      title: 'Line ID',
      value: '',
      error: '',
      disabled: true,
      key: 'lineId',
      span: '6',
    },
    hudNo: {
      inputType: 'text',
      title: 'HUD No',
      type: 'number',
      value: '',
      error: '',
      required: true,
      key: 'hudNo',
      span: '6',
    },
    hudNoLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'hudNoLocked',
      required: true,
      span: '6',
    },
    lineName: {
      inputType: 'text',
      title: 'Line Name',
      value: '',
      error: '',
      key: 'lineName',
      span: '6',
    },
    lineNameLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'lineNameLocked',
      required: true,
      span: '3',
    },
    lineNameApplyType: {
      title: 'Apply Type',
      inputType: 'Select',
      type: 'number',
      options: ClosingCostApplyType,
      value: '',
      error: '',
      key: 'lineNameApplyType',
      required: true,
      hasDefaultOption: true,
      span: '3',
    },
    lineNameGFE: {
      inputType: 'text',
      title: 'Line Name - GFE',
      value: '',
      error: '',
      key: 'lineName',
      span: '6',
    },
    tridBlock: {
      title: 'TRID Block',
      inputType: 'Select',
      type: 'number',
      options: TridBlockMap,
      value: '',
      error: '',
      key: 'tridBlock',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    tridBlockLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'tridBlockLocked',
      required: true,
      span: '3',
    },
    tridBlockApplyType: {
      title: 'Apply Type',
      inputType: 'Select',
      type: 'number',
      options: ClosingCostApplyType,
      value: '',
      error: '',
      key: 'tridBlockApplyType',
      required: true,
      hasDefaultOption: true,
      span: '3',
    },
    gfeBlock: {
      title: 'GFE Block',
      inputType: 'Select',
      type: 'number',
      options: GFEBlockMap,
      value: '',
      error: '',
      key: 'gfeBlock',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    gfeBlockLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'gfeBlockLocked',
      required: true,
      span: '6',
    },
    type: {
      title: 'Type',
      inputType: 'Select',
      type: 'number',
      options: ClosingCostsMap,
      value: '',
      error: '',
      key: 'type',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    typeLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'typeLocked',
      required: true,
      span: '6',
    },
    paidToType: {
      title: 'Paid to',
      inputType: 'Select',
      type: 'number',
      options: PaidToMap,
      value: '',
      error: '',
      key: 'paidToType',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    paidToTypeLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'paidToTypeLocked',
      required: true,
      span: '6',
    },
    responsiblePartyType: {
      title: 'Responsible Party',
      inputType: 'Select',
      type: 'number',
      options: ResponsiblePartyMap,
      value: '',
      error: '',
      key: 'responsiblePartyType',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    responsiblePartyTypeLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'responsiblePartyTypeLocked',
      required: true,
      span: '6',
    },
    poc: {
      title: 'POC',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'poc',
      required: true,
      span: '6',
    },
    ppfc: {
      title: 'PPFC',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'ppfc',
      required: true,
      span: '6',
    },
    ppfcLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'ppfcLocked',
      required: true,
      span: '6',
    },
    optional: {
      title: 'Optional',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'optional',
      required: true,
      span: '6',
    },
    optionalLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'optionalLocked',
      required: true,
      span: '6',
    },
    pointsAndFeesDirective: {
      title: 'Points and Fees Directive',
      inputType: 'Select',
      type: 'number',
      options: PointsAndFeesMap,
      value: '',
      error: '',
      key: 'pointsAndFeesDirective',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    nonRefundable: {
      title: 'Non-Refundable',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'nonRefundable',
      required: true,
      span: '6',
    },
    netFromWire: {
      title: 'Net From Wire',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'netFromWire',
      required: true,
      span: '6',
    },
    glCode: {
      inputType: 'text',
      title: 'GL Code',
      value: '',
      error: '',
      key: 'glCode',
      span: '6',
    },
    status: {
      title: 'Status',
      inputType: 'Select',
      type: 'number',
      options: ClosingCostStatus,
      value: '',
      error: '',
      key: 'status',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    notes: {
      inputType: 'textarea',
      value: '',
      error: '',
      key: 'notes',
      rows: 3,
      title: 'Notes',
      span: 'full',
    },
    addTermSheet: {
      inputType: 'togglebutton',
      title: 'Add to Term Sheet',
      span: 3,
    },
    channels: {
      inputType: 'multiselect',
      title: 'Channel',
      options: loanTypeMap,
      span: 3,
    },
    placeholderType: {
      inputType: 'select',
      title: 'Placeholder Type',
      options: ClosingCostPlaceholderMap,
      span: 3,
    },
  }
}
