import { CheckIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getSitexProProperty, getSitexProReport } from 'services'
import { Button, Modal } from 'stories/components'
import { getItemsFromFullAddress, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    type: {
      title: 'Address Type',
      inputType: 'select',
      value: 'propertyAddress',
      error: '',
      required: true,
      options: {
        propertyAddress: 'Property Address',
        mailingAddress: 'Mailing Address',
        apn: 'APN',
        // intersection: 'Intersection',
        ownerName: 'Owner Name',
        googleMapSearch: 'Google Map Search',
      },
      visible: true,
    },
    info1: {
      title: 'Enter House #, Street Name, Unit #',
      inputType: 'text',
      value: '',
      required: true,
      visible: true,
    },
    info2: {
      title: 'Enter City, County, State or ZIP (FIPS for APN search)',
      inputType: 'text',
      value: '',
      required: true,
      visible: true,
    },
    address: {
      title: 'Address',
      inputType: 'map',
      value: '',
      required: true,
      visible: false,
    },
  }
}

const titleMap: Record<string, string> = {
  propertyAddress: 'Enter House #, Street Name, Unit #',
  mailingAddress: 'Enter House #, Street Name, Unit #',
  apn: 'Enter an APN',
  intersection: 'Enter an Intersection',
  ownerName: 'Enter Owner Name',
  googleMapSearch: 'Enter search criteria',
}

interface ISitexProLocation {
  FIPS: string
  APN: string
  Address: string
  City: string
  State: string
  ZIP: string
  ZIP4: string
  UnitType: string
  UnitNumber: string
  UseCode: string
  UseCodeDescription: string
  Owner: string
}

export const SitexProModal = ({ address, onClose }: { address: string; onClose: Function }) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [locations, setLocations] = useState<ISitexProLocation[] | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  useEffect(() => {
    getItemsFromFullAddress(address).then((addrObj) => {
      if (!addrObj) return

      const newInputs = cloneDeep(defaultInputs())
      newInputs.info1.value = addrObj.street_address1 || ''
      newInputs.info2.value = addrObj.postal_code || ''
      newInputs.address.value = address
      setInputs(newInputs)
    })
  }, [address])

  const onChangeInputs = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    if (key === 'type') {
      const showGoogleMap = value === 'googleMapSearch'
      newInputs.info1.visible = !showGoogleMap
      newInputs.info2.visible = !showGoogleMap
      newInputs.address.visible = showGoogleMap

      newInputs.info1.title = titleMap[value]
      newInputs.info1.value = ''
    }
    setInputs(newInputs)
  }

  const getInputData = () => {
    const inputData: Record<string, any> = {}
    for (const key in inputs) {
      const { value, visible } = inputs[key]
      if (!visible) continue

      if (value !== undefined) inputData[key] = value
    }
    return inputData
  }
  const onSearch = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const inputData: Record<string, any> = {}
    for (const key in newStats) {
      const { value, visible } = newStats[key]
      if (!visible) continue
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (value !== undefined) inputData[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)
    const { success, locations } = (await getSitexProProperty(inputData)) as {
      success: boolean
      locations: ISitexProLocation[]
    }
    if (success) setLocations(locations)
    setLoading(false)
    setSelectedIndex(-1)
  }

  const onOk = async () => {
    if (!locations || !locations[selectedIndex]) return

    let inputData = getInputData()
    setLoading(true)
    const location = locations[selectedIndex]
    let newAddress = `${location.Address} ${location.UnitType} ${location.UnitNumber}`.trim()
    if (locations.length == 1) newAddress = inputs.info1.value
    else {
      const hasSameLocations = locations.filter((v) => v.Address == location.Address).length >= 2
      if (hasSameLocations) {
        inputData = {
          type: 'apn',
          info1: location.APN,
          info2: location.FIPS,
          address: newAddress,
          addr: newAddress,
          owner: location.Owner,
        }
      }
    }

    const { success, locations: _locations } = await getSitexProReport(inputData)
    setLoading(false)

    if (success) {
      toast(`We've started to generate SitexPro Report and it will take few seconds.`, { type: 'info' })
      onClose()
      return
    }
    if (_locations) setLocations(_locations)
  }

  return (
    <Modal
      isOpen
      loading={isLoading}
      title="Pull Sitexpro Report"
      titleOkay="Pull Report"
      disabled={selectedIndex === -1}
      onOk={onOk}
      onClose={() => onClose()}
    >
      <div className={`text-gray-600 text-md w-120`}>
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          if (!input.visible) return null
          return (
            <div key={index} className="input mb-4">
              <RenderInput input={input} Key={key} onChange={onChangeInputs} />
            </div>
          )
        })}
        <div className="flex flex-col w-full items-end -mt-6">
          <Button link onClick={onSearch}>
            Search
          </Button>
        </div>

        {!!locations && locations.length == 0 && (
          <p className="text-gray-400 text-sm text-center">- The address is not available. -</p>
        )}

        {!!locations &&
          locations.map((item, index: number) => (
            <div
              className={`border rounded-md overflow-hidden p-4 ${
                index != locations.length - 1 && 'mb-4'
              } cursor-pointer hover:border-shade-blue ${selectedIndex == index ? 'border-shade-blue' : ''}`}
              onClick={() => {
                setSelectedIndex(index)
              }}
              key={index}
            >
              <div className="flex justify-between items-center">
                <p className="font-semibold">
                  {index + 1}. {item.Owner} - {item.Address} {item.UnitType} {item.UnitNumber}
                </p>
                {selectedIndex == index ? <CheckIcon className="w-5 h-5 text-shade-blue" /> : null}
              </div>
              <p className="text-xs mb-1 underline">
                {item.APN}, {item.FIPS}, {item.UseCodeDescription}
              </p>
            </div>
          ))}
      </div>
    </Modal>
  )
}
