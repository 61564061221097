import { setGlobalConfig } from 'actions/global.config.action'
import { GlobalConfigType } from 'actions/global.config.type'
import { setLoanGlobalStatus, setMenu } from 'actions/loan.action'
import { setVendor } from 'actions/loanDetail.action'
import { LayoutLoading } from 'components/LayoutLoading'
import { BaseLayout } from 'layouts'
import { setLoanFieldDefaultSection } from 'pages/Admin/AdminTools/Configuration/LoanFieldsProperties/utils'
import { Suspense, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useDispatch } from 'react-redux'
import { Switch } from 'react-router-dom'
import { BaseRoute, TemporaryRoute } from 'routes'
import { getAdminConfig } from 'services/apis'
import { phoneConvertor } from 'utils'
const queryClient = new QueryClient()

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    getAdminConfig('appInit').then((data) => {
      const {
        loanStatus,
        menu,
        vendorDetail,
        pagePermissions,
        footerConfig,
        acceptedFileTypes,
        resourceVisibleAccountTypes,
        conditionTypeOptions,
        documentCategoryOptions,
        globalSettingV2,
        loanFieldProperties,
      } = data
      let loanStatusObj: any = {}
      Object.keys(loanStatus).map((key) => {
        if (loanStatus[key].use) loanStatusObj[key] = loanStatus[key].new
      })
      dispatch(setLoanGlobalStatus(loanStatusObj))
      dispatch(setMenu(menu))
      dispatch(setVendor(vendorDetail))
      dispatch(setGlobalConfig(GlobalConfigType.ConditionTypes, conditionTypeOptions))
      dispatch(setGlobalConfig(GlobalConfigType.DocumentCategory, documentCategoryOptions))
      dispatch(setGlobalConfig(GlobalConfigType.PagePermissions, pagePermissions))
      footerConfig.phone.value = phoneConvertor(footerConfig.phone.value)
      footerConfig.tollFree.value = phoneConvertor(footerConfig.tollFree.value)
      dispatch(setGlobalConfig(GlobalConfigType.Footer, footerConfig))
      dispatch(setGlobalConfig(GlobalConfigType.AcceptedFileTypes, acceptedFileTypes))
      dispatch(setGlobalConfig(GlobalConfigType.ResourceVisibility, resourceVisibleAccountTypes))
      dispatch(setGlobalConfig(GlobalConfigType.GlobalSettingV2, globalSettingV2))
      dispatch(setGlobalConfig(GlobalConfigType.LoanFields, setLoanFieldDefaultSection(loanFieldProperties)))
    })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LayoutLoading show={true} />}>
        <Switch>
          {TemporaryRoute}
          <BaseLayout>
            <BaseRoute />
          </BaseLayout>
        </Switch>
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
