import { GlobalConfigType } from 'actions'
import cloneDeep from 'clone-deep'
import { conditionInputs, nonADHocConditionKeys } from 'pages/Admin/ConditionsAndTemplates/constant'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { getOptionValues } from 'utils/general'
import { RenderInput } from 'utils/RenderInput'

export const AddHoc = ({ onAfterSubmit = () => {}, ...props }: any) => {
  const [conditionTypeOptions] = useSelector((state: any) => {
    return [state.globalConfigReducer[GlobalConfigType.ConditionTypes]]
  })

  const [inputStates, setInputStates] = useState<Record<string, any>>(conditionInputs())
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())

  const onCloseUserModal = () => {
    let newState = conditionInputs()
    setInputStates(newState)
  }

  const onChange = async (key: string, value: string | boolean) => {
    let newState = cloneDeep(inputStates)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setInputStates(newState)
  }

  const onSubmit = async () => {
    let hasError = false
    const newStats = cloneDeep(inputStates)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (!disabled && value !== undefined) data[key] = value
    }
    if (hasError) {
      setInputStates(newStats)
      return
    }
    setLoading(true)
    const result = await onAfterSubmit(data)
    setLoading(false)
    if (!result) return
    setIsOpen(false)
    setLastUpdatedAt(Date.now())
  }

  return (
    <Modal
      button={
        props.type === 'exception' ? (
          <span className="hover:text-shade-blue cursor-pointer text-[15px] underline">Ad-Hoc</span>
        ) : (
          <Button outline>Ad-Hoc</Button>
        )
      }
      title={'Add Condition'}
      titleOkay="Add"
      isOpen={isOpen}
      lastUpdatedAt={lastUpdatedAt}
      onClose={onCloseUserModal}
      onOk={onSubmit}
      loading={loading}
    >
      <div className="grid gap-4 md:grid-cols-2">
        {Object.keys(inputStates).map((key: any, index) => {
          const input = inputStates[key]
          if (input.visible === false) return null
          if (nonADHocConditionKeys.includes(key)) return null
          if (key == 'type') {
            ;(input as any).options = getOptionValues(conditionTypeOptions)
          }

          return (
            <div className={`input ${key == 'description' ? 'md:col-span-2' : 'w-80'}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
