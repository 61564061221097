export type LoanStatusRestrictionType =
  | 'structure'
  | 'application'
  | 'submission'
  | 'overview'
  | 'underwriting'
  | 'tasks'
  | 'budget'

export const LoanStatusRestrictionTypes: LoanStatusRestrictionType[] = [
  'structure',
  'application',
  'submission',
  'overview',
  'underwriting',
  'tasks',
  'budget',
]

export const LoanStatusRestrictionTextMap: Record<string, string> = {
  // structure
  interestRateBasePrice: `Interest Rate and Base Price`,
  loanAmountMaxLimit: `The Loan Amount exceeds the Maximum Limit`,
  mustBeLocked: `Must be Locked`,
  lockExpired: `Lock Expired. Relock is required!`,

  // application
  atLeastExpTrackRecords: `At least [Experience] Track Records`,
  pendingAssets: `Pending Assets Verification`,

  // submission
  unclearedPTD: `Uncleared PTD Conditions exist.`,
  unclearedPTF: `Uncleared PTF Conditions exist.`,
  unclearedPTDInternal: `Uncleared PTD - Internal Conditions exist.`,
  unclearedLoanIntake: 'Uncleared Loan Intake Conditions exist.',
  loanClosingDate: `Loan Closing Date`,

  // overview
  party: `Party -> [Errors]`,

  // underwriting
  expDateInfo: `Expiration Date Information`,
  summaryInfo: `Underwriting Summary Information`,

  // tasks
  loanChangeRequest: 'Loan Change Request',
  revalidationReviewLockDesk: 'Revalidation Review LockDesk',
  revalidationReviewUnderwriter: 'Revalidation Review Underwriter',
  exceptionReviewRequest: 'UW Loan Structure Exception Review Request',
  loanStructureChangeRequest: 'Loan Structure Change Request',
  rateLock: 'Initial Rate Lock',
  reLockRate: 'ReLock Rate',

  // budget
  estimateNotMatch: `Construction Budget and Grand Total of Rehab Cost Borrower Estimated do not match`,
}

export interface LoanStatusRestriction {
  key: string
  loanStatus: string[]
  enabled: boolean
}

export type LoanStatusRectrictionConfigType = Record<LoanStatusRestrictionType, LoanStatusRestriction[]>
