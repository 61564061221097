import cloneDeep from 'clone-deep'
import { useRef, useState } from 'react'
import { addLoanTermSheetField } from 'services'
import { Modal } from 'stories/components'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { type ILoanTermSheetTemp, fieldsInputs, TermSheetPlaceholders } from './constants'
import { validatePlaceholders } from './logic'

interface IProps {
  data: ILoanTermSheetTemp
  additionalPlaceholders?: Array<string>
  onAddField: (value: ILoanTermSheetTemp) => void
  onClose: () => void
}
export const AddTermSheetField = (props: IProps) => {
  const { data, onClose } = props

  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(fieldsInputs())
  const [cursorPos, setCursorPos] = useState(0)
  const [focusKey, setFocusKey] = useState('')

  const activeInputRef = useRef(null)

  const onChange = async (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const newInputs = cloneDeep(inputs)
    let hasError = false
    const reqBody: Record<string, any> = {}
    Object.keys(newInputs).forEach((key) => {
      reqBody[key] = newInputs[key].value.trim()
      newInputs[key].error = InputValidate(newInputs[key])
      if (newInputs[key].error) hasError = true
    })

    const isInvalidPlaceholder = validatePlaceholders(
      newInputs.content?.value || '',
      props.additionalPlaceholders || [],
    )

    if (isInvalidPlaceholder) {
      hasError = isInvalidPlaceholder
      newInputs.content.error = 'Invalid placeholders exist'
    }
    setInputs(newInputs)

    if (hasError) {
      return
    }

    setLoading(true)
    try {
      await addLoanTermSheetField(data.id, reqBody)
      setLoading(false)
      data.fields.push({
        title: reqBody.title,
        content: reqBody.content,
      })
      props.onAddField(data)
      onClose()
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handleFocus = (event: any, key: string) => {
    activeInputRef.current = event.target
    setCursorPos(event.target.selectionStart)
    setFocusKey(key)
  }

  const handleCursorChange = (event: any) => {
    setCursorPos(event.target.selectionStart)
  }

  const insertTextAtCursor = (text: string) => {
    const input: any = activeInputRef.current
    if (!input) return

    const value = input.value
    const newValue = value.substring(0, cursorPos) + text + value.substring(cursorPos)

    onChange(focusKey, newValue)

    setTimeout(() => {
      input.focus()
      input.setSelectionRange(cursorPos + text.length, cursorPos + text.length)
    }, 0)
  }

  return (
    <Modal
      title={`Add a new field`}
      titleOkay="Add"
      loading={isLoading}
      isOpen={true}
      onClose={onClose}
      onOk={onSubmit}
    >
      <div className="w-full md:w-[70vw]">
        <div className="mb-6">
          <p className="ml-3 text-lg font-medium mb-2">- Placeholders</p>
          <div className="p-3 border rounded-md">
            <div className="flex gap-x-1 gap-y-2 flex-wrap justify-between">
              {[...TermSheetPlaceholders, ...(props.additionalPlaceholders || [])].sort().map((item, index) => {
                return (
                  <pre key={index} className="w-[240px]">
                    <span className="cursor-pointer hover:underline" onClick={() => insertTextAtCursor(`{{${item}}}`)}>
                      {item}
                    </span>
                  </pre>
                )
              })}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]

            return (
              <div className={`col-span-${input.span} content-center`} key={index}>
                <RenderInput
                  input={input}
                  Key={key}
                  onChange={onChange}
                  onFocus={handleFocus}
                  onKeyUp={handleCursorChange}
                  onClick={handleCursorChange}
                />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
